import React from 'react';

interface IconCloseProps {
  size: string;
  color?: string;
}

const IconClose = React.memo(
  ({ size = '16px', color = '#ffffff' }: IconCloseProps) => (
    <svg
      id="icon-close"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26863 9L0.38065 2.11202C-0.773596 0.957773 0.957773 -0.773596 2.11202 0.38065L9 7.26863L15.888 0.38065C17.0422 -0.773596 18.7736 0.957773 17.6194 2.11202L10.7314 9L17.6194 15.888C18.7736 17.0422 17.0422 18.7736 15.888 17.6194L9 10.7314L2.11202 17.6194C0.957773 18.7736 -0.773596 17.0422 0.38065 15.888L7.26863 9Z"
        fill={color}
      />
    </svg>
  ),
);

export default IconClose;
export { IconClose };
