import * as types from './types';

// Get all brazillian state request
export const getBrStatesRequest = () => ({
  type: types.GET_BR_STATES_REQUEST,
});
export const getBrStatesSuccess = (payload) => ({
  type: types.GET_BR_STATES_SUCCESS,
  payload,
});
export const getBrStatesFailure = () => ({
  type: types.GET_BR_STATES_FAILURE,
});

export const getBrStatesMock = () => ({
  type: types.GET_BR_STATES_MOCK,
});

// Get city request
export const getBrCitiesRequest = ({ params }) => ({
  type: types.GET_BR_CITIES_REQUEST,
  payload: { params },
});
export const getBrCitiesSuccess = (payload) => ({
  type: types.GET_BR_CITIES_SUCCESS,
  payload,
});
export const getBrCitiesFailure = () => ({
  type: types.GET_BR_CITIES_FAILURE,
});

// CEP request
export const getCepRequest = ({ params }) => ({
  type: types.GET_CEP_REQUEST,
  payload: { params },
});
export const getCepSuccess = (payload) => ({
  type: types.GET_CEP_SUCCESS,
  payload,
});
export const getCepFailure = (payload) => ({
  type: types.GET_CEP_FAILURE,
  payload,
});
