import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 12px;

  @media (min-width: 360px) {
    a {
      margin-inline: auto;
    }
  }

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    a {
      min-width: 230px;
    }
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 769px) {
    h4 {
      font-size: 14px;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1500px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 32px;
  }
`;
