import React from 'react';

const IconWrong = React.memo(() => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="8.88894"
      cy="9.50014"
      rx="8.88894"
      ry="8.79946"
      fill="#DB3D27"
    />
    <path
      d="M5 5.70068L13 13.7007"
      stroke="#1D1D1D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13 5.70068L5 13.7007"
      stroke="#1D1D1D"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
));

export default IconWrong;
export { IconWrong };
