import React from 'react';
import PropTypes from 'prop-types';

const IconFacebookColored = React.memo(({ size }) => (
  <svg
    id="icon-facebook-colored"
    viewBox="0 0 12 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      d="M11.5996 0.243164V4.64316H9.39961C8.64061 4.64316 8.29961 5.53416 8.29961 6.29316V9.04316H11.5996V13.4432H8.29961V22.2432H3.89961V13.4432H0.599609V9.04316H3.89961V4.64316C3.89961 2.21311 5.86956 0.243164 8.29961 0.243164H11.5996Z"
      fill="#3B5996"
    />
  </svg>
));

IconFacebookColored.propTypes = {
  size: PropTypes.string,
};

IconFacebookColored.defaultProps = {
  size: '23px',
};

export default IconFacebookColored;
export { IconFacebookColored };
