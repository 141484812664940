/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { IconFind, IconClose, IconLoading, Text } from 'components';

import { actions as searchActions } from 'store/ducks/search';

import { Wrapper } from './searchInput.styles';

interface SearchInputProps {
  searchState: boolean;
  hideSearch: React.Dispatch<React.SetStateAction<boolean>>;
  refreshToken: string;
  reference: React.MutableRefObject<any>;
}

const SearchInput = React.forwardRef(
  ({
    searchState = false,
    hideSearch = () => {},
    refreshToken = '',
    reference,
  }: SearchInputProps) => {
    // @ts-ignore
    const { loading, searchData } = useSelector((state) => state.searchReducer);

    const dispatch = useDispatch();

    const router = useRouter();

    const [searchText, setSearchText] = useState('');
    const [searchQuery, setSearchQuery] = useState('' as any);

    const handleClose = () => {
      hideSearch(false);
      setSearchText('');
    };

    const handleSumbit = (e) => {
      e.preventDefault();
      router.push(`/buscar?term=${searchText}`);
      hideSearch(false);
    };

    const cssClasses = classNames({
      'search-results': true,
      show: searchText,
    });

    const fetchData = (value) => {
      const data = { term: value, page: 0, newSearch: true };
      dispatch(
        searchActions.sendSearchRequest({
          payload: {
            data,
            auth: {
              refresh_token: refreshToken,
            },
          },
        }),
      );
    };

    useEffect(() => {
      const slug = searchText;
      const search = _.debounce(fetchData, 1500);

      setSearchQuery((prevSearch) => {
        if (prevSearch.cancel) prevSearch.cancel();
        return search;
      });

      if (!!searchText && searchText.length >= 3) search(slug);
    }, [searchText]);

    return (
      <Wrapper visible={searchState ? searchState : undefined}>
        <form className="search-bar">
          <button
            className="search-bar__do-search-icon"
            type="submit"
            id="header-busca"
            onClick={(e) => handleSumbit(e)}>
            <IconFind size="18px" color="white" />
          </button>
          <div className="search-bar__form">
            <input
              id="search__input__desktop"
              className="search-bar__form"
              name="searchText"
              type="text"
              placeholder="Buscar no meuSucesso.com"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              ref={reference}
            />
          </div>
          <button
            className="search-bar__close-icon"
            onClick={handleClose}
            type="button">
            <IconClose size="18px" color="white" />
          </button>
        </form>
        <div className={cssClasses}>
          {loading && !searchData ? (
            <div className="search-results__loading">
              <IconLoading />
            </div>
          ) : (
            <div className="search-results__suggestions">
              {searchData?.data &&
                searchData.data.map((itemSearched, itemIdx) => {
                  const key = `search-results-${itemIdx}`;
                  return (
                    itemIdx < 5 && (
                      <div
                        className="search-results__suggestions--item"
                        key={key}>
                        <Link href="/curso/aula/[aula]" as={itemSearched.url}>
                          {/* @ts-ignore */}
                          <Text
                            className="search-suggestion"
                            variant="Body2"
                            fontFamily="muli"
                            fontSize="16px"
                            lineHeight="18px"
                            color="white">
                            {itemSearched.title}
                          </Text>
                        </Link>
                      </div>
                    )
                  );
                })}
            </div>
          )}
        </div>
      </Wrapper>
    );
  },
);

export default SearchInput;
export { SearchInput };
