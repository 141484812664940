import { styled } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  padding-bottom: 8rem;

  @media (max-width: 768px) {
    padding-bottom: 50%;
  }

  cursor: pointer;
`;

export const ModalContent = styled.div`
  max-width: 90%;
  max-height: 90%;

  cursor: default;
`;
