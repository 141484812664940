import { css } from 'styled-components';

const styles = ({ theme, imagePlaceholder }) => css`
  width: 30rem;
  max-width: 30rem;
  min-width: 30rem;
  height: 11.5rem;
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;

  * {
    outline: none;
  }

  .upload__avatar {
    width: 11.5rem;
    height: 11.5rem;
    min-width: 11.5rem;
    min-height: 11.5rem;
    z-index: 2;

    position: relative;

    &__image {
      position: absolute;
      width: 11.5rem;
      height: 11.5rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      background-color: ${theme.colorPallete.base.default};
      background-image: url(${imagePlaceholder});
      background-size: cover;
      background-position: center;
      border-radius: 100%;
      border: 1px solid ${theme.colorPallete.base.default};
      overflow: hidden;
    }
  }

  .upload__button {
    cursor: pointer;
    width: 19.3rem;
    height: 5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 0.1rem solid ${theme.colorPallete.base[500]};
    padding-left: 1.3rem;
    margin-left: -0.2rem;
    border-radius: 0.4rem;
    position: absolute;
    z-index: 1;
    right: 0;
    transition: all 0.2s ease-in-out;

    input {
      visibility: hidden;
      display: none;
    }

    &:hover {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
      opacity: 0.9;
      transition: all 0.2s ease-in-out;
    }

    .upload__button__icon {
      width: 5rem;
      height: 5rem;
      min-width: 5rem;
      min-height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${theme.colorPallete.base.default};
    }
  }
`;

export default styles;
export { styles };
