import React from 'react';
import PropTypes from 'prop-types';

const IconMasterCard = React.memo(({ size, color }) => (
  <svg
    id="icon-master-card"
    viewBox="0 0 51 35"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      d="M31.714 10C29.9772 10.0023 28.301 10.6384 27 11.789C28.1224 13.4819 28.7191 15.4689 28.715 17.5C28.715 19.617 28.081 21.583 27 23.211C28.301 24.3616 29.9772 24.9977 31.714 25C35.732 25 39 21.636 39 17.5C39 13.364 35.732 10 31.714 10Z"
      fill={color}
    />
    <path
      d="M49.59 -1.99999H1.41C1.09448 -2.00104 0.781845 -1.93992 0.489953 -1.82011C0.198061 -1.70031 -0.0673676 -1.52417 -0.29117 -1.30176C-0.514973 -1.07935 -0.692764 -0.815031 -0.814387 -0.523893C-0.93601 -0.232754 -0.999082 0.0794935 -1 0.395013V34.605C-0.999082 34.9205 -0.93601 35.2328 -0.814387 35.5239C-0.692764 35.8151 -0.514973 36.0794 -0.29117 36.3018C-0.0673676 36.5242 0.198061 36.7003 0.489953 36.8201C0.781845 36.9399 1.09448 37.0011 1.41 37H49.59C50.921 37 52 35.928 52 34.605V0.395013C51.9991 0.0794935 51.936 -0.232754 51.8144 -0.523893C51.6928 -0.815031 51.515 -1.07935 51.2912 -1.30176C51.0674 -1.52417 50.8019 -1.70031 50.51 -1.82011C50.2182 -1.93992 49.9055 -2.00104 49.59 -1.99999ZM31.524 26.68C29.224 26.68 27.119 25.838 25.501 24.45C23.8253 25.8908 21.688 26.6821 19.478 26.68C14.386 26.68 10.243 22.562 10.243 17.5C10.243 12.438 14.386 8.32001 19.478 8.32001C21.778 8.32001 23.883 9.16201 25.501 10.55C27.1767 9.10923 29.314 8.31791 31.524 8.32001C36.616 8.32001 40.759 12.438 40.759 17.5C40.759 22.562 36.616 26.68 31.524 26.68Z"
      fill={color}
    />
  </svg>
));

IconMasterCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconMasterCard.defaultProps = {
  color: '#0D0C0C',
  size: '49px',
};

export default IconMasterCard;
export { IconMasterCard };
