import React from 'react';
import PropTypes from 'prop-types';

const IconWarning = React.memo(({ size, color }) => (
  <svg
    id="icon-warning"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46873 14.8237H15.531L8.99986 2.54726L2.46873 14.8237ZM10.6801 0.975907L10.682 0.9795L17.7952 14.3499C18.2638 15.2224 17.902 16.292 16.9795 16.7426C16.7123 16.8731 16.4158 16.9413 16.1149 16.9413H1.8848C0.840501 16.9413 0 16.1397 0 15.1579C0 14.8772 0.0700193 14.6003 0.202601 14.3535L7.31956 0.975907C7.79239 0.0955165 8.93355 -0.255557 9.86445 0.199167C10.2161 0.370954 10.5013 0.642876 10.6801 0.975907ZM10.1249 6.33712V10.1649C10.1249 11.5766 7.87492 11.5766 7.87492 10.1649V6.33712C7.87492 4.92537 10.1249 4.92537 10.1249 6.33712ZM8.99994 11.984L9.0071 14.1017L8.99994 11.984ZM8.99991 11.984H9.00707C10.5071 11.984 10.5071 14.1017 9.00707 14.1017H8.99991C7.49993 14.1017 7.49993 11.984 8.99991 11.984Z"
      fill={color}
    />
  </svg>
));

IconWarning.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconWarning.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconWarning;
export { IconWarning };
