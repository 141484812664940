/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-cycle */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { IconCheck, IconDownload, IconLock, Text } from '@components';

import { main as theme } from '@themes';

import { styles } from './DownloadButton.styles';

const Wrapper = styled.div`
  ${styles}
`;

const DownloadButton = ({
  color,
  disabled,
  size,
  text,
  url,
  variant,
  slug,
  id,
}) => {
  const [clicked, setClicked] = useState(false);

  const {
    userReducer: { userData },
  } = useSelector((state) => state);

  useEffect(() => {
    if (localStorage.getItem(`${slug}-${userData.id}`)) {
      setClicked(true);
    }
  }, [slug, userData]);

  const putInfo = () => {
    localStorage.setItem(`${slug}-${userData.id}`, true);
    setClicked(true);
  };

  const cssClasses = classNames({
    clicked,
    light: variant === 'light',
    dark: variant === 'dark',
    big: size === 'big',
    regular: size === 'regular',
  });

  if (disabled) {
    return (
      <Wrapper className={cssClasses}>
        <div
          id={id}
          className="disabled download__button download__button--disabled"
        >
          <div className="disabled__text-container">
            <Text
              className="disabled__text"
              size="1.6rem"
              dsize="1.6rem"
              family={theme.fonts.fontFamily.sans}
              weight={theme.fonts.fontWeight.bold}
              color={theme.colorPallete.base[300]}
            >
              {text}
            </Text>
          </div>
          <div className="disabled__icon-container">
            <IconLock size="22px" color={theme.colorSystem.white} />
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={cssClasses} color={color}>
      <a
        className="button download__button"
        target="_blank"
        rel="noreferrer"
        onClick={() => putInfo()}
        clicked={clicked.toString()}
        href={url}
        id={id}
      >
        <div className="button__text-container">
          <Text
            className="button__text"
            size="1.6rem"
            dsize="1.6rem"
            family={theme.fonts.fontFamily.sans}
            weight={theme.fonts.fontWeight.bold}
            color={theme.colorPallete.base.default}
          >
            {text}
          </Text>
        </div>
        <div className="button__icon-container">
          <IconDownload size="22px" color={theme.colorSystem.white} />
          <IconCheck size="22px" color={theme.colorSystem.white} />
        </div>
      </a>
    </Wrapper>
  );
};

DownloadButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  size: PropTypes.string,
  slug: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  variant: PropTypes.string,
};

DownloadButton.defaultProps = {
  color: theme.colorSystem.primaryColor,
  disabled: false,
  id: '',
  size: 'big',
  slug: null,
  text: 'Baixar agora',
  url: '',
  variant: 'dark',
};

export default DownloadButton;
export { DownloadButton };
