import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: transparent;
    background-image: linear-gradient(
      to bottom,
      rgba(13, 12, 12, 1),
      rgba(13, 12, 12, 0)
    );
  `}
`;

export const MenuList = styled.button`
  background-color: transparent;
  padding-top: 1.3rem;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

export const UserMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  #header-perfil-mobile {
    position: relative;
    z-index: 1;
  }

  .skeleton {
    width: 44px;
    height: 44px;
  }
`;

export const PhotoHolder = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${theme.colorPallete.base[100]};

    img {
      width: 100%;
      height: auto;
    }

    & > div {
      width: 100%;
      height: 100%;
    }
  `}
`;
