import React from 'react';
import PropTypes from 'prop-types';

const IconCalendar = React.memo(({ size, color }) => (
  <svg
    id="icon-calendar"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00624 0C4.67488 0 4.40624 0.262841 4.40624 0.587092V2.84372C3.7105 3.08802 3.20624 3.74087 3.20624 4.50104C3.20624 5.4668 4.01926 6.26231 5.00624 6.26231C5.99324 6.26231 6.80624 5.4668 6.80624 4.50104C6.80624 3.74169 6.30082 3.08865 5.60624 2.84372V0.587092C5.60624 0.262841 5.3376 0 5.00624 0ZM13.0063 0C12.675 0 12.4063 0.262841 12.4063 0.587092V2.84372C11.7106 3.08802 11.2063 3.74087 11.2063 4.50104C11.2063 5.4668 12.0193 6.26231 13.0063 6.26231C13.9933 6.26231 14.8063 5.4668 14.8063 4.50104C14.8063 3.74169 14.3009 3.08865 13.6063 2.84372V0.587092C13.6063 0.262841 13.3377 0 13.0063 0ZM0 3.33309C0 2.14741 0.99448 1.17431 2.20624 1.17431H3.4C3.71698 1.16992 4.00624 1.4512 4.00624 1.7614C4.00624 2.0716 3.71698 2.3528 3.4 2.34849H2.20624C1.63968 2.34849 1.2 2.7787 1.2 3.33309V6.65385H16.8V3.33309C16.8 2.7787 16.3603 2.34849 15.7938 2.34849H14.6C14.283 2.35288 13.9875 2.0716 13.9875 1.7614C13.9875 1.4512 14.283 1.17 14.6 1.17431H15.7938C17.0055 1.17431 18 2.14741 18 3.33309V15.8455C18 17.0312 17.0055 18.0043 15.7938 18.0043H2.20624C0.9945 18.0043 0 17.0312 0 15.8455V3.33309ZM6.60006 1.17432C6.26868 1.17432 6.00006 1.43716 6.00006 1.76141C6.00006 2.08564 6.26868 2.3485 6.60006 2.3485H11.4001C11.7314 2.3485 12.0001 2.08564 12.0001 1.76141C12.0001 1.43716 11.7314 1.17432 11.4001 1.17432H6.60006ZM5.60605 4.5009C5.60605 4.16971 5.34453 3.91382 5.00605 3.91382C4.66759 3.91382 4.40605 4.16971 4.40605 4.5009C4.40605 4.83209 4.66759 5.08798 5.00605 5.08798C5.34453 5.08798 5.60605 4.83209 5.60605 4.5009ZM13.0061 3.91382C13.3446 3.91382 13.6061 4.16971 13.6061 4.5009C13.6061 4.83209 13.3446 5.08798 13.0061 5.08798C12.6677 5.08798 12.4061 4.83209 12.4061 4.5009C12.4061 4.16971 12.6677 3.91382 13.0061 3.91382ZM16.7998 7.82788H1.19981V15.8454C1.19981 16.3998 1.63947 16.83 2.20605 16.83H15.7936C16.3601 16.83 16.7998 16.3998 16.7998 15.8454V7.82788Z"
      fill={color}
    />
  </svg>
));

IconCalendar.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconCalendar.defaultProps = {
  color: '#565555',
  size: '18px',
};

export default IconCalendar;
export { IconCalendar };
