import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, IconArrow, IconArrowBold } from 'components';
import Link from 'next/link';
import { CardTypes } from 'components/Card/types';
import {
  CourseListItemDto,
  PaginationDto,
  WatchingListItemDto,
} from 'libs/ms-api';
import { ContinueWatchingSkeleton, CardSkeleton } from 'skeletons';
import * as S from './CarouselCards.styles';
import { Content } from 'libs/ms-api-react-query';

interface Filter {
  slug: string;
  text: string;
}
interface CarouselCardsProps {
  titleCarousel?: 'Continue estudando' | 'Estudos de Caso' | 'Séries';
  content: WatchingListItemDto[] | CourseListItemDto[] | Content;
  currentFilter?: Filter;
  loadedCards?: number;
  fetchNextPage?: () => any;
}

const CarouselCards = ({
  content,
  titleCarousel,
  currentFilter,
  loadedCards,
  fetchNextPage,
}: CarouselCardsProps) => {
  const fetchPages = (fetchFunction: () => void, pages: number) => {
    if (pages === 0) fetchFunction();
    else if (pages > 0) {
      fetchFunction();
      fetchPages(fetchFunction, pages - 1);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    draggable: false,
    speed: 1000,
    slidesToShow: 6.5,
    slidesToScroll: 6,
    rows: 1,
    variableWidth: false,
    arrows: true,
    centerMode: false,
    beforeChange: (oldIndex, newIndex) => {
      if (typeof fetchNextPage === 'function') {
        if (newIndex > oldIndex) {
          const offset = Math.floor(newIndex / loadedCards);
          fetchPages(fetchNextPage, offset);
        }
      }
    },
    prevArrow: (
      <S.NavigationArrow className="arrow__previous" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    nextArrow: (
      <S.NavigationArrow className="arrow__next" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
        },
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          swipeToSlide: true,
          arrows: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          swipeToSlide: true,
          arrows: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1460,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1780,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
  };

  const defineSubtitle = () => {
    if (currentFilter?.text && content.length > 0) {
      return (
        <>
          Foram encontrados <span>{content.length} resultado(s)</span>{' '}
          relacionado(s) a <span>{currentFilter?.text}</span>
        </>
      );
    } else if (currentFilter?.text && content.length === 0) {
      return (
        <>
          Nenhum resultado encontrado relacionado a{' '}
          <span>{currentFilter?.text}</span>
        </>
      );
    } else {
      return <></>;
    }
  };

  if (!content) return <ContinueWatchingSkeleton />;

  return (
    <S.Wrapper>
      <div>
        <S.HeaderSliderContainer>
          {!!titleCarousel && <S.Title>{titleCarousel}</S.Title>}
          {titleCarousel !== 'Continue estudando' && (
            <Link
              href={
                titleCarousel === 'Estudos de Caso'
                  ? '/estudos-de-caso'
                  : '/series'
              }
              legacyBehavior>
              <S.SeeMore>
                Ver mais <IconArrowBold />
              </S.SeeMore>
            </Link>
          )}
        </S.HeaderSliderContainer>
        {titleCarousel !== 'Continue estudando' && (
          <S.Subtitle>{defineSubtitle()}</S.Subtitle>
        )}
      </div>
      {content?.length > 0 && (
        <S.SliderContainer>
          <Slider {...settings}>
            {content?.map((card) => {
              if (card?.type && card.type === 'placeholder')
                return (
                  <div className="card-container">
                    <CardSkeleton />{' '}
                  </div>
                );
              else
                return (
                  <div className="card-container">
                    <Card
                      cardContent={card}
                      type={
                        titleCarousel === 'Continue estudando' &&
                        CardTypes.CONTINUE
                      }
                    />
                  </div>
                );
            })}
          </Slider>
        </S.SliderContainer>
      )}
    </S.Wrapper>
  );
};

export default CarouselCards;
export { CarouselCards };
