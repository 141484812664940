/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as watchedActions } from '@store/ducks/watched';

// MSV2-api
import { watchedApi as msv2WatchedApi } from '@services/msv2-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * WATCHED
 * ---------------------------------------------------
 */
export function* getWatched({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(msv2WatchedApi.watched, {
      method: 'GET',
      token: auth.token,
    });
    if (response.status === 200) {
      yield put(
        watchedActions.getWatchedSuccess({
          payload: {
            watchedData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    yield put(watchedActions.getWatchedFailure());
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_WATCHED_REQUEST, getWatched);
}

export const sagas = [watchUser];
