import * as types from './types';

/**
 * ---------------------------------------------------
 * AUTH
 * ---------------------------------------------------
 */
export const loginRequest = (payload) => ({
  type: types.LOGIN_REQUEST,
  payload,
});
export const loginSuccess = () => ({
  type: types.LOGIN_REQUEST_SUCCESS,
});
export const loginSuccessDataLayer = (payload) => ({
  type: types.LOGIN_REQUEST_SUCCESS_DATA_LAYER,
  payload
});

export const resetDataLayer = (payload) => ({
  type: types.RESET_DATA_LAYER,
  payload
});

export const loginFailure = ({ toast }) => ({
  type: types.LOGIN_REQUEST_FAILURE,
  toast,
});

export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST,
});
export const logoutSuccess = () => ({
  type: types.LOGOUT_REQUEST_SUCCESS,
});
export const logoutFailure = () => ({
  type: types.LOGOUT_REQUEST_FAILURE,
});

/**
 * ---------------------------------------------------
 * FIRST-ACCESS
 * ---------------------------------------------------
 */
export const getFirstAccessRequest = ({ params }) => ({
  type: types.GET_FIRST_ACCESS_REQUEST,
  payload: { params },
});
export const getFirstAccessSuccess = ({ payload }) => ({
  type: types.GET_FIRST_ACCESS_SUCCESS,
  payload,
});
export const getFirstAccessFailure = (payload) => ({
  type: types.GET_FIRST_ACCESS_FAILURE,
  payload,
});

export const sendFirstAccessRequest = (payload) => ({
  type: types.SEND_FIRST_ACCESS_REQUEST,
  payload,
});
export const sendFirstAccessSuccess = ({ payload }) => ({
  type: types.SEND_FIRST_ACCESS_SUCCESS,
  payload,
});
export const sendFirstAccessFailure = () => ({
  type: types.SEND_FIRST_ACCESS_FAILURE,
});

/**
 * ---------------------------------------------------
 * PASSWORD
 * ---------------------------------------------------
 */
export const forgotPasswordEmailRequest = (payload) => ({
  type: types.FORGOT_PASSWORD_EMAIL_REQUEST,
  payload,
});
export const forgotPasswordEmailSuccess = () => ({
  type: types.FORGOT_PASSWORD_EMAIL_REQUEST_SUCCESS,
});
export const forgotPasswordEmailFailure = () => ({
  type: types.FORGOT_PASSWORD_EMAIL_REQUEST_FAILURE,
});

export const validatePasswordRequest = (payload) => ({
  type: types.VALIDATE_PASSWORD_TOKEN_REQUEST,
  payload,
});
export const validatePasswordSuccess = () => ({
  type: types.VALIDATE_PASSWORD_TOKEN_REQUEST_SUCCESS,
});
export const validatePasswordFailure = () => ({
  type: types.VALIDATE_PASSWORD_TOKEN_REQUEST_FAILURE,
});

export const resetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload,
});
export const resetPasswordRequestSuccess = ({ toast }) => ({
  type: types.RESET_PASSWORD_REQUEST_SUCCESS,
  toast,
});
export const resetPasswordRequestFailure = ({ toast }) => ({
  type: types.RESET_PASSWORD_REQUEST_FAILURE,
  toast,
});

export const forgotNewPasswordRequest = ({ payload }) => ({
  type: types.FORGOT_NEW_PASSWORD_REQUEST,
  payload,
});
export const forgotNewPasswordSuccess = ({ toast }) => ({
  type: types.FORGOT_NEW_PASSWORD_SUCCESS,
  toast,
});
export const forgotNewPasswordFailure = ({ toast }) => ({
  type: types.FORGOT_NEW_PASSWORD_FAILURE,
  toast,
});

/**
 * ---------------------------------------------------
 * PROFILE
 * ---------------------------------------------------
 */
export const getUserDataRequest = ({ payload }) => ({
  type: types.GET_USER_DATA_REQUEST,
  payload,
});
export const getUserDataSuccess = ({ payload }) => ({
  type: types.GET_USER_DATA_SUCCESS,
  payload,
});
export const getUserDataFailure = () => ({
  type: types.GET_USER_DATA_FAILURE,
});

export const editPersonalDataRequest = ({ payload }) => ({
  type: types.EDIT_PERSONAL_DATA_REQUEST,
  payload,
});
export const editPersonalDataSuccess = ({ payload, toast }) => ({
  type: types.EDIT_PERSONAL_DATA_SUCCESS,
  payload,
  toast,
});
export const editPersonalDataFailure = ({ toast }) => ({
  type: types.EDIT_PERSONAL_DATA_FAILURE,
  toast,
});

export const editAddressDataRequest = ({ payload }) => ({
  type: types.EDIT_ADDRESS_DATA_REQUEST,
  payload,
});
export const editAddressDataSuccess = ({ payload, toast }) => ({
  type: types.EDIT_ADDRESS_DATA_SUCCESS,
  payload,
  toast,
});
export const editAddressDataFailure = ({ toast }) => ({
  type: types.EDIT_ADDRESS_DATA_FAILURE,
  toast,
});

export const editFinancialDataRequest = ({ payload }) => ({
  type: types.EDIT_FINANCIAL_DATA_REQUEST,
  payload,
});
export const editFinancialDataSuccess = ({ payload, toast }) => ({
  type: types.EDIT_FINANCIAL_DATA_SUCCESS,
  payload,
  toast,
});
export const editFinancialDataFailure = ({ toast }) => ({
  type: types.EDIT_FINANCIAL_DATA_FAILURE,
  toast,
});

export const linkToSocialRequest = ({ payload }) => ({
  type: types.LINK_TO_SOCIAL_REQUEST,
  payload,
});
export const linkToSocialSuccess = ({ payload }) => ({
  type: types.LINK_TO_SOCIAL_SUCCESS,
  payload,
});
export const linkToSocialFailure = () => ({
  type: types.LINK_TO_SOCIAL_FAILURE,
});

export const socialLoginRequest = ({ payload }) => ({
  type: types.SOCIAL_LOGIN_REQUEST,
  payload,
});
export const socialLoginSuccess = () => ({
  type: types.SOCIAL_LOGIN_SUCCESS,
});
export const socialLoginFailure = ({ toast }) => ({
  type: types.SOCIAL_LOGIN_FAILURE,
  toast,
});

export const editNewsletterRequest = ({ payload }) => ({
  type: types.EDIT_NEWSLETTER_REQUEST,
  payload,
});
export const editNewsletterSuccess = ({ payload }) => ({
  type: types.EDIT_NEWSLETTER_SUCCESS,
  payload,
});
export const editNewsletterFailure = () => ({
  type: types.EDIT_NEWSLETTER_FAILURE,
});

export const getProfileDataRequest = ({ payload }) => ({
  type: types.GET_PROFILE_DATA_REQUEST,
  payload,
});
export const getProfileDataSuccess = ({ payload }) => ({
  type: types.GET_PROFILE_DATA_SUCCESS,
  payload,
});
export const getProfileDataFailure = () => ({
  type: types.GET_PROFILE_DATA_FAILURE,
});

/**
 * ---------------------------------------------------
 * EDIT MODAL
 * ---------------------------------------------------
 */
export const setEditModal = ({ payload }) => ({
  type: types.SET_EDIT_MODAL,
  payload,
});

/**
 * ---------------------------------------------------
 * CLOSE ZERO LESSON
 * ---------------------------------------------------
 */
export const updateZeroLessonRequest = ({ payload }) => ({
  type: types.UPDATE_ZEROLESSON_REQUEST,
  payload,
});
export const updateZeroLessonSuccess = ({ payload }) => ({
  type: types.UPDATE_ZEROLESSON_SUCCESS,
  payload,
});
export const updateZeroLessonFailure = () => ({
  type: types.UPDATE_ZEROLESSON_FAILURE,
});
