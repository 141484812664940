/* eslint-disable import/no-cycle */
import { callApiBase } from './base';

const caseStudiesApi = {
  getCaseStudies: ({ token, data }) =>
    callApiBase({
      title: 'Case studies - Get Case Studies',
      endpoint: `/courses${data.slug ? "/" + data.slug : ""}`,
      method: 'GET',
      token,
    }),
};

export default caseStudiesApi;
export { caseStudiesApi };
