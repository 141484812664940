import { MSApi } from 'libs/ms-api';
import { useMutation } from 'react-query';

import { UsePostCertificateIssuanceDataProps } from './types';

export const usePostCertificateIssuanceData = (msApiLib: MSApi) => {
  return useMutation(
    async ({ bearerToken, params }: UsePostCertificateIssuanceDataProps) => {
      const res = await msApiLib.v3.certificates.certificateIssuance({
        bearerToken,
        params,
      });

      return res;
    },
  );
};
