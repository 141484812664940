import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Themes
import { main as theme } from '@themes';

// Components
import { Button } from '../Button';
import { IconGoogleColored, IconLoading } from '../../Icons';
import { Text } from '../../Text';

// Styles
import { Wrapper } from './GoogleButton.styles';

const GoogleButton = ({ refreshToken, type, id }) => {
  const dispatch = useDispatch();

  const [eventClicked, toggleEventClicked] = useState(false);

  const { profileData, loading } = useSelector((state) => state.userReducer);

  const onFailure = (error) => {
    return error;
  };

  const googleResponse = (response) => {
    const data = {
      access_token: response.accessToken,
      provider: 'google',
      subscription: response.googleId,
    };

    if (type === 'login') {
      return dispatch(
        userActions.socialLoginRequest({
          payload: { data },
        })
      );
    }

    toggleEventClicked(true);

    return dispatch(
      userActions.linkToSocialRequest({
        payload: { auth: { refresh_token: refreshToken }, data },
      })
    );
  };

  return loading && eventClicked ? (
    <Wrapper>
      <IconLoading />
    </Wrapper>
  ) : (
    <GoogleLogin
      clientId={process.env.GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <Button
          variant="secondary"
          size="medium"
          onClick={renderProps.onClick}
          disabled={profileData?.profile?.provider?.google}
          id={id}
        >
          <IconGoogleColored />
          <Text
            className="social-button"
            size="1.4rem"
            dsize="1.4rem"
            color={theme.colorPallete.white}
            weight="bold"
          >
            {profileData?.profile?.provider?.google
              ? 'Conectado com o Google'
              : 'Conectar Google'}
          </Text>
        </Button>
      )}
      buttonText="Login"
      onSuccess={googleResponse}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
    />
  );
};

GoogleButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  refreshToken: PropTypes.string,
};

GoogleButton.defaultProps = {
  id: null,
  type: 'link',
  refreshToken: '',
};

export { GoogleButton };
export default GoogleButton;
