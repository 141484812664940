import styled, { css } from 'styled-components';

const AvatarStyles = ({ theme, bgColor, fontColor, round, size }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${size}rem;
  height: ${size}rem;
  border-radius: ${round ? '100%' : '0'};
  background-color: ${bgColor};

  span {
    color: ${fontColor};
    font-size: ${size / 2.5}rem;
    font-family: ${theme.fonts.fontFamily.sans};
  }
`;

const AvatarWrapper = styled.div`
  ${AvatarStyles}
`;

export { AvatarWrapper };
export default AvatarWrapper;
