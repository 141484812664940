import * as types from './types';

/**
 * ---------------------------------------------------
 * NOTIFICATION
 * ---------------------------------------------------
 */
export const getNotification = ({ payload }) => ({
  type: types.GET_NOTIFICATION_REQUEST,
  payload,
});
export const getNotificationSuccess = ({ payload }) => {
  return ({
    type: types.GET_NOTIFICATION_SUCCESS,
    payload,
  })
};
export const getNotificationFailure = () => ({
  type: types.GET_NOTIFICATION_FAILURE,
});

export const setReadNotification = ({ payload }) => ({
  type: types.SET_READ_NOTIFICATION_REQUEST,
  payload,
});
export const setReadNotificationSuccess = ({ payload }) => {
  return ({
    type: types.SET_READ_NOTIFICATION_SUCCESS,
    payload,
  })
};
export const setReadNotificationFailure = () => ({
  type: types.SET_READ_NOTIFICATION_FAILURE,
});
