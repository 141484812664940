/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as caseStudiesActions } from '@store/ducks/caseStudies';

// MSV2-api
import { caseStudiesApi as msv2CaseStudiesApi } from '@services/msv2-api';

// Mirror-api
import { caseStudiesApi as mirrorCaseStudiesApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * CASE STUDIES
 * ---------------------------------------------------
 */
export function* getCaseStudies({ payload }) {
  const { auth, isServer } = payload;
  let whichFilterCourses = ""
  const request = isServer ? msv2CaseStudiesApi : mirrorCaseStudiesApi;
  const token = isServer ? auth.token : '';

  if (payload.whichFilterCourses !== undefined) {
    whichFilterCourses = payload.whichFilterCourses
  }


  try {
    const response = yield call(request.getCaseStudies, {
      method: 'GET',
      token,
      data: { slug: whichFilterCourses },
    });

    if (response.status === 200) {
      yield put(
        caseStudiesActions.getCaseStudiesSuccess({
          payload: {
            caseStudiesData: response.data.content.instance,
            filterCaseStudies: whichFilterCourses && whichFilterCourses !== undefined ? true : false,
            whichFilterCourses: whichFilterCourses ? whichFilterCourses : null,
            textFilterCaseStudies: payload.textFilterCaseStudies ? payload.textFilterCaseStudies : null
          },
        })
      );
    }
  } catch (error) {
    if (error && error.response) {
      yield put(caseStudiesActions.getCaseStudiesFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_CASE_STUDIES_REQUEST, getCaseStudies);
}

export const sagas = [watchUser];