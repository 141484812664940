import SentryAdapter from './adapters/Sentry';
import ElasticAdapter from './adapters/Elastic';
// import { ConsoleAdapter } from './adapters/Console'

class ErrorLogger {
  constructor(
    adapters = [
      // new ConsoleAdapter(),
      new SentryAdapter(),
      new ElasticAdapter(),
    ]
  ) {
    this.adapters = adapters;
    this.storeWatchers = [];
    this.createStoreWatcher();
  }

  createStoreWatcher() {
    this.adapters.forEach((logger) => {
      if (typeof logger.createStoreWatcher === 'function') {
        logger.createStoreWatcher();
        this.storeWatchers.push(logger.storeWatchers);
      }
    });
  }

  init() {
    this.adapters.forEach((logger) => {
      logger.init();
    });
  }

  captureException(transactionName, severityLevel, error, details) {
    this.adapters.forEach((logger) => {
      logger.captureException(transactionName, severityLevel, error, details);
    });
  }

  captureLog(transactionName, severityLevel, details) {
    this.adapters.forEach((logger) => {
      logger.captureLog(transactionName, severityLevel, details);
    });
  }

  captureHTTPException(error) {
    this.adapters.forEach((logger) => {
      logger.captureHTTPException(error);
    });
  }

  captureUIException(error, { componentStack }) {
    this.adapters.forEach((logger) => {
      logger.captureUIException(error, { componentStack });
    });
  }
}

export default new ErrorLogger();
