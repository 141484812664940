import React from 'react';
import { Skeleton } from 'skeletons/Skeleton';
import * as S from './CardSkeleton.styles';

const CardSkeleton = () => {
  return (
    <S.SkeletonCard data-testid="card-skeleton">
      <Skeleton />
    </S.SkeletonCard>
  );
};

export default CardSkeleton;
export { CardSkeleton };
