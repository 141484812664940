import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import * as S from './Modal.styles';

interface ModalProps {
  children: JSX.Element;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  visible?: boolean;
  onClose?: () => any;
}

export const Modal = ({
  children,
  setVisibility,
  visible = false,
  onClose,
}: ModalProps) => {
  const contentRef = useRef(null);

  const handleOnClickOutside = (e: any) => {
    if (
      contentRef &&
      contentRef.current &&
      !contentRef.current.contains(e.target)
    ) {
      setVisibility(false);
      if (typeof onClose === 'function') onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOnClickOutside);
    return () => document.removeEventListener('click', handleOnClickOutside);
  }, []);

  if (!visible) return <></>;
  return (
    <S.Wrapper>
      <S.ModalContent ref={contentRef}>{children}</S.ModalContent>
    </S.Wrapper>
  );
};
