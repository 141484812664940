import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import { MeDto, ResponseApiV2Dto } from 'libs/ms-api/dtos';
import { RequestWithBearer } from 'libs/ms-api/types';

type IAuthResource = MSApi['v2']['auth'];

export class AuthResource implements IAuthResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async me(data: RequestWithBearer<object>): Promise<ResponseApiV2Dto<MeDto>> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;
    const { data: result } = await this.msApi.get(`/me`);
    return result;
  }
}
