import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';

import { createWrapper } from 'next-redux-wrapper';

import { ToastMiddleware } from '@components';
import errorLogger from 'utils/monitoring/errorLogger';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootSaga from './ducks/rootSaga';
import rootReducer from './ducks/rootReducer';

export const configureStore = () => {
  // 1: Create the middleware
  const sagaMiddleware = createSagaMiddleware();

  const errorLoggerEnhancer = errorLogger.storeWatchers;

  // 2: Add an extra parameter for applying middleware:
  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, ToastMiddleware),
      ...errorLoggerEnhancer
    )
  );

  // 3: Run your sagas on server
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

const wrapper = createWrapper(configureStore, {
  debug: false,
});

export default wrapper;
