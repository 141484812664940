export enum ImageTypes {
  logo = 'logo',
  banner = 'banner',
  bannerResponsive = 'banner-responsive',
  bannerMobile = 'banner-mobile',
}

interface Video {
  id: string;
  grouper: string;
  type: string;
  vimeo_id: string;
  youtube_id: string | null;
  duration: number | null;
}

interface Image {
  order: number;
  type: string;
  path: string;
  alt: string | null;
}

export interface CourseNewBanner {
  type: string;
  order: number;
  title: string;
  subtitle: string;
  description: string;
  course_program: any;
  video: Video;
  imagens: Image[];
  summary: string;
  progress: number;
}
