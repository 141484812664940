/**
 * Transform a word to this conventional format
 */
const capitalizeFirstLetter = (string) => {
  const strFormat = string.toLowerCase();

  const strArray = strFormat.split(' ');

  const array = [];

  strArray.map((str) => {
    if (str !== 'de' && str !== 'da') {
      return array.push(str.charAt(0).toUpperCase() + str.slice(1));
    }
    return array.push(str);
  });

  return array.join().replace(/,+/g, ' ');
};

const getRandomId = () => {
  return Math.random().toString(36).substring(2, 10);
};

const characterLimiter = (text: string, delimiter: number) => {
  if (text?.length > delimiter) {
    return text.slice(0, delimiter).concat('...');
  }
  return text;
};

const getFirstLetterOfName = (fullName: string): string => {
  return fullName?.substring(0, 1);
};

const getInitialOfString = (string) => {
  const strFormat = string.toUpperCase();

  const strArray = strFormat.split(' ');

  const array = [];

  strArray.map((str) => {
    return array.push(str.substr(0, 1));
  });

  let initialName = '';

  if (array.length >= 2) {
    initialName = `${array[0]}${array[array.length - 1]}`;
  } else {
    initialName = `${array[0]}`;
  }

  return initialName;
};

/**
 * Default polishing when happens on onChange event
 * @param {string} value
 */
const polishingString = (value) => value.split(/\s/g).join('');

/**
 * Verify route for dinamic routes on nextjs
 * @param {string} route
 */
const verifyDinamicRoute = (route) => {
  if (route) {
    if (route.search('/curso/') !== -1 && route.search('/aula/') === -1) {
      return '/curso/[slug]';
    }
    if (route.search('/curso/') !== -1 && route.search('/aula/') === 1) {
      return '/curso/[slug]/[aula]';
    }
  }
  return route;
};

/**
 * Return the correspond letter of number:
 * @param {integer} number
 * @param {string} type
 */
const transformNumberToLetters = (number, type = 'uppercase') => {
  if (typeof number === 'number') {
    switch (type) {
      case 'uppercase':
        return String.fromCharCode(97 + number).toUpperCase();
      case 'lowercase':
        return String.fromCharCode(97 + number);
      default:
        return number;
    }
  }
  return number;
};

export {
  capitalizeFirstLetter,
  getInitialOfString,
  polishingString,
  verifyDinamicRoute,
  transformNumberToLetters,
  characterLimiter,
  getFirstLetterOfName,
  getRandomId,
};
