import { msApiLib } from 'services/config/ms-api';
import {
  useInfiniteEntrepreneursCourseData,
  useInfiniteThematicCoursesData,
} from 'libs/ms-api-react-query';
import { useWindowSize } from 'helpers';

import { Categories, Content } from './types';
import { CourseListItemDto, CourseTags, PaginationDto } from '@libs/ms-api';

const useGetCards = (category: Categories, tags?: CourseTags[]) => {
  const { width = 1200 } = useWindowSize();

  const getQueryFunction = () => {
    if (category === 'thematic') return useInfiniteThematicCoursesData;
    else return useInfiniteEntrepreneursCourseData;
  };

  const getPageSize = () => {
    const pageSize = Math.ceil(width / 300) + 1;
    if (pageSize <= 4) return 4;
    else return pageSize;
  };

  const pageSize = getPageSize();

  const {
    data,
    totalCards,
    currentPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
  } = getQueryFunction()(msApiLib, {
    enabled: true,
    query: { pageSize: pageSize, tags: tags },
  });

  const combinePages = data?.pages?.flatMap(
    (page: PaginationDto<CourseListItemDto[]>) => page.data,
  );
  if (combinePages?.length <= pageSize && hasNextPage) fetchNextPage();

  const loadedCards = combinePages?.length;

  let placeholders = Array.from(
    { length: totalCards - combinePages?.length },
    () => ({
      type: 'placeholder',
    }),
  );

  let content: Content;
  if (combinePages) content = [...combinePages, ...placeholders];

  if (!width) return;
  return {
    content,
    loadedCards,
    currentPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
  };
};

export { useGetCards };
