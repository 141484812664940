import React from 'react';
import PropTypes from 'prop-types';

const IconVisaAlt = React.memo(({ color, size }) => (
  <svg
    id="icon-visa-alt"
    viewBox="0 0 41 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      d="M20.5 0L17.676 13.37H14.261L17.086 0H20.5ZM33.87 8.022L35.567 3.565L36.543 8.022H33.87ZM38.012 13.37H41L38.392 0H35.635C35.015 0 34.492 0.386 34.26 0.98L29.413 13.37H32.804L33.478 11.372H37.622L38.012 13.37ZM28.817 8.945C28.831 5.533 24.264 5.345 24.295 3.82C24.305 3.356 24.731 2.863 25.664 2.737C26.126 2.674 27.401 2.626 28.846 3.315L29.413 0.573C28.636 0.281 27.637 0 26.393 0C23.203 0 20.958 1.758 20.94 4.274C20.919 6.137 22.542 7.174 23.766 7.794C25.023 8.427 25.444 8.834 25.44 9.401C25.43 10.269 24.437 10.651 23.508 10.666C21.885 10.692 20.943 10.211 20.193 9.849L19.609 12.683C20.362 13.041 21.754 13.354 23.198 13.37C26.588 13.37 28.806 11.634 28.817 8.945ZM15.152 0L9.927 13.37H6.52L3.947 2.7C3.791 2.043 3.656 1.802 3.181 1.525C2.406 1.074 1.126 0.651 0 0.388L0.076 0H5.564C6.263 0 6.892 0.499 7.051 1.363L8.409 9.1L11.765 0H15.152Z"
      fill={color}
    />
  </svg>
));

IconVisaAlt.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconVisaAlt.defaultProps = {
  color: '#777777',
  size: '49px',
};

export default IconVisaAlt;
export { IconVisaAlt };
