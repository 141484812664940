import React from 'react';

interface IconList {
  color?: string;
}

const IconList = React.memo(({ color = '#7F7F7F' }: IconList) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.21875 13.5H22.7812"
      stroke="white"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.21875 6.75H22.7812"
      stroke="white"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.21875 20.25H22.7812"
      stroke="white"
      strokeWidth="1.6875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconList;
export { IconList };
