import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

const IconCalendarPlus = React.memo(({ color = '#fff', className }: Props) => (
  <svg
    width="14"
    height="15"
    className={className}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.375 3.16309H2.625C2.38338 3.16309 2.1875 3.35896 2.1875 3.60059V12.3506C2.1875 12.5922 2.38338 12.7881 2.625 12.7881H11.375C11.6166 12.7881 11.8125 12.5922 11.8125 12.3506V3.60059C11.8125 3.35896 11.6166 3.16309 11.375 3.16309Z"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.625 2.28809V4.03809"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.375 2.28809V4.03809"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.1875 5.78809H11.8125"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.53125 9.28809H5.46875"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7.75684V10.8193"
      stroke={color}
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconCalendarPlus;
export { IconCalendarPlus };
