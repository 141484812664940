import { css } from 'styled-components';

const styles = ({ theme, bgimage }) => css`
  position: relative;

  .avatar-image {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 2.4rem;
    background-color: ${theme.colorPallete.base[100]};
    background-image: url(${bgimage});
    background-size: cover;
    background-position: center;
  }

  .notification-badge {
    width: 1rem;
    height: 1rem;
    border-radius: 3rem;
    background-color: ${theme.colorPallete.primary.default};
    display: block;
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 0;
  }
`;

export default styles;
export { styles };
