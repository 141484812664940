/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import { Picture } from '../Picture';
import { PosterBadge } from '../PosterBadge';
import { ProgressBar } from '../ProgressBar';

import { styles } from './PosterCard.styles';

const Wrapper = styled.article`
  ${styles}
`;

const PosterCard = ({ onMouseEnter, onMouseLeave, content, resize }) => {
  const cssClasses = classNames({
    'poster-card': true,
    details: content.details,
    resize,
  });

  return (
    <Wrapper
      className={cssClasses}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="list-gap">
        {content.mark && (
          <PosterBadge
            className="poster-card__marker"
            color={content.mark.color ? content.mark.color : 'red'}
            title={content.mark.text}
            resize={resize}
          />
        )}
        {content.slug ? (
          (<Link as={content.slug} href="/curso/[slug]" className="poster-card__link">

            <Card content={content} />

          </Link>)
        ) : (
          <Card content={content} />
        )}
      </div>
    </Wrapper>
  );
};

PosterCard.propTypes = {
  resize: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  content: PropTypes.shape({
    details: PropTypes.string,
    mark: PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string,
    }),
    slug: PropTypes.string,
  }),
};

PosterCard.defaultProps = {
  resize: false,
  onMouseEnter: null,
  onMouseLeave: null,
  content: {
    details: null,
    mark: {
      text: null,
      color: null,
    },
    slug: null,
  },
};

const Card = ({ content }) => {
  const time = content.progress ? content.progress.toString() : '0';

  return (
    <>
      {/* img card */}
      {content.image ? (
        <Picture
          src={content.image.path_storage}
          variant="storage"
          alt={content.image.alt ? content.image.alt : ''}
          useLazingLoading={true}
        />
      ) : (
        <span className="poster-card__no-image">
          <></>
        </span>
      )}

      {content.title && content.course !== 'serie' && (
        <div className="poster-card__title">{content.autor.title}</div>
      )}

      <div className="poster-card__progress">
        <ProgressBar time={time} />
      </div>
    </>
  );
};

Card.propTypes = {
  content: PropTypes.shape({
    autor: PropTypes.shape({
      title: PropTypes.string,
    }),
    course: PropTypes.string,
    image: PropTypes.shape({
      path_storage: PropTypes.string,
      alt: PropTypes.string,
    }),
    progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
  }),
};

Card.defaultProps = {
  content: {
    autor: {
      type: null,
    },
    course: null,
    image: {
      path_storage: null,
      alt: null,
    },
    progress: null,
    title: null,
  },
};

export default PosterCard;
export { PosterCard };
