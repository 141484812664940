import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UseFeatureToggle } from './types';

export const useFeatureToggle: UseFeatureToggle = ({
  ComponentWhenEnabled,
  ComponentWhenDisabled,
  featureKeys,
}) => {
  const [enabled, setEnabled] = useState(false);

  const {
    userReducer: { userData },
  } = useSelector((state: any) => state);

  const userFeatures = userData?.features;

  const areAllFeaturesEnabled = (features: string[]) => {
    if (!userFeatures) return false;
    return features?.every(
      (key) =>
        userFeatures?.some((obj) => obj.name === key && obj.isEnabled === true),
    );
  };

  const renderComponent: React.FC = () => {
    if (enabled) {
      return <ComponentWhenEnabled />;
    } else if (ComponentWhenDisabled) {
      return <ComponentWhenDisabled />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setEnabled(areAllFeaturesEnabled(featureKeys));
  }, [!!userData, featureKeys]);

  return renderComponent;
};
