import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseGetCheckCourseProgressProps } from './types';

export const useGetCheckCourseProgress = (
  msApiLib: MSApi,
  query?: UseGetCheckCourseProgressProps,
) => {
  const { enabled, cacheTime, bearerToken } = query ?? {};

  return useQuery(
    ['useGetCheckCourseProgress'],
    async () => {
      const res = await msApiLib.v3.courses.checkCourseProgress({
        bearerToken,
        query,
      });

      return res.data;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
