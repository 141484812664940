import { css } from 'styled-components';

const styles = ({ theme }) => css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding-bottom: 20px;
  margin: 2rem -5px 0px;
  overflow-x: visible;
  width: auto;

  &.wrap {
    @media all and (max-width: ${theme.breakpoints.bigger}) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media all and (max-width: ${theme.breakpoints.big}) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media all and (max-width: 998px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media all and (max-width: ${theme.breakpoints.small}) {
      grid-template-columns: repeat(2, 1fr);
      margin: 20px -5px 0;
    }
  }

  .poster-card {
    transition-delay: 10ms;
  }
`;

export default styles;
export { styles };
