/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  commentsData: null,
  error: null,
  lessonData: null,
  loading: null,
  videoUnavailable: false,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.lessonReducer,
      };

    /**
     * ---------------------------------------------------
     * GET LESSON
     * ---------------------------------------------------
     */
    case types.GET_LESSON_REQUEST:
      return { ...state, lessonData: null, loading: true, error: false };
    case types.CLEAR_LESSON:
      return { ...state, lessonData: null };
    case types.GET_LESSON_SUCCESS:
      return {
        ...state,
        lessonData: action.payload.lessonData,
        loading: false,
        error: false,
      };
    case types.GET_LESSON_FAILURE:
      return {
        ...state,
        lessonData: action.payload.lessonData,
        loading: false,
        error: true,
      };

    /**
     * ---------------------------------------------------
     * UPDATE LESSON
     * ---------------------------------------------------
     */
    case types.UPDATE_LESSON_CONTENT:
      return {
        ...state,
        lessonData: action.payload.lessonData,
        loading: false,
        error: false,
      };

    /**
     * ---------------------------------------------------
     * GET COMMENTS
     * ---------------------------------------------------
     */
    case types.GET_COMMENTS_REQUEST:
      return { ...state, commentsData: null, loading: true, error: false };
    case types.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsData: action.payload.commentsData,
        loading: false,
        error: false,
      };
    case types.GET_COMMENTS_FAILURE:
      return {
        ...state,
        commentsData: action.payload.commentsData,
        loading: false,
        error: true,
      };

    /**
     * ---------------------------------------------------
     * SEND COMMENTS
     * ---------------------------------------------------
     */
    case types.SEND_COMMENTS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsData: action.payload.commentsData,
        loading: false,
        error: false,
      };
    case types.SEND_COMMENTS_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * VIDEO_UNAVAILABLE
     * ---------------------------------------------------
     */
    case types.TOGGLE_VIDEO_UNAVAILABLE:
      return { ...state, videoUnavailable: action.payload };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
