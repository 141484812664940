import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 100%;
  padding: 3.6rem 1rem;
  border-radius: 0.4rem;
  background-color: ${theme.colorPallete.white};

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding: 3.6rem;
  }
`;

export default styles;
export { styles };
