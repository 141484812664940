import { callApiBase } from './base';

const passwordApi = {
  forgot: ({ data }) =>
    callApiBase({
      title: `Auth - Send token to email POST`,
      endpoint: '/password/forgot',
      method: 'POST',
      data,
    }),
  validatePasswordToken: ({ params }) =>
    callApiBase({
      title: `Auth - Validate Password Token GET`,
      endpoint: '/password/validate-password-token',
      method: 'GET',
      params,
    }),
  reset: ({ data }) =>
    callApiBase({
      title: `Auth - Reset token via email PUT`,
      endpoint: '/password/reset',
      method: 'PUT',
      data,
    }),
  newPassword: ({ data, token }) =>
    callApiBase({
      title: `Auth - Change password PUT`,
      endpoint: '/password/new-password',
      method: 'PUT',
      data,
      token,
    }),
};

export default passwordApi;
export { passwordApi };
