/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Themes
import { main as theme } from '@themes';

// Components
import { Button, IconAttachments, Text } from '@components';

// Actions
import { actions as courseActions } from '@store/ducks/course';

import { VideoThumb } from '../VideoThumb';

import { styles } from './LessonCard.styles';

const Wrapper = styled.div`
  ${styles}
`;

const LessonCard = ({
  className,
  lessonDescription,
  lessonNumber,
  lessonPhoto,
  lessonSubtitle,
  lessonTitle,
  lessonSequence,
  lessonAttachments,
  lessonUrl,
  lessonProgress,
  modalContent,
}) => {
  const dispatch = useDispatch();
  const setShowModal = (value) => {
    dispatch(courseActions.setCourseModal({ payload: { courseModal: value } }));
  };

  // Split desciptions
  const lessonDescriptionDesk = lessonDescription;
  const lessonDescriptionMobile = lessonDescription;

  return (
    <Wrapper className={className}>
      <Link href="/curso/aula/[aula]" as={lessonUrl}>
        <div className="thumbnail">
          <VideoThumb
            className="thumbnail__video"
            img={lessonPhoto}
            source="external"
            time={lessonProgress !== null ? lessonProgress.toString() : '0'}
            radius="0.5rem"
          />
          <div className="lesson-number">{lessonNumber}</div>
        </div>
      </Link>
      <div className="info">
        <div className="info__content-container">
          <Text
            as="h4"
            className="info__lesson-title"
            color={theme.colorPallete.primary.default}
            size="1.8rem"
            dsize="1.8rem"
            weight={theme.fonts.fontWeight.bold}>
            {lessonTitle}
          </Text>
          <Text
            as="span"
            className="info__lesson-subtitle"
            color={theme.colorPallete.base[300]}
            size="1rem"
            dsize="1rem"
            transform="uppercase"
            weight={theme.fonts.fontWeight.bold}>
            {lessonSubtitle}
          </Text>
          {lessonSequence && (
            <Text
              as="span"
              className="info__lesson-subtitle"
              color={theme.colorPallete.base[500]}
              size="1rem"
              dsize="1rem"
              height="2"
              dheight="2"
              transform="uppercase"
              weight={theme.fonts.fontWeight.bold}>
              {lessonSequence.type && lessonSequence.name
                ? {
                    Documentário: (
                      <>{`${lessonSequence.type} . ${lessonSequence.name}`}</>
                    ),
                    LiveClass: <>{`${lessonSequence.name}`}</>,
                    Empreendedor: (
                      <>
                        {`Vídeo com ${lessonSequence.type} . ${lessonSequence.name}`}
                      </>
                    ),
                    Especialista: (
                      <>
                        {`Vídeo com ${lessonSequence.type} . ${lessonSequence.name}`}
                      </>
                    ),
                  }[lessonSequence.type] || (
                    <>{`${lessonSequence.type} . ${lessonSequence.name}`}</>
                  )
                : ``}
            </Text>
          )}

          <div className="info__lesson-description">
            <Text
              as="p"
              color={theme.colorPallete.base[400]}
              size="1.4rem"
              dsize="1.4rem"
              weight={theme.fonts.fontWeight.regular}>
              <span className="info__lesson-description--desk">
                {parseInt(lessonDescriptionDesk.length, 10) > 260
                  ? `${lessonDescriptionDesk.slice(0, 260)}...`
                  : lessonDescriptionDesk}
              </span>
              <span className="info__lesson-description--mobile">
                {parseInt(lessonDescriptionMobile.length, 10) > 100
                  ? `${lessonDescriptionMobile.slice(0, 100)}...`
                  : lessonDescriptionMobile}
              </span>
            </Text>
          </div>
        </div>
        <div className="info__buttons">
          <div className="info__buttons__details">
            <Button
              as="button"
              variant="hollow"
              onClick={() => setShowModal(modalContent)}
              type="button"
              size="big">
              <Text
                color="inherit"
                size="1.6rem"
                dsize="1.6rem"
                weight={theme.fonts.fontWeight.bold}>
                O que você vai aprender?
              </Text>
            </Button>
          </div>

          <span className="info__buttons--download">
            {lessonAttachments ? (
              <Link href={lessonAttachments} passHref>
                <IconAttachments
                  size="16px"
                  color={theme.colorPallete.primary.default}
                />
                <Text
                  className="info__buttons--download__text"
                  color={theme.colorPallete.base[400]}
                  size="1.4rem"
                  dsize="1.4rem"
                  weight={theme.fonts.fontWeight.regular}>
                  Análise do episódio
                </Text>
              </Link>
            ) : null}
          </span>
        </div>
      </div>
    </Wrapper>
  );
};
LessonCard.propTypes = {
  className: PropTypes.string,
  lessonAttachments: PropTypes.string,
  lessonDescription: PropTypes.string,
  lessonNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lessonPhoto: PropTypes.string,
  lessonSubtitle: PropTypes.string,
  lessonTitle: PropTypes.string,
  lessonSequence: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  lessonUrl: PropTypes.string,
  lessonProgress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  modalContent: PropTypes.shape({
    text: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

LessonCard.defaultProps = {
  className: 'lesson',
  lessonAttachments: null,
  lessonDescription: '',
  lessonNumber: 0,
  lessonPhoto: '',
  lessonSubtitle: '',
  lessonTitle: '',
  lessonProgress: 0.001,
  lessonProgress: 0.001,
  lessonSequence: {
    type: null,
    name: null,
  },
  modalContent: {},
  lessonUrl: '',
};

export default LessonCard;
export { LessonCard };
