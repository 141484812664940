import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import {
  PaginationDto,
  CourseListItemDto,
  GetRecommendedCoursesQueryDto,
  PaginationQueryDto,
} from 'libs/ms-api/dtos';
import qs from 'qs';

import { AxiosImpUtils } from '../utils';

type IRecommendedCoursesResource = MSApi['v3']['recommendedCourses'];

export class RecommendedCoursesResource implements IRecommendedCoursesResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(data: {
    bearerToken: string;
    query: PaginationQueryDto;
    params: GetRecommendedCoursesQueryDto;
  }): Promise<PaginationDto<CourseListItemDto[]>> {
    const parsedQuery = AxiosImpUtils.parserPaginationQuery(data.query);
    const query = qs.stringify(parsedQuery);

    const { courseSlug } = data.params;

    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(
      `v3/recommended-courses/${courseSlug}?${query}`,
    );
    return result;
  }
}
