import { MSApi } from 'libs/ms-api';
import { useMutation } from 'react-query';

import { UsePostCertificateDataProps } from './types';

export const usePostSocialNameData = (msApiLib: MSApi) => {
  return useMutation(
    async ({ bearerToken, query }: UsePostCertificateDataProps) => {
      const res = await msApiLib.v2.certificate.postSocialName({
        bearerToken,
        query,
      });

      return res;
    },
  );
};
