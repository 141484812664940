import styled from 'styled-components';
import { main as theme } from '@themes';

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;

  svg {
    width: 100%;
  }

  .small {
    display: block;
  }

  .medium {
    display: none;
  }

  .big {
    display: none;
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    .small {
      display: none;
    }

    .medium {
      display: block;
    }

    .big {
      display: none;
    }
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    .small {
      display: none;
    }

    .medium {
      display: none;
    }

    .big {
      display: block;
    }
  }
`;

export default Wrapper;
export { Wrapper };
