/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Themes
import { main } from '@themes';

// Components
import { Text } from '../Text';
import { Picture } from '../Picture';
import { ProgressBar } from '../ProgressBar';
import { IconDownload, IconPlayer, IconPlay } from '../Icons';

// Styles
import { styles } from './CourseBanner.styles';

// Helpers
import fileReaderPath from '@helpers/fileReaderPath';

const Wrapper = styled.article`
  ${styles}
`;

const CourseBanner = ({ content, color, openedVideo, toggleOpenedVideo }) => {
  const [viewMore, setViewMore] = useState(false);
  let imageBanner = null;
  let imageLogo = null;

  const { authStatus } = useSelector((state) => state.userReducer);

  const bannerImageExtractor = () => {
    // extract paths from banner
    const imageBannerDesk = content.imagens.filter(
      (el) => el.type === 'banner',
    );

    const imageBannerMobile = content.imagens.filter(
      (el) => el.type === 'banner-responsive',
    );

    // add and reordening
    imageBanner = {
      src: imageBannerDesk[0] ? imageBannerDesk[0].path : ``,
      alt: imageBannerDesk[0] ? imageBannerDesk[0].alt : null,
    };

    if (imageBannerMobile && imageBannerDesk) {
      imageBanner.srcSet = [
        {
          id: 1,
          max: '767px',
          src: imageBannerMobile[0]
            ? `/w_750,h_1244,c_fill,g_auto,q_auto/${imageBannerMobile[0].path}`
            : ``,
        },
        {
          id: 2,
          max: '1199px',
          src: `/w_1440,h_590,c_fill,g_auto,q_auto/${imageBannerDesk[0].path}`,
        },
        {
          id: 3,
          min: '1200px',
          src: `/w_2880,h_1180,c_fill,g_auto,q_auto/${imageBannerDesk[0].path}`,
        },
      ];
    }
  };

  const logoImageExtractor = () => {
    // extract paths from logo
    const imageLogoObj = content.imagens.filter((el) => el.type === 'logo');

    imageLogo = {
      src: imageLogoObj[0] ? imageLogoObj[0].path : null,
      alt: imageLogoObj[0] ? imageLogoObj[0].alt : content.title,
    };
  };

  bannerImageExtractor();
  logoImageExtractor();

  const cssClasses = classNames({
    'course-banner': true,
  });

  const courseAttach = (content) => {
    const uriEncode = btoa(`${content?.course_program?.url}`);
    return `/anexo/${content?.filetype}/Ementa - ${content?.title}/${uriEncode}`;
  };

  return (
    <Wrapper className={cssClasses} color={color}>
      {/* img background */}
      {imageBanner.src ? (
        <Picture
          className="course-banner__picture"
          src={imageBanner.src}
          srcSet={imageBanner.srcSet}
          alt={imageBanner.alt}
        />
      ) : null}

      {/* text content */}
      <div className="course-banner__content">
        <div className="course-banner__content__wrap">
          {/* Mobile progress */}
          <div className="course-banner__content__progress mobile-only ">
            <ProgressBar
              time={
                content?.progress !== null ? content.progress.toString() : '0'
              }
              color={color}
            />
            <span className="course-banner__content__progress--percent">
              <Text size="1.2rem" dsize="1.2rem" color={color}>
                {`${(content.progress * 100).toFixed(0)}%`}
              </Text>
            </span>
          </div>

          {content.type ? (
            <div className="course-banner__content__type">
              <Text size="1.6rem" dsize="1.6rem" weight="bold" color="white">
                {content.type}
              </Text>
            </div>
          ) : null}
          {!!content.title && !imageLogo ? (
            <div className="course-banner__content__title">
              <Text size="3.5rem" dsize="3.5rem" weight="bold" color={color}>
                {content.title}
              </Text>
            </div>
          ) : (
            <Picture
              className="course-banner__content__logo"
              src={imageLogo.src}
              alt={imageLogo.alt}
            />
          )}
          {content.subtitle ? (
            <div className="course-banner__content__subtitle">
              <Text size="1.2rem" dsize="1.2rem" weight="bold" color="white">
                {content.subtitle}
              </Text>
            </div>
          ) : null}
          {content.summary ? (
            <div className="course-banner__content__summary">
              {/* <Text
                size="1.2rem"
                dsize="1.2rem"
                weight="bold"
                color={main.colorPallete.base[300]}
              >
                {`${content.summary.videos} vídeos • ${content.summary.studies} horas de estudo • ${content.summary.complements} materiais complementares`}
              </Text> */}
            </div>
          ) : null}
          {content.description ? (
            <div
              className={`course-banner__content__details ${
                viewMore ? `more` : ``
              }`}>
              <Text size="1.4rem" dsize="1.4rem" color="white">
                {content.description}
              </Text>
            </div>
          ) : null}

          {/* Desktop Progress */}
          <div className="course-banner__content__progress desk-only">
            <ProgressBar
              time={
                content?.progress !== null ? content.progress.toString() : ''
              }
              color={color}
            />
            <span className="course-banner__content__progress--percent">
              <Text
                size="1.2rem"
                dsize="1.2rem"
                color={color}
                id="course__text__progress">
                {`${(content.progress * 100).toFixed(0)}%`}
              </Text>
            </span>
          </div>

          <div className="course-banner__content__cta--holder desk-only">
            {/* Trailler button - desk */}
            {content.video && (
              <button
                className="course-banner__content__cta__button--trailer"
                type="button"
                onClick={() => toggleOpenedVideo(!openedVideo)}>
                <Text
                  color={main.colorPallete.base[200]}
                  size="1rem"
                  dsize="1rem">
                  <IconPlay color="#fff" />
                  <span>Assista ao trailer</span>
                </Text>
              </button>
            )}
            {/* Course program button - desk */}
            {content?.course_program?.url && (
              <a
                className="course-banner__content__cta__button--course-program"
                target="_blank"
                rel="noreferrer"
                href={courseAttach(content)}>
                <Text
                  color={main.colorPallete.base[200]}
                  size="1rem"
                  dsize="1rem">
                  <IconDownload color={color} />
                  <span>Leia a proposta de estudo</span>
                </Text>
              </a>
            )}
          </div>

          <div className="course-banner__content__cta--mobile mobile-only">
            <button
              className="course-banner__cta-more"
              onClick={() => {
                setViewMore(!viewMore);
              }}
              type="button">
              <Text
                color={color}
                size="1.4rem"
                dsize="1.4rem"
                decoration="underline"
                cursor="pointer">
                {viewMore ? `Ver menos` : `Ver mais`}
              </Text>
            </button>
            {/* Trailler button - mobil */}
            {content.video && (
              <button
                className="course-banner__cta-trailer"
                type="button"
                onClick={() => toggleOpenedVideo(!openedVideo)}>
                <Text
                  color={main.colorPallete.base[200]}
                  size="1rem"
                  dsize="1rem">
                  <IconPlayer />
                  <span>Assistir trailer</span>
                </Text>
              </button>
            )}
          </div>

          {/* Course program button - mobile */}
          {content?.course_program?.url && (
            <div className="course-banner__content__cta--mobile mobile-only">
              <a
                className="course-banner__content__cta__button--course-program"
                target="_blank"
                rel="noreferrer"
                href={content?.course_program?.url}>
                <Text
                  color={main.colorPallete.base[200]}
                  size="1rem"
                  dsize="1rem">
                  <IconDownload color={color} />
                  <span>Leia a proposta de estudo</span>
                </Text>
              </a>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

CourseBanner.propTypes = {
  color: PropTypes.string,
  content: PropTypes.shape({
    autor: PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
    }),
    description: PropTypes.string,
    content: PropTypes.string,
    imagens: PropTypes.instanceOf(Array),
    order: PropTypes.number,
    progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    slug: PropTypes.string,
    summary: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        videos: PropTypes.number,
        studies: PropTypes.number,
        complements: PropTypes.number,
      }),
    ]),
    subtitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    video: PropTypes.shape({
      vimeo_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    course_program: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  openedVideo: PropTypes.bool,
  toggleOpenedVideo: PropTypes.func,
};

CourseBanner.defaultProps = {
  color: null,
  content: {
    autor: {
      type: null,
      title: null,
      slug: null,
    },
    description: null,
    content: null,
    imagens: [],
    order: null,
    progress: null,
    slug: null,
    summary: {},
    subtitle: null,
    title: null,
    type: null,
    course_program: {},
  },
  openedVideo: false,
  toggleOpenedVideo: () => '',
};

export default CourseBanner;
export { CourseBanner };
