import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1vw;
  position: relative;
`;

export const LinkContainer = styled.div`
  position: static;
  height: 24px;
  margin: 0px 24px 24px;
  .back-button {
    height: 24px;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  aspect-ratio: 3.2 / 1;

  @media (max-width: 769px) {
    flex-direction: column-reverse;
    aspect-ratio: 1 / 1;
    div {
      width: 100%;
    }
  }
`;

export const TextBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 45px;
  padding-bottom: 30px;
  padding-left: calc(10vw - 16px);
  padding-right: 16px;
  width: 45%;
  gap: 32px;

  .title {
    width: min(100%, 500px);
    height: auto;
    max-width: 100%;
    max-height: 6vw;

    aspect-ratio: 5.8;
  }
  .description {
    width: 90%;
    max-width: 90%;
    height: auto;
    aspect-ratio: 6.3;
  }
  .progress {
    width: 80%;
    max-width: 80%;
    height: auto;
    aspect-ratio: 12;
  }
  .button {
    width: 275px;
    height: 45px;
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 55%;
  div {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
  }

  @media (max-width: 769px) {
    aspect-ratio: 1.8;
  }
`;
