import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { main as theme } from '@themes';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

// Components
import { Button, Text, IconWarning, IconClose } from '@components';

// Styles
import { styles } from './UpdateBillingModal.styles';

const Wrapper = styled.div`
  ${styles}
`;

const UpdateBillingModal = React.memo(
  ({ visible, title, message, icon, customCallBack }) => {
    const [isVisible, setIsVisible] = useState(visible);
    const modclass = classNames({
      visible: isVisible === true,
    });

    const { push } = useRouter();

    const handleOnClick = () => {
      if (customCallBack) {
        customCallBack();
      }

      return setIsVisible(false);
    };

    const openProfile = () => {
      handleOnClick();
      return push(`/perfil`);
    };

    return (
      <Wrapper className={modclass}>
        <div className="update-billing__box">
          <div className="update-billing__box--close">
            <button type="button" onClick={handleOnClick}>
              <IconClose size="22px" color={theme.colorPallete.white} />
            </button>
          </div>

          <div className="update-billing__box__icon">
            <IconWarning
              size="52px"
              color={theme.colorPallete.primary.default}
            />
          </div>
          <Text
            dsize="2.4rem"
            size="2rem"
            color={theme.colorPallete.primary.default}
            weight={theme.fonts.fontWeight.bold}
          >
            {title}
          </Text>
          <Text
            dsize="1.4rem"
            size="1.4rem"
            color={theme.colorPallete.base[500]}
            weight={theme.fonts.fontWeight.bold}
          >
            {message}
          </Text>
          <div className="button">
            <Button variant="primary" size="medium" onClick={openProfile}>
              Ir para Perfil
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
);

UpdateBillingModal.propTypes = {
  customCallBack: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  message: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

UpdateBillingModal.defaultProps = {
  customCallBack: false,
  icon: null,
  message: 'Por favor, atualize seus dados cadastrais',
  title: 'Atualize os seus dados',
  visible: false,
};

export default UpdateBillingModal;
export { UpdateBillingModal };
