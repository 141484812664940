/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  favoriteData: null,
  loading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.favoriteReducer,
      };

    /**
     * ---------------------------------------------------
     * GET FAVORITE
     * ---------------------------------------------------
     */
    case types.GET_FAVORITE_REQUEST:
      return { ...state, favoriteData: null, loading: true, error: false };
    case types.GET_FAVORITE_SUCCESS:
      return {
        ...state,
        favoriteData: action.payload.favoriteData,
        loading: false,
        error: false,
      };
    case types.GET_FAVORITE_FAILURE:
      return { ...state, favoriteData: null, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * SEND FAVORITE
     * ---------------------------------------------------
     */
    case types.SEND_FAVORITE_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_FAVORITE_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.SEND_FAVORITE_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * DELETE FAVORITE
     * ---------------------------------------------------
     */
    case types.DELETE_FAVORITE_REQUEST:
      return { ...state, loading: true, error: false };
    case types.DELETE_FAVORITE_SUCCESS:
      return {
        ...state,
        favoriteData: action.payload.favoriteData,
        loading: false,
        error: false,
      };
    case types.DELETE_FAVORITE_FAILURE:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
}

export { actions, sagas };
