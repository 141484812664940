/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  keepWatchingData: null,
  loading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.keepWatchingReducer,
      };

    /**
     * ---------------------------------------------------
     * KEEP WATCHING
     * ---------------------------------------------------
     */
    case types.GET_KEEP_WATCHING_REQUEST:
      return { ...state, keepWatchingData: null, loading: true, error: false };

    case types.GET_KEEP_WATCHING_SUCCESS:
      return {
        ...state,
        keepWatchingData: action.payload.keepWatchingData,
        loading: false,
        error: false,
      };
    case types.GET_KEEP_WATCHING_FAILURE:
      return { ...state, keepWatchingData: null, loading: false, error: true };

    default:
      return state;
  }
}

export { actions, sagas };
