import React from 'react';
import PropTypes from 'prop-types';

const IconVolume = React.memo(({ size, color }) => (
  <svg
    id="icon-volume"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.24 8C14.24 11.15 12.01 13.58 9 14.31V16C12.99 15.24 16 11.94 16 8C16 4.05 12.99 0.75 9 0V1.68C12.01 2.41 14.24 4.84 14.24 8ZM7 15.5L2.4 10.44H0V5.54H2.39L7 0.48V15.5ZM12 7.99C12 6.4 10.74 5.05 9 4.54V11.44C10.74 10.93 12 9.59 12 7.99Z"
      fill={color}
    />
  </svg>
));

IconVolume.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconVolume.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconVolume;
export { IconVolume };
