import { callApiBase } from './base';

const checklistApi = {
  getChecklist: ({ data }) =>
    callApiBase({
      title: 'Checklist - Get questions',
      endpoint: `/checklist/${data.slug}`,
      method: 'GET',
    }),
  resetChecklist: ({ data }) =>
    callApiBase({
      title: 'Checklist - Reset user answers',
      endpoint: `/checklist/${data.slug}`,
      method: 'PUT',
    }),
  sendChecklistAnswers: ({ data,token }) =>
    callApiBase({
      title: 'Checklist - Send answers',
      endpoint: `/checklist/${data.lesson_slug}`,
      method: 'POST',
      data: data,
      token
    }),
};

export default checklistApi;
export { checklistApi };
