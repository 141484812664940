import React from 'react';
import PropTypes from 'prop-types';

const IconPerson = React.memo(({ size, color }) => (
  <svg
    id="icon-person"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      d="M8.00012 9.77692C8.91823 9.77692 9.78942 9.5612 10.5695 9.17326C13.0876 10.1214 14.9307 12.451 15.2127 15.2499H0.78756C1.0695 12.451 2.91262 10.1214 5.43076 9.17326C6.21083 9.5612 7.08202 9.77692 8.00012 9.77692Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M11.2757 4.02554C11.2757 5.83457 9.80918 7.30108 8.00015 7.30108C6.19112 7.30108 4.72461 5.83457 4.72461 4.02554C4.72461 2.21651 6.19112 0.75 8.00015 0.75C9.80918 0.75 11.2757 2.21651 11.2757 4.02554Z"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
));

IconPerson.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPerson.defaultProps = {
  color: '#565555',
  size: '16px',
};

export default IconPerson;
export { IconPerson };
