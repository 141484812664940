import React from 'react';
import * as S from './InvolvedPersonSkeleton.styles';
import { Skeleton } from '..';

const InvolvedPersonSkeleton = () => {
  const amountCards = ['card1', 'card2', 'card3', 'card4'];
  return (
    <S.Wrapper>
      <S.Content>
        <S.TextContainer>
          <Skeleton className="title" />
        </S.TextContainer>
        <S.EnvolvedPersonsContainer>
          {amountCards.map((card) => (
            <S.Card key={card}>
              <Skeleton className="avatar"></Skeleton>
              <S.TitlesCard>
                <Skeleton className="name"></Skeleton>
                <Skeleton className="bio"></Skeleton>
                <Skeleton className="bio"></Skeleton>
              </S.TitlesCard>
            </S.Card>
          ))}
        </S.EnvolvedPersonsContainer>
      </S.Content>
    </S.Wrapper>
  );
};

export default InvolvedPersonSkeleton;
export { InvolvedPersonSkeleton };
