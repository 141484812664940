import Link from 'next/link';

import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { useWindowSize } from 'helpers/useWindowSize';

import { Banner as BannerType } from 'libs/ms-api';
import * as S from './Banner.styles';

interface BannerProps {
  banner: BannerType;
}

const Banner = ({ banner }: BannerProps) => {
  const { width } = useWindowSize();
  const isExternalLink = (url: string) => {
    return url.startsWith('http') ? true : false;
  };
  const showSecondaryButton =
    banner?.button2?.category === 'live-class' || banner?.button2?.lesson_id;

  const isMobile = !!width && width < 768;
  const imagePath = 'https://res.cloudinary.com/redfuel/image/upload';
  const url = isMobile
    ? `${banner.imagens[2].path}`
    : `${banner.imagens[0].path}`;
  
    
  return (
    <S.Wrapper directionText={banner.direction_text} url={url}>
      <S.Content />
      <S.Content className="banner-content">
        <div>
          <S.Badge
            color={banner.mark.color}
            textColor={banner.mark.text_color}
            className={banner.mark.type}>
            {banner.mark.text}
          </S.Badge>
          <S.TitleImage src={`${banner.imagens[1].path}`} />
          <S.Description textColor={banner.text_color.description}>
            {banner.description}
          </S.Description>
          <S.ButtonContainer>
            {isExternalLink(banner.button?.slug) ? (
              <S.MainButton
                id={`banner`}
                color={banner.button.color}
                textColor={banner.button.text_color}
                className={banner.button.type}
                href={banner.button?.slug}
                target="_blank">
                {banner.button.text}
              </S.MainButton>
            ) : (
              <Link href={banner.button?.slug} passHref legacyBehavior>
                <S.MainButton
                  id={`banner`}
                  color={banner.button.color}
                  textColor={banner.button.text_color}
                  className={banner.button.type}>
                  {banner.button.text}
                </S.MainButton>
              </Link>
            )}

            {showSecondaryButton && (
              <SecondaryButton
                color={banner.button2.color}
                textColor={banner.button2.text_color}
                category={banner.button2.category}
                text={
                  banner.button2.category === 'class'
                    ? 'Lista'
                    : 'Salvar na agenda'
                }
                slug={banner.button2.slug}
              />
            )}
          </S.ButtonContainer>
        </div>
      </S.Content>
    </S.Wrapper>
  );
};

export default Banner;
export { Banner };
