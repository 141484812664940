import React from 'react';
import PropTypes from 'prop-types';

const IconMovie = React.memo(({ size, color }) => (
  <svg
    id="icon-movie"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24998 12.375V19.25H13.75V12.375H8.24998ZM8.24998 9.62501H13.75V2.75001H8.24998V9.62501ZM19.25 12.375H16.5V14.4375H19.25V12.375ZM5.50001 12.375H2.75001V14.4375H5.50001V12.375ZM16.5 9.62499H19.25V7.56249H16.5V9.62499ZM2.75001 9.62499H5.50001V7.56249H2.75001V9.62499ZM19.25 4.81251V2.75001H16.5V4.81251H19.25ZM5.50001 4.81251V2.75001H2.75001V4.81251H5.50001ZM0 20.625V1.375C0 0.615608 0.615608 0 1.375 0H20.625C21.3844 0 22 0.615608 22 1.375V20.625C22 21.3844 21.3844 22 20.625 22H1.375C0.615608 22 0 21.3844 0 20.625ZM2.75001 17.1875V19.25H5.50001V17.1875H2.75001ZM16.5 19.25H19.25V17.1875H16.5V19.25Z"
      fill={color}
    />
  </svg>
));

IconMovie.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconMovie.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconMovie;
export { IconMovie };
