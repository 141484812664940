import { callApiBase } from './base';

const notificationApi = {
  notification: ({ }) =>
    callApiBase({
      title: 'Notification',
      endpoint: '/user/notifications',
      method: 'GET',
    }),
  setnotification: ({ data }) =>
    callApiBase({
      title: 'Notification',
      endpoint: `/user/notifications`,
      method: 'PUT',
      data
    }),
};

export default notificationApi;
export { notificationApi };
