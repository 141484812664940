import * as types from './types';

/**
 * ---------------------------------------------------
 * SERIES
 * ---------------------------------------------------
 */
export const getSeriesRequest = ({ payload }) => ({
  type: types.GET_SERIES_REQUEST,
  payload,
});
export const getSeriesSuccess = ({ payload }) => ({
  type: types.GET_SERIES_SUCCESS,
  payload,
});
export const getSeriesFailure = () => ({
  type: types.GET_SERIES_FAILURE,
});
