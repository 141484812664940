/* eslint-disable import/no-cycle */
import { callApiBase } from './base';

const checklistApi = {
  getChecklist: ({ data, token }) =>
    callApiBase({
      title: 'Checklist - Get question',
      endpoint: `/checklist/${data.slug}`,
      method: 'GET',
      token,
    }),
  resetChecklist: ({ data, token }) =>
    callApiBase({
      title: 'Checklist - Reset user answers',
      endpoint: `/checklist/${data.slug}`,
      method: 'PUT',
      token,
    }),
  sendChecklistAnswers: ({ data, token }) =>
    callApiBase({
      title: 'Checklist - Send answers',
      endpoint: `/checklist/${data.lesson_slug}`,
      method: 'POST',
      data: data,
      token,
    }),
};

export default checklistApi;
export { checklistApi };
