import React from 'react';
import PropTypes from 'prop-types';

const IconComputer = React.memo(({ size, color }) => (
  <svg
    id="icon-computer"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.74977 14.6667H19.2498V2.75001H2.74977V14.6667ZM15.1034 17.4167L15.9326 19.6508C16.0047 19.8452 16.0417 20.051 16.0417 20.2583C16.0417 21.2161 15.2683 22 14.3049 22H7.69515C7.48413 22 7.27494 21.9614 7.07783 21.8863C6.18287 21.545 5.73569 20.5449 6.06752 19.6508L6.89671 17.4167H2.075C0.892878 17.4167 0 16.4189 0 15.2594V2.15722C0 0.997756 0.892878 0 2.075 0H19.925C21.1071 0 22 0.997756 22 2.15722V15.2594C22 16.4189 21.1071 17.4167 19.925 17.4167H15.1034ZM9.14945 19.25H12.8504L12.17 17.4167H9.82989L9.14945 19.25Z"
      fill={color}
    />
  </svg>
));

IconComputer.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconComputer.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconComputer;
export { IconComputer };
