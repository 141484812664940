import React from 'react';
import PropTypes from 'prop-types';

const IconGoogle = React.memo(({ size, color }) => (
  <svg
    id="icon-google"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11.1929C22 10.2873 21.9248 9.62648 21.7621 8.94119H11.1804V13.0285H17.3916C17.2664 14.0442 16.5902 15.5739 15.0874 16.6018L15.0664 16.7387L18.4121 19.2715L18.6439 19.2941C20.7728 17.3728 22 14.5459 22 11.1929Z"
      fill={color}
    />
    <path
      d="M11.2129 22C14.2385 22 16.7786 21.0223 18.6338 19.3358L15.0977 16.6472C14.1514 17.2949 12.8814 17.747 11.2129 17.747C8.24957 17.747 5.73445 15.8284 4.83788 13.1765L4.70646 13.1874L1.24738 15.8149L1.20215 15.9384C3.04491 19.5313 6.83008 22 11.2129 22Z"
      fill={color}
    />
    <path
      d="M4.80874 13.1332C4.57327 12.4519 4.43699 11.722 4.43699 10.9678C4.43699 10.2136 4.57327 9.48372 4.79635 8.8025L4.79012 8.65742L1.30384 5.99998L1.18978 6.05324C0.433787 7.53735 0 9.20395 0 10.9678C0 12.7317 0.433787 14.3982 1.18978 15.8823L4.80874 13.1332Z"
      fill={color}
    />
    <path
      d="M11.239 4.25287C13.3487 4.25287 14.7718 5.14499 15.5832 5.89052L18.7541 2.85971C16.8067 1.08767 14.2725 0 11.239 0C6.84472 0 3.0497 2.46862 1.20215 6.06155L4.83486 8.82353C5.74624 6.17158 8.26791 4.25287 11.239 4.25287Z"
      fill={color}
    />
  </svg>
));

IconGoogle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconGoogle.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconGoogle;
export { IconGoogle };
