/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  homeData: null,
  loading: null,
  filter: false,
  whichFilter: null,
  textFilter: null
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.catalogReducer,
      };

    /**
     * ---------------------------------------------------
     * HOME
     * ---------------------------------------------------
     */
    case types.GET_HOME_REQUEST:
      // console.log(action.payload)
      return { ...state, homeData: null, loading: true, error: false, filter: false, whichFilter: null, textFilter: null };
    case types.GET_HOME_SUCCESS:
      // console.log(action.payload)
      return {
        ...state,
        homeData: action.payload.homeData,
        filter: action.payload.filter,
        whichFilter: action.payload.whichFilter,
        textFilter: action.payload.textFilter,
        loading: false,
        error: false,
      };
    case types.GET_HOME_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
