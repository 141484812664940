import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { AuthResource } from './v2/auth';
import { Certificate } from './v2/certificate';
import { FilterTagsResource } from './v2/filterTags';
import { BannersResource } from './v3/banners';
import { CoursesResource } from './v3/courses';
import { LessonResource } from './v3/lesson';
import { PlaylistResource } from './v3/playlist';
import { RecommendedCoursesResource } from './v3/recommendedCourses';
import { WatchingResource } from './v3/watching';
import { CertificatesResource } from './v3/certificates';
import { MSApi } from '../contract';

export type ConfigWithAxios = {
  axiosInstance: AxiosInstance;
};

export type ConfigWithBaseUrl = AxiosRequestConfig;

export type FactoryConfig = ConfigWithAxios | ConfigWithBaseUrl;

export function msApiFactory(config: FactoryConfig): MSApi {
  let msApi: AxiosInstance;
  if (isConfigWithAxios(config)) {
    msApi = config.axiosInstance;
  } else {
    msApi = Axios.create(config);
  }

  const filterTags = new FilterTagsResource(msApi);
  const auth = new AuthResource(msApi);
  const certificate = new Certificate(msApi);
  const certificates = new CertificatesResource(msApi);
  const courses = new CoursesResource(msApi);
  const watching = new WatchingResource(msApi);
  const banners = new BannersResource(msApi);
  const recommendedCourses = new RecommendedCoursesResource(msApi);
  const playlist = new PlaylistResource(msApi);
  const lesson = new LessonResource(msApi);

  return {
    v2: {
      auth,
      filterTags,
      certificate,
    },
    v3: {
      courses,
      watching,
      banners,
      recommendedCourses,
      lesson,
      playlist,
      certificates,
    },
  };
}

function isConfigWithAxios(data: FactoryConfig): data is ConfigWithAxios {
  return !!(data as ConfigWithAxios)?.axiosInstance;
}
