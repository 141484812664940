import React from 'react';

interface Props {
  color?: string;
  size?: string;
  fillColor?: string;
}

const IconAlertCircle = React.memo(
  ({ color = '#ffffff', fillColor = '#F2B20C', size = '24px' }: Props) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 12.5C22.5 18.299 17.799 23 12 23C6.20101 23 1.5 18.299 1.5 12.5C1.5 6.70101 6.20101 2 12 2C17.799 2 22.5 6.70101 22.5 12.5Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M11 15.5006C11 15.3694 11.0259 15.2394 11.0761 15.1182C11.1264 14.9969 11.2 14.8868 11.2929 14.794C11.3858 14.7012 11.496 14.6275 11.6173 14.5773C11.7386 14.5271 11.8687 14.5012 12 14.5012C12.1313 14.5012 12.2614 14.5271 12.3827 14.5773C12.504 14.6275 12.6142 14.7012 12.7071 14.794C12.8 14.8868 12.8736 14.9969 12.9239 15.1182C12.9741 15.2394 13 15.3694 13 15.5006C13 15.7657 12.8946 16.0199 12.7071 16.2073C12.5196 16.3947 12.2652 16.5 12 16.5C11.7348 16.5 11.4804 16.3947 11.2929 16.2073C11.1054 16.0199 11 15.7657 11 15.5006ZM11.098 9.49938C11.0847 9.3733 11.098 9.24583 11.1372 9.12524C11.1764 9.00465 11.2405 8.89364 11.3253 8.7994C11.4102 8.70516 11.5139 8.62981 11.6298 8.57823C11.7457 8.52665 11.8711 8.5 11.998 8.5C12.1249 8.5 12.2503 8.52665 12.3662 8.57823C12.4821 8.62981 12.5858 8.70516 12.6707 8.7994C12.7555 8.89364 12.8196 9.00465 12.8588 9.12524C12.898 9.24583 12.9113 9.3733 12.898 9.49938L12.548 13.0042C12.5362 13.1419 12.4732 13.2701 12.3714 13.3636C12.2695 13.4571 12.1363 13.5089 11.998 13.5089C11.8597 13.5089 11.7265 13.4571 11.6246 13.3636C11.5228 13.2701 11.4598 13.1419 11.448 13.0042L11.098 9.49938Z"
        fill={fillColor}
      />
    </svg>
  ),
);

export default IconAlertCircle;
export { IconAlertCircle };
