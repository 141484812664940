/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as keepWatchingActions } from '@store/ducks/keepWatching';

// MSV2-api
import { keepWatchingApi as msv2KeepWatchingApi } from '@services/msv2-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * KEEP WATCHING
 * ---------------------------------------------------
 */
export function* getKeepWatching({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(msv2KeepWatchingApi.keepWatching, {
      method: 'GET',
      token: auth.token,
    });
    if (response.status === 200) {
      yield put(
        keepWatchingActions.getKeepWatchingSuccess({
          payload: {
            keepWatchingData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    yield put(keepWatchingActions.getKeepWatchingFailure());
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_KEEP_WATCHING_REQUEST, getKeepWatching);
}

export const sagas = [watchUser];
