import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import { FilterTagsItemListDto, ResponseApiV2Dto } from 'libs/ms-api/dtos';

type IFilterTagsResource = MSApi['v2']['filterTags'];

export class FilterTagsResource implements IFilterTagsResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(data: {
    bearerToken: string;
  }): Promise<ResponseApiV2Dto<FilterTagsItemListDto>> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(`/hats`);
    return result;
  }
}
