import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseGetPublicCertificateInformationProps } from './types';

export const useGetPublicCertificateInformation = (
  msApiLib: MSApi,
  query?: UseGetPublicCertificateInformationProps,
) => {
  const { enabled, cacheTime, params } = query ?? {};

  return useQuery(
    ['useGetPublicCertificateInformation', params],
    async () => {
      const res = await msApiLib.v3.certificates.publicCertificate({
        params,
      });

      return res.data;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
