import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import { ComponentStyles, RadioStyles } from './Radio.styles';

const RadioWrapper = styled.div`
  ${RadioStyles}
`;

const Wrapper = styled.div`
  ${ComponentStyles}
`;

const Radio = ({
  display,
  name,
  objects,
  required,
  validation,
  validationMsg,
  ...restProps
}) => {
  const { idTip } = restProps;

  const radioClasses = classNames({
    radio: true,
    line: display === 'line',
    collumn: display === 'column',
  });

  const cssValidation = classNames({
    'mp-input__tip': true,
    invalid: validation === 'invalid',
    valid: validation === 'valid',
  });

  return (
    <Wrapper className="ms-radio">
      <RadioWrapper className={radioClasses}>
        {objects.map((row) => (
          <div className="radio-item" key={`radio-item-${row.id}`}>
            <input
              id={row.id}
              name={name}
              type="radio"
              value={row.value}
              defaultChecked={row.checked || false}
              disabled={row.disabled}
              required={required}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restProps}
            />
            <label htmlFor={row.id} className="radio-label">
              <span className="checkfield">
                <span className="checkmark" />
              </span>
              <span className="label-content">{row.label}</span>
            </label>
          </div>
        ))}
      </RadioWrapper>
      {validationMsg !== '' ? (
        <span className={cssValidation} id={idTip ? `${idTip}--tip` : ``}>
          {validationMsg}
        </span>
      ) : null}
    </Wrapper>
  );
};

Radio.propTypes = {
  display: PropTypes.string,
  name: PropTypes.string.isRequired,
  objects: PropTypes.instanceOf(Array).isRequired,
  required: PropTypes.bool,
  validation: PropTypes.string,
  validationMsg: PropTypes.string,
};

Radio.defaultProps = {
  required: false,
  display: 'line',
  validation: null,
  validationMsg: '',
};

export default Radio;
export { Radio };
