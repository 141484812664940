/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as courseActions } from '@store/ducks/course';

// MSV2-api
import { courseApi as msv2CourseApi } from '@services/msv2-api';

// Mirror-api
import { courseApi as mirrorCourseApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * COURSE
 * ---------------------------------------------------
 */
export function* getCourse({ payload }) {
  const { auth, isServer, params } = payload;

  const request = isServer ? msv2CourseApi : mirrorCourseApi;
  const token = isServer ? auth.token : '';

  try {
    const response = yield call(request.course, {
      method: 'GET',
      token,
      data: { slug: params.slug },
    });

    if (response.status === 200) {
      yield put(
        courseActions.getCourseSuccess({
          payload: {
            courseData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    yield put(courseActions.getCourseFailure());
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_COURSE_REQUEST, getCourse);
}

export const sagas = [watchUser];
