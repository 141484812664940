import styled, { css } from 'styled-components';

interface AvatarWrapperProps {
  cursor: string;
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  ${({ theme, cursor }) => css`
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    font-weight: ${theme.fonts.fontWeight.bold};
    cursor: ${cursor};

    .initials {
      margin-bottom: 4px;
    }

    img {
      border-radius: 50%;
    }

    .skeleton {
      width: 44px;
      height: 44px;
    }
  `}
`;
