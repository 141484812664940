import React from 'react';
import PropTypes from 'prop-types';

const IconVisaCard = React.memo(({ color, size }) => (
  <svg
    id="icon-visa-card"
    viewBox="0 0 51 35"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.985 35C49.65 35 51 33.657 51 32V3.00001C50.9991 2.60504 50.9204 2.21412 50.7683 1.84958C50.6163 1.48504 50.394 1.15402 50.114 0.875434C49.834 0.596845 49.5019 0.376144 49.1366 0.225936C48.7713 0.0757291 48.38 -0.00104121 47.985 1.06644e-05H3.015C1.35 1.06644e-05 0 1.34301 0 3.00001V32C0.000918052 32.395 0.079639 32.7859 0.231666 33.1504C0.383694 33.515 0.606049 33.846 0.886031 34.1246C1.16601 34.4032 1.49814 34.6239 1.86343 34.7741C2.22872 34.9243 2.62003 35.0011 3.015 35H47.985ZM22.676 24.37L25.5 11H22.086L19.261 24.37H22.676ZM40.567 14.565L38.87 19.022H41.543L40.567 14.565ZM46 24.37H43.012L42.622 22.372H38.478L37.804 24.37H34.413L39.26 11.98C39.492 11.386 40.015 11 40.635 11H43.392L46 24.37ZM30.8887 16.3063C32.1941 17.0164 33.8254 17.9037 33.817 19.945C33.806 22.634 31.588 24.37 28.198 24.37C26.754 24.354 25.362 24.041 24.609 23.683L25.193 20.849C25.2157 20.86 25.2386 20.871 25.2617 20.8822C26.0007 21.2397 26.9342 21.6912 28.508 21.666C29.437 21.651 30.43 21.269 30.44 20.401C30.444 19.834 30.023 19.427 28.766 18.794C27.542 18.174 25.919 17.137 25.94 15.274C25.958 12.758 28.203 11 31.393 11C32.637 11 33.636 11.281 34.413 11.573L33.846 14.315C32.401 13.626 31.126 13.674 30.664 13.737C29.731 13.863 29.305 14.356 29.295 14.82C29.2825 15.4326 30.0121 15.8295 30.8887 16.3063ZM14.927 24.37L20.152 11H16.765L13.409 20.1L12.051 12.363C11.892 11.499 11.263 11 10.564 11H5.076L5 11.388C6.126 11.651 7.406 12.074 8.181 12.525C8.656 12.802 8.791 13.043 8.947 13.7L11.52 24.37H14.927Z"
      fill={color}
    />
  </svg>
));

IconVisaCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconVisaCard.defaultProps = {
  color: '#0d0c0c',
  size: '49px',
};

export default IconVisaCard;
export { IconVisaCard };
