import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const IconVibratingBell = React.memo(({ color = '#868585' }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 28H20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.02571 14C7.02242 12.8193 7.25213 11.6495 7.7017 10.5577C8.15126 9.46586 8.81184 8.47349 9.64558 7.63742C10.4793 6.80134 11.4699 6.13799 12.5604 5.68538C13.6509 5.23277 14.8201 4.99979 16.0008 4.99979C17.1816 4.99979 18.3507 5.23277 19.4413 5.68538C20.5318 6.13799 21.5223 6.80134 22.3561 7.63742C23.1898 8.47349 23.8504 9.46586 24.3 10.5577C24.7495 11.6495 24.9792 12.8193 24.9759 14V14C24.9759 18.477 25.9126 21.0749 26.7376 22.4948C26.8264 22.6465 26.8737 22.819 26.8747 22.9948C26.8756 23.1706 26.8302 23.3436 26.7429 23.4962C26.6557 23.6489 26.5297 23.7759 26.3778 23.8643C26.2258 23.9527 26.0532 23.9996 25.8774 24H6.12329C5.94744 23.9996 5.77482 23.9527 5.62284 23.8643C5.47087 23.7758 5.34491 23.6488 5.25767 23.4961C5.17044 23.3434 5.12502 23.1704 5.12599 22.9946C5.12696 22.8188 5.17429 22.6463 5.26321 22.4946C6.08862 21.0746 7.02571 18.4767 7.02571 14H7.02571Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9287 2.99854C24.9635 4.28311 26.6038 6.10497 27.6685 8.26299"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.33105 8.26299C5.39575 6.10497 7.03604 4.28311 9.07087 2.99854"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconVibratingBell;
export { IconVibratingBell };
