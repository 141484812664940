import * as types from './types';

/**
 * ---------------------------------------------------
 * HOME
 * ---------------------------------------------------
 */
export const getHomeRequest = ({ payload }) => ({
  type: types.GET_HOME_REQUEST,
  payload,
});
export const getHomeSuccess = ({ payload }) => ({
  type: types.GET_HOME_SUCCESS,
  payload,
});
export const getHomeFailure = () => ({
  type: types.GET_HOME_FAILURE,
});
