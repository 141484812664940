import { callApiBase } from './base';

const paymentsApi = {
  newCreditCard: ({ data, token }) =>
    callApiBase({
      title: `New credit card - POST`,
      endpoint: '/payments/new-credit-card',
      method: 'POST',
      data,
      token,
    }),
};

export default paymentsApi;
export { paymentsApi };
