import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { main } from '@themes';
import { styles } from './ProgressBar.styles';

const Wrapper = styled.span`
  ${styles}
`;

const ProgressBar = ({ time, color }) => {
  const decimalProgress = time > 0.985 ? 1 : time;

  return <Wrapper className='progress-bar' time={decimalProgress} color={color} />;
};

ProgressBar.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

ProgressBar.defaultProps = {
  time: 0,
  color: main.colorPallete.primary.default,
};

export default ProgressBar;
export { ProgressBar };
