import React from 'react';

const IconSend = React.memo(() => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="#CAD9CA" fill-opacity="0.08" />
    <path
      d="M31.7156 15.3656L14.2406 20.2875C14.0919 20.3281 13.9593 20.4137 13.8609 20.5324C13.7625 20.6512 13.7031 20.7974 13.6908 20.9511C13.6785 21.1048 13.7139 21.2586 13.7922 21.3915C13.8704 21.5244 13.9877 21.6299 14.1281 21.6937L22.1531 25.4906C22.3103 25.5634 22.4366 25.6896 22.5094 25.8468L26.3062 33.8718C26.3701 34.0122 26.4756 34.1296 26.6085 34.2078C26.7414 34.2861 26.8951 34.3215 27.0489 34.3092C27.2026 34.2969 27.3488 34.2375 27.4675 34.1391C27.5863 34.0407 27.6718 33.9081 27.7125 33.7593L32.6344 16.2843C32.6717 16.1568 32.674 16.0216 32.6411 15.8929C32.6081 15.7642 32.5412 15.6467 32.4472 15.5527C32.3533 15.4588 32.2358 15.3918 32.1071 15.3589C31.9784 15.326 31.8431 15.3283 31.7156 15.3656V15.3656Z"
      stroke="#5E665E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.3969 25.6031L26.6344 21.3656"
      stroke="#5E665E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
));

export default IconSend;
export { IconSend };
