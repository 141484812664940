import { callApiBase } from './base';

const lessonApi = {
  lesson: ({ data }) =>
    callApiBase({
      title: 'Catalog - Lesson',
      endpoint: `/lesson/${data.slug}`,
      method: 'GET',
    }),
  getComments: ({ data }) =>
    callApiBase({
      title: 'Catalog - Get Comments',
      endpoint: `/lesson/comment/${data.lessonId}`,
      method: 'GET',
    }),
  sendComments: ({ data }) =>
    callApiBase({
      title: 'Catalog - Send Comments',
      endpoint: `/lesson/comment`,
      method: 'POST',
      data,
    }),
};

export default lessonApi;
export { lessonApi };
