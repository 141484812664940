import * as types from './types';

/**
 * ---------------------------------------------------
 * SEND PROGRESS
 * ---------------------------------------------------
 */
export const sendProgressRequestV2 = ({ payload }) => ({
  type: types.SEND_PROGRESS_REQUEST_V2,
  payload,
});
export const sendProgressSuccessV2 = () => ({
  type: types.SEND_PROGRESS_SUCCESS_V2,
});
export const sendProgressFailureV2 = () => ({
  type: types.SEND_PROGRESS_FAILURE_V2,
});
export const updateProgressV2 = ({ payload }) => ({
  type: types.UPDATE_PROGRESS_V2,
  payload,
});
