/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Utils
import { refreshTokenFlow } from '@utils';

// Actions
import { actions as lessonActions } from '@store/ducks/lesson';

// MSV2-api
import {
  lessonApi as msv2LessonApi,
  catalogApi as msv2CatalogApi,
} from '@services/msv2-api';
// Mirror-api
import { lessonApi as mirrorLessonApi } from '@services/mirror-api';

import Cookies from 'js-cookie';

import * as types from './types';

/**
 * ---------------------------------------------------
 * GET LESSON
 * ---------------------------------------------------
 */
export function* getLesson({ payload }) {
  const { auth, params, isServer } = payload;

  const request = isServer ? msv2LessonApi : mirrorLessonApi;
  const token = isServer ? auth.token : '';

  try {
    const response = yield call(request.lesson, {
      method: 'GET',
      token,
      data: { slug: params.slug },
    });

    if (response.status === 200) {
      yield put(
        lessonActions.getLessonSuccess({
          payload: {
            lessonData: {
              ...response.data.content.instance,
              reduxSlug: params.slug,
            },
          },
        })
      );
    }
  } catch (error) {
    if (error && error.response.status !== 401) {
      // Course not found
      yield put(
        lessonActions.getLessonFailure({
          payload: {
            lessonData: 'not found',
          },
        })
      );
    } else {
      yield put(
        lessonActions.getLessonFailure({
          payload: {
            lessonData: false,
          },
        })
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * GET COMMENTS
 * ---------------------------------------------------
 */
export function* getComments({ payload }) {
  const { data } = payload;

  const request =  msv2LessonApi;

  const token = Cookies.get('tokenClient')
  const refreshToken = Cookies.get('refreshtokenClient')

  try {
    const response = yield call(request.getComments, {
      method: 'GET',
      token,
      data,
    });

    if (response.status === 200) {
      yield put(
        lessonActions.getCommentsSuccess({
          payload: {
            commentsData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    if (
      error.response.status === 401 &&
      refreshToken !== ''
    ) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2CatalogApi.newPassword,
        null, // res
        refreshToken,
        { refresh_token: refreshToken, data: payload.data }, // payload
      );

      if (responseReq.status === 200) {
        yield put(
          lessonActions.getCommentsSuccess({
            toast: {
              type: 'success',
              message: responseReq.data,
            },
          })
        );
      }
    } else {
      yield put(lessonActions.getCommentsFailure());
    }
  }
}

/**
 * ---------------------------------------------------
 * SEND COMMENTS
 * ---------------------------------------------------
 */
export function* sendComments({ payload }) {
  const { auth, data } = payload;

  try {
    const response = yield call(mirrorLessonApi.sendComments, {
      method: 'POST',
      data,
    });

    if (response.status === 200) {
      yield put(
        lessonActions.sendCommentsSuccess({
          payload: {
            commentsData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2CatalogApi.sendComments,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(lessonActions.sendCommentsSuccess());
      }
    } else {
      yield put(lessonActions.sendCommentsFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_LESSON_REQUEST, getLesson);
  yield takeEvery(types.GET_COMMENTS_REQUEST, getComments);
  yield takeEvery(types.SEND_COMMENTS_REQUEST, sendComments);
}

export const sagas = [watchUser];
