import { MSApi, Lesson } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseGetPlaylistProps } from './types';

const findNextLesson = (lessons: Lesson[]) => {
  const nextLesson = lessons.find((lesson) => {
    return (
      lesson.type !== 'quiz' &&
      (lesson.progress === null || !lesson?.progress?.completed)
    );
  });
  if (nextLesson === null) return lessons[0];
  else return nextLesson;
};

export const useGetPlaylist = (
  msApiLib: MSApi,
  queryAndParams: UseGetPlaylistProps,
) => {
  const { enabled, cacheTime, params, bearerToken } = queryAndParams ?? {};
  const { moduleSlug } = params;

  return useQuery(
    ['getPlaylist', params],
    async () => {
      const res = await msApiLib.v3.playlist.list({
        bearerToken,
        moduleSlug,
      });

      const nextLesson = findNextLesson(res.data.lessons);

      return { ...res.data, nextLesson };
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
