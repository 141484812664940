import * as types from './types';

/**
 * ---------------------------------------------------
 * CASE STUDIES
 * ---------------------------------------------------
 */
export const getCaseStudiesRequest = ({ payload }) => ({
  type: types.GET_CASE_STUDIES_REQUEST,
  payload,
});
export const getCaseStudiesSuccess = ({ payload }) => ({
  type: types.GET_CASE_STUDIES_SUCCESS,
  payload,
});
export const getCaseStudiesFailure = () => ({
  type: types.GET_CASE_STUDIES_FAILURE,
});
