import styled from 'styled-components';

export const SkeletorWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 16px;
  gap: 20px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 998px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 20px -5px 0px;
  }

  div {
    width: 100%;
    max-width: 100%;
    height: auto;

    aspect-ratio: 0.6;
  }
`;
