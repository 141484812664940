import axios, { CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios';

import { getUserAuthToken } from 'utils';

import errorLogger from 'utils/monitoring/errorLogger';

export function baseApiFactory(
  BASE_URL?: string,
  config?: CreateAxiosDefaults,
) {
  if (!BASE_URL) {
    throw new Error('BASE_URL env var missing');
  }
  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Client-Device': 'browser',
      'Accept-Language': 'pt-BR',
      ...(config?.headers && config?.headers),
    },
    timeout: 30000,
    validateStatus: (status) => status >= 200 && status < 300,
  });

  api.interceptors.request.use((config: any) => {
    const jwtToken: string = getUserAuthToken();

    if (config?.headers) {
      config.headers = {
        ...config.headers,
        ...(!config?.headers['Authorization'] &&
          jwtToken && { Authorization: `Bearer ${jwtToken}` }),
      };
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      errorLogger.captureHTTPException(error);

      return Promise.reject(error);
    },
  );

  return api;
}
