/* eslint-disable import/no-cycle */
import { callApiBase } from './base';

const catalogApi = {
  home: ({ token, refreshToken }, data) =>
    callApiBase({
      title: 'Catalog - Home',
      endpoint: `/home${data.slug ? "/" + data.slug : ""}`,
      // endpoint: `/home`,
      method: 'GET',
      token,
      refreshToken,
    }),
};

export default catalogApi;
export { catalogApi };
