export const GET_BR_STATES_REQUEST = 'place/GET_BR_STATES_REQUEST';
export const GET_BR_STATES_SUCCESS = 'place/GET_BR_STATES_SUCCESS';
export const GET_BR_STATES_FAILURE = 'place/GET_BR_STATES_FAILURE';
export const GET_BR_STATES_MOCK = 'place/GET_BR_STATES_MOCK';

export const GET_BR_CITIES_REQUEST = 'place/GET_BR_CITIES_REQUEST';
export const GET_BR_CITIES_SUCCESS = 'place/GET_BR_CITIES_SUCCESS';
export const GET_BR_CITIES_FAILURE = 'place/GET_BR_CITIES_FAILURE';

export const GET_CEP_REQUEST = 'place/GET_CEP_REQUEST';
export const GET_CEP_SUCCESS = 'place/GET_CEP_SUCCESS';
export const GET_CEP_FAILURE = 'place/GET_CEP_FAILURE';
