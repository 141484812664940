import { css } from 'styled-components';

const styles = ({ bgcolor, color, theme }) => css`
  width: 100%;
  background-color: black;
  border-radius: 0.4rem;
  overflow: hidden;

  a {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    color: ${color};
    background-color: ${bgcolor};
    line-height: normal;
    font-size: 1.6rem;
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    cursor: pointer;

    &:hover {
      opacity: 0.95;
    }
  }

  &.big {
    height: 6rem;
    min-height: 6rem;
    max-height: 6rem;
  }

  &.regular {
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;
  }

  &.small {
    height: 3rem;
    min-height: 3rem;
    max-height: 3rem;
  }
`;

export default styles;
export { styles };
