import { Skeleton } from '@skeletons';
import { useWindowSize } from '@helpers/useWindowSize';
import * as S from './BannerSkeleton.styles';

const BannerSkeleton = () => {
  const { width } = useWindowSize();
  const isMobile = !!width && width < 769;

  return (
    <S.Wrapper>
      <S.Content>
        <S.InfosContent>
          <S.Description>
            <Skeleton width={60} height={20} />
            <Skeleton width={360} height={isMobile ? 60 : 65} />
            <Skeleton width={390} height={isMobile ? 74 : 88} />
          </S.Description>
          <S.ButtonsContainer>
            <Skeleton width={180} height={33} />
            <Skeleton width={76} height={33} />
          </S.ButtonsContainer>
        </S.InfosContent>
      </S.Content>
      <S.Image>
        <Skeleton mb={isMobile ? 16 : undefined} />
      </S.Image>
    </S.Wrapper>
  );
};

export default BannerSkeleton;
export { BannerSkeleton };
