/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import Link from 'next/link';
import { main as theme } from '@themes';

// Helpers
import { verifyDinamicRoute } from '@helpers';

import { styles } from './CustomButton.styles';

const Wrapper = styled.div`
  ${styles}
`;

const CustomButton = React.memo(
  ({ bgcolor, children, color, size, url, id }) => {
    const classes = classNames({
      small: size === 'small',
      regular: size === 'regular',
      big: size === 'big',
    });

    return (
      <Wrapper bgcolor={bgcolor} color={color} className={classes}>
        <Link
          href={url && verifyDinamicRoute(url)}
          passHref
          as={url}
          id={id}
          className="button__custom">

          {children}

        </Link>
      </Wrapper>
    );
  }
);

CustomButton.propTypes = {
  bgcolor: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  url: PropTypes.string,
};

CustomButton.defaultProps = {
  bgcolor: theme.colorPallete.primary.default,
  color: theme.colorSystem.white,
  id: '',
  size: 'regular',
  url: '/home',
};

export default CustomButton;
export { CustomButton };
