import { callApiBase } from './base';

const passwordApi = {
  forgot: ({ data }) =>
    callApiBase({
      title: `Send token to email POST`,
      endpoint: '/password/forgot',
      method: 'POST',
      data,
    }),
  validatePasswordToken: ({ params }) =>
    callApiBase({
      title: `Validate Password Token GET`,
      endpoint: '/password/validate-password-token',
      method: 'GET',
      params,
    }),
  reset: ({ data }) =>
    callApiBase({
      title: `Reset token via email PUT`,
      endpoint: '/password/reset',
      method: 'PUT',
      data,
    }),
  newPassword: ({ data }) =>
    callApiBase({
      title: `Change password PUT`,
      endpoint: '/password/new-password',
      method: 'PUT',
      data,
    }),
};

export default passwordApi;
export { passwordApi };
