import { put, takeEvery, call } from 'redux-saga/effects';

// Utils
import { refreshTokenFlow } from '@utils';

// Actions
import { actions as favoriteActions } from '@store/ducks/favorite';

// Mirror-api
import { favoriteApi as mirrorFavoriteApi } from '@services/mirror-api';

// MSV2-api
import {
  favoriteApi as msv2FavoriteApi,
  profileApi as msv2ProfileApi,
} from '@services/msv2-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * GET FAVORITE
 * ---------------------------------------------------
 */
export function* getFavorite({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(msv2FavoriteApi.favorite, {
      method: 'GET',
      token: auth.token,
    });
    if (response.status === 200) {
      yield put(
        favoriteActions.getFavoriteSuccess({
          payload: {
            favoriteData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    yield put(favoriteActions.getFavoriteFailure());
  }
}

/**
 * ---------------------------------------------------
 * SEND FAVORITE
 * ---------------------------------------------------
 */
export function* sendFavorite({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorFavoriteApi.sendFavorite, {
      method: 'POST',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(favoriteActions.sendFavoriteSuccess());
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.sendFavorite,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(favoriteActions.sendFavoriteSuccess());
      }
    } else if (error.response) {
      yield put(favoriteActions.sendFavoriteFailure());
    }
  }
}

/**
 * ---------------------------------------------------
 * DELETE FAVORITE
 * ---------------------------------------------------
 */
export function* deleteFavorite({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorFavoriteApi.deleteFavorite, {
      method: 'DELETE',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(
        favoriteActions.deleteFavoriteSuccess({
          payload: {
            favoriteData: response.data.content.instance,
          },
        })
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.deleteFavorite,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          favoriteActions.deleteFavoriteSuccess({
            payload: {
              favoriteData: responseReq.data.content.instance,
            },
          })
        );
      }
    } else if (error.response) {
      yield put(favoriteActions.deleteFavoriteFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_FAVORITE_REQUEST, getFavorite);
  yield takeEvery(types.SEND_FAVORITE_REQUEST, sendFavorite);
  yield takeEvery(types.DELETE_FAVORITE_REQUEST, deleteFavorite);
}

export const sagas = [watchUser];
