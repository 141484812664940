import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: trasparent;
    padding-block: 2.7rem;

    padding-inline: 20px;
    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    height: 20px;
  }
  .subtitle {
    height: 15px;
    width: 350px;
    max-width: 350px;
  }
`;

export const ButtonsContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    gap: 24px;
    max-width: 100%;
    overflow: scroll;
    white-space: nowrap;

    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 230px;
      gap: 8px;
      height: 44px;
      border-radius: 4px;
      padding: 12px 16px;

      @media (max-width: 768px) {
        min-width: 180px;
      }

      &:last-child {
        margin-right: 22px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;
