import React from 'react';
import PropTypes from 'prop-types';

const IconRedo = React.memo(({ size, color }) => (
  <svg
    width={size}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1078 4.55084C16.9235 2.48523 14.9316 0.954732 12.6431 0.351882L12.5986 0.34463C12.5655 0.342779 12.5337 0.334292 12.5039 0.319479L12.4615 0.303432C9.89404 -0.383511 7.17666 0.108397 5.00602 1.65248C4.544 1.98021 4.43236 2.62611 4.7572 3.09225C5.08203 3.55854 5.72223 3.67118 6.18456 3.34314C7.85952 2.1521 9.95628 1.77221 11.938 2.30162L11.9838 2.30902C12.0165 2.31087 12.048 2.31921 12.0771 2.33386L12.121 2.35037C15.8139 3.32925 18.0289 7.15681 17.0588 10.8828C16.5889 12.6877 15.4504 14.2 13.853 15.1409C12.2555 16.082 10.3909 16.339 8.60172 15.8647C5.23619 14.9727 3.08177 11.7192 3.48323 8.29416C3.79736 8.65538 4.33127 8.7569 4.76087 8.50956C5.24996 8.22781 5.42186 7.59657 5.14382 7.10188L3.97263 5.02624C3.83728 4.78615 3.61751 4.6138 3.35369 4.54066C3.09018 4.46768 2.81383 4.5027 2.57555 4.6391L0.518246 5.82058C0.280429 5.95698 0.109445 6.17886 0.0369533 6.44503C-0.0353858 6.7112 -0.000822063 6.99002 0.134527 7.2298C0.269876 7.46973 0.489646 7.64224 0.753463 7.71538C1.00596 7.78512 1.26962 7.75627 1.50025 7.63406C0.730675 12.3333 3.73711 16.8908 8.40046 17.9401C9.05579 18.0876 9.71572 18.1604 10.3712 18.1604C12.0555 18.1604 13.7086 17.6791 15.1649 16.7458C17.1876 15.4492 18.589 13.4354 19.1108 11.0756C19.604 8.84408 19.2478 6.52681 18.1078 4.55084Z"
      fill={color}
    />
  </svg>
));

IconRedo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconRedo.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconRedo;
export { IconRedo };
