/**
 * ---------------------------------------------------
 * SEARCH
 * ---------------------------------------------------
 */
export const SEND_SEARCH_REQUEST = '@search/SEND_SEARCH_REQUEST';
export const SEND_SEARCH_SUCCESS = '@search/SEND_SEARCH_SUCCESS';
export const SEND_SEARCH_FAILURE = '@search/SEND_SEARCH_FAILURE';

/**
 * ---------------------------------------------------
 * CLEAN SEARCH
 * ---------------------------------------------------
 */
export const SEND_SEARCH_CLEAN_SUCCESS = '@search/SEND_SEARCH_CLEAN_SUCCESS';
