import React from 'react';
import PropTypes from 'prop-types';

const IconCellphone = React.memo(({ size, color }) => (
  <svg
    id="icon-cellphone"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58291 2.75001H7.19486C6.50833 2.75001 6.32951 2.78454 6.1485 2.88135C6.0296 2.94493 5.94451 3.03003 5.88092 3.14892C5.78411 3.32994 5.74958 3.50876 5.74958 4.19528V17.8047C5.74958 18.4913 5.78411 18.6701 5.88092 18.8511C5.94451 18.97 6.0296 19.0551 6.1485 19.1187C6.32951 19.2155 6.50833 19.25 7.19486 19.25H14.3876C15.0742 19.25 15.253 19.2155 15.434 19.1187C15.5529 19.0551 15.638 18.97 15.7016 18.8511C15.7984 18.6701 15.8329 18.4913 15.8329 17.8047V4.19528C15.8329 3.50876 15.7984 3.32994 15.7016 3.14892C15.638 3.03003 15.5529 2.94493 15.434 2.88135C15.253 2.78454 15.0742 2.75001 14.3876 2.75001H13.9996C13.9996 3.5094 13.384 4.12501 12.6246 4.12501H8.95791C8.19852 4.12501 7.58291 3.5094 7.58291 2.75001ZM7.19527 0H14.3881C15.4897 0 16.0974 0.117353 16.7313 0.456353C17.3295 0.77624 17.8071 1.25388 18.127 1.85202C18.466 2.4859 18.5833 3.0936 18.5833 4.19527V17.8047C18.5833 18.9064 18.466 19.5141 18.127 20.148C17.8071 20.7461 17.3295 21.2238 16.7313 21.5436C16.0974 21.8826 15.4897 22 14.3881 22H7.19527C6.0936 22 5.4859 21.8826 4.85202 21.5436C4.25388 21.2238 3.77624 20.7461 3.45635 20.148C3.11735 19.5141 3 18.9064 3 17.8047V4.19527C3 3.0936 3.11735 2.4859 3.45635 1.85202C3.77624 1.25388 4.25388 0.77624 4.85202 0.456353C5.4859 0.117353 6.0936 0 7.19527 0Z"
      fill={color}
    />
  </svg>
));

IconCellphone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconCellphone.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconCellphone;
export { IconCellphone };
