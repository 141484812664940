import React from 'react';

const IconCorrect = React.memo(() => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="8.88894"
      cy="8.79946"
      rx="8.88894"
      ry="8.79946"
      fill="#DB3D27"
    />
    <path
      d="M5 5L13 13"
      stroke="#1D1D1D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13 5L5 13"
      stroke="#1D1D1D"
      stroke-width="2"
      stroke-linecap="round"
    />
    <ellipse
      cx="8.88894"
      cy="8.79946"
      rx="8.88894"
      ry="8.79946"
      fill="#00AD8B"
    />
    <path
      d="M13.8906 6L8.30469 11.5L5.76562 9"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
));

export default IconCorrect;
export { IconCorrect };
