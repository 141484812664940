import styled, { css } from 'styled-components';

interface WrapperProps {
  backgroundColor: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ backgroundColor }) => css`
    width: 100%;
    height: 72px;
    padding: 14px 48px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    background-color: ${backgroundColor};

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 84px;
      height: max-content;
      padding: 14px 16px;
    }
  `}
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`;

export const Text = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.md};
  `}
`;

export const Button = styled.a`
  ${({ theme }) => css`
    width: min(100%, 340px);
    padding: 12px;
    background-color: ${theme.colorPallete.white};
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    color: ${theme.colorPallete.black};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.md};
  `}
`;
