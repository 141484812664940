import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 12px;
    user-select: none;
    touch-action: manipulation;
    cursor: pointer;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px !important;
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #868585 !important;
      border-radius: 8px !important;
    }
  `}
`;
