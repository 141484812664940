import React from 'react';

interface Props {
  color?: string;
}

const IconBell = React.memo(({ color = '#7F7F7F' }: Props) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.47626 20.6665H15.381"
      stroke={color}
      strokeWidth="1.47619"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8042 9.59511C5.80297 8.72008 5.97487 7.85345 6.31 7.04514C6.64514 6.23683 7.13688 5.50281 7.7569 4.88535C8.37693 4.2679 9.11299 3.77922 9.92268 3.44745C10.7324 3.11568 11.5997 2.94738 12.4747 2.95225C16.1283 2.97993 19.053 6.01535 19.053 9.67814V10.3332C19.053 13.6362 19.745 15.5552 20.3539 16.607C20.4186 16.719 20.4527 16.8461 20.4528 16.9754C20.4529 17.1048 20.419 17.2319 20.3546 17.344C20.2901 17.4561 20.1973 17.5493 20.0854 17.6143C19.9736 17.6793 19.8466 17.7137 19.7173 17.7142H5.13991C5.01057 17.7137 4.88362 17.6793 4.77178 17.6143C4.65994 17.5493 4.56713 17.4561 4.50265 17.344C4.43818 17.2319 4.4043 17.1048 4.40442 16.9754C4.40453 16.8461 4.43864 16.719 4.50331 16.607C5.11224 15.5552 5.8042 13.6362 5.8042 10.3332V9.59511Z"
      stroke={color}
      strokeWidth="1.47619"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconBell;
export { IconBell };
