import React from 'react';
import PropTypes from 'prop-types';

const IconRightArrow = React.memo(({ size, color }) => (
  <svg
    id="icon-right-arrow"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46965 2.49644C3.88332 1.96341 3.84011 1.05599 4.37314 0.469654C4.90617 -0.11668 5.8136 -0.159893 6.39993 0.373138L16.9217 9.93835C17.5479 10.5076 17.5479 11.4924 16.9217 12.0617L6.39993 21.6269C5.8136 22.1599 4.90617 22.1167 4.37314 21.5303C3.84011 20.944 3.88332 20.0366 4.46965 19.5036L13.8236 11L4.46965 2.49644Z"
      fill={color}
    />
  </svg>
));

IconRightArrow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconRightArrow.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconRightArrow;
export { IconRightArrow };
