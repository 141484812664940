import React from 'react';
import DownloadButton from './Components/DownloadButton/DownloadButton';
import { ScrollableContainer } from 'components';
import { Material } from '@libs/ms-api';
import * as S from './ComplementaryMaterial.styles';

interface ComplementaryMaterialProps {
  color: string;
  materials?: Material[];
}

const ComplementaryMaterial = ({
  color,
  materials,
}: ComplementaryMaterialProps) => {
  const orderingByCourseware = materials?.sort((a, b) => {
    if (a.type === 'courseware') return -1;
    if (b.type === 'courseware') return 1;
    return 0;
  });
  if (materials.length < 1) return null;
  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>Material Complementar</S.Title>
        <S.Subtitle>
          Para auxiliar em seus estudos confira e baixe o material complementar
          abaixo:
        </S.Subtitle>
        <ScrollableContainer className="complementary-material-carousel">
          {orderingByCourseware.map((material) => (
            <div>
              <DownloadButton
                buttonContent={material}
                color={color}
                key={material.id}
              />
            </div>
          ))}
        </ScrollableContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default ComplementaryMaterial;
export { ComplementaryMaterial };
