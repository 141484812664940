import * as S from './Alert.styles';
import { IconAlertCircle } from '@components';

interface AlertProps {
  text: string;
  backgroundColor: string;
  buttonLink?: string;
  buttonText?: string;
}

export const Alert = ({
  text,
  backgroundColor,
  buttonLink,
  buttonText,
}: AlertProps) => {
  return (
    <S.Wrapper backgroundColor={backgroundColor}>
      <S.IconBox>
        <IconAlertCircle fillColor={backgroundColor} />
        <S.Text>{text}</S.Text>
      </S.IconBox>
      {buttonLink && buttonText ? (
        <S.Button href={buttonLink} target="_blank">
          {buttonText}
        </S.Button>
      ) : (
        <></>
      )}
    </S.Wrapper>
  );
};
