import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-family: ${theme.fonts.fontFamily.sans};
    padding-block: 16px;
    background-color: ${theme.colorSystem.black};

    padding-inline: 20px;

    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3.2rem;
  margin: 0 auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    height: 25px;
    width: 250px;
    max-width: 320px;
  }
`;

export const EnvolvedPersonsContainer = styled.div`
  ${() => css`
    display: flex;
    gap: 32px;
  `}
`;

export const Card = styled.div`
  ${() => css`
    display: flex;
    gap: 16px;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }

    .name {
      width: 250px;
      max-width: 250px;
      height: 20px;
    }

    .bio {
      width: 250px;
      max-width: 250px;
      height: 18px;
    }
  `}
`;

export const TitlesCard = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `}
`;
