// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
// Require `PhoneNumberFormat`.
const PNF = require('google-libphonenumber').PhoneNumberFormat;

/**
 * Case the phone doesn't come with internacional pattern, add '+55' by default
 * @param {string} phone
 * @return {string} phone
 */
function filterBrPhone(phone) {
  if (phone) {
    if (phone.indexOf('+') !== -1) {
      return phone;
    }
    return `+55${phone}`;
  }
  return phone;
}

/**
 * Case the phone doesn't come with internacional pattern, add '+55' by default
 * Clears special characters and spaces
 * @param {string} phone
 * @return {string} phone
 */
function polishingPhone(phone) {
  if (phone) {
    let phoneFormated = phone.replace('+55', '');
    if (phone) {
      if (phoneFormated.indexOf('0') === 0)
        phoneFormated = phoneFormated.substring(1, phoneFormated.length);
      phoneFormated = filterBrPhone(phoneFormated);
    }

    return phoneFormated;
  }
  return phone;
}

/**
 * Validation of lib 'google-libphonenumber'. Get`s the phone and country, ans test if is valid.
 * @param {string} phone - Ex.: +5541998277364
 * @param {string} cctld - Ex.:  BR
 * @return {string} - validNUmber
 */
function validatePhone(phone, cctld) {
  const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, cctld);

  // phoneUtil.getNumberType(phoneNumber) to get the number type
  const validNumber = phoneUtil.isValidNumberForRegion(phoneNumber, cctld)
    ? phoneUtil.format(phoneNumber, PNF.NATIONAL)
    : null;

  return validNumber;
}

export { filterBrPhone, polishingPhone, validatePhone };
