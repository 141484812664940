import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const IconExit = React.memo(({ size = '16px', color = '#ffffff' }: Props) => (
  <svg
    id="icon-exit"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1307 10.125H6C4.5 10.125 4.5 7.875 6 7.875H13.1307L10.1558 5.79732C9.64645 5.44156 9.52192 4.74022 9.87768 4.23084C10.2334 3.72145 10.9348 3.59692 11.4442 3.95268L17.199 7.97192C17.6201 8.15645 17.8309 8.57756 17.8312 8.99888V9.00112C17.8309 9.42244 17.6201 9.84355 17.199 10.0281L11.4442 14.0473C10.9348 14.4031 10.2334 14.2785 9.87768 13.7692C9.52192 13.2598 9.64645 12.5584 10.1558 12.2027L13.1307 10.125ZM16.875 15.75C17.4963 15.75 18 16.2537 18 16.875C18 17.4963 17.4963 18 16.875 18H3.5045C1.57081 18 0 16.4404 0 14.5125V3.4875C0 1.55959 1.57081 0 3.5045 0H16.875C17.4963 0 18 0.50368 18 1.125C18 1.74632 17.4963 2.25 16.875 2.25H3.5045C2.80986 2.25 2.25 2.80586 2.25 3.4875V14.5125C2.25 15.1941 2.80986 15.75 3.5045 15.75H16.875Z"
      fill={color}
    />
  </svg>
));

export default IconExit;
export { IconExit };
