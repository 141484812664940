import styled from 'styled-components';
import { main as theme } from '@themes';

const Wrapper = styled.section`
  width: 100%;
  margin: 5rem auto;
  margin-bottom: 0;
  padding-top: 6rem;
  border-top: 1px solid ${theme.colorPallete.base[200]};

  .bio-list {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 5.4rem;

    @media (min-width: ${theme.breakpoints.medium}) {
      margin-bottom: 4.4rem;
    }

    &__picture {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 180px;
      position: relative;
      display: block;

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 100%;
      }

      @media all and (min-width: ${theme.breakpoints.big}) {
        width: 120px;
        height: 120px;
      }
    }

    &__description {
      max-width: 100%;
      padding: 0 2.6rem;
      padding-right: 0;

      h4 {
        padding-bottom: 1rem;
      }

      @media (min-width: ${theme.breakpoints.medium}) {
        margin-left: 2rem;
      }
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    margin-bottom: 6rem;
  }
`;

export { Wrapper };
export default Wrapper;
