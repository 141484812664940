/* eslint-disable import/no-cycle */
import { callApiBase } from './base';

const hatApi = {
  hat: ({ token, refreshToken }) =>
    callApiBase({
      title: 'Hats',
      endpoint: '/hats',
      method: 'GET',
      token,
      refreshToken,
    }),
};

export default hatApi;
export { hatApi };
