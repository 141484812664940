import { Skeleton } from 'skeletons/Skeleton';

import { useWindowSize } from 'helpers/useWindowSize';
import * as S from './PlaylistSkeleton.styles';

export const PlaylistSkeleton = (className) => {
  const { width } = useWindowSize();
  const threeCards = !!width && width <= 1400 && width >= 1025;
  return (
    <S.Wrapper className={className}>
      <Skeleton height={20} width={200} />
      <Skeleton isFullWidth height={44} mt={8} />
      <Skeleton height={20} width={250} mt={12} />
      <Skeleton isFullWidth height={96} mt={8} />
      <Skeleton isFullWidth height={96} mt={8} />
      {!threeCards && <Skeleton isFullWidth height={96} mt={8} />}
    </S.Wrapper>
  );
};
