import { callApiBase } from './base';

const firstAccessApi = {
  step1: ({ data }) =>
    callApiBase({
      title: `First Access Step1 PATCH`,
      endpoint: '/first-access/step1',
      method: 'PATCH',
      data,
    }),
  step2: ({ data }) =>
    callApiBase({
      title: `First Access Step2 PATCH`,
      endpoint: '/first-access/step2',
      method: 'PATCH',
      data,
    }),
  step3: ({ data }) => {
    return callApiBase({
      title: `First Access Step3 PATCH`,
      endpoint: '/first-access/step3',
      method: 'PATCH',
      data,
    });
  }
};

export default firstAccessApi;
export { firstAccessApi };
