/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import { updateArrayNewProgress } from '@helpers';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
  newProgress: [],
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.userReducer,
      };

    /**
     * ---------------------------------------------------
     * SEND PROGRESS
     * ---------------------------------------------------
     */
    case types.SEND_PROGRESS_REQUEST_V2:
      return { ...state, loading: true, error: false };
    case types.SEND_PROGRESS_SUCCESS_V2:
      return { ...state, loading: false, error: false };
    case types.SEND_PROGRESS_FAILURE_V2:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * UPDATE PROGRESS
     * ---------------------------------------------------
     */
    case types.UPDATE_PROGRESS_V2:
      return {
        ...state,
        newProgress: updateArrayNewProgress(state.newProgress, action.payload),
      };

    default:
      return state;
  }
}

export { actions, sagas };
