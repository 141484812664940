import React from 'react';
import { Skeleton } from 'skeletons/Skeleton';

import * as S from './TitleSkeleton.styles';

const TitleSkeleton = () => {
  return (
    <S.Wrapper>
      <Skeleton height={40} mb={16} />
      <Skeleton height={40} />
    </S.Wrapper>
  );
};

export default TitleSkeleton;
export { TitleSkeleton };
