import { ThemeType, ColorSystem } from './types';

const colors: ColorSystem = {
  // BLACK & WHITE
  //
  black: '#000',
  white: '#FFF',
  //
  // ALERT COLORS
  //
  info: '#11A3DE',
  successLight: '#00ad8b',
  success: '#23B63B',
  warning: '#FFB800',
  danger: '#C71F3D',
  //
  // BASE COLOR
  //

  baseColor: '#0D0C0C',
  baseColor90: '#d9d9d9',
  baseColor100: '#DBDBDB',
  baseColor150: '#cecece',
  baseColor170: '#c3c4c7',
  baseColor200: '#B6B6B6',
  baseColor220: '#9e9e9e',
  baseColor250: '#8E8E8E',
  baseColor300: '#868585',
  baseColor320: '#7F7F7F',
  baseColor350: '#565555',
  baseColor400: '#343333',
  baseColor425: '#111211',
  baseColor450: '#3d3d3d',
  baseColor500: '#313030',
  baseColor550: '#2A2A2A',
  baseColor600: '#272727',
  baseColor680: '#1A1A1A',
  baseColor690: '#191919',
  baseColor700: '#181818',
  baseColor900: '#100F0F',

  //
  // PRIMARY COLOR
  //
  primaryColor: '#F26522',
  primaryColor100: '#FACEBC',
  primaryColor200: '#fe7000 ',
  primaryColor300: '#EC5012',
  primaryColor400: '#DA571A',
  primaryColor500: '#B83400',
  primaryColor600: '#8b4005',
};

const main: ThemeType = {
  breakpoints: {
    smaller: '360px',
    small: '480px',
    medium: '768px',
    big: '1200px',
    bigger: '1440px',
    extraBigger: '2100px',
  },
  colorSystem: colors,
  colorPallete: {
    black: colors.black,
    white: colors.white,
    base: {
      default: colors.baseColor,
      90: colors.baseColor90,
      100: colors.baseColor100,
      150: colors.baseColor150,
      170: colors.baseColor170,
      200: colors.baseColor200,
      220: colors.baseColor220,
      250: colors.baseColor250,
      300: colors.baseColor300,
      320: colors.baseColor320,
      350: colors.baseColor350,
      400: colors.baseColor400,
      425: colors.baseColor425,
      450: colors.baseColor450,
      500: colors.baseColor500,
      550: colors.baseColor550,
      600: colors.baseColor600,
      680: colors.baseColor680,
      690: colors.baseColor690,
      700: colors.baseColor700,
      900: colors.baseColor900,
    },
    primary: {
      default: colors.primaryColor,
      100: colors.primaryColor100,
      200: colors.primaryColor200,
      300: colors.primaryColor300,
      400: colors.primaryColor400,
      500: colors.primaryColor500,
      600: colors.primaryColor600,
    },
  },
  gutter: '0.2rem',
  radius: '0.2rem',
  button: {
    bgcolor: {
      primary: {
        action: colors.primaryColor400,
        hover: colors.primaryColor500,
        pressed: colors.primaryColor300,
        disabled: colors.primaryColor100,
      },
      secondary: {
        action: colors.baseColor500,
        hover: colors.baseColor,
        pressed: colors.baseColor400,
        disabled: colors.baseColor100,
      },
      hollow: {
        action: 'transparent',
        hover: 'transparent',
        pressed: 'transparent',
        disabled: 'transparent',
      },
    },
    fontcolor: {
      primary: {
        action: colors.white,
        hover: colors.white,
        pressed: colors.white,
        disabled: colors.white,
      },
      secondary: {
        action: colors.white,
        hover: colors.white,
        pressed: colors.white,
        disabled: colors.white,
      },
      hollow: {
        action: colors.primaryColor,
        hover: colors.primaryColor500,
        active: colors.primaryColor300,
        disabled: colors.primaryColor100,
      },
    },
    bordercolor: {
      primary: {
        action: colors.primaryColor400,
        hover: colors.primaryColor500,
        pressed: colors.primaryColor300,
        disabled: colors.primaryColor100,
      },
      secondary: {
        action: colors.baseColor500,
        hover: colors.baseColor,
        pressed: colors.baseColor400,
        disabled: colors.baseColor100,
      },
      hollow: {
        action: colors.primaryColor,
        hover: colors.primaryColor500,
        active: colors.primaryColor300,
        disabled: colors.primaryColor100,
      },
    },
  },
  fonts: {
    fontFamily: {
      sans: `'Muli', Helvetica, Arial, sans-serif`,
      serif: `'Merriweather', Times, serif`,
    },
    fontWeight: {
      light: 300,
      regular: 400,
      bold: 700,
      bolder: 800,
    },
    fontSize: {
      xxs: '10px',
      xs: '12px',
      sm: '14px',
      md: '16px',
      g: '20px',
      gg: '24px',
      xg: '34px',
      xxg: '48px',
    },
  },
};

export default main;
export { main };
