import React from 'react';

interface Props {
  size: string;
  color?: string;
}

const IconPlay = React.memo(({ size = '16px', color = '#ffffff' }: Props) => (
  <svg
    id="icon-play"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.68212C0 1.3963 0.07284 1.1152 0.211643 0.865357C0.662727 0.0534067 1.68662 -0.239134 2.49857 0.21195L13.871 6.52999C14.1456 6.68251 14.3719 6.90886 14.5244 7.18339C14.9755 7.99534 14.683 9.01924 13.871 9.47032L2.49857 15.7884C2.24872 15.9272 1.96762 16 1.68181 16C0.752972 16 0 15.247 0 14.3182V1.68212Z"
      fill={color}
    />
  </svg>
));

export default IconPlay;
export { IconPlay };
