import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;

  div:nth-child(1) {
    max-width: 15%;
  }

  div:nth-child(2) {
    max-width: 40%;
  }
`;
