import { callApiBase } from './base';

const ibgeLocalesApi = {
  getStates: () =>
    callApiBase({
      title: 'IBGElocales - Get State',
      endpoint: '/localidades/estados',
      method: 'GET',
    }),
  getCities: ({ params }) =>
    callApiBase({
      title: 'IBGElocales - Get Cities',
      endpoint: `/localidades/estados/${params.uf}/municipios`,
      method: 'GET',
    }),
};

export default ibgeLocalesApi;
export { ibgeLocalesApi };
