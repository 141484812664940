import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const IconLock = React.memo(({ color = '#ffffff', size = '16px' }: Props) => (
  <svg
    id="icon-lock"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13748 0H8.85094C4.99855 0.127352 2.03763 3.40666 2.03763 7.25904V9.55137H1.87844C0.827786 9.55137 0 10.3792 0 11.4298V20.1216C0 21.1722 0.827786 22 1.87844 22H16.301C17.3517 22 18.1794 21.1722 18.1794 20.1216V11.4298C18.1794 10.3792 17.3517 9.55137 16.301 9.55137H16.2373V7.06802C16.2373 3.18379 13.0535 0 9.13748 0ZM10.4746 17.9566C10.5065 18.1795 10.3473 18.3705 10.1244 18.3705H9.07377H8.02312C7.80026 18.3705 7.64107 18.1795 7.6729 17.9566L8.0868 15.9827C7.70474 15.6325 7.45004 15.1231 7.54555 14.55C7.64107 13.8814 8.21415 13.3401 8.88275 13.2446H9.07377C9.9334 13.2446 10.6338 13.9451 10.6338 14.8047C10.6338 15.2822 10.411 15.728 10.0607 16.0145L10.4746 17.9566ZM5.12598 9.55134H13.1173V7.06798C13.1173 4.83933 11.3025 3.0564 9.10572 3.0564C9.04205 3.0564 8.97837 3.06436 8.9147 3.07232C8.85102 3.08028 8.78734 3.08824 8.72367 3.08824C6.6542 3.27927 5.12598 5.12587 5.12598 7.19533V9.55134Z"
      fill={color}
    />
  </svg>
));

export default IconLock;
export { IconLock };
