import styled from 'styled-components';
import { main as theme } from '@themes';

const Wrapper = styled.div`
  width: 100%;

  svg {
    width: 100%;
  }

  .video {
    padding: 2rem 1rem;
  }

  .separator {
    padding: 2rem 0;
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    .video {
      padding: 2rem;
    }
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    .video {
      max-width: 80%;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 2rem;
    }

    .video-info {
      width: 100%;
      max-width: 70%;
      margin: 0 auto;
      padding-top: 2rem;
    }
  }
`;

export default Wrapper;
export { Wrapper };
