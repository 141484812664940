import * as types from './types';

/**
 * ---------------------------------------------------
 * GET LIBRARY
 * ---------------------------------------------------
 */
export const getLibraryRequest = ({ payload }) => ({
  type: types.GET_LIBRARY_REQUEST,
  payload,
});
export const getLibrarySuccess = ({ payload }) => ({
  type: types.GET_LIBRARY_SUCCESS,
  payload,
});
export const getLibraryFailure = () => ({
  type: types.GET_LIBRARY_FAILURE,
});

/**
 * ---------------------------------------------------
 * CLEAN LIBRARY
 * ---------------------------------------------------
 */
export const getLibraryCleanRequest = () => ({
  type: types.GET_LIBRARY_CLEAN_REQUEST,
});
export const getLibraryCleanSuccess = ({ payload }) => ({
  type: types.GET_LIBRARY_CLEAN_SUCCESS,
  payload,
});

/**
 * ---------------------------------------------------
 * TOGGLE TAG
 * ---------------------------------------------------
 */
export const toggleActiveTag = ({ payload }) => ({
  type: types.TOGGLE_ACTIVE_TAG,
  payload,
});
