/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as progressActions } from '@store/ducks/progress';

// MSV2-api
import { progressApiMSV2 as msv2ProgressApi } from '@services/msv2-api';

// Mirror-api
import { progressApiMirrorV2 as mirrorProgressApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * PROGRESS
 * ---------------------------------------------------
 */
export function* sendProgress({ payload }) {
  const { auth, isServer } = payload;
  const request = isServer ? msv2ProgressApi : mirrorProgressApi;
  const token = isServer ? auth.token : '';

  try {
    yield call(request.sendProgress, {
      method: 'POST',
      token,
      data: payload.data,
      content: payload.content,
    });

    yield put(
      progressActions.updateProgressV2({
        payload: {
          start_at: payload.data.end_at,
          slug_lesson: payload.content.slug,
          duration: payload.data.duration,
        },
      })
    );
  } catch (error) {
    yield put(progressActions.sendProgressFailureV2());
  }
}

export function* watchUser() {
  yield takeEvery(types.SEND_PROGRESS_REQUEST_V2, sendProgress);
}

export const sagas = [watchUser];
