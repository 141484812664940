export const isValidRedirectUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.protocol === 'https:' &&
      parsedUrl.hostname.endsWith('.meusucesso.com')
    );
  } catch (e) {
    return false;
  }
};
