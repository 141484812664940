import React from 'react';
import PropTypes from 'prop-types';

const IconProfile = React.memo(({ size, color }) => (
  <svg
    id="icon-profile"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18004 0C6.90004 0 5.04004 1.86 5.04004 4.14C5.04004 6.39 6.90004 8.25 9.18004 8.25C11.46 8.25 13.32 6.42 13.32 4.14C13.32 1.86 11.46 0 9.18004 0ZM17 16.11C16.9292 12.57 13.5664 9.66 9.38938 9.48C7.19469 9.39 5.0708 10.05 3.47788 11.34C1.88496 12.63 1 14.37 1 16.26C1 17.22 1.95575 18 3.0885 18H14.9115C15.4779 18 16.0089 17.82 16.3982 17.49C16.7876 17.16 17 16.71 17 16.26V16.11Z"
      fill={color}
    />
  </svg>
));

IconProfile.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconProfile.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconProfile;
export { IconProfile };
