import React from 'react';
import PropTypes from 'prop-types';

const IconBarCode = React.memo(({ color, size }) => (
  <svg
    id="icon-bar-code"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      d="M5.2654 13.6199V4.33265C5.2654 3.85393 4.88242 3.47095 4.40369 3.47095C3.92497 3.47095 3.54199 3.85393 3.54199 4.33265V13.596C3.54199 14.0747 3.92497 14.4577 4.40369 14.4577C4.88242 14.4577 5.2654 14.0747 5.2654 13.6199Z"
      fill={color}
    />
    <path
      d="M7.89881 3.49475C7.42009 3.49475 7.03711 3.87773 7.03711 4.35645V13.6198C7.03711 14.0985 7.42009 14.4815 7.89881 14.4815C8.37754 14.4815 8.76052 14.0985 8.76052 13.6198V4.33252C8.76052 3.87773 8.37754 3.49475 7.89881 3.49475Z"
      fill={color}
    />
    <path
      d="M11.3932 3.49475C10.9145 3.49475 10.5315 3.87773 10.5315 4.35645V13.6198C10.5315 14.0985 10.9145 14.4815 11.3932 14.4815C11.8719 14.4815 12.2549 14.0985 12.2549 13.6198V4.33252C12.2549 3.87773 11.8719 3.49475 11.3932 3.49475Z"
      fill={color}
    />
    <path
      d="M14.8883 3.49475C14.4096 3.49475 14.0266 3.87773 14.0266 4.35645V13.6198C14.0266 14.0985 14.4096 14.4815 14.8883 14.4815C15.367 14.4815 15.75 14.0985 15.75 13.6198V4.33252C15.7261 3.87773 15.3431 3.49475 14.8883 3.49475Z"
      fill={color}
    />
    <path
      d="M19.2202 13.6199V4.33265C19.2202 3.85393 18.8373 3.47095 18.3585 3.47095C17.8798 3.47095 17.4968 3.85393 17.4968 4.33265V13.596C17.4968 14.0747 17.8798 14.4577 18.3585 14.4577C18.8373 14.4577 19.2202 14.0747 19.2202 13.6199Z"
      fill={color}
    />
    <path
      d="M5.91174 0H0.88514C0.406417 0 0.0234375 0.382979 0.0234375 0.861703V5.8883C0.0234375 6.36703 0.406417 6.75001 0.88514 6.75001C1.36386 6.75001 1.74684 6.36703 1.74684 5.8883V1.69947H5.93568C6.4144 1.69947 6.79738 1.31649 6.79738 0.837767C6.79738 0.359043 6.39047 0 5.91174 0Z"
      fill={color}
    />
    <path
      d="M21.902 0H16.8754C16.3967 0 16.0137 0.382979 16.0137 0.861703C16.0137 1.34043 16.3967 1.72341 16.8754 1.72341H21.0642V5.91224C21.0642 6.39096 21.4472 6.77394 21.9259 6.77394C22.4046 6.77394 22.7876 6.39096 22.7876 5.91224V0.885639C22.7637 0.382979 22.3807 0 21.902 0Z"
      fill={color}
    />
    <path
      d="M21.902 11.2262C21.4233 11.2262 21.0403 11.6092 21.0403 12.0879V16.2767H16.8754C16.3967 16.2767 16.0137 16.6597 16.0137 17.1384C16.0137 17.6172 16.3967 18.0001 16.8754 18.0001H21.902C22.3807 18.0001 22.7637 17.6172 22.7637 17.1384V12.1118C22.7637 11.5852 22.3807 11.2262 21.902 11.2262Z"
      fill={color}
    />
    <path
      d="M5.91224 16.2526H1.72341V12.0637C1.72341 11.585 1.34043 11.202 0.861703 11.202C0.382979 11.202 0 11.585 0 12.0637V17.0903C0 17.5691 0.382979 17.952 0.861703 17.952H5.8883C6.36703 17.952 6.75001 17.5691 6.75001 17.0903C6.75001 16.6116 6.39096 16.2526 5.91224 16.2526Z"
      fill={color}
    />
  </svg>
));

IconBarCode.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconBarCode.defaultProps = {
  color: '#777777',
  size: '49px',
};

export default IconBarCode;
export { IconBarCode };
