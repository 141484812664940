import { callApiBase } from './base';

const libraryApi = {
  library: ({ params, token }) =>
    callApiBase({
      title: 'Library - library',
      endpoint: `/library`,
      method: 'GET',
      token,
      params,
    }),
};

export default libraryApi;
export { libraryApi };
