import * as types from './types';

/**
 * ---------------------------------------------------
 * CHECKLIST
 * ---------------------------------------------------
 */
export const getChecklistRequest = (payload) => ({
  type: types.GET_CHECKLIST_REQUEST,
  payload,
});

export const getChecklistSuccess = ({ payload }) => ({
  type: types.GET_CHECKLIST_SUCCESS,
  payload,
});
export const getChecklistFailure = () => ({
  type: types.GET_CHECKLIST_FAILURE,
});

/**
 * ---------------------------------------------------
 * RESET ANSWERS
 * ---------------------------------------------------
 */
export const resetChecklistRequest = (payload) => ({
  type: types.RESET_CHECKLIST_REQUEST,
  payload,
});
export const resetChecklistSuccess = ({ payload }) => ({
  type: types.RESET_CHECKLIST_SUCCESS,
  payload,
});
export const resetChecklistFailure = () => ({
  type: types.RESET_CHECKLIST_FAILURE,
});

/**
 * ---------------------------------------------------
 * CHECKLIST ANSWERS
 * ---------------------------------------------------
 */
export const sendChecklistAnswersRequest = ({ payload }) => ({
  type: types.SEND_CHECKLIST_ANSWERS_REQUEST,
  payload,
});

export const sendChecklistAnswersSuccess = ({ payload }) => ({
  type: types.SEND_CHECKLIST_ANSWERS_SUCCESS,
  payload,
});
export const sendChecklistAnswersFailure = () => ({
  type: types.SEND_CHECKLIST_ANSWERS_FAILURE,
});
