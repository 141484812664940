export const checkFeatureToggle = (userData: any, featureKeys: string[]) => {
  const userFeatures = userData?.features;

  const areAllFeaturesEnabled = (features: string[]) => {
    if (!userFeatures) return false;
    return features?.every(
      (key) =>
        userFeatures?.some((obj) => obj.name === key && obj.isEnabled === true),
    );
  };

  return areAllFeaturesEnabled(featureKeys);
};
