import React from 'react';

interface Props {
  size?: string;
  color?: string;
}

const IconHome = React.memo<Props>(
  ({ size = '16px', color = '#ffffff' }: Props) => (
    <svg
      id="icon-home"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.88 18H7.13005C7.10866 18 7.08757 17.9995 7.06679 17.9986H3.58091C2.95959 17.9986 2.45591 17.495 2.45591 16.8736V10.5H1.13005C0.690503 10.4597 0.690503 10.4597 0.196739 10.0034C-0.0174058 9.48568 -0.0174058 9.48568 0.0166508 9.21102C0.0963885 8.9111 0.0963885 8.9111 0.147585 8.82544C0.212339 8.72041 0.212339 8.72041 0.239845 8.68493C0.279073 8.6354 0.279073 8.6354 0.303706 8.60685C0.330824 8.5755 0.366003 8.53637 0.412023 8.48614C0.494983 8.39558 0.618348 8.2634 0.783465 8.08807C1.10931 7.74209 1.60085 7.22487 2.25875 6.53569C3.57239 5.15959 5.55085 3.0963 8.19446 0.34547C8.63609 -0.114068 9.3709 -0.115321 9.81409 0.342708C12.4766 3.09439 14.4648 5.1585 15.7794 6.53574C16.4389 7.22671 16.9297 7.74563 17.253 8.09402C17.4194 8.27321 17.5431 8.40906 17.6271 8.50494C17.6789 8.56417 17.7189 8.61176 17.7531 8.65604C17.7895 8.70295 17.7895 8.70295 17.8602 8.81679C17.9143 8.90687 17.9143 8.90687 17.9955 9.21642C18.0278 9.4998 18.0278 9.4998 17.803 10.0194C17.3082 10.4628 17.3082 10.4628 16.8801 10.5H15.5531V16.8736C15.5531 17.495 15.0494 17.9986 14.4281 17.9986H10.9433C10.9225 17.9995 10.9014 18 10.88 18ZM12.005 14C12.005 12.8954 11.1096 12 10.005 12H8.00504C6.90047 12 6.00504 12.8954 6.00504 14V18H12.005V14Z"
        fill={color}
      />
    </svg>
  ),
);

export default IconHome;
export { IconHome };
