import { callApiBase } from './base';

const profileApi = {
  me: ({ token, refreshToken }) =>
    callApiBase({
      title: 'Profile - Me',
      endpoint: '/me',
      method: 'GET',
      token,
      refreshToken,
    }),
  personalData: ({ data, token }) =>
    callApiBase({
      title: `Edit user personal data - PATCH`,
      endpoint: '/user/personal-data',
      method: 'PATCH',
      data,
      token,
    }),
  addressData: ({ data, token }) =>
    callApiBase({
      title: `Edit user personal data - PATCH`,
      endpoint: '/user/address',
      method: 'PATCH',
      data,
      token,
    }),
  linkToSocial: ({ data, token }) =>
    callApiBase({
      title: `Link to social - POST`,
      endpoint: '/user/link-to-social',
      method: 'POST',
      data,
      token,
    }),
  newsletter: ({ data, token }) =>
    callApiBase({
      title: `Edit user newsletter - PATCH`,
      endpoint: '/user/newsletter',
      method: 'PATCH',
      data,
      token,
    }),
  profile: ({ token }) =>
    callApiBase({
      title: 'Profile - profile',
      endpoint: '/user/profile',
      method: 'GET',
      token,
    }),
  updateZeroLesson: ({ data, token }) =>
    callApiBase({
      title: `Update Zero Lesson state - PATCH`,
      endpoint: '/user/aula-zero',
      method: 'PATCH',
      data,
      token,
    }),
};

export default profileApi;
export { profileApi };
