import React from 'react';
import PropTypes from 'prop-types';

const IconChangeSize = React.memo(({ size, color }) => (
  <svg
    id="icon-change-size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2H16V13.2H0V2ZM2.4 10.8H13.6V4.4H2.4V10.8Z"
      fill={color}
    />
  </svg>
));

IconChangeSize.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconChangeSize.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconChangeSize;
export { IconChangeSize };
