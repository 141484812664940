import React from 'react';

interface IconChalkboardTeacherProps {
  color?: string;
}

const IconChalkboardTeacher = React.memo(
  ({ color = '#7F7F7F' }: IconChalkboardTeacherProps) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5952 16.2383C11.2258 16.2383 12.5476 14.9164 12.5476 13.2859C12.5476 11.6553 11.2258 10.3335 9.5952 10.3335C7.96465 10.3335 6.64282 11.6553 6.64282 13.2859C6.64282 14.9164 7.96465 16.2383 9.5952 16.2383Z"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9268 19.1906C5.34575 18.3071 6.00683 17.5605 6.83323 17.0378C7.65962 16.515 8.6174 16.2375 9.59525 16.2375C10.5731 16.2375 11.5309 16.515 12.3573 17.0378C13.1837 17.5605 13.8448 18.3071 14.2637 19.1906H19.9286C20.1243 19.1906 20.3121 19.1129 20.4505 18.9744C20.5889 18.836 20.6667 18.6483 20.6667 18.4525V5.16681C20.6667 4.97105 20.5889 4.78331 20.4505 4.64489C20.3121 4.50647 20.1243 4.42871 19.9286 4.42871H3.69049C3.49473 4.42871 3.307 4.50647 3.16858 4.64489C3.03016 4.78331 2.95239 4.97105 2.95239 5.16681V18.4525C2.95239 18.6483 3.03016 18.836 3.16858 18.9744C3.307 19.1129 3.49473 19.1906 3.69049 19.1906H4.9268Z"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2381 16.238H17.7143V7.38086H5.90479V8.85705"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconChalkboardTeacher;
export { IconChalkboardTeacher };
