import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseGetMeDataProps } from './types';

export const useGetMeData = (msApiLib: MSApi, params?: UseGetMeDataProps) => {
  const { enabled, cacheTime, bearerToken } = params ?? {};

  return useQuery(
    ['getMe'],
    async () => {
      const res = await msApiLib.v2.auth.me({
        bearerToken,
      });

      return res.content.instance;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
