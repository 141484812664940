import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { main as theme } from '@themes';
import { Text } from '../Text';

import { styles } from './Tag.styles';

const Wrapper = styled.span`
  ${styles}
`;

const Tag = React.memo(
  ({ className, text, active, handleActive, id, disabled }) => {
    return disabled ? (
      <Wrapper className={`${className} ${active ? `active` : ``}`}>
        <Text weight={theme.fonts.fontWeight.bold}>{text}</Text>
      </Wrapper>
    ) : (
      <Wrapper
        className={`${className} ${active ? `active` : ``}`}
        onClick={() => handleActive(id)}
      >
        <Text weight={theme.fonts.fontWeight.bold}>{text}</Text>
      </Wrapper>
    );
  }
);

Tag.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleActive: PropTypes.func,
  id: PropTypes.number,
  text: PropTypes.string,
};

Tag.defaultProps = {
  active: false,
  className: '',
  disabled: false,
  handleActive: () => '',
  id: 0,
  text: '',
};

export default Tag;
export { Tag };
