import React from 'react';
import PropTypes from 'prop-types';

const IconPlayer = React.memo(({ size, color }) => (
  <svg
    id="icon-player"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.84106C5 4.69815 5.03515 4.5576 5.10213 4.43268C5.3198 4.0267 5.81388 3.88043 6.2057 4.10598L11.6935 7.26499C11.826 7.34125 11.9352 7.45443 12.0088 7.5917C12.2265 7.99767 12.0853 8.50962 11.6935 8.73516L6.2057 11.8942C6.08513 11.9636 5.94949 12 5.81156 12C5.36335 12 5 11.6235 5 11.1591V4.84106ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      fill={color}
    />
  </svg>
));

IconPlayer.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPlayer.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPlayer;
export { IconPlayer };
