/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as checklistActions } from '@store/ducks/checklist';

// MSV2-api
import { checklistApi as msv2ChecklistApi } from '@services/msv2-api';

// Mirror-api
import { checklistApi as mirrorChecklistApi } from '@services/mirror-api';

import { orderArrayByOrder } from 'helpers';

import * as types from './types';

/**
 * ---------------------------------------------------
 * CHECKLIST
 * ---------------------------------------------------
 */
export function* getChecklist({ payload }) {
  const { auth, data } = payload;

  try {
    const response = yield call(msv2ChecklistApi.getChecklist, {
      method: 'GET',
      token: auth.token,
      data,
    });

    if (response.status === 200) {
      yield put(
        checklistActions.getChecklistSuccess({
          payload: {
            checklistData: {
              ...response.data.content.instance,
              checklist: orderArrayByOrder(
                response.data.content.instance.checklist
              ),
            },
          },
        })
      );
    }
  } catch (error) {
    yield put(checklistActions.getChecklistFailure());
  }
}

/**
 * ---------------------------------------------------
 * RESET ANSWERS
 * ---------------------------------------------------
 */
export function* resetChecklist({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(mirrorChecklistApi.resetChecklist, {
      data,
    });

    if (response.status === 200) {
      yield put(
        checklistActions.resetChecklistSuccess({
          payload: {
            checklistData: {
              ...response.data.content.instance,
              checklist: orderArrayByOrder(
                response.data.content.instance.checklist
              ),
            },
          },
        })
      );
    }
  } catch (error) {
    yield put(checklistActions.resetChecklistFailure());
  }
}

/**
 * ---------------------------------------------------
 * CHECKLIST ANSWERS
 * ---------------------------------------------------
 */

export function* sendChecklistAnswers({ payload }) {
  try {
    const response = yield call(mirrorChecklistApi.sendChecklistAnswers, {
      method: 'POST',
      data: {
        slug: payload.lesson_slug,
        content: payload,
      },
    });

    if (response.status === 200) {
      yield put(
        checklistActions.sendChecklistAnswersSuccess({
          payload: {
            checklistData: {
              ...response.data.content.instance,
              checklist: orderArrayByOrder(
                response.data.content.instance.checklist
              ),
            },
          },
        })
      );
    }
  } catch (error) {
    if (error.response) {
      yield put(checklistActions.sendChecklistAnswersFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_CHECKLIST_REQUEST, getChecklist);
  yield takeEvery(types.RESET_CHECKLIST_REQUEST, resetChecklist);
  yield takeEvery(types.SEND_CHECKLIST_ANSWERS_REQUEST, sendChecklistAnswers);
}

export const sagas = [watchUser];
