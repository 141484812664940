import React from 'react';
import PropTypes from 'prop-types';

const IconFacebook = React.memo(({ size, color }) => (
  <svg
    id="icon-facebook"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0V4.4H13.8C13.041 4.4 12.7 5.291 12.7 6.05V8.8H16V13.2H12.7V22H8.3V13.2H5V8.8H8.3V4.4C8.3 1.96995 10.2699 0 12.7 0H16Z"
      fill={color}
    />
  </svg>
));

IconFacebook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconFacebook.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconFacebook;
export { IconFacebook };
