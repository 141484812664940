import { CertificatesListDto, MSApi, PaginationDto } from 'libs/ms-api';
import { useInfiniteQuery } from 'react-query';

import { UseGetCertificatesProps } from './types';

export const useGetCertificates = (
  msApiLib: MSApi,
  params?: UseGetCertificatesProps,
) => {
  const { enabled, cacheTime, query, bearerToken } = params ?? {};
  const { page = 0, pageSize, studentId, sortOrder } = query ?? {};

  const fetchCertificates = async ({ pageParam = page, pageSize }) => {
    const res = await msApiLib.v3.certificates.list({
      bearerToken,
      query: {
        studentId,
        sortOrder,
        pageSize,
        page: pageParam,
      },
    });

    return res;
  };

  let totalCards: number;
  let currentPage: number;

  const nextPageParam = (lastPage: PaginationDto<CertificatesListDto[]>) => {
    const { page, pageSize, total } = lastPage.meta.pagination;
    totalCards = total;
    currentPage = page;
    if (page * pageSize >= total) return undefined;
    else return page + 1;
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [studentId, sortOrder, page, pageSize],
    queryFn: (params) => fetchCertificates({ ...params, pageSize }),
    getNextPageParam: (lastPage, pages) =>
      nextPageParam(lastPage as PaginationDto<CertificatesListDto[]>),
    refetchOnWindowFocus: false,
    ...(cacheTime !== undefined && { cacheTime }),
    ...(enabled !== undefined && { enabled }),
  });

  return {
    data,
    totalCards,
    currentPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
  };
};
