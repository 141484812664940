import cookie from 'js-cookie';

// Service - msv2
import { authApi } from '@services/msv2-api';
import { profileApi } from '@services/mirror-api';

/**
 * Redirect user to login case isnt authenticated
 * @param {object} res
 */
const redirectNotAuthUser = (res) => {
  res.setHeader('location', '/login');
  res.statusCode = 302;
  return res.end();
};

/**
 * Assign cookies when the user is on server side
 * @param {object} res
 * @param {string} key
 * @param {string} value
 * @param {object} cookieConfig
 */
const assignCookieServerSide = (res, key, value, cookieConfig) => {
  return res.cookie(key, value, cookieConfig);
};

/**
 * Assign cookies when the user is on client side
 * @param {string} key
 * @param {string} value
 */
const assignCookieClientSide = (key, value) =>
  cookie.set(key, value, {
    path: '/',
  });

/**
 * Executes the invoked function, to start again the request.
 * @param {function} invokedFunction
 * @param {string} token
 * @return {string} data
 */
const execInvokedFunction = async (invokedFunction, token, data) => {
  let responseData = {
    status: 500,
    data: null,
  };

  const responseReq = await invokedFunction({
    token,
    data,
  });
  if (responseReq.status === 200) {
    responseData = {
      status: 200,
      data: responseReq.data.content.instance,
    };
  }

  return responseData;
};

/**
 * Helper that inits all refresh token flow, calling and inserting teh cookies too.
 * @param {function} invokedFunction
 * @param {object} res
 * @param {string} refreshToken
 * @param {boolean} isServer
 * @return {object} data
 */
const refreshTokenFlow = async (
  invokedFunc,
  res,
  refreshToken,
  payload,
  isServer,
  keepMeLogged
) => {
  let data = {};

  try {
    const responseAuth = await authApi.refresh_token({
      method: 'POST',
      data: { refresh_token: refreshToken },
    });
    if (responseAuth.status === 200) {
      let cookieConfig = {
        httpOnly: true,
        signed: true,
      };

      let cookieConfigClient = {
        httpOnly: false,
        signed: false,
      };


      let refreshCookieConfig = cookieConfig;
      let refreshcookieConfigClient = cookieConfigClient;

      if (keepMeLogged !== 'false') {
        cookieConfig = { ...cookieConfig, maxAge: 1000 * 60 * 60 * 24 * 7 };
        cookieConfigClient = { ...cookieConfigClient, maxAge: 1000 * 60 * 60 * 24 * 7 };
        refreshCookieConfig = {
          ...cookieConfig,
          maxAge: 1000 * 60 * 60 * 24 * 8,
        };
        refreshcookieConfigClient = {
          ...cookieConfigClient,
          maxAge: 1000 * 60 * 60 * 24 * 8,
        };
      }

      const payloadRefreshed = {
        ...payload,
        refresh_token: responseAuth.data.content.instance.refresh_token,
        token: responseAuth.data.content.instance.token,
      };

      if (isServer) {
        // token
        assignCookieServerSide(
          res,
          encodeURIComponent('@auth/token'),
          responseAuth.data.content.instance.token,
          cookieConfig
        );
        res.cookie(encodeURIComponent('tokenClient'),responseAuth.data.content.instance.token,cookieConfigClient)
        // refresh_token
        assignCookieServerSide(
          res,
          encodeURIComponent('@auth/refresh_token'),
          responseAuth.data.content.instance.refresh_token,
          refreshCookieConfig
        );
        res.cookie(encodeURIComponent('refresh_tokenClient'),responseAuth.data.content.instance.refresh_token,refreshCookieConfig)

      } else {
        await profileApi.assignCookies({
          token: responseAuth.data.content.instance.token,
          data: payloadRefreshed,
        });
      }

      data = execInvokedFunction(
        invokedFunc,
        responseAuth.data.content.instance.token,
        payloadRefreshed
      );

      if (isServer) {
        res.setHeader('location', payload.query);
        res.statusCode = 302;
        return res.end();
      }
    }
  } catch (error) {
    if (isServer) {
      return redirectNotAuthUser(res);
    }

    return {
      status: 500,
      data: 'Failed to refresh token',
    };
  }

  return data;
};

export { assignCookieServerSide, assignCookieClientSide, refreshTokenFlow };
