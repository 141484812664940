/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as catalogActions } from '@store/ducks/catalog';

// MSV2-api
import { catalogApi as msv2CatalogApi } from '@services/msv2-api';

// Mirror-api
import { catalogApi as mirrorCatalogApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * HOME
 * ---------------------------------------------------
 */
export function* getHome({ payload }) {
  const { auth, isServer } = payload;
  let whichFilter = "";
  const request = isServer ? msv2CatalogApi : mirrorCatalogApi;
  const token = isServer ? auth.token : '';

  if (payload.whichFilter !== undefined) {
    whichFilter = payload.whichFilter
  }

  try {
    const response = yield call(request.home, {
      method: 'GET',
      token,
      data: { slug: whichFilter },
    });

    if (response.status === 200) {
      yield put(
        catalogActions.getHomeSuccess({
          payload: {
            homeData: response.data.content.instance,
            filter: whichFilter ? true : false,
            whichFilter: whichFilter ? whichFilter : null,
            textFilter: payload.textFilter ? payload.textFilter : null
          },
        })
      );
    }
  } catch (error) {
    if (error && error.response) {
      yield put(catalogActions.getHomeFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_HOME_REQUEST, getHome);
}

export const sagas = [watchUser];
