/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
  watchedData: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.watchedReducer,
      };

    /**
     * ---------------------------------------------------
     * WATCHED
     * ---------------------------------------------------
     */
    case types.GET_WATCHED_REQUEST:
      return { ...state, watchedData: null, loading: true, error: false };
    case types.GET_WATCHED_SUCCESS:
      return {
        ...state,
        watchedData: action.payload.watchedData,
        loading: false,
        error: false,
      };
    case types.GET_WATCHED_FAILURE:
      return { ...state, watchedData: null, loading: false, error: true };

    default:
      return state;
  }
}

export { actions, sagas };
