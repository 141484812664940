import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Wrapper } from './SlideModal.styles';

const SlideModal = ({ children, showing }) => {
  return (
    <>
      <Wrapper className={`slidemodal ${showing ? `slidemodal--showing` : ``}`}>
        <div className="slidemodal__content">{children}</div>
      </Wrapper>
    </>
  );
};

SlideModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.bool, PropTypes.element.isRequired]),
  showing: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      text: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  ]),
};

SlideModal.defaultProps = {
  children: false,
  showing: false,
};

export default SlideModal;
export { SlideModal };
