/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

// Helpers
import { authRoute } from '@helpers';

// Actions
// eslint-disable-next-line import/no-cycle
import { actions as userActions } from '@store/ducks/user';

const AuthContext = createContext();

const AuthProvider = ({ tokenIsSetted, children, authObject }) => {
  const { pathname, push, asPath } = useRouter();
  const dispatch = useDispatch();
  const { userData, error } = useSelector((state) => state.userReducer);

  // ? Try to user request always the URL address changes:
  useEffect(() => {
    if (!userData && !error && authRoute(pathname)) {

      dispatch(
        userActions.getUserDataRequest({
          payload: {
            auth: {
              refreshToken:
                authObject[encodeURIComponent('@auth/refresh_token')],
            },
            query: pathname,
          },
        })
      );
    }
  }, [pathname]);

  // ? Send to login if the token doesnt exists, or the user req on API was failed:
  useEffect(() => {
    if (error && authRoute(pathname) && !tokenIsSetted) {
      localStorage.setItem('route', asPath);
      push('/login');
    }

    // ? If user has already authenticated:
    if (
      userData &&
      localStorage.getItem('route') &&
      localStorage.getItem('route') !== '/login'
    ) {
      const userRoute = localStorage.getItem('route');
      localStorage.removeItem('route');
      push(userRoute);
    }

    if (!authRoute(pathname) && userData && pathname !== '/nps') {
      push('/home');
    }
  }, [userData]);

  return (
    <AuthContext.Provider value={userData}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  tokenIsSetted: PropTypes.bool,
  authObject: PropTypes.shape({
    refreshToken: PropTypes.string,
    token: PropTypes.string,
  }),
};

AuthProvider.defaultProps = {
  tokenIsSetted: false,
  authObject: {},
};

export { AuthProvider };
export default AuthProvider;
