import React from 'react';

interface IconDownloadSimpleProps {
  color?: string;
  size?: string;
}

const IconDownloadSimple = React.memo(
  ({ color = '#00AD8B', size = '21px' }: IconDownloadSimpleProps) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.9187 8.59375L10.2 11.875L13.4812 8.59375"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2 3.125V11.875"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.075 11.875V16.25C17.075 16.4158 17.0091 16.5747 16.8919 16.6919C16.7747 16.8092 16.6157 16.875 16.45 16.875H3.94995C3.78419 16.875 3.62522 16.8092 3.50801 16.6919C3.3908 16.5747 3.32495 16.4158 3.32495 16.25V11.875"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconDownloadSimple;
export { IconDownloadSimple };
