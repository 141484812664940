import React from 'react';

interface IconTelevisionSimpleProps {
  color?: string;
}

const IconTelevisionSimple = React.memo(
  ({ color = '#7F7F7F' }: IconTelevisionSimpleProps) => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5477 6.64307H4.30963C3.90199 6.64307 3.57153 6.97352 3.57153 7.38116V18.4526C3.57153 18.8602 3.90199 19.1907 4.30963 19.1907H20.5477C20.9554 19.1907 21.2858 18.8602 21.2858 18.4526V7.38116C21.2858 6.97352 20.9554 6.64307 20.5477 6.64307Z"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.21436L12.4286 6.64293L16.8571 2.21436"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconTelevisionSimple;
export { IconTelevisionSimple };
