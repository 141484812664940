import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseCourseDataProps } from './types';

export const useGetCourseData = (
  msApiLib: MSApi,
  params?: UseCourseDataProps,
) => {
  const { enabled, cacheTime, bearerToken, courseSlug } = params ?? {};

  return useQuery(
    ['listOneCourse', courseSlug],
    async () => {
      const res = await msApiLib.v3.courses.listOneCourse({
        bearerToken,
        query: {
          courseSlug,
        },
      });

      return res;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
