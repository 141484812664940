import React from 'react';
import PropTypes from 'prop-types';

const IconBurger = React.memo(({ size, color }) => (
  <svg
    id="icon-burger"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.125 9.75H16.875C18.375 9.75 18.375 12 16.875 12H1.125C-0.375 12 -0.375 9.75 1.125 9.75ZM1.125 4.875H16.875C18.375 4.875 18.375 7.125 16.875 7.125H1.125C-0.375 7.125 -0.375 4.875 1.125 4.875ZM1.125 0H16.875C18.375 0 18.375 2.25 16.875 2.25H1.125C-0.375 2.25 -0.375 0 1.125 0Z"
      fill={color}
    />
  </svg>
));

IconBurger.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconBurger.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconBurger;
export { IconBurger };
