import { RequestWithBearer } from '@libs/ms-api/types';
import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import { PaginationDto, PlaylistDto, GetPlaylistDto } from 'libs/ms-api/dtos';

type IPlaylistResource = MSApi['v3']['playlist'];

export class PlaylistResource implements IPlaylistResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(
    data: RequestWithBearer<GetPlaylistDto>,
  ): Promise<PaginationDto<PlaylistDto>> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(
      `v3/module/${data.moduleSlug}`,
    );
    return result;
  }
}
