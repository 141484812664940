import React from 'react';
import PropTypes from 'prop-types';

const IconFullscreen = React.memo(({ size, color }) => (
  <svg
    id="icon-fullscreen"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.36364H2.18182V4.18182H4.36364V2H0V6.36364ZM11.6364 2V4.18182H13.8182V6.36364H16V2H11.6364ZM11.6364 11.4545V13.6364H16V9.27273H13.8182V11.4545H11.6364ZM2.18182 9.27273H0V13.6364H4.36364V11.4545H2.18182V9.27273Z"
      fill={color}
    />
  </svg>
));

IconFullscreen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconFullscreen.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconFullscreen;
export { IconFullscreen };
