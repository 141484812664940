import { callApiBase } from './base';

const catalogApi = {
  home: ({ data }) =>
    callApiBase({
      title: 'Catalog - Home',
      endpoint: `/home${data.slug ? "/" + data.slug : ""}`,
      method: 'GET',
    }),
};

export default catalogApi;
export { catalogApi };
