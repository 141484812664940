import React, { useEffect, useState } from 'react';
import { IconCheckSquare, IconDownloadSimple } from 'components';
import { Material } from '@libs/ms-api';
import * as S from './DownloadButton.styled';

interface DownloadButtonProps {
  color: string;
  buttonContent: Material;
}

const DownloadButton = ({ buttonContent, color }: DownloadButtonProps) => {
  const [downloaded, setDownloaded] = useState(false);

  const handleDownload = () => {
    localStorage.setItem(buttonContent.id, 'true');
    setDownloaded(true);
  };

  useEffect(() => {
    setDownloaded(!!localStorage.getItem(buttonContent.id));
  }, []);

  return (
    <S.Button
      key={`${buttonContent.id}`}
      target="_blank"
      href={buttonContent.url}
      className={
        buttonContent.type === 'courseware' ? 'highlight-button' : null
      }
      courseColor={color}
      onClick={handleDownload}>
      {buttonContent.title}
      {!downloaded ? (
        <IconDownloadSimple color={color} />
      ) : (
        <IconCheckSquare color={color} />
      )}
    </S.Button>
  );
};

export default DownloadButton;
