import { callApiBase } from './base';

const authApi = {
  login: ({ data }) =>
    callApiBase({
      title: 'Auth - Login',
      endpoint: '/login',
      method: 'POST',
      data,
    }),
  logout: () =>
    callApiBase({
      title: 'Auth - Logout',
      endpoint: '/logout',
      method: 'POST',
    }),
  refresh_token: () =>
    callApiBase({
      title: 'Auth - Refresh Token',
      endpoint: '/refresh-token',
      method: 'POST',
    }),
  socialLogin: ({ data }) =>
    callApiBase({
      title: `Social Login - POST`,
      endpoint: '/social-login',
      method: 'POST',
      data,
    }),
};

export default authApi;
export { authApi };
