import React from 'react';
import PropTypes from 'prop-types';

const IconPlusRonded = React.memo(({ size, color }) => (
  <svg
    id="icon-plus-ronded"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.125 7.87501H13.2955C14.7955 7.87501 14.7955 10.125 13.2955 10.125H10.125V13.2955C10.125 14.7955 7.875 14.7955 7.875 13.2955V10.125H4.70454C3.20454 10.125 3.20454 7.87501 4.70454 7.87501H7.875V4.70455C7.875 3.20455 10.125 3.20455 10.125 4.70455V7.87501ZM9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
      fill={color}
    />
  </svg>
));

IconPlusRonded.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPlusRonded.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPlusRonded;
export { IconPlusRonded };
