import styled, { css } from 'styled-components';

interface StatusIconProps {
  completed?: boolean;
}

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 270px;
    max-width: 270px;

    font-family: ${theme.fonts.fontFamily.sans};
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px 10px 20px 10px;
    background-color: ${theme.colorPallete.base[600]};
    border-radius: 4px;

    @media (min-width: ${theme.breakpoints.extraBigger}) {
      max-width: 100%;

      h3 {
        font-size: 14px;
      }
      h4 {
        font-size: 18px;
      }
    }

    @media (max-width: 340px) {
      min-width: 250px;
      max-width: 250px;
    }
  `}
`;

export const NewContent = styled.span`
  ${({ theme }) => css`
    display: block;
    position: absolute;
    padding: 6px 12px;

    top: 6px;
    left: 6px;

    font-weight: ${theme.fonts.fontWeight.bold};

    background-color: ${theme.colorPallete.primary[200]};
    border-radius: 4px;
  `}
`;

export const StatusIcon = styled.div<StatusIconProps>`
  ${({ theme, completed }) => css`
    position: absolute;
    top: 6px;
    right: 6px;

    width: 25px;
    height: 25px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${completed
      ? theme.colorPallete.primary[200]
      : 'rgba(255, 255, 255, 0.20)'};
    padding: 4px;
  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 4px;
  aspect-ratio: 1.67;

  img {
    border-radius: 4px;
  }
`;

export const ContainerProgress = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 13px;
    margin-block: 16px;
    gap: 6px;

    p {
      font-weight: ${theme.fonts.fontWeight.regular};
      display: flex;
      align-items: center;
      margin-top: -2px;
    }
  `}
`;

export const ProgressBar = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 4px;
    background: ${theme.colorPallete.base[425]};
    border-radius: 10px;
    @media (min-width: 1440px) {
      height: 5px;
    }
  `}
`;

interface ProgressProps {
  progress: number;
}

export const Progress = styled.div<ProgressProps>`
  ${({ progress, theme }) => css`
    width: calc(${progress} * 100%);
    height: 100%;
    background-color: ${theme.colorPallete.primary[200]};
    border-radius: 10px;
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    padding: 18px 4px 0 4px;
    background-color: ${theme.colorPallete.base[600]};
    max-width: 100%;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const HeaderContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
    text-wrap: nowrap;

    div {
      height: 36px;
    }
  `}
`;

export const Title = styled.h3`
  ${() => css`
    font-weight: 700;
    font-size: 12px;
  `}
`;

export const Company = styled.h3`
  ${() => css`
    font-weight: 400;
    font-size: 12px;
    color: #a8a8a8;
    margin-top: 4px;
  `}
`;

export const Duration = styled.h3`
  ${() => css`
    font-weight: 400;
    font-size: 12px;
  `}
`;

export const Description = styled.h4`
  ${() => css`
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    margin-top: 16px;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;

export const FlagsContainer = styled.div`
  ${() => css`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    gap: max(0.6rem, 0.8vw);
  `}
`;

export const Flag = styled.p`
  ${({ theme }) => css`
    display: inline-block;
    padding: 2px 8px;
    border-radius: 100px;
    border: 0.5px solid ${theme.colorPallete.base[250]};
    color: ${theme.colorPallete.base[250]};
    font-weight: 700;
    font-size: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
