import React from 'react';
import PropTypes from 'prop-types';

const IconOpenBook = React.memo(({ size, color }) => (
  <svg
    id="icon-open-book"
    viewBox="0 0 35 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8196 0H26.9956C27.8726 0 28.5836 0.708883 28.5836 1.58333V6.33333H33.3475C34.2245 6.33333 34.9355 7.04222 34.9355 7.91667V31.6667C34.9355 32.5411 34.2245 33.25 33.3475 33.25H23.8196C21.656 33.2606 19.7713 34.7237 19.2304 36.8125C19.0495 37.5111 18.4177 37.9995 17.694 38H17.2415C16.5178 37.9995 15.886 37.5111 15.7051 36.8125C15.1642 34.7237 13.2795 33.2606 11.1158 33.25H1.58798C0.710961 33.25 0 32.5411 0 31.6667V7.91667C0 7.04222 0.710961 6.33333 1.58798 6.33333H11.1158C12.8394 6.33864 14.5133 6.9089 15.8798 7.95625V7.91667C15.8798 3.54441 19.4346 0 23.8196 0ZM23.8196 3.16667C21.1886 3.16667 19.0557 5.29331 19.0557 7.91667V25.3729C20.4222 24.3256 22.0961 23.7553 23.8196 23.75H25.4076V3.16667H23.8196ZM11.1158 30.0833H3.17595V9.5H11.1158C13.7469 9.5 15.8798 11.6266 15.8798 14.25V31.6944C14.5112 30.6523 12.8378 30.0864 11.1158 30.0833ZM31.7595 30.0833V9.5H28.5836V25.3333C28.5836 26.2078 27.8726 26.9167 26.9956 26.9167H23.8196C21.1886 26.9167 19.0557 29.0433 19.0557 31.6667V31.6944C20.424 30.6518 22.0976 30.0858 23.8196 30.0833H31.7595Z"
      fill={color}
    />
  </svg>
));

IconOpenBook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconOpenBook.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconOpenBook;
export { IconOpenBook };
