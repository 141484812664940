/**
 * ---------------------------------------------------
 * GET LIBRARY
 * ---------------------------------------------------
 */
export const GET_LIBRARY_REQUEST = '@library/GET_LIBRARY_REQUEST';
export const GET_LIBRARY_SUCCESS = '@library/GET_LIBRARY_SUCCESS';
export const GET_LIBRARY_FAILURE = '@library/GET_LIBRARY_FAILURE';

/**
 * ---------------------------------------------------
 * CLEAN LIBRARY
 * ---------------------------------------------------
 */
export const GET_LIBRARY_CLEAN_REQUEST = '@library/GET_LIBRARY_CLEAN_REQUEST';
export const GET_LIBRARY_CLEAN_SUCCESS = '@library/GET_LIBRARY_CLEAN_SUCCESS';

/**
 * ---------------------------------------------------
 * TOGGLE TAG
 * ---------------------------------------------------
 */
export const TOGGLE_ACTIVE_TAG = '@library/TOGGLE_ACTIVE_TAG';
