import React, { useState } from 'react';

import * as S from './FiltersByTheme.styles';
import { FilterTag } from 'libs/ms-api';
import { FiltersByThemeSkeleton } from 'skeletons';

interface Filter {
  slug: string;
  text: string;
}

interface FiltersByTheme {
  filters: FilterTag[];
  whichPath?: string;
  onChange?: (filterSlug: Filter[]) => void;
}

const FiltersByTheme = React.memo(
  ({ whichPath, onChange, filters }: FiltersByTheme) => {
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);

    if (!filters) return <FiltersByThemeSkeleton />;

    return (
      <S.Wrapper className={!whichPath ? 'top-spacing' : ''}>
        <S.TitleContainer>
          <S.Title className={!whichPath ? 'left-spacing' : ''}>
            Filtrar por temas
          </S.Title>

          {selectedFilter && (
            <S.ClearFilter
              onClick={() => {
                setSelectedFilter(null);
                onChange([]);
              }}>
              Limpar filtros
            </S.ClearFilter>
          )}
        </S.TitleContainer>

        <S.FilteredItems className={!whichPath ? 'left-spacing' : ''}>
          {filters?.map((filter: Filter) => {
            return (
              <a
                key={filter?.slug}
                onClick={() => {
                  onChange([filter]);
                  setSelectedFilter(filter);
                }}>
                <li
                  className={filter?.slug === selectedFilter?.slug && 'active'}>
                  {filter.text}
                </li>
              </a>
            );
          })}
        </S.FilteredItems>
      </S.Wrapper>
    );
  },
);

export { FiltersByTheme };
export default FiltersByTheme;
