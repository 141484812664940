import React from 'react';

interface IconClockClockwiseProps {
  color?: string;
}

const IconClockClockwise = React.memo(
  ({ color = '#7F7F7F' }: IconClockClockwiseProps) => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2383 7.5V12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1383 14.25L12.2383 12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5073 9.34668H21.2573V5.59668"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0699 17.8313C16.9164 18.9857 15.4464 19.7721 13.8459 20.091C12.2453 20.4099 10.5862 20.2469 9.0783 19.6227C7.57042 18.9984 6.28155 17.941 5.37473 16.5842C4.46791 15.2273 3.98389 13.632 3.98389 12C3.98389 10.368 4.46791 8.7727 5.37473 7.41585C6.28155 6.059 7.57042 5.00158 9.0783 4.37735C10.5862 3.75313 12.2453 3.59014 13.8459 3.90902C15.4464 4.22789 16.9164 5.0143 18.0699 6.16875L21.2574 9.34688"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconClockClockwise;
export { IconClockClockwise };
