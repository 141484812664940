import { callApiBase } from './base';

const progressApiMSV2 = {
    sendProgress: ({ data, content, token }) => {
        return callApiBase({
            title: 'Video - sendProgressV2',
            endpoint: `/user/progress/${content.slug}`,
            method: 'POST',
            token,
            data
        })
    }

};

export default progressApiMSV2;
export { progressApiMSV2 };
