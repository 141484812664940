/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  cleanLibraryLoading: false,
  error: null,
  libraryData: null,
  libraryDataPage: null,
  libraryLength: 1,
  loading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.libraryReducer,
      };

    /**
     * ---------------------------------------------------
     * GET LIBRARY
     * ---------------------------------------------------
     */
    case types.GET_LIBRARY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_LIBRARY_SUCCESS:
      // Content that already exists on state:
      let stateLibrayDataLessonsContents = [];
      let stateLibrayDataLessonsTags = [];

      if (state.libraryData && state.libraryData.lessons.length > 0) {
        stateLibrayDataLessonsContents = state.libraryData.lessons[0].contents;
        stateLibrayDataLessonsTags = state.libraryData.tags;
      }

      // Content coming from the action:
      let actionLibrayDataLessonsContents = [];
      let actionLibrayDataLessonsTags = [];
      let actionLibrayLenght = 0;
      if (
        action.payload.libraryData &&
        action.payload.libraryData.lessons.length > 0
      ) {
        actionLibrayDataLessonsContents =
          action.payload.libraryData.lessons[0].contents;
        actionLibrayDataLessonsTags = action.payload.libraryData.tags;

        actionLibrayLenght =
          action.payload.libraryData.lessons[0].contents.length;
      }

      // Object that contain the old and the new lessons:
      const lessons = [
        {
          contents: [
            ...stateLibrayDataLessonsContents,
            ...actionLibrayDataLessonsContents,
          ],
        },
      ];

      // Object that contain the old and the new tags:
      const tags = [
        ...stateLibrayDataLessonsTags,
        ...actionLibrayDataLessonsTags,
      ];
      // Delete duplicates tags:
      const uniqueTags = tags.filter(
        (obj, pos, arr) =>
          arr.map((mapObj) => mapObj.title).indexOf(obj.title) === pos
      );

      return {
        ...state,
        libraryData: {
          ...state.libraryData,
          ...action.payload.libraryData,
          lessons,
          tags: uniqueTags,
        },
        libraryDataPage: action.payload.libraryDataPage,
        loading: false,
        error: false,
        libraryLength: actionLibrayLenght,
      };
    case types.GET_LIBRARY_FAILURE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    /**
     * ---------------------------------------------------
     * CLEAN LIBRARY
     * ---------------------------------------------------
     */
    case types.GET_LIBRARY_CLEAN_REQUEST:
      return {
        ...state,
        cleanLibraryLoading: true,
      };
    case types.GET_LIBRARY_CLEAN_SUCCESS:
      // Content that already exists on state:
      let stateTags = [];
      if (state.libraryData && state.libraryData.lessons.length > 0) {
        stateTags = state.libraryData.tags;
      }

      return {
        ...state,
        libraryData: {
          ...state.libraryData,
          ...action.payload.libraryData,
          tags: stateTags,
        },
        libraryDataPage: action.payload.libraryDataPage,
        cleanLibraryLoading: false,
        loading: false,
        error: false,
        libraryLength: 1,
      };

    /**
     * ---------------------------------------------------
     * TOGGLE TAG:
     * ---------------------------------------------------
     */
    case types.TOGGLE_ACTIVE_TAG:
      return {
        ...state,
        libraryData: {
          ...state.libraryData,
          tags: action.payload.tags,
        },
      };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
