/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  courseData: null,
  courseModal: false,
  error: null,
  loading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.courseReducer,
      };

    /**
     * ---------------------------------------------------
     * COURSE
     * ---------------------------------------------------
     */
    case types.GET_COURSE_REQUEST:
      return { ...state, courseData: null, loading: true, error: false };
    case types.GET_COURSE_SUCCESS:
      return {
        ...state,
        courseData: action.payload.courseData,
        loading: false,
        error: false,
      };
    case types.GET_COURSE_FAILURE:
      return { ...state, courseData: false, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * COURSE MODAL
     * ---------------------------------------------------
     */
    case types.SET_COURSE_MODAL:
      return {
        ...state,
        courseModal: action.payload.courseModal,
      };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
