import React from 'react';

interface Props {
  color?: string;
  size?: string;
}

const IconWarningExclamation = React.memo(
  ({ color = '#F26522', size = '81px' }: Props) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.499 32.9062V45.5625"
        stroke={color}
        stroke-width="5.25354"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.1179 12.6514L8.28163 60.7427C7.83621 61.5122 7.60132 62.3855 7.60059 63.2746C7.59985 64.1638 7.8333 65.0374 8.27744 65.8077C8.72158 66.5779 9.36075 67.2176 10.1306 67.6624C10.9005 68.1072 11.774 68.3413 12.6631 68.3413H68.3356C69.2247 68.3413 70.0981 68.1072 70.868 67.6624C71.6379 67.2176 72.2771 66.5779 72.7212 65.8077C73.1654 65.0374 73.3988 64.1638 73.3981 63.2746C73.3973 62.3855 73.1625 61.5122 72.717 60.7427L44.8808 12.6514C44.4361 11.8831 43.7972 11.2453 43.0282 10.8019C42.2591 10.3584 41.387 10.125 40.4993 10.125C39.6116 10.125 38.7395 10.3584 37.9705 10.8019C37.2015 11.2453 36.5626 11.8831 36.1179 12.6514V12.6514Z"
        stroke={color}
        stroke-width="5.25354"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.5 60.7501C42.597 60.7501 44.2969 59.0502 44.2969 56.9532C44.2969 54.8563 42.597 53.1564 40.5 53.1564C38.403 53.1564 36.7031 54.8563 36.7031 56.9532C36.7031 59.0502 38.403 60.7501 40.5 60.7501Z"
        fill={color}
      />
    </svg>
  ),
);

export default IconWarningExclamation;
export { IconWarningExclamation };
