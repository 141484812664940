/**
 * ---------------------------------------------------
 * HOME
 * ---------------------------------------------------
 */
export const GET_NOTIFICATION_REQUEST = '@notification/GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = '@notification/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = '@notification/GET_NOTIFICATION_FAILURE';
export const SET_READ_NOTIFICATION_REQUEST = '@notification/SET_READ_NOTIFICATION_REQUEST';
export const SET_READ_NOTIFICATION_SUCCESS = '@notification/SET_READ_NOTIFICATION_SUCCESS';
export const SET_READ_NOTIFICATION_FAILURE = '@notification/SET_READ_NOTIFICATION_FAILURE';