import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import {
  WatchingListItemDto,
  PaginationDto,
  PaginationQueryDto,
} from 'libs/ms-api/dtos';
import qs from 'qs';

import { AxiosImpUtils } from '../utils';

type IWatchingResource = MSApi['v3']['watching'];

export class WatchingResource implements IWatchingResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(data: {
    bearerToken: string;
    query?: PaginationQueryDto;
  }): Promise<PaginationDto<WatchingListItemDto[]>> {
    const parsedQuery = AxiosImpUtils.parserPaginationQuery(data.query);
    const query = qs.stringify(parsedQuery);

    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(`v3/watching/${query}`);
    return result;
  }
}
