import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ComponentTestStyles } from './ComponentTest.styles';

export const Wrapper = styled.div`
  ${ComponentTestStyles}
`;

const ComponentTest = ({ text }) => {
  return (
    <Wrapper>
      <p>{text}</p>
    </Wrapper>
  );
};

ComponentTest.propTypes = {
  text: PropTypes.string,
};

ComponentTest.defaultProps = {
  text: 'Test',
};

export default ComponentTest;
export { ComponentTest };
