import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  IconHome,
  IconAdd,
  IconFind,
  IconPlay,
  IconClockClockwise,
} from 'components';

import { main } from 'themes';

import * as S from './NavBottom.styles';

interface NavBottomProps {
  handleShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavBottom = ({ handleShowSearch }: NavBottomProps) => {
  const router = useRouter();
  const activeHref = router.pathname;

  return (
    <S.Wrapper>
      <S.MenuItem>
        <Link passHref href="/home">
          <IconHome
            size="18px"
            color={
              activeHref === '/home'
                ? main.colorPallete.primary.default
                : main.colorPallete.base[250]
            }
          />
        </Link>
      </S.MenuItem>
      <S.MenuItem>
        <Link passHref href="/minha-lista">
          <IconAdd
            size="18px"
            color={
              activeHref === '/minha-lista'
                ? main.colorPallete.primary.default
                : main.colorPallete.base[250]
            }
          />
        </Link>
      </S.MenuItem>
      <S.MenuItem>
        <Link passHref href="/continue-assistindo">
          <IconPlay
            size="18px"
            color={
              activeHref === '/continue-assistindo'
                ? main.colorPallete.primary.default
                : main.colorPallete.base[250]
            }
          />
        </Link>
      </S.MenuItem>
      <S.MenuItem>
        <Link passHref href="/assistidos">
          <IconClockClockwise
            color={
              activeHref === '/assistidos'
                ? main.colorPallete.primary.default
                : main.colorPallete.base[250]
            }
          />
        </Link>
      </S.MenuItem>
      <S.MenuItem>
        <button onClick={() => handleShowSearch(true)} type="button">
          <IconFind
            size="24px"
            color={
              activeHref === '/buscar'
                ? main.colorPallete.primary.default
                : main.colorPallete.base[250]
            }
          />
        </button>
      </S.MenuItem>
    </S.Wrapper>
  );
};

export default NavBottom;
export { NavBottom };
