import { css } from 'styled-components';

const styles = ({ theme }) => css`
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: inline-flex;
  min-width: 6.2rem;
  min-height: 2.2rem;
  font-family: ${(props) => props.theme.fonts.sans};
  color: ${(props) => props.theme.colorPallete.white};
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: unset;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  padding: 0.4rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;

  z-index: 10;

  &.resize {
    @media all and (min-width: ${theme.breakpoints.big}) {
      font-size: calc(1vw * 0.6);
      padding: calc(1vw * 0.3) calc(1vw * 0.6);
    }

    @media all and (max-width: ${theme.breakpoints.big}) {
      font-size: 1.1rem;
    }
  }
`;

export default styles;
export { styles };
