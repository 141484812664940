import styled, { css } from 'styled-components';

export const SkeletonWrapper = styled.div`
  padding-inline: 20px;

  .desktop-only {
    display: none;
  }

  @media (min-width: 1025px) {
    padding-inline: 10vw;

    .desktop-only {
      display: block;
    }
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 46px;
`;

export const TopNavigation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;

  margin-top: 100px;

  @media (min-width: 768px) {
    margin-top: 38px;
  }
`;

export const MobileNavigationSkeleton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;

  margin-block: 24px 32px;
`;

export const MaterialWrapper = styled.div`
  padding-inline: 20px;
  padding-block: 2.7rem;
  background-color: #252525;

  .desktop-only {
    display: none;
  }

  @media (min-width: 1025px) {
    padding-inline: 10vw;

    .desktop-only {
      display: block;
    }
  }
`;

export const EntrepreneurWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 46px;
`;

export const CommentsWrapper = styled.div`
  padding-inline: 20px;
  padding-block: 92px 46px;
  background-color: #0d0c0c;

  @media (min-width: 1025px) {
    padding-inline: 10vw;
  }
`;

export const CommentsBoxSkeleton = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
`;
