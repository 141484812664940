import { css } from 'styled-components';

const styles = ({ theme }) => css`
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 998;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  cursor: pointer;

  .update-billing__box {
    width: 100%;
    max-width: 40rem;
    margin-top: -8rem;
    padding: 4rem 2rem;
    background-color: ${theme.colorPallete.white};
    border-radius: 0.4rem;
    text-align: center;
    position: relative;

    &__icon {
      margin-bottom: 2rem;
    }

    &--close {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 2rem;

      position: absolute;
      right: 0;
      top: -3.6rem;

      button {
        background: transparent;
        outline: none;
        padding: 0;
        cursor: pointer;
      }
    }

    .text-component {
      display: block;
      margin-bottom: 1.5rem;
    }

    .button {
      width: 100%;
      max-width: 28rem;
      margin: 0 auto;
      margin-top: 3rem;
    }
  }

  &.visible {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default styles;
export { styles };
