/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import Link from 'next/link';

import { styles } from './Button.styles';

const Wrapper = styled.button`
  ${styles}
`;

const Button = React.memo(
  forwardRef(
    (
      {
        children,
        variant,
        size,
        disabled,
        link,
        href,
        type,
        color,
        asLink,
        id,
        ...restProps
      },
      ref
    ) => {
      const cssClasses = classNames({
        'ms-button': true,
        primary: variant === 'primary',
        secondary: variant === 'secondary',
        hollow: variant === 'hollow',
        tertiary: variant === 'tertiary',
        small: size === 'small',
        medium: size === 'medium',
        big: size === 'big',
        bigger: size === 'bigger',
        'custom-color': color !== null,
      });

      if (link) {
        if (link === 'internal') {
          return (
            <Wrapper as="div" className={cssClasses}>
              <Link href={href} passHref legacyBehavior>
                {disabled === 'true' ? (
                  <a
                    onClick={(event) => event.preventDefault()}
                    onKeyPress={(event) => event.preventDefault()}
                    role="button"
                    disabled="disabled"
                    tabIndex="0"
                    id={id}
                  >
                    {children}
                  </a>
                ) : (
                  <a id={id}>{children}</a>
                )}
              </Link>
            </Wrapper>
          );
        }
        if (link === 'dynamic') {
          return (
            <Wrapper as="div" className={cssClasses}>
              <Link href={href} as={asLink} legacyBehavior>
                {disabled === 'true' ? (
                  <a
                    onClick={(event) => event.preventDefault()}
                    onKeyPress={(event) => event.preventDefault()}
                    role="button"
                    disabled="disabled"
                    tabIndex="0"
                    id={id}
                  >
                    {children}
                  </a>
                ) : (
                  <a id={id}>{children}</a>
                )}
              </Link>
            </Wrapper>
          );
        }
        return (
          <Wrapper
            as="div"
            className={cssClasses}
            disabled={disabled}
            color={color}
          >
            {disabled === 'true' ? (
              <a
                href={href}
                target="_blank"
                onClick={(event) => event.preventDefault()}
                className="disabled"
                tabIndex="0"
                id={id}
              >
                {children}
              </a>
            ) : (
              <a href={href} target="_blank" tabIndex="0" id={id}>
                {children}
              </a>
            )}
          </Wrapper>
        );
      }
      return (
        <Wrapper
          className={cssClasses}
          disabled={disabled}
          type={type}
          ref={ref}
          color={color}
          id={id}
          {...restProps}
        >
          {children}
        </Wrapper>
      );
    }
  )
);

Button.propTypes = {
  asLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  id: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

Button.defaultProps = {
  asLink: null,
  color: null,
  disabled: false,
  href: '',
  id: '',
  link: '',
  size: 'big',
  type: 'button',
};

export default Button;
export { Button };
