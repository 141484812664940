import { callApiBase } from './base';

const favoriteApi = {
  favorite: ({ token }) =>
    callApiBase({
      title: 'Favorite - favorite',
      endpoint: `/favorite`,
      method: 'GET',
      token,
    }),
  sendFavorite: ({ data, token }) =>
    callApiBase({
      title: 'Favorite - sendFavorite',
      endpoint: `/favorite`,
      method: 'POST',
      token,
      data,
    }),
  deleteFavorite: ({ data, token }) =>
    callApiBase({
      title: 'Favorite - sendFavorite',
      endpoint: `/favorite/${data.id}`,
      method: 'DELETE',
      token,
      data,
    }),
};

export default favoriteApi;
export { favoriteApi };
