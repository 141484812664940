import { css } from 'styled-components';

const ToastStyles = ({ theme }) => css`
  .Toastify__toast {
    border-radius: 4px;
    font-family: ${theme.fonts.fontFamily.nunito};
    padding-left: 15px;
    font-size: 1.6rem;
  }

  .Toastify__toast--error {
    background-color: ${theme.colorSystem.error};
  }

  .Toastify__toast--info {
    background-color: ${theme.colorSystem.quaternarybg};
  }

  .Toastify__toast--success {
    background-color: ${theme.colorSystem.success};
  }

  .Toastify__toast--warning {
    background-color: ${theme.colorSystem.warning};
  }
`;

export default ToastStyles;
export { ToastStyles };
