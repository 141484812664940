import * as types from './types';

/**
 * ---------------------------------------------------
 * SEARCH
 * ---------------------------------------------------
 */
export const sendSearchRequest = ({ payload }) => ({
  type: types.SEND_SEARCH_REQUEST,
  payload,
});
export const sendSearchSuccess = ({ payload }) => ({
  type: types.SEND_SEARCH_SUCCESS,
  payload,
});
export const sendSearchFailure = () => ({
  type: types.SEND_SEARCH_FAILURE,
});

/**
 * ---------------------------------------------------
 * CLEAN SEARCH
 * ---------------------------------------------------
 */
export const sendSearchCleanSuccess = ({ payload }) => ({
  type: types.SEND_SEARCH_CLEAN_SUCCESS,
  payload,
});
