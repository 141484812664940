import React from 'react';
import PropTypes from 'prop-types';

// Componentes
import { Text } from '@components';

// Styles
import { main as theme } from '@themes';
import { Wrapper } from './InstructorBio.styles';

const InstructorBio = ({ content }) => {
  return (
    <Wrapper>
      <div>
        {content &&
          content.map((item, idx) => {
            const key = `article-${idx}`;
            return (
              <article className="bio-list" key={key}>
                <div className="bio-list__picture">
                  {item.image && (
                    <img src={item.image?.url} alt={item.image?.alt || ''} />
                  )}
                </div>
                <div className="bio-list__description">
                  <Text
                    as="h4"
                    size="1.8rem"
                    dsize="1.8rem"
                    weight="bold"
                    dheight="200%"
                    color={theme.colorPallete.primary.default}
                  >
                    {item.name}
                  </Text>
                  <Text
                    as="p"
                    dsize="1.4rem"
                    dheight="160%"
                    color={theme.colorPallete.base[400]}
                  >
                    {item.about}
                  </Text>
                </div>
              </article>
            );
          })}
      </div>
    </Wrapper>
  );
};

InstructorBio.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
};

InstructorBio.defaultProps = {
  content: {},
};

export { InstructorBio };
export default InstructorBio;
