import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseGetCertificateInformationProps } from './types';

export const useGetCertificateInformation = (
  msApiLib: MSApi,
  query?: UseGetCertificateInformationProps,
) => {
  const { enabled, cacheTime, bearerToken, params } = query ?? {};

  return useQuery(
    ['useGetCertificateInformation', params],
    async () => {
      const res = await msApiLib.v3.certificates.certificate({
        bearerToken,
        params,
      });

      return res.data;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
