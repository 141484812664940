import { Skeleton } from 'skeletons/Skeleton';

import { PlaylistSkeleton } from './PlaylistSkeleton';
import { VideoPlayerSkeleton } from './VideoPlayerSkeleton';

import { useWindowSize } from 'helpers/useWindowSize';
import * as S from './LessonContentSkeleton.styles';

interface LessonContentSkeletonProps {
  isTablet: boolean;
}

export const LessonContentSkeleton = ({
  isTablet,
}: LessonContentSkeletonProps) => {
  if (isTablet) {
    return (
      <>
        <S.SkeletonWrapper>
          <S.TopNavigation>
            <Skeleton width={84} height={24} />
          </S.TopNavigation>

          <Skeleton width={650} height={30} mt={32} mb={20} />

          <S.VideoContainer>
            <VideoPlayerSkeleton />
          </S.VideoContainer>
        </S.SkeletonWrapper>
        <S.SkeletonWrapper>
          <S.MobileNavigationSkeleton>
            <Skeleton width={100} height={20} />
            <Skeleton width={100} height={20} />
            <Skeleton width={100} height={20} />
          </S.MobileNavigationSkeleton>
          <PlaylistSkeleton />
        </S.SkeletonWrapper>

        <S.CommentsWrapper>
          <Skeleton width={600} height={23} />
          <Skeleton width={250} height={23} mt={4} />
          <Skeleton width={150} height={20} mt={32} mb={24} />
          <S.CommentsBoxSkeleton>
            <Skeleton width={42} height={42} variant="circle" />
            <Skeleton width={556} height={75} />
          </S.CommentsBoxSkeleton>
        </S.CommentsWrapper>
      </>
    );
  }
  return (
    <>
      <S.SkeletonWrapper>
        <S.TopNavigation>
          <Skeleton width={84} height={24} />
          <Skeleton width={350} height={20} />
        </S.TopNavigation>

        <Skeleton width={650} height={30} mt={32} mb={20} />

        <S.VideoContainer>
          <VideoPlayerSkeleton />
          <PlaylistSkeleton />
        </S.VideoContainer>
      </S.SkeletonWrapper>

      <S.MaterialWrapper>
        <Skeleton width={200} height={20} />
        <Skeleton width={400} height={15} mt={6} />
        <Skeleton width={228} height={42} mt={24} />
      </S.MaterialWrapper>

      <S.SkeletonWrapper>
        <Skeleton width={250} height={23} mt={46} />
        <Skeleton width={300} height={15} mt={13} />
        <Skeleton width={300} height={15} mt={6} />
        <Skeleton width={300} height={15} mt={6} />
        <Skeleton width={250} height={23} mt={26} mb={26} />
        <S.EntrepreneurWrapper>
          <Skeleton width={42} height={42} variant="circle" />
          <Skeleton width={180} height={23} />
        </S.EntrepreneurWrapper>
      </S.SkeletonWrapper>

      <S.CommentsWrapper>
        <Skeleton width={600} height={23} />
        <Skeleton width={250} height={23} mt={4} />
        <Skeleton width={150} height={20} mt={32} mb={24} />
        <S.CommentsBoxSkeleton>
          <Skeleton width={42} height={42} variant="circle" />
          <Skeleton width={556} height={75} />
        </S.CommentsBoxSkeleton>
      </S.CommentsWrapper>
    </>
  );
};
