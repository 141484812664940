import React from 'react';
import PropTypes from 'prop-types';

const IconGoogleColored = React.memo(({ size }) => (
  <svg
    id="icon-google-colored"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M22.5997 11.5002C22.5997 10.5947 22.5245 9.93383 22.3618 9.24854H11.78V13.3358H17.9913C17.8661 14.3516 17.1899 15.8813 15.6871 16.9092L15.666 17.046L19.0118 19.5789L19.2436 19.6015C21.3724 17.6802 22.5997 14.8533 22.5997 11.5002Z"
        fill="#4285F4"
      />
      <path
        d="M11.8125 22.3074C14.8381 22.3074 17.3782 21.3297 19.2335 19.6433L15.6973 16.9546C14.751 17.6023 13.481 18.0545 11.8125 18.0545C8.84918 18.0545 6.33406 16.1358 5.43749 13.4839L5.30607 13.4948L1.84699 16.1224L1.80176 16.2458C3.64452 19.8387 7.42969 22.3074 11.8125 22.3074Z"
        fill="#34A853"
      />
      <path
        d="M5.40835 13.4405C5.17288 12.7593 5.0366 12.0294 5.0366 11.2752C5.0366 10.521 5.17288 9.7911 5.39596 9.10989L5.38973 8.96481L1.90345 6.30737L1.78938 6.36063C1.0334 7.84474 0.599609 9.51134 0.599609 11.2752C0.599609 13.0391 1.0334 14.7056 1.78938 16.1897L5.40835 13.4405Z"
        fill="#FBBC05"
      />
      <path
        d="M11.8386 4.56024C13.9483 4.56024 15.3714 5.45237 16.1829 6.1979L19.3537 3.16708C17.4063 1.39504 14.8721 0.307373 11.8386 0.307373C7.44433 0.307373 3.64931 2.77599 1.80176 6.36893L5.43447 9.1309C6.34585 6.47896 8.86752 4.56024 11.8386 4.56024Z"
        fill="#EB4335"
      />
    </g>
  </svg>
));

IconGoogleColored.propTypes = {
  size: PropTypes.string,
};

IconGoogleColored.defaultProps = {
  size: '23px',
};

export default IconGoogleColored;
export { IconGoogleColored };
