import React from 'react';
import PropTypes from 'prop-types';

// Card Icons
import {
  IconAmexAlt,
  IconDinnersAlt,
  IconEloAlt,
  IconHiperAlt,
  IconMasterAlt,
  IconVisaAlt,
} from '../Icons';

const BankFlag = React.memo(({ name }) => {
  return (
    <>
      {
        {
          'American Express': <IconAmexAlt size="30px" />,
          'Dinners Club': <IconDinnersAlt size="30px" />,
          Elo: <IconEloAlt size="30px" />,
          Hipercard: <IconHiperAlt size="30px" />,
          MasterCard: <IconMasterAlt size="30px" />,
          Visa: <IconVisaAlt size="30px" />,
        }[name]
      }
    </>
  );
});

BankFlag.propTypes = {
  name: PropTypes.string,
};

BankFlag.defaultProps = {
  name: '',
};

export default BankFlag;
export { BankFlag };
