import { css } from 'styled-components';

const globalStyles = css`
  :root {
  }

  .page-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #loading-hold {
    transition: all 0.1s ease-in-out;
    z-index: 999;
  }
`;

export default globalStyles;
export { globalStyles };
