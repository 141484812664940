import { CourseListItemDto, MSApi, PaginationDto } from 'libs/ms-api';
import { useInfiniteQuery, useQuery } from 'react-query';

import { UseThematicCoursesDataProps } from './types';

/**
 * @deprecated para paginação utilize o hook de infinite query
 */
export const useThematicCoursesData = (
  msApiLib: MSApi,
  params?: UseThematicCoursesDataProps,
) => {
  const { enabled, cacheTime, query, bearerToken } = params ?? {};
  const { page = 1, pageSize = 100, tags = [] } = query ?? {};

  return useQuery(
    ['getThematicCourses', tags, page, pageSize],
    async () => {
      const res = await msApiLib.v3.courses.list({
        bearerToken,
        query: { categories: ['serie'], tags, pageSize, page },
      });

      return res;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};

export const useInfiniteThematicCoursesData = (
  msApiLib: MSApi,
  params?: UseThematicCoursesDataProps,
) => {
  const { enabled, cacheTime, query, bearerToken } = params ?? {};
  const { page = 0, pageSize, tags = [] } = query ?? {};

  const fetchCourses = async ({ pageParam = page, pageSize }) => {
    const res = await msApiLib.v3.courses.list({
      bearerToken,
      query: {
        categories: ['serie'],
        tags,
        pageSize,
        page: pageParam,
      },
    });

    return res;
  };

  let totalCards: number;
  let currentPage: number;

  const nextPageParam = (lastPage: PaginationDto<CourseListItemDto[]>) => {
    const { page, pageSize, total } = lastPage.meta.pagination;
    totalCards = total;
    currentPage = page;
    if (page * pageSize >= total) return undefined;
    else return page + 1;
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['getInfiniteThematicCourses', tags, page, pageSize],
    queryFn: (params) => fetchCourses({ ...params, pageSize }),
    getNextPageParam: (lastPage, pages) =>
      nextPageParam(lastPage as PaginationDto<CourseListItemDto[]>),
    refetchOnWindowFocus: false,
    ...(cacheTime !== undefined && { cacheTime }),
    ...(enabled !== undefined && { enabled }),
  });

  return {
    data,
    totalCards,
    currentPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
  };
};
