import * as types from './types';

/**
 * ---------------------------------------------------
 * CLEAR LESSON
 * ---------------------------------------------------
 */
export const clearLesson = () => ({
  type: types.CLEAR_LESSON,
});

/**
 * ---------------------------------------------------
 * GET LESSON
 * ---------------------------------------------------
 */
export const getLessonRequest = (payload) => ({
  type: types.GET_LESSON_REQUEST,
  payload,
});
export const getLessonSuccess = ({ payload }) => ({
  type: types.GET_LESSON_SUCCESS,
  payload,
});
export const getLessonFailure = ({ payload }) => ({
  type: types.GET_LESSON_FAILURE,
  payload,
});

/**
 * ---------------------------------------------------
 * UPDATE LESSON
 * ---------------------------------------------------
 */
export const updateLessonContent = ({ payload }) => ({
  type: types.UPDATE_LESSON_CONTENT,
  payload,
});

/**
 * ---------------------------------------------------
 * GET COMMENTS
 * ---------------------------------------------------
 */
export const getCommentsRequest = ({ payload }) => ({
  type: types.GET_COMMENTS_REQUEST,
  payload,
});

export const getCommentsSuccess = ({ payload }) => ({
  type: types.GET_COMMENTS_SUCCESS,
  payload,
});
export const getCommentsFailure = ({ payload }) => ({
  type: types.GET_COMMENTS_FAILURE,
  payload,
});

/**
 * ---------------------------------------------------
 * SEND COMMENTS
 * ---------------------------------------------------
 */
export const sendCommentsRequest = ({ payload }) => ({
  type: types.SEND_COMMENTS_REQUEST,
  payload,
});

export const sendCommentsSuccess = ({ payload }) => ({
  type: types.SEND_COMMENTS_SUCCESS,
  payload,
});
export const sendCommentsFailure = ({ payload }) => ({
  type: types.SEND_COMMENTS_FAILURE,
  payload,
});

/**
 * ---------------------------------------------------
 * VIDEO_UNAVAILABLE
 * ---------------------------------------------------
 */
export const toggleVideoUnavailable = (payload) => ({
  type: types.TOGGLE_VIDEO_UNAVAILABLE,
  payload,
});
