import React from 'react';
import ContentLoader from 'react-content-loader';

import { main as theme } from '@themes';

import { Wrapper } from './VideoListSkeleton.styles';

const VideoListSkeleton = () => {
  return (
    <Wrapper>
      <div className="small">
        <ContentLoader
          speed={1}
          width={335}
          viewBox="0 0 335 450"
          backgroundColor={theme.colorPallete.base[700]}
          foregroundColor={theme.colorPallete.base[600]}
        >
          <rect x="00" y="00" rx="8" ry="8" width="150" height="90" />
          <rect x="00" y="100" rx="8" ry="8" width="150" height="10" />
          <rect x="00" y="120" rx="8" ry="8" width="110" height="10" />

          <rect x="185" y="00" rx="8" ry="8" width="150" height="90" />
          <rect x="185" y="100" rx="8" ry="8" width="150" height="10" />
          <rect x="185" y="120" rx="8" ry="8" width="110" height="10" />

          <rect x="00" y="160" rx="8" ry="8" width="150" height="90" />
          <rect x="00" y="260" rx="8" ry="8" width="150" height="10" />
          <rect x="00" y="280" rx="8" ry="8" width="110" height="10" />

          <rect x="185" y="160" rx="8" ry="8" width="150" height="90" />
          <rect x="185" y="260" rx="8" ry="8" width="150" height="10" />
          <rect x="185" y="280" rx="8" ry="8" width="110" height="10" />

          <rect x="00" y="320" rx="8" ry="8" width="150" height="90" />
          <rect x="00" y="420" rx="8" ry="8" width="150" height="10" />
          <rect x="00" y="440" rx="8" ry="8" width="110" height="10" />

          <rect x="185" y="320" rx="8" ry="8" width="150" height="90" />
          <rect x="185" y="420" rx="8" ry="8" width="150" height="10" />
          <rect x="185" y="440" rx="8" ry="8" width="110" height="10" />
        </ContentLoader>
      </div>

      <div className="medium">
        <ContentLoader
          speed={1}
          width={658}
          viewBox="0 0 658 344"
          backgroundColor={theme.colorPallete.base[700]}
          foregroundColor={theme.colorPallete.base[600]}
        >
          <rect x="00" y="00" rx="8" ry="8" width="200" height="112" />
          <rect x="00" y="122" rx="8" ry="8" width="200" height="10" />
          <rect x="00" y="142" rx="8" ry="8" width="140" height="10" />

          <rect x="230" y="00" rx="8" ry="8" width="200" height="112" />
          <rect x="230" y="122" rx="8" ry="8" width="200" height="10" />
          <rect x="230" y="142" rx="8" ry="8" width="140" height="10" />

          <rect x="460" y="00" rx="8" ry="8" width="200" height="112" />
          <rect x="460" y="122" rx="8" ry="8" width="200" height="10" />
          <rect x="460" y="142" rx="8" ry="8" width="140" height="10" />

          <rect x="00" y="192" rx="8" ry="8" width="200" height="112" />
          <rect x="00" y="314" rx="8" ry="8" width="200" height="10" />
          <rect x="00" y="334" rx="8" ry="8" width="140" height="10" />

          <rect x="230" y="192" rx="8" ry="8" width="200" height="112" />
          <rect x="230" y="314" rx="8" ry="8" width="200" height="10" />
          <rect x="230" y="334" rx="8" ry="8" width="140" height="10" />

          <rect x="460" y="192" rx="8" ry="8" width="200" height="112" />
          <rect x="460" y="314" rx="8" ry="8" width="200" height="10" />
          <rect x="460" y="334" rx="8" ry="8" width="140" height="10" />
        </ContentLoader>
      </div>
      <div className="big">
        <ContentLoader
          speed={1}
          width={1090}
          viewBox="0 0 1090 400"
          backgroundColor={theme.colorPallete.base[700]}
          foregroundColor={theme.colorPallete.base[600]}
        >
          <rect x="00" y="00" rx="8" ry="8" width="250" height="140" />
          <rect x="00" y="150" rx="8" ry="8" width="250" height="10" />
          <rect x="00" y="170" rx="8" ry="8" width="180" height="10" />

          <rect x="280" y="00" rx="8" ry="8" width="250" height="140" />
          <rect x="280" y="150" rx="8" ry="8" width="250" height="10" />
          <rect x="280" y="170" rx="8" ry="8" width="180" height="10" />

          <rect x="560" y="00" rx="8" ry="8" width="250" height="140" />
          <rect x="560" y="150" rx="8" ry="8" width="250" height="10" />
          <rect x="560" y="170" rx="8" ry="8" width="180" height="10" />

          <rect x="840" y="00" rx="8" ry="8" width="250" height="140" />
          <rect x="840" y="150" rx="8" ry="8" width="250" height="10" />
          <rect x="840" y="170" rx="8" ry="8" width="180" height="10" />

          <rect x="00" y="220" rx="8" ry="8" width="250" height="140" />
          <rect x="00" y="370" rx="8" ry="8" width="250" height="10" />
          <rect x="00" y="390" rx="8" ry="8" width="180" height="10" />

          <rect x="280" y="220" rx="8" ry="8" width="250" height="140" />
          <rect x="280" y="370" rx="8" ry="8" width="250" height="10" />
          <rect x="280" y="390" rx="8" ry="8" width="180" height="10" />

          <rect x="560" y="220" rx="8" ry="8" width="250" height="140" />
          <rect x="560" y="370" rx="8" ry="8" width="250" height="10" />
          <rect x="560" y="390" rx="8" ry="8" width="180" height="10" />

          <rect x="840" y="220" rx="8" ry="8" width="250" height="140" />
          <rect x="840" y="370" rx="8" ry="8" width="250" height="10" />
          <rect x="840" y="390" rx="8" ry="8" width="180" height="10" />
        </ContentLoader>
      </div>
    </Wrapper>
  );
};

export default VideoListSkeleton;
export { VideoListSkeleton };
