import { callApiBase } from './base';

const hatApi = {
  hat: ({ }) =>
    callApiBase({
      title: 'Hats',
      endpoint: '/hats',
      method: 'GET',
    }),
};

export default hatApi;
export { hatApi };
