import styled, { css } from 'styled-components';
import { convertHexToRGBA } from 'utils/hexToRgba';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-family: ${theme.fonts.fontFamily.sans};
    padding-block: 16px;
    background-color: ${theme.colorSystem.black};
    margin-block: 40px;
    padding-top: 6rem;
    padding-inline: 20px;

    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3.2rem;
  margin: 0 auto;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  .title {
    height: 20px;
  }
  .subtitle {
    height: 15px;
    width: 450px;
    max-width: 450px;
  }
`;

export const CourseTrailContainer = styled.div`
  ${({ theme }) => css`
    margin-left: 4px;
    margin-bottom: 30px;
    position: relative;

    .module-number {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      z-index: 5;

      border-radius: 50%;
    }

    .module-title {
      height: 20px;
      width: 500px;
      max-width: 500px;
    }

    &::before {
      content: '';
      width: 1px;
      height: calc(100% + 30px);
      position: absolute;

      left: calc(35px / 2 - 1px);
      top: calc(35px / 2);

      background: linear-gradient(
        to right,
        ${convertHexToRGBA('#313030', '32%')} 0%,
        ${convertHexToRGBA('#313030', '64%')} 40%,
        ${convertHexToRGBA('#313030', '32%')} 60%,
        ${convertHexToRGBA('#313030', '32%')} 100%
      );
      background-size: 80rem 14rem;
      animation: placeholderShimmer 1.3s linear infinite forwards;
      z-index: 1;
    }

    &.completed {
      &::before {
        background-color: ${theme.colorPallete.primary[200]};
      }
    }
  `}
`;

export const TitleCourseTrailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 24px;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 50px;
  margin-left: 50px;
  padding-right: 12px;

  @media (max-width: 790px) {
    padding-right: 0;
  }

  .cards {
    width: 270px;
    min-width: 270px;
    max-width: 276px;
    position: relative;
    height: 378px;

    box-sizing: border-box;
    padding: 10px 10px 20px 10px;
    border-radius: 4px;
  }
`;
