import * as types from './types';

/**
 * ---------------------------------------------------
 * KEEP WATCHING
 * ---------------------------------------------------
 */
export const getKeepWatchingRequest = ({ payload }) => ({
  type: types.GET_KEEP_WATCHING_REQUEST,
  payload,
});

export const getKeepWatchingSuccess = ({ payload }) => ({
  type: types.GET_KEEP_WATCHING_SUCCESS,
  payload,
});

export const getKeepWatchingFailure = () => ({
  type: types.GET_KEEP_WATCHING_FAILURE,
});
