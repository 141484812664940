/* eslint-disable import/no-cycle */
import { callApiBase } from './base';

const quizApi = {
  getQuiz: ({ token, slug }) =>
    callApiBase({
      title: 'Quiz - Get question',
      endpoint: `/checklist/${slug}`,
      method: 'GET',
      token,
    }),
  resetQuiz: ({ token, slug }) =>
    callApiBase({
      title: 'Quiz - Reset user answers',
      endpoint: `/checklist/${slug}`,
      method: 'PUT',
      token,
    }),
};

export default quizApi;
export { quizApi };
