/**
 * ---------------------------------------------------
 * GET FAVORITE
 * ---------------------------------------------------
 */
export const GET_FAVORITE_REQUEST = '@favorite/GET_FAVORITE_REQUEST';
export const GET_FAVORITE_SUCCESS = '@favorite/GET_FAVORITE_SUCCESS';
export const GET_FAVORITE_FAILURE = '@favorite/GET_FAVORITE_FAILURE';

/**
 * ---------------------------------------------------
 * SEND FAVORITE
 * ---------------------------------------------------
 */
export const SEND_FAVORITE_REQUEST = '@favorite/SEND_FAVORITE_REQUEST';
export const SEND_FAVORITE_SUCCESS = '@favorite/SEND_FAVORITE_SUCCESS';
export const SEND_FAVORITE_FAILURE = '@favorite/SEND_FAVORITE_FAILURE';

/**
 * ---------------------------------------------------
 * DELETE FAVORITE
 * ---------------------------------------------------
 */
export const DELETE_FAVORITE_REQUEST = '@favorite/DELETE_FAVORITE_REQUEST';
export const DELETE_FAVORITE_SUCCESS = '@favorite/DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAILURE = '@favorite/DELETE_FAVORITE_FAILURE';
