import React from 'react';
import PropTypes from 'prop-types';

const IconBookmark = React.memo(({ size, color }) => (
  <svg
    id="icon-bookmark"
    viewBox="0 0 28 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.864 5.2245C27.864 2.26395 25.6 0 22.6395 0H5.2245C2.26395 0 0 2.26395 0 5.2245V34.83L10.449 26.4708L20.898 34.83V13.932H27.864V5.2245ZM17.415 27.5157L10.449 21.9429L3.483 27.5157V5.2245C3.483 4.1796 4.1796 3.483 5.2245 3.483H17.7633C17.5891 4.00545 17.415 4.5279 17.415 5.2245V27.5157ZM20.898 5.2245V10.449H24.381V5.2245C24.381 4.1796 23.6844 3.483 22.6395 3.483C21.5946 3.483 20.898 4.1796 20.898 5.2245Z"
      fill={color}
    />
  </svg>
));

IconBookmark.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconBookmark.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconBookmark;
export { IconBookmark };
