import React from 'react';

interface IconDotsThreeVerticalProps {
  color?: string;
}

const IconDotsThreeVertical = React.memo(
  ({ color = '#7F7F7F' }: IconDotsThreeVerticalProps) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3809 14.4209C13.4854 14.4209 14.3809 13.5255 14.3809 12.4209C14.3809 11.3163 13.4854 10.4209 12.3809 10.4209C11.2763 10.4209 10.3809 11.3163 10.3809 12.4209C10.3809 13.5255 11.2763 14.4209 12.3809 14.4209Z"
        fill="#7F7F7F"
      />
      <path
        d="M12.3809 6C13.4854 6 14.3809 5.10457 14.3809 4C14.3809 2.89543 13.4854 2 12.3809 2C11.2763 2 10.3809 2.89543 10.3809 4C10.3809 5.10457 11.2763 6 12.3809 6Z"
        fill="#7F7F7F"
      />
      <path
        d="M12.3809 22.8423C13.4854 22.8423 14.3809 21.9469 14.3809 20.8423C14.3809 19.7377 13.4854 18.8423 12.3809 18.8423C11.2763 18.8423 10.3809 19.7377 10.3809 20.8423C10.3809 21.9469 11.2763 22.8423 12.3809 22.8423Z"
        fill="#7F7F7F"
      />
    </svg>
  ),
);

export default IconDotsThreeVertical;
export { IconDotsThreeVertical };
