import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colorPallete.black};
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 0;

    width: 100%;
    height: 100%;
    overflow: auto;
  `}
`;

export const NavList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 20px 0px;
    list-style: none;

    &.separator::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.1rem;
      background: ${theme.colorPallete.base[500]};
      margin-block: 18px 28px;
    }
  `}
`;

export const NavItem = styled.li`
  ${({ theme }) => css`
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      font-size: ${theme.fonts.fontSize.md};
      font-family: ${theme.fonts.fontFamily.sans};
      font-weight: ${theme.fonts.fontWeight.regular};
      color: ${theme.colorPallete.white};
    }
  `}
`;

export const Logout = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 80px 60px;
`;

export const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    background: transparent;
    cursor: pointer;

    font-size: ${theme.fonts.fontSize.md};
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.regular};
    color: ${theme.colorPallete.white};
    gap: 16px;
  `}
`;
