import { callApiBase } from './base';

const watchedApi = {
  watched: ({ token }) =>
    callApiBase({
      title: 'Watched - watched',
      endpoint: `/watched`,
      method: 'GET',
      token,
    }),
};

export default watchedApi;
export { watchedApi };
