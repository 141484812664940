import React from 'react';
import { Skeleton } from '..';
import * as S from './RecommendedContentSkeleton.styles';

const RecommendedContentSkeleton = () => {
  const amountCards = ['card1', 'card2', 'card3', 'card4'];

  return (
    <S.Wrapper>
      <S.Content>
        <S.TextContainer>
          <Skeleton className="title" />
        </S.TextContainer>
        <S.RecommendedContent>
          {amountCards.map((card) => (
            <Skeleton key={card} className="cards" />
          ))}
        </S.RecommendedContent>
      </S.Content>
    </S.Wrapper>
  );
};

export default RecommendedContentSkeleton;
export { RecommendedContentSkeleton };
