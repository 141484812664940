/**
 * ---------------------------------------------------
 * COURSE
 * ---------------------------------------------------
 */
export const GET_COURSE_REQUEST = '@course/GET_COURSE_REQUEST';
export const GET_COURSE_SUCCESS = '@course/GET_COURSE_SUCCESS';
export const GET_COURSE_FAILURE = '@course/GET_COURSE_FAILURE';

/**
 * ---------------------------------------------------
 * COURSE MODAL
 * ---------------------------------------------------
 */
export const SET_COURSE_MODAL = '@course/SET_COURSE_MODAL';
