import React from 'react';
import PropTypes from 'prop-types';

const IconDinnersCard = React.memo(({ color, size }) => (
  <svg
    id="icon-dinners-card"
    viewBox="0 0 51 35"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.9844 35C49.6484 35 51 33.6572 51 32V3.00001C51 2.60499 50.9219 2.21436 50.7695 1.84962C50.6172 1.48487 50.3945 1.15382 50.1133 0.875498C49.9727 0.734873 49.8203 0.608896 49.6562 0.499521C49.4922 0.392099 49.3164 0.300302 49.1367 0.226083C48.7695 0.0756928 48.3789 -0.000967349 47.9844 9.21383e-06H3.01562C1.35156 9.21383e-06 0 1.34278 0 3.00001V32C0 32.2573 0.03125 32.5127 0.0976562 32.7603C0.132812 32.8931 0.175781 33.0234 0.230469 33.1504C0.304688 33.3281 0.394531 33.4981 0.5 33.6577C0.613281 33.8252 0.742188 33.9819 0.886719 34.1245C1.16406 34.4033 1.5 34.624 1.86328 34.7739C2.23047 34.9243 2.62109 35.001 3.01562 35H47.9844ZM8.39844 17.022C8.39844 24.1094 14.418 29.8408 21.8125 29.8408C29.2305 29.8408 35.2305 24.1094 35.2305 17.022C35.2305 9.95606 29.2305 4.20313 21.8125 4.20313C14.418 4.20313 8.39844 9.95606 8.39844 17.022ZM13.3203 17.022C13.3203 13.5654 15.5859 10.6123 18.7695 9.45313V24.6123C15.5859 23.4312 13.3203 20.478 13.3203 17.022ZM24.8594 24.6123V9.43116C28.043 10.6123 30.3086 13.5654 30.3086 17.022C30.3086 20.478 28.043 23.4312 24.8594 24.6123ZM21.7891 3.00001H28.6836C36.6953 3.00001 44 9.23438 44 16.8687C44 25.2251 36.6953 31 28.6836 31H21.7891C13.6875 31 7 25.2251 7 16.8687C7 9.23438 13.6875 3.00001 21.7891 3.00001Z"
      fill={color}
    />
  </svg>
));

IconDinnersCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconDinnersCard.defaultProps = {
  color: '#0d0c0c',
  size: '49px',
};

export default IconDinnersCard;
export { IconDinnersCard };
