import React from 'react';

interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const IconArrow = React.memo(
  ({ color = '#ffffff', width = '17', height = '30' }: Props) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16 29L2 15L16 1" stroke={color} strokeWidth="2.33333" />
    </svg>
  ),
);

export default IconArrow;
export { IconArrow };
