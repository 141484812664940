import React from 'react';
import * as S from './ComplementarMaterialSkeleton.styles';
import { Skeleton } from '..';

const ComplementarMaterialSkeleton = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.TextContainer>
          <Skeleton className="title" />
          <Skeleton className="subtitle" />
        </S.TextContainer>

        <S.ButtonsContainer>
          <Skeleton className="button" />
          <Skeleton className="button" />
          <Skeleton className="button" />
          <Skeleton className="button" />
        </S.ButtonsContainer>
      </S.Container>
    </S.Wrapper>
  );
};

export default ComplementarMaterialSkeleton;
export { ComplementarMaterialSkeleton };
