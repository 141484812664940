import React from 'react';
import PropTypes from 'prop-types';

const IconSettings = React.memo(({ size, color }) => (
  <svg
    id="icon-settings"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9.2V6.8H13.8756C13.7244 6.03556 13.4222 5.32444 13.0044 4.69333L14.4978 3.2L12.8 1.50222L11.3067 2.99556C10.6756 2.57778 9.96445 2.27556 9.2 2.11556V0H6.8V2.11556C6.02667 2.27556 5.31556 2.57778 4.68444 2.99556L3.19111 1.50222L1.49333 3.2L2.98667 4.69333C2.56889 5.32444 2.26667 6.03556 2.11556 6.8H0V9.2H2.11556C2.27556 9.96445 2.57778 10.6756 2.99556 11.3067L1.49333 12.8089L3.19111 14.5067L4.69333 13.0044C5.32444 13.4222 6.03556 13.7244 6.8 13.8756V16H9.2V13.8756C9.96445 13.7244 10.6667 13.4222 11.2978 13.0044L12.8 14.5067L14.4978 12.8089L12.9956 11.3067C13.4133 10.6756 13.7156 9.96445 13.8756 9.2H16ZM8.00001 11.6C6.0089 11.6 4.40001 9.98223 4.40001 8.00001C4.40001 6.0089 6.0089 4.40001 8.00001 4.40001C9.98223 4.40001 11.6 6.0089 11.6 8.00001C11.6 9.98223 9.98223 11.6 8.00001 11.6Z"
      fill={color}
    />
  </svg>
));

IconSettings.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconSettings.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconSettings;
export { IconSettings };
