import styled, { css } from 'styled-components';

export const SkeletonCard = styled.div`
  ${() => css`
    display: flex;
    width: 100%;
    gap: 30px;

    div {
      width: 100%;
      max-width: 270px;
      height: auto;
      max-height: 360px;
      border-radius: 4px;

      aspect-ratio: 0.6;
    }
  `}
`;
