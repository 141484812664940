import styled, { css } from 'styled-components';

export const Wrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    background: ${theme.colorPallete.base[680]};
    padding: 1.5rem 2rem;

    a,
    button {
      cursor: pointer;
      background: transparent;
      border: none;
    }
  `}
`;

export const MenuItem = styled.li`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
`;
