/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
  seriesData: null,
  filterSeries: false,
  whichFilterSeries: null,
  textFilterSeries: null
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.seriesReducer,
      };

    /**
     * ---------------------------------------------------
     * SERIES
     * ---------------------------------------------------
     */
    case types.GET_SERIES_REQUEST:
      return { ...state, seriesData: null, loading: true, error: false, filterSeries: false, whichFilterSeries: null, textFilterSeries: null };
    case types.GET_SERIES_SUCCESS:
      return {
        ...state,
        seriesData: action.payload.seriesData,
        loading: false,
        error: false,
        filterSeries: action.payload.filterSeries,
        whichFilterSeries: action.payload.whichFilterSeries,
        textFilterSeries: action.payload.textFilterSeries
      };
    case types.GET_SERIES_FAILURE:
      return { ...state, seriesData: null, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
