import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 516px;
    background-color: ${theme.colorPallete.white};
    border-radius: 4px;
    padding: 40px 64px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: ${theme.fonts.fontFamily.sans};

    a {
      width: 100%;
      text-align: center;
    }

    @media (max-width: 600px) {
      width: 100%;
      padding: 40px 24px;
      margin-top: 30%;
    }
  `}
`;

export const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;

  top: -32px;
  right: 0;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colorPallete.primary[200]};
    font-size: ${theme.fonts.fontSize.gg};
    font-weight: ${theme.fonts.fontWeight.bold};

    margin-block: 4px 24px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorPallete.black};
    font-size: ${theme.fonts.fontSize.sm};
    font-weight: ${theme.fonts.fontWeight.regular};
    text-align: center;
  `}
`;

export const ActionButton = styled.button`
  ${({ theme }) => css`
    margin-top: 24px;
    width: min(100%, 310px);
    padding: 12px;
    border-radius: 8px;

    background-color: ${theme.colorPallete.primary[200]};
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;
