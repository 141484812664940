/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as seriesActions } from '@store/ducks/series';

// MSV2-api
import { seriesApi as msv2SeriesApi } from '@services/msv2-api';

// Mirror-api
import { seriesApi as mirrorSeriesApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * SERIES
 * ---------------------------------------------------
 */
export function* getSeries({ payload }) {
  const { auth, isServer } = payload;
  let whichFilterSeries = ""
  const request = isServer ? msv2SeriesApi : mirrorSeriesApi;
  const token = isServer ? auth.token : '';

  if (payload.whichFilterSeries !== undefined) {
    whichFilterSeries = payload.whichFilterSeries
  }


  try {
    const response = yield call(request.getSeries, {
      method: 'GET',
      token,
      data: { slug: whichFilterSeries },
    });

    if (response.status === 200) {
      yield put(
        seriesActions.getSeriesSuccess({
          payload: {
            seriesData: response.data.content.instance,
            filterSeries: whichFilterSeries && whichFilterSeries !== undefined ? true : false,
            whichFilterSeries: whichFilterSeries ? whichFilterSeries : null,
            textFilterSeries: payload.textFilterSeries ? payload.textFilterSeries : null
          },
        })
      );
    }
  } catch (error) {
    if (error && error.response) {
      yield put(seriesActions.getSeriesFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_SERIES_REQUEST, getSeries);
}

export const sagas = [watchUser];