import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Classnames
import classnames from 'classnames';

// Theme
import { main as theme } from '@themes';

// Components
import { IconCheck, IconError } from '../Icons';
import { Text } from '../Text';

// Styles
import { styles } from './ChecklistAnswer.styles';

// Main wrapper
const Wrapper = styled.div`
  ${styles}
`;

const ChecklistAnswer = ({
  alternative,
  correct,
  label,
  line,
  sendQuestion,
  answeredQuestion,
  toggleAlternative,
}) => {
  const handleAlternativeColor = (type) => {
    switch (type) {
      case 'correct':
        if (answeredQuestion !== 0) {
          return sendQuestion ? correct : answeredQuestion === line && correct;
        }
        return correct && sendQuestion;
      case 'wrong':
        if (answeredQuestion !== 0) {
          return sendQuestion
            ? !correct
            : answeredQuestion === line && !correct;
        }
        return !correct && sendQuestion;
      default:
        return !correct && sendQuestion && alternative !== line;
    }
  };

  return (
    <Wrapper sendQuestion={sendQuestion}>
      <input
        type="radio"
        name="answer"
        className="answer"
        id={`answer-${line}`}
        onChange={() => toggleAlternative(sendQuestion ? alternative : line)}
      />
      <label
        htmlFor={`answer-${line}`}
        className={classnames({
          default: true,
          correct: handleAlternativeColor('correct'),
          wrong: handleAlternativeColor('wrong'),
          'not-selected': handleAlternativeColor('not-selected'),
        })}
      >
        <span className="answer__checkmark">
          <IconCheck size="18px" color={theme.colorPallete.white} />
          <span className="answer__checkmark-filler" />
          <IconError size="18px" color={theme.colorPallete.white} />
        </span>

        <Text
          className="answer-text"
          dsize="1.4rem"
          size="1.4rem"
          color={theme.colorPallete.base.default}
        >
          {label}
        </Text>
      </label>
    </Wrapper>
  );
};

ChecklistAnswer.propTypes = {
  alternative: PropTypes.number,
  correct: PropTypes.bool,
  label: PropTypes.string,
  line: PropTypes.number,
  sendQuestion: PropTypes.bool,
  answeredQuestion: PropTypes.number,
  toggleAlternative: PropTypes.func,
};

ChecklistAnswer.defaultProps = {
  alternative: 0,
  correct: '',
  label: '',
  line: '',
  sendQuestion: false,
  answeredQuestion: 0,
  toggleAlternative: () => '',
};

export default ChecklistAnswer;
export { ChecklistAnswer };
