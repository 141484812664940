import styled, { css } from 'styled-components';

export const Navbar = styled.nav`
  ${({ theme }) => css`
    height: 80px;
    display: flex;
    width: 100%;
    gap: 2vw;
    justify-content: space-between;
    align-items: center;
    padding-inline: 48px;
    background-color: ${theme.colorPallete.base.default};
    position: relative;
  `}
`;

export const MenuList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    gap: 2vw;
    flex-direction: row;
    font-size: ${theme.fonts.fontSize.md};

    @media (max-width: 1500px) {
      gap: 1.5vw;
      font-size: ${theme.fonts.fontSize.sm};
    }
  `}
`;

export const MenuItem = styled.li`
  ${({ theme }) => css`
    transition: all 0.5s;
    list-style: none;

    color: ${theme.colorPallete.base[300]};

    svg path {
      transition: all 0.5s;
      ${theme.colorPallete.base[300]};
    }

    &:hover {
      color: ${theme.colorPallete.white};

      svg path {
        stroke: ${theme.colorPallete.white};
      }
    }

    &.menu-item__active {
      color: ${theme.colorPallete.primary.default};

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: ${theme.colorPallete.primary.default};
        position: relative;
        bottom: -5px;
      }

      svg path {
        stroke: ${theme.colorPallete.primary.default};
      }
    }

    a {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      text-decoration: none;
      white-space: nowrap;
      font-family: ${theme.fonts.fontFamily.sans};

      font-weight: ${theme.fonts.fontWeight.bold};
      color: inherit;
    }
  `}
`;

export const NavbarRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 1vw;

    .caixa-icone-notificacao {
      svg path {
        transition: all 0.5s;
        ${theme.colorPallete.base[300]};
      }

      &:hover {
        color: ${theme.colorPallete.white};

        svg path {
          stroke: ${theme.colorPallete.white};
        }
      }
    }
  `}
`;

export const SearchButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg path {
      transition: all 0.5s;
      ${theme.colorPallete.base[300]};
    }

    &:hover {
      color: ${theme.colorPallete.white};

      svg path {
        stroke: ${theme.colorPallete.white};
      }
    }
  `}
`;
