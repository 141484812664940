import React from 'react';
import PropTypes from 'prop-types';

const IconDownArrow = React.memo(({ size, color }) => (
  <svg
    id="icon-down-arrow"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9575 0.384262C16.3936 -0.0954665 17.136 -0.130821 17.6157 0.305296C18.0955 0.741412 18.1308 1.48385 17.6947 1.96358L9.86862 10.5723C9.40286 11.0846 8.59714 11.0846 8.13138 10.5723L0.305296 1.96358C-0.130821 1.48385 -0.0954665 0.741412 0.384262 0.305296C0.86399 -0.130821 1.60643 -0.0954665 2.04254 0.384262L9 8.03746L15.9575 0.384262Z"
      fill={color}
    />
  </svg>
));

IconDownArrow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconDownArrow.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconDownArrow;
export { IconDownArrow };
