import { AxiosInstance, AxiosResponse } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import { PostSocialNameDto } from 'libs/ms-api/dtos';
import { RequestWithBearerAndQuery } from 'libs/ms-api/types';

type ICertificateResource = MSApi['v2']['certificate'];

export class Certificate implements ICertificateResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async postSocialName(
    data: RequestWithBearerAndQuery<PostSocialNameDto>,
  ): Promise<AxiosResponse> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;
    const { data: result } = await this.msApi.post(
      `user/social-name`,
      data.query,
    );
    return result;
  }
}
