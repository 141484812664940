import styled from 'styled-components';
import { main as theme } from 'themes';

interface WrapperProps {
  visible?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${(props) => (props.visible ? '100%' : '0')};
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  background-color: ${theme.colorSystem.black};
  transition: width 300ms;

  .search-bar {
    background-color: ${theme.colorPallete.base[600]};
    width: 100%;
    height: 5rem;

    display: flex;
    align-items: center;
    padding: 1rem;

    .search-bar__search-icon {
      margin: 1rem;
      display: none;
    }

    .search-bar__close-icon {
      background: transparent;
      cursor: pointer;
      outline: none;
      margin: 1rem;
    }

    .search-bar__do-search-icon {
      background: transparent;
      border: none;
      box-shadow: none;
      margin-bottom: -0.5rem;
      margin-left: 1.5rem;
    }

    .search-bar__form {
      width: 100%;
      padding: 1rem;
      background-color: ${theme.colorPallete.base[600]};
      color: ${theme.colorSystem.white};
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2rem;

      &::placeholder {
        color: #7c7c7c;
      }
    }
  }

  .search-results {
    background-color: ${theme.colorSystem.black};
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 10;

    &.show {
      overflow: auto;
      height: calc(100% - 6rem);
      padding: 10px;
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }

    .block-title {
      display: flex;
      align-items: center;
      padding-bottom: 1.6rem;

      button {
        margin-left: 2rem;
        background: transparent;
        outline: none;
        cursor: pointer;
      }
    }

    &__loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-results__suggestions {
      width: 100%;
      padding: 2rem 1rem;

      .search-suggestion {
        display: block;
        padding-bottom: 2rem;

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        display: inline-flex;
        margin-top: 1rem;
      }

      &--item {
        width: 100%;
      }
    }

    .search-results__recent,
    .search-results__popular {
      padding: 2rem 1rem;
    }

    .search-results__tags {
      & > span {
        margin-right: 1rem;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    max-width: 100%;
    max-height: 40rem;
    height: auto;
    overflow: hidden;
    background-color: transparent;
    box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.7);

    .search-bar {
      height: 80px;

      .search-bar__form {
        font-size: 22px;
        line-height: 22px;

        &:focus,
        &:active {
          box-shadow: none;
          border: none;
          outline: none;
        }
      }

      .search-bar__close-icon {
        margin-right: 4rem;
      }
    }

    .search-results {
      &.show {
        height: calc(100% - 8rem);
      }
    }
  }
`;

export default Wrapper;
export { Wrapper };
