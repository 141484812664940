import React from 'react';
import PropTypes from 'prop-types';

const IconPlus = React.memo(({ size, color }) => (
  <svg
    id="icon-plus"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66038 6.33962H14.3396C16.5535 6.33962 16.5535 9.66038 14.3396 9.66038H9.66038V14.3396C9.66038 16.5535 6.33962 16.5535 6.33962 14.3396V9.66038H1.66038C-0.553459 9.66038 -0.553459 6.33962 1.66038 6.33962H6.33962V1.66038C6.33962 -0.553459 9.66038 -0.553459 9.66038 1.66038V6.33962Z"
      fill={color}
    />
  </svg>
));

IconPlus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPlus.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPlus;
export { IconPlus };
