import * as React from 'react';
import ErrorLogger from 'utils/monitoring/errorLogger';

const INITIAL_STATE = {
  componentStack: null,
  error: null,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidCatch(error, { componentStack }) {
    ErrorLogger.captureUIException(error, { componentStack });
    this.setState({ error });
  }

  resetErrorBoundary() {
    this.setState(INITIAL_STATE);
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
