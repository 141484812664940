import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Theme
import { main as theme } from '@themes';

// Components
import { Text } from '../Text';
import { IconError } from '../Icons';

// Styles
import { styles } from './Notification.styles';

// ------------------------------------------------------------------------- //

const Wrapper = styled.main`
  ${styles}
`;

const Notification = React.memo(
  ({
    colorStrip,
    colorBg,
    imgPath,
    icon,
    noclose,
    subtitle,
    subtitleColor,
    title,
    titleColor,
    text,
  }) => {
    return (
      <Wrapper colorStrip={colorStrip} colorBg={colorBg}>
        <div className="notification__color" />
        <div className="notification__filling">
          <div className="notification__filling__top-container">
            {imgPath && (
              <img
                src={imgPath}
                alt="notification"
                className="top-container__image"
              />
            )}

            {icon && <div className="top-container__icon">{icon}</div>}

            {title && (
              <div className="top-container__header">
                <Text
                  as="h3"
                  className="top-container__header__title"
                  size="1.4rem"
                  dsize="1.4rem"
                  color={titleColor}
                  weight={theme.fonts.fontWeight.bold}
                >
                  {title}
                </Text>
                {subtitle && (
                  <Text
                    as="h3"
                    className="top-container__header__subtitle"
                    size="1.4rem"
                    dsize="1.4rem"
                    color={subtitleColor}
                    weight={theme.fonts.fontWeight.bold}
                  >
                    {subtitle}
                  </Text>
                )}
              </div>
            )}
          </div>

          {text && (
            <Text
              className="notification__filling__text"
              size="1.4rem"
              dsize="1.4rem"
              color={theme.colorPallete.white}
            >
              {text}
            </Text>
          )}
        </div>
        {noclose === !true && (
          <div className="notification__close-button">
            <button type="button">
              <IconError size="18px" color={theme.colorPallete.base[400]} />
            </button>
          </div>
        )}
      </Wrapper>
    );
  }
);

Notification.propTypes = {
  colorBg: PropTypes.string,
  colorStrip: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  imgPath: PropTypes.string,
  noclose: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitleColor: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  titleColor: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
};

Notification.defaultProps = {
  colorBg: theme.colorPallete.base[600],
  colorStrip: theme.colorPallete.primary.default,
  icon: null,
  imgPath: '',
  noclose: false,
  subtitle: '',
  subtitleColor: theme.colorPallete.primary.white,
  title: '',
  titleColor: theme.colorPallete.white,
  text: '',
};

export default Notification;
export { Notification };
