/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Utils
import { refreshTokenFlow } from '@utils';

// Actions
import { actions as vvhActions } from '@store/ducks/vvs';

// VVS-api
import { videoApi } from '@services/vvs-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * PROGRESS
 * ---------------------------------------------------
 */
export function* sendProgress({ payload }) {
  const { auth } = payload;

  try {
    yield call(videoApi.sendProgress, {
      method: 'POST',
      data: payload.data,
    });

    yield put(vvhActions.sendProgressSuccess());
  } catch (error) {
    if (error?.response?.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        videoApi.sendProgress,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(vvhActions.sendProgressSuccess());
      }
    } else {
      yield put(vvhActions.sendProgressFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.SEND_PROGRESS_REQUEST, sendProgress);
}

export const sagas = [watchUser];
