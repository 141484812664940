import { callApiBase } from './base';

const videoApi = {
  sendProgress: ({ data, token }) =>
    callApiBase({
      title: 'Video - sendProgress',
      endpoint: `/video/videoData`,
      method: 'POST',
      token,
      data,
    }),
};

export default videoApi;
export { videoApi };
