import React from 'react';

interface Props {
  color?: string;
  size?: string;
}

const IconHelp = React.memo(({ size = '16px', color = '#ffffff' }: Props) => (
  <svg
    id="icon-help"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75ZM9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM8.90726 14.2898V12.0398H8.91574V14.2898H8.90726ZM8.90724 12.0398H8.91573C10.4157 12.0398 10.4157 14.2898 8.91573 14.2898H8.90724C7.40724 14.2898 7.40724 12.0398 8.90724 12.0398ZM10.0322 10.2375C10.0322 10.8588 9.52857 11.3625 8.90725 11.3625C8.28593 11.3625 7.78225 10.8588 7.78225 10.2375C7.78225 9.17639 8.17645 8.53091 8.97287 7.93413C9.38582 7.63792 9.54136 7.52141 9.70271 7.36766C9.72257 7.34861 9.72257 7.34861 9.77345 7.29931C10.0942 7.0039 10.1063 6.52965 9.80233 6.22063L9.77286 6.19067C9.76177 6.17939 9.75043 6.16832 9.73885 6.15747C9.2603 5.70923 8.48272 5.7215 8.02035 6.18377L7.65352 6.55052C7.21413 6.98982 6.50182 6.98974 6.06253 6.55035C5.62323 6.11096 5.62331 5.39865 6.0627 4.95936L6.42953 4.59261C7.75073 3.2717 9.91284 3.23759 11.277 4.51533C11.311 4.54718 11.3443 4.57972 11.377 4.61291L11.4065 4.64287C12.6026 5.85904 12.5531 7.798 11.329 8.92441C11.2918 8.96108 11.2918 8.96108 11.2548 8.99658C10.9809 9.25762 10.7008 9.46742 10.3221 9.73472C10.0478 9.94022 10.0322 9.96574 10.0322 10.2375Z"
      fill={color}
    />
  </svg>
));

export default IconHelp;
export { IconHelp };
