import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseLessonDataProps } from './types';

export const useGetLessonData = (
  msApiLib: MSApi,
  params?: UseLessonDataProps,
) => {
  const { bearerToken, cacheTime, enabled, slug } = params ?? {};
  return useQuery(
    ['lessonBySlug', slug],
    async () => {
      const res = await msApiLib.v3.lesson.lessonBySlug({
        params: { slug },
        bearerToken,
      });

      return res.data;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
