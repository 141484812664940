import React from 'react';

interface Props {
  width?: string;
  height?: string;
  strokeWidth?: string;
  className?: string;
}

const IconArrowBold = React.memo<any>(
  ({
    width = '16',
    height = '17',
    strokeWidth = '2.59504',
    className,
  }: Props) => (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.02881 12.5004L9.92136 8.60789L6.02881 4.71533"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconArrowBold;
export { IconArrowBold };
