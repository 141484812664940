import React from 'react';
import PropTypes from 'prop-types';

const IconFacebookOutline = React.memo(({ size, color }) => (
  <svg
    id="icon-facebook"
    viewBox="0 0 14 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.30317 14.6846H3.76199C3.90193 14.6846 4.01561 14.7981 4.01561 14.9377V24.1192C4.01561 24.606 4.41089 25 4.89831 25H8.25258C8.74 25 9.13528 24.606 9.13528 24.1192V14.9377C9.13528 14.7981 9.24852 14.6846 9.38889 14.6846H11.8161C12.2476 14.6846 12.6157 14.3731 12.6867 13.9487L13.2495 10.5781C13.3391 10.0415 12.9244 9.55295 12.3789 9.55295H9.53701C9.31526 9.55295 9.13528 9.37336 9.13528 9.15166V6.98711C9.13528 6.42599 9.45649 6.13169 9.99214 6.13169H12.5386C13.0256 6.13169 13.4209 5.7377 13.4209 5.25091V1.88077C13.4209 1.39441 13.0256 1 12.5386 1H9.47329C3.88342 1 4.01561 5.49667 4.01561 6.16735V9.29989C4.01561 9.43953 3.90193 9.55295 3.76199 9.55295H2.30317C1.81575 9.55295 1.4209 9.94737 1.4209 10.4333V13.8039C1.4209 14.2907 1.81575 14.6846 2.30317 14.6846Z"
      stroke={color}
    />
  </svg>
));

IconFacebookOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconFacebookOutline.defaultProps = {
  color: '#F16521',
  size: '16px',
};

export default IconFacebookOutline;
export { IconFacebookOutline };
