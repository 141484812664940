import styled, { css, keyframes } from 'styled-components';

interface SubmenuWrapperProps {
  width: string;
  position: { bottom: string; right: string };
}

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SubmenuWrapper = styled.div<SubmenuWrapperProps>`
  ${({ theme, width, position }) => css`
    animation: ${fadeIn} 0.2s;
    display: flex;
    font-family: ${theme.fonts.fontFamily.sans};
    flex-direction: column;
    position: absolute;
    bottom: ${position.bottom};
    right: ${position.right};
    width: ${width};
    background-color: ${theme.colorPallete.base[900]};
    border: 1px solid ${theme.colorPallete.base[600]};
    border-radius: 5px;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
  `}
`;

export const SocialWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    padding: 20px 31px 16px 32px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 216px;
      background-color: ${theme.colorPallete.base[550]};
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
    }
  `}
`;

export const SubmenuList = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 23px;
  `}
`;

export const SubmenuButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    transition: all 0.5s;
    color: ${theme.colorPallete.base[300]};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    font-weight: ${theme.fonts.fontWeight.bold};
    cursor: pointer;

    svg path {
      transition: all 0.5s;
      stroke: ${theme.colorPallete.base[300]};
    }

    &:hover {
      color: ${theme.colorPallete.white};

      svg path {
        stroke: ${theme.colorPallete.white};
      }
    }
  `}
`;
