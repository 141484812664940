import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Components
import { CustomButton } from '../Buttons/CustomButton';
import { Text } from '../Text';
import { Picture } from '../Picture';
import { ProgressBar } from '../ProgressBar';

// Styles
import { styles, tagStyles } from './PosterBanner.styles';

const Wrapper = styled.article`
  ${styles}
`;

const PosterBanner = ({ content }) => {
  let imageBanner = null;
  let imageLogo = null;

  function bannerImageExtractor() {
    // extract paths from banner

    const imageBannerDesk = content.imagens.filter(function (el) {
      return el.type === 'banner';
    });

    const imageBannerMobile = content.imagens.filter(function (el) {
      return el.type === 'banner-mobile';
    });

    const imageBannerResponsive = content.imagens.filter(function (el) {
      return el.type === 'banner-responsive';
    });

    // add and reordening
    imageBanner = {
      src: imageBannerDesk[0] ? imageBannerDesk[0].path : null,
      alt: imageBannerDesk[0] ? imageBannerDesk[0].alt : null,
    };

    if (imageBannerMobile && imageBannerDesk) {
      imageBanner.srcSet = [
        {
          id: 1,
          max: '767px',
          src: imageBannerResponsive[0]
            ? `/w_750,h_1244,c_fill,g_auto,q_auto/${imageBannerResponsive[0].path}`
            : imageBannerMobile[0]
            ? `/w_750,h_1244,c_fill,g_auto,q_auto/${imageBannerMobile[0].path}`
            : ``,
        },
        {
          id: 2,
          max: '1199px',
          src: `/w_1440,h_590,c_fill,g_auto,q_auto/${imageBannerDesk[0].path}`,
        },
        {
          id: 3,
          min: '1200px',
          src: `/w_2880,h_1180,c_fill,g_auto,q_auto/${imageBannerDesk[0].path}`,
        },
      ];
    }
  }

  function logoImageExtractor() {
    // extract paths from logo
    const imageLogoObj = content.imagens.filter(function (el) {
      return el.type === 'logo';
    });

    imageLogo = {
      src: imageLogoObj[0] ? imageLogoObj[0].path : null,
      alt: imageLogoObj[0] ? imageLogoObj[0].alt : content.title,
    };
  }

  bannerImageExtractor();
  logoImageExtractor();

  const cssClasses = classNames({
    poster: true,
    banner__curso: true,
    'banner-full': content.type === 'banner-full',
    'banner-video': content.type === 'banner-video',
    rtl: content.direction_text === 'right',
  });

  const retornaBotao = () => {
    if (content.button) {
      if (content.order === 1) {
        return (
          <div className="poster__content__cta">
            <CustomButton
              size="regular"
              bgcolor={content.button.color}
              color={content.button.text_color}
              url={content.button.slug}
              id={'banner'}>
              <span>{content.button.text}</span>
            </CustomButton>
          </div>
        );
      } else {
        return (
          <div className="poster__content__cta">
            <CustomButton
              size="regular"
              bgcolor={content.button.color}
              color={content.button.text_color}
              url={content.button.slug}>
              <span>{content.button.text}</span>
            </CustomButton>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <Wrapper className={cssClasses}>
      {/* img background */}
      {imageBanner.src ? (
        <Picture
          className="poster__picture"
          src={imageBanner.src}
          srcSet={imageBanner.srcSet}
          alt={imageBanner.alt}
        />
      ) : null}

      {/* text content */}
      <div className="poster__content">
        <div className="poster__content__wrap">
          {content.mark ? (
            <div className="poster__content__tag">
              <Tag
                color={content?.mark?.color}
                text_color={content?.mark?.text_color}>
                {content?.mark?.text}
              </Tag>
            </div>
          ) : null}
          {!!content.title && !imageLogo ? (
            <div className="poster__content__title">
              <Text
                size="3.5rem"
                dsize="3.5rem"
                weight="bold"
                color={content?.text_color?.title || `white`}>
                {content.title}
              </Text>
            </div>
          ) : (
            <Picture
              className="poster__content__logo"
              src={imageLogo.src}
              alt={imageLogo.alt}
            />
          )}
          {/* {content.subtitle ? (
            <div className="poster__content__subtitle">
              <Text size="1.5rem" dsize="1.5rem" weight="bold" color={content?.text_color?.subtitle || `white`}>
                {content.subtitle}
              </Text>
            </div>
          ) : null} */}
          {content.description ? (
            <div className="poster__content__details">
              <Text
                size="1.4rem"
                dsize="1.4rem"
                color={content?.text_color?.description || `white`}>
                {content.description}
              </Text>
            </div>
          ) : null}
          {content.content ? (
            <div className="poster__content__description">
              <Text
                size="1.4rem"
                dsize="1.4rem"
                color={content?.text_color?.description || `white`}>
                {content.content}
              </Text>
            </div>
          ) : null}
          {retornaBotao()}
        </div>
      </div>

      {/* class progress */}
      {content.progress && content.type !== 'banner-full' ? (
        <div className="poster__progress">
          <ProgressBar time={content.progress || '0'} />
        </div>
      ) : null}
    </Wrapper>
  );
};

PosterBanner.propTypes = {
  content: PropTypes.shape({
    button: PropTypes.shape({
      text: PropTypes.string,
      text_color: PropTypes.string,
      color: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.string,
    }),
    description: PropTypes.string,
    content: PropTypes.string,
    direction_text: PropTypes.string,
    imagens: PropTypes.instanceOf(Array),
    mark: PropTypes.shape({
      text: PropTypes.string,
      text_color: PropTypes.string,
      color: PropTypes.string,
      type: PropTypes.string,
    }),
    order: PropTypes.number,
    progress: PropTypes.string,
    slug: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    text_color: {
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
    },
  }),
};

PosterBanner.defaultProps = {
  content: {
    button: {
      text: null,
      text_color: null,
      color: null,
      slug: null,
      type: null,
    },
    description: null,
    content: null,
    direction_text: null,
    imagens: [],
    mark: {
      text: null,
      text_color: null,
      color: null,
      type: null,
    },
    order: null,
    progress: null,
    slug: null,
    subtitle: null,
    title: null,
    type: null,
    text_color: {
      title: null,
      subtitle: null,
      description: null,
    },
  },
};

const WrapperTag = styled.span`
  ${tagStyles}
`;

const Tag = ({ children, color, text_color, className }) => {
  return (
    <WrapperTag className={className} color={color} text_color={text_color}>
      {children}
    </WrapperTag>
  );
};

Tag.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  text_color: PropTypes.string,
  className: PropTypes.string,
};

Tag.defaultProps = {
  children: null,
  color: null,
  text_color: null,
  className: null,
};

export default PosterBanner;
export { PosterBanner };
