/**
 * ---------------------------------------------------
 * SEND PROGRESS
 * ---------------------------------------------------
 */
export const SEND_PROGRESS_REQUEST = '@vvs/SEND_PROGRESS_REQUEST';
export const SEND_PROGRESS_SUCCESS = '@vvs/SEND_PROGRESS_SUCCESS';
export const SEND_PROGRESS_FAILURE = '@vvs/SEND_PROGRESS_FAILURE';

/**
 * ---------------------------------------------------
 * GET PROGRESS - HOME
 * ---------------------------------------------------
 */
export const GET_HOME_NEW_PROGRESS = '@vvs/GET_HOME_NEW_PROGRESS';

/**
 * ---------------------------------------------------
 * GET PROGRESS - COURSE
 * ---------------------------------------------------
 */
export const GET_COURSE_NEW_PROGRESS = '@vvs/GET_COURSE_NEW_PROGRESS';
