import { css } from 'styled-components';

const styles = ({ theme, time, color }) => css`
  display: inline-block;
  width: 100%;
  height: 6px;
  background: ${theme.colorPallete.base[500]};
  border-radius: 5px;
  z-index: 10;

  &::before {
    content: '';
    display: block;
    position: relative;
    border-radius: 5px;
    height: 6px;
    width: calc(${time} * 100%);
    background-color: ${color};
  }
`;

export default styles;
export { styles };
