import { IconArrow } from 'components';
import { useWindowSize } from 'helpers/useWindowSize';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Banner } from './components/Banner';

import * as S from './BannerSlider.styles';
import { Banner as BannerType } from 'libs/ms-api';
import { BannerSkeleton } from 'skeletons';

interface BannerSliderProps {
  content: BannerType[];
}

const BannerSlider = ({ content }: BannerSliderProps) => {
  const sortBanners = (banners: BannerType[]) => {
    const sortedBanners = banners?.sort((a, b) => a.order - b.order);
    const orderedBanners = sortedBanners?.map((banner, index) => ({
      ...banner,
      order: index,
    }));

    return orderedBanners;
  };

  const orderedBanners = sortBanners(content);

  const { width } = useWindowSize();
  const isTablet = !!width && width <= 1024;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 15000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    autoplay: true,
    arrows: true,
    prevArrow: !isTablet && (
      <S.NavigationArrow className="arrow__previous" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
    nextArrow: !isTablet && (
      <S.NavigationArrow className="arrow__next" type="button">
        <IconArrow />
      </S.NavigationArrow>
    ),
  };

  if (!content) return <BannerSkeleton />;

  return (
    <S.Wrapper>
      <Slider {...settings}>
        {orderedBanners?.map((banner, index) => (
          <Banner key={banner.order} banner={banner} />
        ))}
      </Slider>
    </S.Wrapper>
  );
};

export default BannerSlider;
export { BannerSlider };
