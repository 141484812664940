import * as types from './types';

/**
 * ---------------------------------------------------
 * COURSE
 * ---------------------------------------------------
 */
export const getCourseRequest = (payload) => ({
  type: types.GET_COURSE_REQUEST,
  payload,
});

export const getCourseSuccess = ({ payload }) => ({
  type: types.GET_COURSE_SUCCESS,
  payload,
});
export const getCourseFailure = () => ({
  type: types.GET_COURSE_FAILURE,
});

/**
 * ---------------------------------------------------
 * COURSE MODAL
 * ---------------------------------------------------
 */
export const setCourseModal = ({ payload }) => ({
  type: types.SET_COURSE_MODAL,
  payload,
});
