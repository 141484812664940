import * as types from './types';

/**
 * ---------------------------------------------------
 * WATCHED
 * ---------------------------------------------------
 */
export const getWatchedRequest = ({ payload }) => ({
  type: types.GET_WATCHED_REQUEST,
  payload,
});

export const getWatchedSuccess = ({ payload }) => ({
  type: types.GET_WATCHED_SUCCESS,
  payload,
});

export const getWatchedFailure = () => ({
  type: types.GET_WATCHED_FAILURE,
});
