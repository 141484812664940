import React from 'react';

const IconVector = React.memo(() => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10 1L2 9L10 17" stroke="white" stroke-width="2" />
  </svg>
));

export default IconVector;
export { IconVector };
