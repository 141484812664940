import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { main as theme } from '@themes';

import { styles } from './PosterBadge.styles';

const Wrapper = styled.div`
  ${styles}
`;

const PosterBadge = React.memo(({ className, color, title, resize }) => {
  return (
    <Wrapper className={`${className} ${resize ? `resize` : ``}`} color={color}>
      {title}
    </Wrapper>
  );
});

PosterBadge.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  resize: PropTypes.bool,
};

PosterBadge.defaultProps = {
  className: 'poster-badge',
  color: theme.colorPallete.primary.default,
  title: 'NOVO',
  resize: false,
};

export default PosterBadge;
export { PosterBadge };
