import React from 'react';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { logoutHandler } from 'utils';
import { useFeatureToggle } from 'hooks';

import {
  IconExit,
  IconHelp,
  IconClose,
  IconChalkboardTeacher,
  IconTelevisionSimple,
  IconMedal,
} from 'components';

import * as S from './MenuMobile.styles';

interface MenuMobileProps {
  handleShowMenu: any;
}

const MenuMobile = React.memo(({ handleShowMenu }: MenuMobileProps) => {
  const dispatch = useDispatch();

  const logOut = () => logoutHandler(dispatch);

  const CertificateItemToggle = useFeatureToggle({
    ComponentWhenEnabled: () => (
      <S.NavItem>
        <Link href="/certificados" passHref>
          <IconMedal color="#fff" />
          Meus Certificados
        </Link>
      </S.NavItem>
    ),
    featureKeys: ['certificates'],
  });

  return (
    <S.Wrapper>
      <S.NavList className="separator">
        <S.NavItem>
          <Link href="/estudos-de-caso" passHref>
            <IconChalkboardTeacher color="#fff" />
            Estudos de Caso
          </Link>
        </S.NavItem>
        <S.NavItem>
          <Link href="/series" passHref>
            <IconTelevisionSimple color="#fff" />
            Séries
          </Link>
        </S.NavItem>
        <CertificateItemToggle />
      </S.NavList>
      <S.NavList>
        <S.NavItem>
          <a href="https://meusucesso.com/faq/" target="_blank">
            <IconHelp size="18px" />
            Ajuda
          </a>
        </S.NavItem>
        <S.NavItem>
          <S.Button onClick={logOut} type="button">
            <IconExit size="18px" />
            Sair
          </S.Button>
        </S.NavItem>
      </S.NavList>
      <S.Logout>
        <S.Button
          type="button"
          onClick={() => {
            handleShowMenu(false);
          }}>
          <IconClose size="22px" />
        </S.Button>
      </S.Logout>
    </S.Wrapper>
  );
});

export default MenuMobile;
export { MenuMobile };
