/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

// Components
import { IconFind, IconClose, IconLoading, Text } from '@components';

// Actions
import { actions as searchActions } from '@store/ducks/search';

// Styles
import { Wrapper } from './SearchMobile.styles';

// Actions

const SearchMobile = ({ handleShowSearch, searchState, refreshToken }) => {
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { loading, searchData } = useSelector((state) => state.searchReducer);

  const dispatch = useDispatch();
  const router = useRouter();
  const inputRef = useRef(null);

  const cssClasses = classNames({
    'search-results': true,
    show: searchText,
  });

  const handleClose = () => {
    handleShowSearch(false);
    setSearchText('');
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    router.push(`/buscar?term=${searchText}`);
    handleShowSearch(false);
  };

  useEffect(() => {
    if (searchState) {
      inputRef.current.focus();
    }
  }, [searchState]);

  const fetchData = (value) => {
    const data = { term: value, page: 0, newSearch: true };
    dispatch(
      searchActions.sendSearchRequest({
        payload: {
          data,
          auth: {
            refresh_token: refreshToken,
          },
        },
      })
    );
  };

  useEffect(() => {
    const slug = searchText;
    const search = _.debounce(fetchData, 1500);

    setSearchQuery((prevSearch) => {
      if (prevSearch.cancel) prevSearch.cancel();
      return search;
    });

    if (!!searchText && searchText.length >= 3) search(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Wrapper>
      <div className="search-bar">
        <button
          className="search-bar__do-search-icon"
          onClick={(e) => handleSumbit(e)}
          id="header-busca"
          type="button"
        >
          <IconFind
            className="search-bar__search-icon"
            width="18px"
            height="18px"
            fill="white"
          />
        </button>
        <form className="search-bar__form" onSubmit={(e) => handleSumbit(e)}>
          <input
            id="search__input__mobile"
            className="search-bar__form"
            name="search"
            type="text"
            placeholder="Buscar no meuSucesso.com"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            ref={inputRef}
          />
        </form>
        <button
          className="search-bar__close-icon"
          onClick={handleClose}
          type="button"
        >
          <IconClose width="18px" height="18px" fill="white" />
        </button>
      </div>
      <div className={cssClasses}>
        {loading && !searchData ? (
          <div className="search-results__loading">
            <IconLoading />
          </div>
        ) : (
          <div className="search-results__suggestions">
            {searchData?.data &&
              searchData.data.map((itemSearched, itemIdx) => {
                const key = `search-results-${itemIdx}`;
                return itemIdx < 5 && (
                  <div
                    className="search-results__suggestions--item"
                    key={key}
                  >
                    <Link href="/curso/aula/[aula]" as={itemSearched.url}>

                      <Text
                        className="search-suggestion"
                        variant="Body2"
                        fontFamily="muli"
                        fontSize="16px"
                        lineHeight="18px"
                        color="white"
                      >
                        {itemSearched.title}
                      </Text>

                    </Link>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

SearchMobile.propTypes = {
  searchState: PropTypes.bool,
  handleShowSearch: PropTypes.func,
  refreshToken: PropTypes.string,
};
SearchMobile.defaultProps = {
  searchState: false,
  handleShowSearch: () => '',
  refreshToken: '',
};

export default SearchMobile;
export { SearchMobile };
