import { css } from 'styled-components';

const styles = ({ theme }) => css`
  width: 26.6rem;
  max-width: 50rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  background-color: ${theme.colorPallete.base[100]};
  padding-bottom: 3.5rem;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  .thumbnail {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 0.5rem;

    .lesson-number {
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3rem;
      position: absolute;
      top: 0;
      margin-top: -1.5rem;
      font-size: 1rem;
      font-family: ${theme.fonts.fontFamily.sans};
      font-weight: ${theme.fonts.fontWeight.bolder};
      color: ${theme.colorPallete.white};
      background-color: ${theme.colorPallete.primary.default};
      z-index: 5;
    }
  }

  .info {
    width: 100%;
    padding: 2.4rem 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media all and (min-width: ${theme.breakpoints.medium}) {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr;
      height: 100%;
    }

    .info__content-container {
      width: 100%;

      p {
        padding-top: 1.2rem;
        padding-bottom: 2.3rem;
      }

      .info__lesson-description {
        width: 100%;

        p {
          width: 100%;
        }

        &--desk {
          display: none;
        }
        &--mobile {
          display: block;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          &--desk {
            display: block;
          }

          &--mobile {
            display: none;
          }
        }
      }
    }
    .info__buttons {
      width: 100%;
      padding: 0;

      &__details {
        width: 100%;
      }

      .info__buttons--download {
        display: none;
      }
    }
  }

  @media all and (min-width: ${theme.breakpoints.smaller}) {
    min-width: 30rem;
  }

  @media all and (min-width: ${theme.breakpoints.small}) {
    width: 100%;
    max-width: 30rem;
  }

  @media all and (min-width: ${theme.breakpoints.big}) {
    width: 100%;
    max-width: 115rem;
    height: auto;
    min-height: 27rem;
    display: flex;
    padding-bottom: 0;

    .thumbnail {
      flex-direction: row;
      min-width: 47.7rem;

      .thumbnail__video .thumb__progress {
        bottom: -0.3rem;
      }

      .lesson-number {
        margin: 0;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
      }
    }

    .info {
      width: 100%;
      padding: 2.4rem 2.5rem 1.5rem;
      padding-left: 4.5rem;
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr;
      position: relative;

      .info__buttons {
        width: 100%;
        display: flex;
        align-items: center;

        .info__buttons--download {
          display: flex;
          justify-content: center;
          width: 100%;

          a {
            .info__buttons--download__text {
              padding-left: 1rem;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;

export default styles;
export { styles };
