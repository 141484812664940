/**
 * ---------------------------------------------------
 * AUTH
 * ---------------------------------------------------
 */
export const LOGIN_REQUEST = '@user/LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = '@user/LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_SUCCESS_DATA_LAYER = '@user/LOGIN_REQUEST_SUCCESS_DATA_LAYER';
export const RESET_DATA_LAYER = '@user/RESET_DATA_LAYER';
export const LOGIN_REQUEST_FAILURE = '@user/LOGIN_REQUEST_FAILURE';

export const LOGOUT_REQUEST = '@user/LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = '@user/LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_FAILURE = '@user/LOGOUT_REQUEST_FAILURE';

/**
 * ---------------------------------------------------
 * FIRST-ACCESS
 * ---------------------------------------------------
 */
export const GET_FIRST_ACCESS_REQUEST = '@user/GET_FIRST_ACCESS_REQUEST';
export const GET_FIRST_ACCESS_SUCCESS = '@user/GET_FIRST_ACCESS_SUCCESS';
export const GET_FIRST_ACCESS_FAILURE = '@user/GET_FIRST_ACCESS_FAILURE';

export const SEND_FIRST_ACCESS_REQUEST = '@user/SEND_FIRST_ACCESS_REQUEST';
export const SEND_FIRST_ACCESS_SUCCESS = '@user/SEND_FIRST_ACCESS_SUCCESS';
export const SEND_FIRST_ACCESS_FAILURE = '@user/SEND_FIRST_ACCESS_FAILURE';

/**
 * ---------------------------------------------------
 * PASSWORD
 * ---------------------------------------------------
 */
export const FORGOT_PASSWORD_EMAIL_REQUEST =
  '@user/FORGOT_PASSWORD_EMAIL_REQUEST';
export const FORGOT_PASSWORD_EMAIL_REQUEST_SUCCESS =
  '@user/FORGOT_PASSWORD_EMAIL_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_EMAIL_REQUEST_FAILURE =
  '@user/FORGOT_PASSWORD_EMAIL_REQUEST_FAILURE';

export const VALIDATE_PASSWORD_TOKEN_REQUEST =
  '@user/VALIDATE_PASSWORD_TOKEN_REQUEST';
export const VALIDATE_PASSWORD_TOKEN_REQUEST_SUCCESS =
  '@user/VALIDATE_PASSWORD_TOKEN_REQUEST_SUCCESS';
export const VALIDATE_PASSWORD_TOKEN_REQUEST_FAILURE =
  '@user/VALIDATE_PASSWORD_TOKEN_REQUEST_FAILURE';

export const RESET_PASSWORD_REQUEST = '@user/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS =
  '@user/RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE =
  '@user/RESET_PASSWORD_REQUEST_FAILURE';

export const FORGOT_NEW_PASSWORD_REQUEST = '@user/FORGOT_NEW_PASSWORD_REQUEST';
export const FORGOT_NEW_PASSWORD_SUCCESS = '@user/FORGOT_NEW_PASSWORD_SUCCESS';
export const FORGOT_NEW_PASSWORD_FAILURE = '@user/FORGOT_NEW_PASSWORD_FAILURE';

/**
 * ---------------------------------------------------
 * PROFILE
 * ---------------------------------------------------
 */
export const GET_USER_DATA_REQUEST = '@user/GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = '@user/GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = '@user/GET_USER_DATA_FAILURE';

export const EDIT_PERSONAL_DATA_REQUEST = '@user/EDIT_PERSONAL_DATA_REQUEST';
export const EDIT_PERSONAL_DATA_SUCCESS = '@user/EDIT_PERSONAL_DATA_SUCCESS';
export const EDIT_PERSONAL_DATA_FAILURE = '@user/EDIT_PERSONAL_DATA_FAILURE';

export const EDIT_ADDRESS_DATA_REQUEST = '@user/EDIT_ADDRESS_DATA_REQUEST';
export const EDIT_ADDRESS_DATA_SUCCESS = '@user/EDIT_ADDRESS_DATA_SUCCESS';
export const EDIT_ADDRESS_DATA_FAILURE = '@user/EDIT_ADDRESS_DATA_FAILURE';

export const EDIT_FINANCIAL_DATA_REQUEST = '@user/EDIT_FINANCIAL_DATA_REQUEST';
export const EDIT_FINANCIAL_DATA_SUCCESS = '@user/EDIT_FINANCIAL_DATA_SUCCESS';
export const EDIT_FINANCIAL_DATA_FAILURE = '@user/EDIT_FINANCIAL_DATA_FAILURE';

export const LINK_TO_SOCIAL_REQUEST = '@user/LINK_TO_SOCIAL_REQUEST';
export const LINK_TO_SOCIAL_SUCCESS = '@user/LINK_TO_SOCIAL_SUCCESS';
export const LINK_TO_SOCIAL_FAILURE = '@user/LINK_TO_SOCIAL_FAILURE';

export const SOCIAL_LOGIN_REQUEST = '@user/SOCIAL_LOGIN_REQUEST';
export const SOCIAL_LOGIN_SUCCESS = '@user/SOCIAL_LOGIN_SUCCESS';
export const SOCIAL_LOGIN_FAILURE = '@user/SOCIAL_LOGIN_FAILURE';

export const EDIT_NEWSLETTER_REQUEST = '@user/EDIT_NEWSLETTER_REQUEST';
export const EDIT_NEWSLETTER_SUCCESS = '@user/EDIT_NEWSLETTER_SUCCESS';
export const EDIT_NEWSLETTER_FAILURE = '@user/EDIT_NEWSLETTER_FAILURE';

export const GET_PROFILE_DATA_REQUEST = '@user/GET_PROFILE_DATA_REQUEST';
export const GET_PROFILE_DATA_SUCCESS = '@user/GET_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_DATA_FAILURE = '@user/GET_PROFILE_DATA_FAILURE';

/**
 * ---------------------------------------------------
 * EDIT MODAL
 * ---------------------------------------------------
 */
export const SET_EDIT_MODAL = '@user/SET_EDIT_MODAL';

/**
 * ---------------------------------------------------
 * ZERO LESSON
 * ---------------------------------------------------
 */
export const UPDATE_ZEROLESSON_REQUEST = '@user/UPDATE_ZEROLESSON_REQUEST';
export const UPDATE_ZEROLESSON_SUCCESS = '@user/UPDATE_ZEROLESSON_SUCCESS';
export const UPDATE_ZEROLESSON_FAILURE = '@user/UPDATE_ZEROLESSON_FAILURE';
