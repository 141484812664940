/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Utils
import { refreshTokenFlow } from '@utils';

// Actions
import { actions as searchActions } from '@store/ducks/search';

// Mirror-api
import { searchApi as mirrorSearchApi } from '@services/mirror-api';

// MSV2-api
import { searchApi as msv2SearchApi } from '@services/msv2-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * SEARCH
 * ---------------------------------------------------
 */
export function* sendSearch({ payload }) {
  const { auth, isServer } = payload;

  const request = isServer ? msv2SearchApi : mirrorSearchApi;
  const token = isServer ? auth.token : null;

  try {
    const response = yield call(request.sendSearch, {
      method: 'POST',
      data: payload.data,
      token,
    });

    if (response.status === 200) {
      // If it exists, execute a library that clean every video:
      if (payload?.data?.newSearch) {
        yield put(
          searchActions.sendSearchCleanSuccess({
            payload: { searchData: response.data.content.instance },
          })
        );
      } else {
        yield put(
          searchActions.sendSearchSuccess({
            payload: { searchData: response.data.content.instance },
          })
        );
      }
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2SearchApi.sendSearch,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        if (payload?.data?.newSearch) {
          yield put(
            searchActions.sendSearchCleanSuccess({
              payload: { searchData: responseReq.data },
            })
          );
        } else {
          yield put(
            searchActions.sendSearchSuccess({
              payload: { searchData: responseReq.data },
            })
          );
        }
      }
    } else if (error.response) {
      yield put(searchActions.sendSearchFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.SEND_SEARCH_REQUEST, sendSearch);
}

export const sagas = [watchUser];
