import styled, { css } from 'styled-components';

export const SkeletorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 24px;
  margin-bottom: 40px;
`;

export const TitleContainer = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
`;

export const FilteredItems = styled.ul`
  ${() => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: 768px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-inline: -24px;
      padding-inline: 24px;

      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  `}
`;
