import { AxiosInstance } from 'axios'
import { MSApi } from 'libs/ms-api/contract'
import { LessonDto, GetLessonQueryDto, PaginationDto } from 'libs/ms-api/dtos'
import { RequestWithBearerAndQueryAndParams } from 'libs/ms-api/types'

type ILessonResource = MSApi['v3']['lesson']

export class LessonResource implements ILessonResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async lessonBySlug(data: RequestWithBearerAndQueryAndParams<GetLessonQueryDto>): Promise<PaginationDto<LessonDto>> {
    if (data.bearerToken) this.msApi.defaults.headers.common['Authorization'] = `Bearer ${data.bearerToken}`
    const { data: result } = await this.msApi.get(`v3/lesson/${data.params?.slug}`)
    return result
  }
}
