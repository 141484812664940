import React from 'react';
import PropTypes from 'prop-types';

const IconLesson = React.memo(({ size, color }) => (
  <svg
    id="icon-lesson"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6.4268L19.5961 4.21066H18.3985L16.9884 5.51059V1.78309L16.139 1H0.849421L0 1.78309V20.5772L0.849421 21.3603H16.139L16.9884 20.5772V12.1512L22 7.53096V6.4268ZM15.2896 19.7941H1.69884V2.56618H15.2896V7.07677L7.06718 14.6571L6.83784 15.0408L6.14981 17.8912L7.16062 18.8231L10.2525 18.1888L10.6687 17.9774L15.2896 13.7174V19.7941ZM9.64942 16.7088L8.10347 17.022L8.44324 15.5968L18.9931 5.87081L20.1907 6.97496L9.64942 16.7088ZM13.5907 4.91544H3.39768V3.34927H13.5907V4.91544ZM13.5907 7.26471H3.39768V5.69853H13.5907V7.26471Z"
      fill={color}
    />
  </svg>
));

IconLesson.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconLesson.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconLesson;
export { IconLesson };
