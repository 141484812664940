import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import {
  PaginationDto,
  GetCertificatesListQueryDto,
  CertificatesListDto,
  GetCertificateQueryDto,
  CertificateDto,
  GetPublicCertificateQueryDto,
  PublicCertificateDto,
} from 'libs/ms-api/dtos';

import qs from 'qs';

import { AxiosImpUtils } from '../utils';

type ICertificatesResource = MSApi['v3']['certificates'];

export class CertificatesResource implements ICertificatesResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(data: {
    bearerToken: string;
    query?: GetCertificatesListQueryDto;
  }): Promise<PaginationDto<CertificatesListDto[]>> {
    const { studentId, sortOrder: sort, page, pageSize } = data?.query ?? {};
    const searchQuery = { sort, page, pageSize };
    const parsedQuery = AxiosImpUtils.parserPaginationQuery(searchQuery);
    const stringfiedQuery = qs.stringify(parsedQuery);

    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;
    const { data: result } = await this.msApi.get(
      `v3/students/${studentId}/certificates/?${stringfiedQuery}`,
    );
    return result;
  }

  async certificate(data: {
    bearerToken: string;
    params?: GetCertificateQueryDto;
  }): Promise<PaginationDto<CertificateDto>> {
    const { courseId, certificateId, studentId } = data?.params ?? {};

    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;
    const { data: result } = await this.msApi.get(
      `v3/students/${studentId}/courses/${courseId}/certificates/${certificateId}`,
    );
    return result;
  }

  async certificateIssuance(data: {
    bearerToken: string;
    params?: GetCertificateQueryDto;
  }): Promise<PaginationDto<CertificateDto>> {
    const { courseId, studentId } = data?.params ?? {};

    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.post(
      `v3/students/${studentId}/courses/${courseId}/certificates`,
    );
    return result;
  }

  async publicCertificate(data: {
    params: GetPublicCertificateQueryDto;
  }): Promise<PaginationDto<PublicCertificateDto>> {
    const { certificateId } = data?.params ?? {};
    const { data: result } = await this.msApi.get(
      `v3/certificates/${certificateId}`,
    );

    return result;
  }
}
