/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  checklistData: null,
  error: null,
  loading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.checklistReducer,
      };

    /**
     * ---------------------------------------------------
     * CHECKLIST
     * ---------------------------------------------------
     */
    case types.GET_CHECKLIST_REQUEST:
      return { ...state, checklistData: null, loading: true, error: false };
    case types.GET_CHECKLIST_SUCCESS:
      return {
        ...state,
        checklistData: action.payload.checklistData,
        loading: false,
        error: false,
      };
    case types.GET_CHECKLIST_FAILURE:
      return { ...state, checklistData: null, loading: true, error: false };

    /**
     * ---------------------------------------------------
     * RESET ANSWERS
     * ---------------------------------------------------
     */
    case types.RESET_CHECKLIST_REQUEST:
      return { ...state, loading: true, error: false };
    case types.RESET_CHECKLIST_SUCCESS:
      return {
        ...state,
        checklistData: action.payload.checklistData,
        loading: false,
        error: false,
      };
    case types.RESET_CHECKLIST_FAILURE:
      return { ...state, loading: true, error: false };

    /**
     * ---------------------------------------------------
     * CHECKLIST ANSWERS
     * ---------------------------------------------------
     */
    case types.SEND_CHECKLIST_ANSWERS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_CHECKLIST_ANSWERS_SUCCESS:
      return {
        ...state,
        checklistData: action.payload.checklistData,
        loading: false,
        error: false,
      };
    case types.SEND_CHECKLIST_ANSWERS_FAILURE:
      return { ...state, loading: true, error: false };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
