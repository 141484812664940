import React from 'react';
import PropTypes from 'prop-types';

const IconPen = React.memo(({ size, color }) => (
  <svg
    id="icon-pen"
    viewBox="0 0 33 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9508 33.25C32.9508 33.075 32.7758 27.475 26.3008 20.825L11.4258 6.125L12.6508 4.9L18.7758 11.025L21.2258 8.575L12.6508 0L8.97578 3.675C7.05078 1.75 3.55078 1.75 1.62578 3.675C-0.474219 5.775 -0.474219 9.1 1.62578 11.025L18.7758 28.175C25.4258 34.825 30.8508 34.825 31.2008 34.825H32.9508V33.25ZM4.07578 8.575C3.37578 7.875 3.37578 6.825 4.07578 6.125C4.42578 5.775 4.77578 5.6 5.30078 5.6C5.82578 5.6 6.17578 5.775 6.52578 6.125L22.4508 22.05L20.0008 24.675L4.07578 8.575ZM25.0758 24.675L22.6258 27.125C25.0758 29.225 27.3508 30.45 28.9258 30.975C28.4008 29.4 27.3508 27.125 25.0758 24.675Z"
      fill={color}
    />
  </svg>
));

IconPen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPen.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPen;
export { IconPen };
