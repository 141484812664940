import React, { useEffect } from 'react';
import { useEventListener } from '@helpers';
import PropTypes from 'prop-types';

// Styles
import { MainContainer, CarouselWrapper } from './CarouselContainer.styles';

const CarouselContainer = ({ children }) => {
  const target = React.createRef();

  /**
   * Ref: https://codepen.io/toddwebdev/pen/yExKoj
   */

  let isDown = false;
  let startX;
  let scrollLeft;

  useEventListener(target, 'mousedown', (e) => {
    isDown = true;
    // target.classList.add('active');
    startX = e.pageX - target.current.offsetLeft;
    scrollLeft = target.current.scrollLeft;
  });

  useEventListener(target, 'mouseleave', () => {
    isDown = false;
    // target.classList.remove('active');
  });

  useEventListener(target, 'mouseup', () => {
    isDown = false;
    // target.classList.remove('active');
  });

  useEventListener(target, 'mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - target.current.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    target.current.scrollLeft = scrollLeft - walk;
  });

  return (
    <MainContainer>
      <CarouselWrapper ref={target}>{children}</CarouselWrapper>
      {/* <ScrollIndicator count={count} target={target} /> */}
    </MainContainer>
  );
};

CarouselContainer.propTypes = {
  children: PropTypes.node,
};

CarouselContainer.defaultProps = {
  children: <></>,
};

export { CarouselContainer };
export default CarouselContainer;
