import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { styles } from './Paper.styles';

const Wrapper = styled.div`
  ${styles}
`;

const Paper = React.memo(({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
});

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Paper.defaultProps = {
  className: '',
};

export default Paper;
export { Paper };
