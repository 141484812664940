import { fork, all } from 'redux-saga/effects';
import { sagas as caseStudiesSagas } from './caseStudies';
import { sagas as catalogSagas } from './catalog';
import { sagas as checklistSagas } from './checklist';
import { sagas as courseSagas } from './course';
import { sagas as favoriteSagas } from './favorite';
import { sagas as keepWatchingSagas } from './keepWatching';
import { sagas as lessonSagas } from './lesson';
import { sagas as librarySagas } from './library';
import { sagas as placeSagas } from './place';
import { sagas as searchSagas } from './search';
import { sagas as seriesSagas } from './series';
import { sagas as userSagas } from './user';
import { sagas as vvsSagas } from './vvs';
import { sagas as watchedSagas } from './watched';
import { sagas as notificationSagas } from './notification';
import { sagas as progressSagas } from './progress';

const allSagas = [
  ...caseStudiesSagas,
  ...catalogSagas,
  ...checklistSagas,
  ...courseSagas,
  ...favoriteSagas,
  ...keepWatchingSagas,
  ...lessonSagas,
  ...librarySagas,
  ...placeSagas,
  ...searchSagas,
  ...seriesSagas,
  ...userSagas,
  ...vvsSagas,
  ...watchedSagas,
  ...notificationSagas,
  ...progressSagas
];

// start all sagas in parallel
export default function* rootSaga() {
  yield all(allSagas.map((saga) => fork(saga)));
}
