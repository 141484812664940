import React from 'react';
import PropTypes from 'prop-types';
import HeadBase from 'next/head';

const Head = ({ children, ...restProps }) => {
  return (
    <HeadBase>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
      />
      <link rel="stylesheet" type="text/css" href="/assets/css/reset.css" />
      <link rel="icon" href="/favicon.ico" />
      <title>{restProps.title}</title>
      {children}
    </HeadBase>
  );
};

Head.propTypes = {
  children: PropTypes.element,
  restProps: PropTypes.shape({
    title: PropTypes.string,
  }),
};

Head.defaultProps = {
  children: <></>,
  restProps: {
    title: 'meuSucesso.com',
  },
};

export default Head;
export { Head };
