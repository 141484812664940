import { css } from 'styled-components';

const styles = ({ theme }) => css`
  font-family: ${theme.fonts.fontFamily.sans};
  font-size: 1.2rem;
  color: inherit;
  overflow: hidden;
  position: relative;

  img,
  source {
    max-width: 100%;
    height: auto;
    transition: opacity 0.1s ease;
    z-index: 5;
  }

  .fill {
    width: 100%;

    img,
    source {
      width: 100%;
    }
  }
`;

export default styles;
export { styles };
