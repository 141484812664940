import styled, { css } from 'styled-components';

interface WrapperProps {
  bottom: string;
  right: string;
  width: string;
  shouldAppear: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, bottom, right, width, shouldAppear }) => css`
    display: ${shouldAppear ? 'block' : 'none'};

    background-color: ${theme.colorSystem.baseColor};
    padding: 16px;
    border-radius: 4px;
    border: 1px solid ${theme.colorPallete.base[690]};

    position: absolute;
    bottom: ${bottom};
    right: ${right};
    width: ${width};
    max-width: ${width};
  `}
`;
