import { css } from 'styled-components';

const styles = ({ theme, color }) => css`
  width: 100%;
  min-width: 20rem;

  .disabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.4rem;
    border: 0.1rem solid ${theme.colorPallete.base[300]};
    overflow: hidden;

    .disabled__text-container {
      padding-left: 2.5rem;
    }

    .disabled__icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.4rem 0 0 0.4rem;
      background-color: ${theme.colorPallete.base[300]};
    }
  }

  &.dark {
    .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.4rem;
      border: 0.1rem solid ${theme.colorPallete.base.default};
      overflow: hidden;

      .button__text-container {
        padding-left: 2.5rem;
      }

      .button__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 0.4rem 0 0 0.4rem;
        background-color: ${theme.colorPallete.base.default};

        #icon-download,
        #icon-check {
          position: absolute;
          opacity: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        #icon-check {
          opacity: 0;
        }
      }

      &:active {
        border: 0.1rem solid ${color};

        .button__icon-container {
          background-color: ${color};
          transition: ease 0.5s;

          #icon-download {
            opacity: 0;
          }

          #icon-check {
            opacity: 1;
            transition: ease 0.5s;
          }
        }
      }
    }

    &.clicked {
      .button {
        border: 0.1rem solid ${color};

        .button__icon-container {
          background-color: ${color};

          #icon-download {
            opacity: 0;
          }

          #icon-check {
            opacity: 1;
          }
        }
      }
    }
  }

  &.light {
    .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.4rem;
      border: 0.1rem solid ${theme.colorSystem.white};
      overflow: hidden;

      .button__text-container {
        padding-left: 2.5rem;

        .button__text {
          color: ${theme.colorSystem.white};
        }
      }

      .button__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 0.4rem 0 0 0.4rem;
        background-color: ${theme.colorSystem.white};

        #icon-download,
        #icon-check {
          position: absolute;
          opacity: 1;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          path {
            fill: ${color};
          }
        }

        #icon-check {
          opacity: 0;
        }
      }

      &:active {
        border: 0.1rem solid ${color};

        .button__icon-container {
          background-color: ${color};
          transition: ease 0.5s;

          #icon-download {
            opacity: 0;

            path {
              fill: ${theme.colorSystem.white};
            }
          }

          #icon-check {
            opacity: 1;
            fill: ${theme.colorSystem.white};
            transition: ease 0.5s;
          }
        }
      }
    }

    &.clicked {
      .button {
        border: 0.1rem solid ${color};

        .button__icon-container {
          background-color: ${color};

          #icon-download {
            opacity: 0;
            path {
              fill: ${theme.colorSystem.white};
            }
          }

          #icon-check {
            opacity: 1;
            path {
              fill: ${theme.colorSystem.white};
            }
          }
        }
      }
    }
  }

  &.big {
    .disabled .disabled__icon-container,
    .button .button__icon-container {
      height: 6.8rem;
      width: 6.8rem;
    }
  }

  &.regular {
    .disabled .disabled__icon-container,
    .button .button__icon-container {
      height: 4.8rem;
      width: 4.8rem;
    }
  }
`;

export default styles;
export { styles };
