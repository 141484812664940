/* eslint-disable class-methods-use-this */

import { init as initApm, apm } from '@elastic/apm-rum';
import getConfig from 'next/config';
// import { start as apmNodeStart } from 'elastic-apm-node';
import packageInfo from '../../../../../package.json';

const { publicRuntimeConfig } = getConfig();

export default class ElasticAdapter {
  // getNavigationHistory(history) {
  //   this.history = history;
  // }

  init() {
    initApm({
      serviceName:
        process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME ||
        publicRuntimeConfig.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
      serverUrl:
        process.env.REACT_APP_ELASTIC_APM_SERVER_URL ||
        publicRuntimeConfig.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
      environment:
        process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT ||
        publicRuntimeConfig.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
      serviceVersion: packageInfo.version,
    });
  }

  captureException(transactionName, severityLevel, error, details) {
    if (details) {
      apm.addLabels({ errorDetails: details });
    }
    // apm.setTransactionName(transactionName);
    apm.captureError(error);
  }

  captureLog(message, severityLevel, details) {
    apm.captureError(message, details);
  }

  captureUIException(error, { componentStack }) {
    apm.addLabels({ componentStack: JSON.stringify(componentStack) });
    apm.captureError(error);
  }

  captureHTTPException(error) {
    apm.addLabels({
      ...(error?.config?.url && {
        requestUrl: error.config.url,
      }),
      ...(error?.response?.status && {
        statusCode: error.response.status,
      }),
      ...(error?.config?.method && {
        requestMethod: error.config.method,
      }),
      ...(error?.response?.data?.error && {
        errorMessage: error.response.data.error,
      }),
      genericErrorMessage: error?.message,
    });

    apm.captureError(error);
  }
}
