import { callApiBase } from './base';

const searchApi = {
  sendSearch: ({ data }) =>
    callApiBase({
      title: 'Search - send search',
      endpoint: `/search`,
      method: 'POST',
      data,
    }),
};

export default searchApi;
export { searchApi };
