/**
 * ---------------------------------------------------
 * CHECKLIST
 * ---------------------------------------------------
 */
export const GET_CHECKLIST_REQUEST = '@checklist/GET_CHECKLIST_REQUEST';
export const GET_CHECKLIST_SUCCESS = '@checklist/GET_CHECKLIST_SUCCESS';
export const GET_CHECKLIST_FAILURE = '@checklist/GET_CHECKLIST_FAILURE';

/**
 * ---------------------------------------------------
 * RESET ANSWERS
 * ---------------------------------------------------
 */
export const RESET_CHECKLIST_REQUEST = '@catalog/RESET_CHECKLIST_REQUEST';
export const RESET_CHECKLIST_SUCCESS = '@catalog/RESET_CHECKLIST_SUCCESS';
export const RESET_CHECKLIST_FAILURE = '@catalog/RESET_CHECKLIST_FAILURE';

/**
 * ---------------------------------------------------
 * CHECKLIST ANSWERS
 * ---------------------------------------------------
 */
export const SEND_CHECKLIST_ANSWERS_REQUEST =
  '@checklist/SEND_CHECKLIST_ANSWERS_REQUEST';
export const SEND_CHECKLIST_ANSWERS_SUCCESS =
  '@checklist/SEND_CHECKLIST_ANSWERS_SUCCESS';
export const SEND_CHECKLIST_ANSWERS_FAILURE =
  '@checklist/SEND_CHECKLIST_ANSWERS_FAILURE';
