import { callApiBase } from './base';

const courseApi = {
  course: ({ data }) =>
    callApiBase({
      title: 'Catalog - Course',
      endpoint: `/course/${data.slug}`,
      method: 'GET',
    }),
};

export default courseApi;
export { courseApi };
