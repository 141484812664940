import React from 'react';
import PropTypes from 'prop-types';

const IconPlaylist = React.memo(({ size, color }) => (
  <svg
    id="icon-playlist"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.06956 0H18.6655C20.5038 0 22 1.49527 22 3.33422V15.9307C22 17.5819 20.7927 18.952 19.2155 19.2153C18.952 20.7929 17.5812 22 15.9307 22H3.33422C1.49527 22 0 20.5047 0 18.6658V6.06956C0 4.41809 1.20707 3.04773 2.78471 2.78447C3.04798 1.20707 4.41833 0 6.06956 0ZM15.9307 20.3326C16.851 20.3326 17.5976 19.5873 17.5976 18.6655V18.4631V18.3531V6.06955C17.5976 5.14898 16.8513 4.40244 15.9307 4.40244H3.64687H3.54445H3.33423C2.41267 4.40244 1.66712 5.14873 1.66712 6.06955V18.6655C1.66712 19.5871 2.41267 20.3326 3.33423 20.3326H15.9307ZM20.3329 15.9307C20.3329 16.6403 19.8892 17.2443 19.2649 17.4849V6.06931C19.2649 4.23035 17.7689 2.73509 15.9307 2.73509H4.51488C4.75566 2.11078 5.35944 1.66711 6.06931 1.66711H18.6655C19.5863 1.66711 20.3326 2.41364 20.3326 3.33422V15.9307H20.3329ZM11.2036 13.496L12.2281 12.905C12.7402 12.6102 12.7402 12.1262 12.2281 11.8301L11.2036 11.2391C10.6915 10.943 9.85404 10.4593 9.34169 10.1642L8.3182 9.57245C7.80584 9.2774 7.38711 9.51892 7.38711 10.11V11.2926V13.4425V14.6254C7.38711 15.2164 7.80609 15.4574 8.3182 15.1626L9.34169 14.5708C9.81293 14.2993 10.5592 13.8682 11.0744 13.5707L11.2036 13.496Z"
      fill={color}
    />
  </svg>
));

IconPlaylist.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPlaylist.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPlaylist;
export { IconPlaylist };
