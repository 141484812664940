/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  caseStudiesData: null,
  error: null,
  loading: null,
  filterCaseStudies: false,
  whichFilterCourses: null,
  textFilterCaseStudies: null
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.caseStudiesReducer,
      };

    /**
     * ---------------------------------------------------
     * CASE STUDIES
     * ---------------------------------------------------
     */
    case types.GET_CASE_STUDIES_REQUEST:
      return { ...state, caseStudiesData: null, loading: true, error: false, filterCaseStudies: false, whichFilterCourses: null, textFilterCaseStudies: null };
    case types.GET_CASE_STUDIES_SUCCESS:
      return {
        ...state,
        caseStudiesData: action.payload.caseStudiesData,
        filterCaseStudies: action.payload.filterCaseStudies,
        whichFilterCourses: action.payload.whichFilterCourses,
        textFilterCaseStudies: action.payload.textFilterCaseStudies,
        loading: false,
        error: false,
      };
    case types.GET_CASE_STUDIES_FAILURE:
      return { ...state, caseStudiesData: null, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };