import { callApiBase } from './base';

const seriesApi = {
  getSeries: ({ token, data }) =>
    callApiBase({
      title: 'Catalog - Get Series',
      endpoint: `/series${data.slug ? "/" + data.slug : ""}`,
      method: 'GET',
      token,
    }),
};

export default seriesApi;
export { seriesApi };
