import React from 'react';

const IconAvatar = React.memo(() => (
  <svg
    id="icon-avatar"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 115 115"
    fill="none"
    width="115px"
    height="115px"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="115"
      height="115"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.5 115C89.2564 115 115 89.2564 115 57.5C115 25.7436 89.2564 0 57.5 0C25.7436 0 0 25.7436 0 57.5C0 89.2564 25.7436 115 57.5 115Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.5 115C89.2564 115 115 89.2564 115 57.5C115 25.7436 89.2564 0 57.5 0C25.7436 0 0 25.7436 0 57.5C0 89.2564 25.7436 115 57.5 115Z"
        fill="#0D0C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.3208 65.7142C69.5061 68.4812 64.0036 70.0375 58.1572 70.0375C52.3109 70.0375 46.8084 68.4812 41.9937 65.7142C21.3594 72.4586 6.57153 91.9999 6.57153 115H109.743C109.743 91.9999 94.9551 72.4586 74.3208 65.7142Z"
        fill="#313030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.1571 63.7429C72.4928 63.7429 84.1142 52.1215 84.1142 37.7857C84.1142 23.45 72.4928 11.8286 58.1571 11.8286C43.8214 11.8286 32.2 23.45 32.2 37.7857C32.2 52.1215 43.8214 63.7429 58.1571 63.7429Z"
        fill="#313030"
      />
    </g>
  </svg>
));

export default IconAvatar;
export { IconAvatar };
