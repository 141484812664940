/* eslint-disable import/no-cycle */
import { callApiBase } from './base';

const keepWatchingApi = {
  keepWatching: ({ token }) =>
    callApiBase({
      title: 'Keep Watching - watched',
      endpoint: `/towatch`,
      method: 'GET',
      token,
    }),

  keepWatchingList: ({ limit, token }) =>
    callApiBase({
      title: 'Keep Watching List',
      endpoint: `/towatch/${limit}`,
      method: 'GET',
      token,
    }),

  toWatchLesson: ({ course_id, token }) =>
    callApiBase({
      title: 'Last Wacthed on Course',
      endpoint: `/toWatchLesson/${course_id}`,
      method: 'GET',
      token,
    }),
};

export default keepWatchingApi;
export { keepWatchingApi };
