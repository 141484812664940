// from http://stackoverflow.com/a/32490603
export default async function getOrientation(file, callback) {
  var reader = new FileReader();

  reader.onload = function (event) {
    var view = new DataView(event.target.result);

    if (view.getUint16(0, false) != 0xffd8)
      return callback(convertRotationToDegrees(-2));

    var length = view.byteLength,
      offset = 2;

    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;

      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          return callback(convertRotationToDegrees(-1));
        }
        var little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;

        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + i * 12, little) == 0x0112)
            return callback(
              convertRotationToDegrees(
                view.getUint16(offset + i * 12 + 8, little)
              )
            );
      } else if ((marker & 0xff00) != 0xff00) break;
      else offset += view.getUint16(offset, false);
    }
    return callback(convertRotationToDegrees(-1));
  };

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
}

/**
 * Based off snippet here: https://github.com/mosch/react-avatar-editor/issues/123#issuecomment-354896008
 * @param rotation converts the int into a degrees rotation.
 */
function convertRotationToDegrees(rotation) {
  let rotationInDegrees = 0;
  switch (rotation) {
    case 8:
      rotationInDegrees = 270;
      break;
    case 6:
      rotationInDegrees = 90;
      break;
    case 3:
      rotationInDegrees = 180;
      break;
    default:
      rotationInDegrees = 0;
  }
  return rotationInDegrees;
}
