import { css } from 'styled-components';

const ComponentTestStyles = () => css`
  padding: 2rem;
  background: #234;
  text-align: center;
  color: #fff;
  font-family: sans-serif;

  p {
    background: #000;
    max-width: 1440px;
    display: block;
    margin: auto;
    padding: 4rem;
  }
`;

export default ComponentTestStyles;
export { ComponentTestStyles };
