import Link from 'next/link';
import { useSelector } from 'react-redux';
import { IconList, ReactAvatar } from 'components';
import { Notifications } from '../../Notifications';
import { Skeleton } from 'skeletons/Skeleton';

import * as S from './HeaderMobile.styles';
import { useEffect, useRef, useState } from 'react';

interface HeaderMobileProps {
  handleShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderMobile = ({ handleShowMenu }: HeaderMobileProps) => {
  const userData = useSelector((state: any) => state.userReducer.userData);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationsRef = useRef(null);

  const handleOnClickNotifications = () => {
    setShowNotifications((prevState) => !prevState);
  };

  const handleClickOutside = (e: any) => {
    if (
      notificationsRef &&
      notificationsRef.current &&
      !notificationsRef.current.contains(e.target)
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <S.HeaderWrapper>
      <S.MenuList
        type="button"
        onClick={() => {
          handleShowMenu(true);
        }}>
        <IconList />
      </S.MenuList>

      {userData && (
        <S.UserMenu>
          <Notifications
            notificationsRef={notificationsRef}
            isVisible={showNotifications}
            onClick={handleOnClickNotifications}
          />

          <Link href="/perfil" passHref id="header-perfil-mobile">

            {userData.name ? (
              userData.avatar ? (
                <S.PhotoHolder>
                  <img
                    src={`${userData.avatar}`}
                    alt={userData.name}
                  />
                </S.PhotoHolder>
              ) : (
                <S.PhotoHolder>
                  <ReactAvatar name={userData?.name || ''} size="4" />
                </S.PhotoHolder>
              )
            ) : (
              <div className="skeleton">
                <Skeleton width={35} height={35} variant="circle" />
              </div>
            )}

          </Link>
        </S.UserMenu>
      )}
    </S.HeaderWrapper>
  );
};

export default HeaderMobile;
export { HeaderMobile };
