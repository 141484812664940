import React from 'react';
import PropTypes from 'prop-types';

const IconMasterAlt = React.memo(({ size, color }) => (
  <svg
    id="icon-master-alt"
    viewBox="0 0 31 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.501 16.45C17.119 17.838 19.224 18.68 21.524 18.68C26.616 18.68 30.759 14.562 30.759 9.5C30.759 4.438 26.616 0.32 21.524 0.32C19.314 0.317892 17.1767 1.10922 15.501 2.55C13.883 1.162 11.778 0.32 9.478 0.32C4.38599 0.32 0.242996 4.438 0.242996 9.5C0.242996 14.562 4.38599 18.68 9.478 18.68C11.688 18.6821 13.8253 17.8908 15.501 16.45ZM17 3.789C18.301 2.63843 19.9772 2.00229 21.714 2C25.732 2 29 5.364 29 9.5C29 13.636 25.732 17 21.714 17C19.9772 16.9977 18.301 16.3616 17 15.211C18.081 13.583 18.715 11.617 18.715 9.5C18.7191 7.46885 18.1224 5.48185 17 3.789Z"
      fill={color}
    />
  </svg>
));

IconMasterAlt.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconMasterAlt.defaultProps = {
  color: '#777777',
  size: '49px',
};

export default IconMasterAlt;
export { IconMasterAlt };
