import { useState } from 'react';

import { IconCalendarPlus, IconChecked, IconPlusThin } from 'components';
import { useDispatch } from 'react-redux';
import { actions as favoriteActions } from 'store/ducks/favorite';

import * as S from './SecondaryButton.styles';

interface SecondaryButtonProps {
  color: string;
  textColor: string;
  category: string;
  text: string;
  slug: string;
}

const SecondaryButton = ({
  color,
  textColor,
  category,
  text,
  slug,
}: SecondaryButtonProps) => {
  return (
    <S.SecondaryButton
      color={color}
      textColor={textColor}
      as="a"
      className={category}
      href={slug}
      target="_blank">
      <IconCalendarPlus color={textColor} className="calendar-plus" />

      {text}
    </S.SecondaryButton>
  );
};

export default SecondaryButton;
export { SecondaryButton };
