import React from 'react';
import * as S from './CourseTrailSkeleton.styles';
import { Skeleton } from '..';

const CourseTrailSkeleton = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.TextContainer>
          <Skeleton className="title" />
          <Skeleton className="subtitle" />
        </S.TextContainer>
        <S.CourseTrailContainer>
          <S.TitleCourseTrailContainer>
            <Skeleton className="module-number" />
            <Skeleton className="module-title" />
          </S.TitleCourseTrailContainer>
          <S.CardsContainer>
            <Skeleton className="cards" />
            <Skeleton className="cards" />
            <Skeleton className="cards" />
            <Skeleton className="cards" />
          </S.CardsContainer>
        </S.CourseTrailContainer>
      </S.Content>
    </S.Wrapper>
  );
};

export default CourseTrailSkeleton;
export { CourseTrailSkeleton };
