import { callApiBase } from './base';

const keepWatchingApi = {
  keepWatching: () =>
    callApiBase({
      title: 'Keep Watching - watched',
      endpoint: `/towatch`,
      method: 'GET',
    }),
  keepWatchingList: ({ limit, token }) =>
    callApiBase({
      title: 'Keep Watching List',
      endpoint: `/towatch/${limit}`,
      method: 'GET',
      token,
    }),
  lastWatchedOnCourse: ({ course_id, token }) =>
    callApiBase({
      title: 'Last Wacthed on Course',
      endpoint: `/towatch/1/${course_id}`,
      method: 'GET',
      token,
    }),
};

export default keepWatchingApi;
export { keepWatchingApi };
