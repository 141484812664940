import { PaginationQueryDto } from '../dtos';

function parserPaginationQuery<T extends PaginationQueryDto>(data?: T) {
  const defaultQuery = {
    page: 1,
    pageSize: 10,
  };

  if (data) Object.assign(defaultQuery, data);

  defaultQuery['page-size'] = defaultQuery.pageSize;
  Reflect.deleteProperty(defaultQuery, 'pageSize');

  return defaultQuery as T;
}

export const AxiosImpUtils = {
  parserPaginationQuery,
};
