import * as types from './types';

/**
 * ---------------------------------------------------
 * GET FAVORITE
 * ---------------------------------------------------
 */
export const getFavoriteRequest = ({ payload }) => ({
  type: types.GET_FAVORITE_REQUEST,
  payload,
});
export const getFavoriteSuccess = ({ payload }) => ({
  type: types.GET_FAVORITE_SUCCESS,
  payload,
});
export const getFavoriteFailure = () => ({
  type: types.GET_FAVORITE_FAILURE,
});

/**
 * ---------------------------------------------------
 * SEND FAVORITE
 * ---------------------------------------------------
 */
export const sendFavoriteRequest = ({ payload }) => ({
  type: types.SEND_FAVORITE_REQUEST,
  payload,
});
export const sendFavoriteSuccess = () => ({
  type: types.SEND_FAVORITE_SUCCESS,
});
export const sendFavoriteFailure = () => ({
  type: types.SEND_FAVORITE_FAILURE,
});

/**
 * ---------------------------------------------------
 * DELETE FAVORITE
 * ---------------------------------------------------
 */
export const deleteFavoriteRequest = ({ payload }) => ({
  type: types.DELETE_FAVORITE_REQUEST,
  payload,
});
export const deleteFavoriteSuccess = ({ payload }) => ({
  type: types.DELETE_FAVORITE_SUCCESS,
  payload,
});
export const deleteFavoriteFailure = () => ({
  type: types.DELETE_FAVORITE_FAILURE,
});
