import React from 'react';

interface Props {
  color?: string;
}

const IconNotePencil = React.memo(({ color = '#7F7F7F' }: Props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="NotePencil">
      <path
        id="Vector"
        d="M10.8198 12.5H8.31982V10L15.8198 2.5L18.3198 5L10.8198 12.5Z"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M13.9448 4.375L16.4448 6.875"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M17.6948 9.375V16.25C17.6948 16.4158 17.629 16.5747 17.5118 16.6919C17.3946 16.8092 17.2356 16.875 17.0698 16.875H4.56982C4.40406 16.875 4.24509 16.8092 4.12788 16.6919C4.01067 16.5747 3.94482 16.4158 3.94482 16.25V3.75C3.94482 3.58424 4.01067 3.42527 4.12788 3.30806C4.24509 3.19085 4.40406 3.125 4.56982 3.125H11.4448"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
));

export default IconNotePencil;
export { IconNotePencil };
