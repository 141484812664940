import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  background: ${(props) => props.theme.colorSystem.white};

  position: fixed;
  top: 0;
  left: 100%;
  transition: all 0.3s ease-in-out;

  z-index: 99;

  &.slidemodal {
    &--showing {
      left: 0;
      transition: all 0.3s ease-in-out 0.4s;
    }
  }

  .slidemodal {
    &__content {
      position: relative;
      overflow-y: auto;
    }
  }
`;

export { Wrapper };
export default Wrapper;
