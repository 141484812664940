import styled, { css } from 'styled-components';

interface BulletProps {
  active: boolean;
}

export const Wrapper = styled.div`
  .slick-slider {
    margin-inline: auto;
    padding-bottom: 24px;

    @media (min-width: 768px) {
      padding: 24px;
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    @media (min-width: 768px) {
      border-radius: 8px;
    }
  }

  .slick-arrow {
    &::before {
      display: none;
    }

    &.slick-next {
      right: 22px;
      @media (min-width: 769px) {
        border-radius: 8px;
        background-image: linear-gradient(
          90deg,
          transparent 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
      svg {
        transform: rotate(180deg);
      }
    }
    &.slick-prev {
      @media (min-width: 769px) {
        border-radius: 8px;
        background-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.4) 0%,
          transparent 100%
        );
      }
      left: 22px;
    }
  }

  .slick-dots {
    bottom: -16px;

    li {
      &.slick-active {
        button::before {
          opacity: 1;
          color: #ffffff;
        }
      }
      button::before {
        opacity: 1;
        color: #3d3d3d;
        font-size: 12px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  position: relative;
`;

export const SliderContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 1620px;
`;

export const NavigationArrow = styled.button`
  width: 30px;
  z-index: 2;

  height: max-content;
  aspect-ratio: 1.5;

  cursor: pointer;

  @media (min-width: 768px) {
    height: 100%;
  }
`;
