import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { IconChecked, IconMedal, IconMedalChecked } from '@components';
import { CardSkeleton } from '@skeletons';
import { characterLimiter, formatDuration } from '@helpers';
import { useFeatureToggle } from 'hooks';
import * as S from './Card.styles';

import { CourseListItemDto } from 'libs/ms-api/';
import { CardTypes } from './types';

type CardContent = CourseListItemDto & { metadata: { lesson: string } };
interface CardProps {
  cardContent: CardContent;
  type?: string;
}

const Card = ({ cardContent, type }: CardProps) => {
  const title =
    cardContent?.category?.slug === 'case'
      ? cardContent?.metadata.author
      : cardContent?.title;

  const linkOptions = {
    [CardTypes.CONTINUE]: cardContent?.metadata?.lesson,
    [CardTypes.RECOMMENDED]: cardContent?.slug,
  };

  const link = linkOptions[type] || `curso/${cardContent?.slug}`;

  if (!cardContent) {
    return <CardSkeleton />;
  }

  const getStatusIcon = (content: CardContent) => {
    if (content?.canCertify && !content?.studentIssuedCertificate)
      return <IconMedal color="#fff" size="18px" />;
    if (content?.canCertify && content?.studentIssuedCertificate)
      return <IconMedalChecked color="#fff" size="18px" />;
    if (content?.progress?.percent >= 0.95)
      return (
        <IconChecked color="#fff" strokeWidth="2.5" width="12" height="10" />
      );
  };

  const checkCompletion = (content: CardContent) => {
    if (content?.canCertify && content?.studentIssuedCertificate) return true;
    else if (!content.canCertify && content.progress.percent >= 0.95)
      return true;
    else return false;
  };

  const StatusIcon = () => {
    if (cardContent?.canCertify || cardContent?.progress?.percent >= 0.95)
      return (
        <S.StatusIcon completed={checkCompletion(cardContent)}>
          {getStatusIcon(cardContent)}
        </S.StatusIcon>
      );
  };

  const ToggleStatusIcon = useFeatureToggle({
    ComponentWhenEnabled: () => StatusIcon(),
    featureKeys: ['certificates'],
  });

  return (
    <Link
      href={{ pathname: link, query: { id: cardContent.id } }}
      legacyBehavior>
      <S.Wrapper>
        <S.ImageContainer>
          <Image
            src={cardContent?.thumbnail?.path}
            alt={cardContent?.thumbnail?.alt}
            layout="fill"
            objectFit="cover"
          />
          {!!cardContent?.metadata?.mark && <S.NewContent>NOVO</S.NewContent>}
          <ToggleStatusIcon />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.HeaderContainer>
            <div>
              <S.Title title={title}>
                {characterLimiter(title || '', 28)}
              </S.Title>

              {!!cardContent?.subtitle && (
                <S.Company title={cardContent?.subtitle}>
                  {characterLimiter(cardContent?.subtitle, 28)}
                </S.Company>
              )}
            </div>
            {cardContent?.metadata?.durationInSec && (
              <S.Duration>
                {formatDuration(cardContent?.metadata?.durationInSec)}
              </S.Duration>
            )}
          </S.HeaderContainer>

          <S.Description title={cardContent?.metadata?.description}>
            {cardContent?.metadata?.description}
          </S.Description>
          <S.ContainerProgress>
            <>
              <S.ProgressBar>
                <S.Progress progress={Number(cardContent?.progress?.percent)} />
              </S.ProgressBar>
              <p>{`${(
                Number(cardContent?.progress?.percent) * 100
              ).toFixed()}%`}</p>
            </>
          </S.ContainerProgress>
          <S.FlagsContainer>
            {cardContent?.metadata?.tags.map((tag, index) => {
              if (index < 2 && !!tag?.title) {
                return (
                  <S.Flag title={tag?.title} key={tag?.title}>
                    {tag?.title}
                  </S.Flag>
                );
              }
            })}
          </S.FlagsContainer>
        </S.ContentContainer>
      </S.Wrapper>
    </Link>
  );
};

export default Card;
