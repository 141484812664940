import { callApiBase } from './base';

const watchingApi = {
  watching: ({ token }) =>
    callApiBase({
      title: 'Watching - watching',
      endpoint: `/watching`,
      method: 'GET',
      token,
    }),
};

export default watchingApi;
export { watchingApi };
