import React from 'react';
import PropTypes from 'prop-types';

const IconPause = React.memo(({ size, color }) => (
  <svg
    id="icon-pause"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0H1V16H7V0ZM16 0H10V16H16V0Z"
      fill={color}
    />
  </svg>
));

IconPause.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPause.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPause;
export { IconPause };
