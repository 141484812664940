import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconPlay, Picture, ProgressBar } from '@components';

import { styles } from './VideoThumb.styles';

const Wrapper = styled.div`
  ${styles}
`;

const VideoThumb = React.memo(
  ({ alt, className, img, radius, time, source, iconSize }) => {
    return (
      <Wrapper className={className} img={img} radius={radius}>
        <div className="thumb__image">
          <Picture wide alt={alt} src={img} variant={source} />
        </div>
        <div className="thumb__play-icon">
          <IconPlay size={iconSize} />
        </div>
        {time && (
          <div className="thumb__progress">
            <ProgressBar time={time} />
          </div>
        )}
      </Wrapper>
    );
  }
);

VideoThumb.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  source: PropTypes.string,
  iconSize: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VideoThumb.defaultProps = {
  alt: 'Picsum placeholder image',
  className: '',
  img: 'https://picsum.photos/370/270?grayscale',
  radius: 0,
  source: 'cloudinary',
  iconSize: '20px',
  time: null,
};

export default VideoThumb;
export { VideoThumb };
