import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  max-height: 465px;

  .playlist-mobile {
    display: none;
  }

  @media (min-width: 1025px) {
    width: 40%;
    aspect-ratio: 0.88;
    height: auto;
    margin-left: 24px;
    padding-block: 2.7rem;
    max-width: 410px;

    .playlist-mobile {
      display: block;
    }
  }
`;
