import React from 'react';
import PropTypes from 'prop-types';

const IconUpload = React.memo(({ size, color }) => (
  <svg
    id="icon-upload"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3.8C11.2 3.8 11.5 3.9 11.7 4.1L18.8 11.2C19.2 11.6 19.2 12.2 18.8 12.6C18.4 13 17.8 13 17.4 12.6L12 7.2L12 21C12 21.6 11.6 22 11 22C10.4 22 10 21.6 10 21L10 7.2L4.6 12.6C4.2 13 3.6 13 3.2 12.6C2.8 12.2 2.8 11.6 3.2 11.2L10.3 4.1C10.5 3.9 10.8 3.8 11 3.8ZM21 2L1 2C0.4 2 0 1.6 0 1C0 0.4 0.4 0 1 0L21 0C21.6 0 22 0.4 22 1C22 1.6 21.6 2 21 2Z"
      fill={color}
    />
  </svg>
));

IconUpload.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconUpload.defaultProps = {
  color: '#ffffff',
  size: '22px',
};

export default IconUpload;
export { IconUpload };
