import React from 'react';

interface Props {
  color?: string;
}

const IconCheckCircle = React.memo(({ color = '#fff' }: Props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1365 8.052L9.59174 12.3877L7.32324 10.2198"
      stroke={color}
      strokeWidth="1.23877"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.73 17.3428C14.8349 17.3428 18.1626 14.0151 18.1626 9.91016C18.1626 5.80524 14.8349 2.47754 10.73 2.47754C6.62506 2.47754 3.29736 5.80524 3.29736 9.91016C3.29736 14.0151 6.62506 17.3428 10.73 17.3428Z"
      stroke={color}
      strokeWidth="1.23877"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconCheckCircle;
export { IconCheckCircle };
