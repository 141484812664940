import { css } from 'styled-components';

const styles = ({ sendQuestion, theme }) => css`
  width: 100%;
  min-height: 6rem;
  position: relative;

  input:checked + .default {
    .answer__checkmark {
      border-color: ${sendQuestion
        ? 'inherit'
        : theme.colorPallete.primary.default};

      .answer__checkmark-filler {
        display: ${sendQuestion ? 'inherit' : 'block'};
        width: ${sendQuestion ? 0 : '100%'};
        height: ${sendQuestion ? 0 : '100%'};
        background-color: ${sendQuestion
          ? 'inherit'
          : theme.colorPallete.primary.default};
        border-radius: 5rem;
      }

      // ? Disable svg when user select this input:
      #icon-error,
      #icon-check {
        display: ${sendQuestion ? 'block' : 'none'};
      }
    }
  }

  .answer {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  label {
    display: block;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .default {
    background-color: ${theme.colorPallete.base[100]};
    width: 100%;
    padding: 0 2.2rem;
    display: flex;
    align-items: center;
    margin: 2rem 0;

    .answer__checkmark {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.6rem;
      padding: 0.2rem;
      border: 0.1rem solid ${theme.colorPallete.base.default};
      border-radius: 2rem;
      overflow: hidden;

      #icon-check,
      #icon-error {
        display: none;
      }
    }
  }

  .default {
    .answer-text {
      display: inline-block;
      padding: 2rem 0;
    }

    &.correct {
      background-color: ${theme.colorSystem.success};

      .answer__checkmark {
        border-color: ${theme.colorPallete.white};

        .answer__checkmark-filler {
          display: none;
        }

        #icon-check {
          display: block;
        }
        #icon-error {
          display: none !important;
        }
      }

      .answer-text {
        color: ${theme.colorPallete.white};
      }
    }

    &.wrong {
      background-color: ${theme.colorSystem.danger};

      .answer__checkmark {
        border-color: ${theme.colorPallete.white};

        .answer__checkmark-filler {
          display: none;
        }

        #icon-error {
          display: block;
        }
        #icon-check {
          display: none !important;
        }
      }

      .answer-text {
        color: ${theme.colorPallete.white};
      }
    }

    &.not-selected {
      background-color: ${theme.colorSystem.baseColor200};

      .answer__checkmark {
        border-color: ${theme.colorPallete.white};

        .answer__checkmark-filler {
          display: none;
        }

        #icon-error {
          display: block;
        }
      }

      .answer-text {
        color: ${theme.colorPallete.white};
      }
    }
  }
`;

export default styles;
export { styles };
