/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Utils
import { refreshTokenFlow } from '@utils';

// Actions
import { actions as libraryActions } from '@store/ducks/library';

// MSV2-api
import { libraryApi as msv2LibraryApi } from '@services/msv2-api';

// Mirror-api
import { libraryApi as mirrorLibraryApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * GET LIBRARY
 * ---------------------------------------------------
 */
export function* getLibrary({ payload }) {
  const { auth, params, isServer, data } = payload;

  const request = isServer ? msv2LibraryApi : mirrorLibraryApi;
  const token = isServer ? auth.token : '';

  try {
    // Exec clean loading, when change active tag:
    if (data?.newTag) {
      yield put(libraryActions.getLibraryCleanRequest());
    }
    const response = yield call(request.library, {
      method: 'GET',
      token,
      params,
    });

    if (response.status === 200) {
      // If it exists, execute a library that clean every video:
      if (data?.newTag) {
        yield put(
          libraryActions.getLibraryCleanSuccess({
            payload: {
              libraryData: response.data.content.instance,
              libraryDataPage: params.page,
            },
          })
        );
      } else {
        yield put(
          libraryActions.getLibrarySuccess({
            payload: {
              libraryData: response.data.content.instance,
              libraryDataPage: params.page,
            },
          })
        );
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 401 && !isServer) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2LibraryApi.library,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        // If it exists, execute a library that clean every video:
        if (data.newTag) {
          yield put(
            libraryActions.getLibraryCleanSuccess({
              payload: {
                libraryData: responseReq.data,
                libraryDataPage: 1,
              },
            })
          );
        } else {
          yield put(
            libraryActions.getLibrarySuccess({
              payload: {
                libraryData: responseReq.data,
                libraryDataPage: params.page + 1,
              },
            })
          );
        }
      }
    } else {
      yield put(libraryActions.getLibraryFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.GET_LIBRARY_REQUEST, getLibrary);
}

export const sagas = [watchUser];
