import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 769px) {
    padding: 24px;
    flex-direction: row;
  }

  margin-bottom: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const InfosContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 8px;

  @media (min-width: 769px) {
    gap: 16px;
    width: 75%;
  }
`;

export const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 768px) {
    div:nth-child(2),
    div:nth-child(3) {
      max-width: 100%;
    }
  }

  @media (min-width: 1440px) {
    div {
      width: 100%;
      height: auto;
    }

    div:nth-child(1) {
      aspect-ratio: 5;
      max-width: 33%;
    }

    div:nth-child(2) {
      aspect-ratio: 4;
      max-width: 80%;
    }

    div:nth-child(3) {
      aspect-ratio: 3.5;
      max-width: 100%;
    }
  }
`;

export const Image = styled.div`
  div {
    width: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: 1.8;
  }

  @media (min-width: 769px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    width: 60%;
    div {
      aspect-ratio: 2;
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    div {
      max-width: 100%;
    }
  }

  @media (min-width: 1440px) {
    div {
      width: 100%;
      height: auto;
      margin-top: 24px;
    }

    div:nth-child(1) {
      aspect-ratio: 4.5;
      max-width: 40%;
    }

    div:nth-child(2) {
      aspect-ratio: 4.5;
      max-width: 30%;
    }
  }
`;
