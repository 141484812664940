import React from 'react';

interface Props {
  className?: string;
  color?: string;
  size?: string;
}

const IconMedalChecked = React.memo(
  ({ className, color = '#fff', size = '17' }: Props) => (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 11.6875C11.434 11.6875 13.8125 9.30901 13.8125 6.375C13.8125 3.44099 11.434 1.0625 8.5 1.0625C5.56599 1.0625 3.1875 3.44099 3.1875 6.375C3.1875 9.30901 5.56599 11.6875 8.5 11.6875Z"
        stroke={color}
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6875 10.625V15.9375L8.5 14.3437L5.3125 15.9375V10.625"
        stroke={color}
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.3501 6.67671L7.86012 8.17097C7.98325 8.29281 8.18168 8.29229 8.30417 8.16981L11.1117 5.3623"
        stroke={color}
        stroke-width="1.1"
        stroke-linecap="round"
      />
    </svg>
  ),
);

export default IconMedalChecked;
export { IconMedalChecked };
