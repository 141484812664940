import { css } from 'styled-components';

const styles = ({ theme, color }) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  background: ${theme.colorPallete.base.default};
  padding-bottom: 171%;

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding-bottom: 40.98%;
  }

  img,
  source {
    width: 100%;
    height: auto;
  }

  .course-banner__picture {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;

    img,
    source {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .course-banner__content {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 50%;
    height: auto;

    position: absolute;
    bottom: 0;
    right: auto;
    padding: 2rem 1rem;

    @media all and (min-width: ${theme.breakpoints.medium}) {
      width: 52%;
      height: 100%;
      bottom: unset;
      top: 0;
      left: auto;
      right: 0;
      z-index: 5;
    }

    &__wrap {
      display: inline-flex;
      flex-direction: column;
      position: relative;
      padding: 0 1rem 0 2rem;
      transition: all 0.2s ease-in-out;

      width: 100%;
      margin: auto;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        width: 70%;
      }

      .desk-only {
        display: none;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          display: grid;
        }
      }

      .mobile-only {
        @media all and (min-width: ${theme.breakpoints.medium}) {
          display: none;
        }
      }
    }

    &__tag {
      position: relative;
      margin-bottom: 0.4em;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.82em;
      }
    }

    &__logo {
      margin: 1em 0;
      max-height: 26.9vw;
      height: auto;
      width: auto;
      /* padding: 0.5rem 0; */

      @media all and (min-width: ${theme.breakpoints.medium}) {
        max-height: 8.9vw;
        margin-bottom: 0.32em;
      }

      @media all and (min-width: ${theme.breakpoints.big}) {
        max-height: 10.9vw;
        width: auto;
      }

      img,
      source {
        max-width: 100%;
        max-height: 26.9vw;
        height: auto;
        width: auto;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          max-height: 8.9vw;
          width: auto;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          max-height: 10.9vw;
        }
      }
    }

    &__type {
      width: 100%;
      margin-bottom: 0;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.82em;
      }

      * {
        font-size: 1.6rem;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.2vw;
        }
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 0;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.82em;
      }

      * {
        font-size: 1.6rem;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.2vw;
        }
      }
    }

    &__subtitle {
      width: 100%;
      margin-bottom: 0.22em;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.09em;
      }

      * {
        text-transform: uppercase;
        font-size: 1.2rem;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          line-height: 100%;
          margin-top: 0.15em;
          margin-bottom: 0.15em;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          font-size: 1vw;
        }
      }
    }

    &__summary {
      width: 100%;
      margin-bottom: 0.49em;
      margin-top: 0.49em;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-top: 0.82vw;
        margin-bottom: 0.82vw;
      }

      * {
        font-size: 1.2rem;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          line-height: 100%;
          margin-top: 0.15em;
          margin-bottom: 0.15em;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          font-size: 0.9vw;
        }
      }
    }

    &__description,
    &__details {
      width: 100%;
      padding: 1rem 0 0.5rem;
      margin-top: 0.5em;
      margin-bottom: 0.2em;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        margin-top: 0.6em;
        margin-bottom: 0.6em;
      }

      @media all and (min-width: 998px) {
        margin-top: 0.8em;
        margin-bottom: 0.8em;
      }

      .text-component {
        height: 3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        transition: all 0.4s ease-in-out;

        @media (min-width: ${theme.breakpoints.medium}) {
          height: auto;
          overflow: auto;
          text-overflow: unset;
          white-space: unset;
        }
      }

      &.more {
        .text-component {
          height: auto;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
          transition: all 0.4s ease-in-out;
        }
      }

      * {
        display: inline-block;
        font-size: 1.4rem;
        line-height: 172% !important;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.1vw;
          line-height: 160%;
        }

        @media all and (min-width: 998px) {
          line-height: 128%;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          line-height: 140%;
        }
      }
    }

    &__progress {
      padding: 1vw 0 3vw;
      max-width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        max-width: 80%;
        padding: 1vw 0;
      }

      &--percent {
        padding-left: 0.7vw;

        * {
          font-size: 1.2rem;

          @media all and (min-width: ${theme.breakpoints.medium}) {
            font-size: 0.9vw;
            line-height: 100%;
          }
        }
      }
    }

    &__cta {
      margin: 1.1vw 0;
      max-width: 80%;
      margin: 2vw 0 1.1vw;

      * {
        font-size: 1.6rem !important;
        width: auto !important;
        min-height: unset !important;
        height: unset !important;
        max-height: unset !important;

        @media all and (min-width: ${theme.breakpoints.big}) {
          font-size: 1.1vw !important;
        }

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.4vw !important;
          line-height: 100%;
        }

        & > button,
        & > a {
          min-height: unset !important;
          height: unset !important;
          max-height: unset !important;
          padding: 2rem !important;
          width: 100% !important;
          max-width: 100% !important;

          transition: all 0.35s ease-in-out !important;

          @media all and (min-width: ${theme.breakpoints.medium}) {
            padding: 1vw 4.3vw !important;
          }

          @media all and (min-width: ${theme.breakpoints.big}) {
            padding: 0.8vw 4.3vw !important;
          }

          path {
            transition: all 0.4s ease-in-out !important;
          }

          &:hover {
            transition-timing-function: 0.35s !important;
            transition-property: color, background-color !important;

            path {
              fill: inherit;

              transition-timing-function: 0.4s !important;
              transition-property: color, background-color, fill !important;
            }
          }
        }
      }

      &--mobile {
        display: flex;
        justify-content: space-between;

        .course-banner__cta-more {
          background: transparent;
        }

        .course-banner__cta-trailer {
          background: transparent;
          padding: 1rem;
          cursor: pointer;

          span {
            display: inline-flex;
            align-items: center;

            span {
              padding-left: 1rem;
            }
          }
        }
      }

      &--holder {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.6vw;
        grid-gap: 0.6vw;
        width: 100%;
      }

      &__button {
        &--course-program,
        &--trailer {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          border: 0.2rem solid ${color};
          border-radius: 0.5rem;
          background-color: transparent;
          margin-top: 1.4rem;
          cursor: pointer;
          padding: 0.8rem 0.5rem;

          @media all and (min-width: ${theme.breakpoints.medium}) {
            width: auto;
            margin-top: 0.4vw;
            padding: 0.9vw 1vw;
          }

          span {
            width: 100%;
            display: flex;
            align-items: center;
            color: ${color};
            font-family: ${theme.fonts.sans};
            font-size: 1.6rem;
            font-weight: 700;

            @media all and (min-width: ${theme.breakpoints.medium}) {
              font-size: 1.1vw;
            }
          }

          svg {
            width: 3.2rem;
            margin: 0 1.3rem;

            @media all and (min-width: ${theme.breakpoints.medium}) {
              width: 1.8vw;
            }
          }
        }

        &--trailer {
          background-color: ${color};

          span {
            color: #fff;
          }

          svg {
            width: 1.5vw;
            margin: 0 1.3rem;
          }
        }
      }
    }
  }
`;

export { styles };
export default styles;
