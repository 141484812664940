import React from 'react';

interface IconCheckProps {
  color?: string;
  size?: string;
}

const IconCheck = React.memo(
  ({ color = '#ffffff', size = '16' }: IconCheckProps) => (
    <svg
      id="icon-check"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9622 13.9622 18 9 18C4.03784 18 0 13.9622 0 9C0 4.03784 4.03784 0 9 0C13.9622 0 18 4.03784 18 9ZM5.95102 9.08867C5.66339 8.80595 5.19931 8.80595 4.91168 9.08867C4.62405 9.3714 4.62405 9.82993 4.90927 10.1103L7.14747 12.3103C7.29008 12.4505 7.47861 12.5217 7.66714 12.5217C7.85567 12.5217 8.0442 12.4505 8.18681 12.3103L13.0886 7.4945C13.3763 7.21178 13.3763 6.75562 13.0886 6.4729C12.801 6.19018 12.3369 6.19018 12.0493 6.4729L7.66956 10.7779L5.95102 9.08867Z"
        fill={color}
      />
    </svg>
  ),
);

export default IconCheck;
export { IconCheck };
