import { Avatar } from 'components';

import * as S from './Submenu.styles';

interface SubmenuProps {
  isVisible: boolean;
  width: string;
  position: { bottom: string; right: string };
  options: { item: string; action?: any; href?: string; icon?: JSX.Element }[];
  subMenuRef?: React.MutableRefObject<any>;
  showSocial?: boolean;
  onClick?: () => void;
}

const Submenu = ({
  isVisible,
  position,
  width,
  options,
  subMenuRef,
  showSocial,
  onClick,
}: SubmenuProps) => {
  if (!isVisible) return null;
  return (
    <S.SubmenuWrapper position={position} width={width} ref={subMenuRef}>
      {showSocial && (
        <S.SocialWrapper>
          <Avatar showName onClick={onClick} />
        </S.SocialWrapper>
      )}

      <S.SubmenuList>
        {options.map((option) => {
          return (
            <li key={option.item}>
              <S.SubmenuButton
                onClick={option.action}
                as={option.href ? 'a' : 'button'}
                type={option.action && 'button'}
                href={option.href && option.href}
                target={option.href ? '_blank' : null}>
                {option.icon && option.icon}
                {option.item}
              </S.SubmenuButton>
            </li>
          );
        })}
      </S.SubmenuList>
    </S.SubmenuWrapper>
  );
};

export { Submenu };
