import styled, { css } from 'styled-components';

interface ButtonProps {
  textColor: string;
  color: string;
}

export const SecondaryButton = styled.button<ButtonProps>`
  ${({ color, textColor, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    padding: 6px 12px;
    color: ${textColor};
    border-radius: 3px;
    background-color: transparent;
    font-weight: inherit;
    font-size: inherit;
    border: 1px solid ${color};
    gap: 8px;
    cursor: pointer;
    transition: all 0.5s;

    &.watchList {
      border-color: ${theme.colorPallete.base[450]};
      background-color: ${theme.colorPallete.base[450]};
    }

    @media (max-width: 768px) {
      &.live-class {
        padding-inline: 8px;
      }
    }

    @media (min-width: 769px) {
      padding: 8px 24px;

      svg {
        height: 60%;
        width: 60%;
      }

      .calendar-plus {
        width: 100%;
        height: 100%;
      }
    }
  `}
`;
