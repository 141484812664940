import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from 'next/image';

import { styles } from './Picture.styles';

const Wrapper = styled.picture`
  ${styles}
`;

const Picture = ({ className, src, srcSet, alt, variant, wide, useLazingLoading }) => {
  // base path cloudinary
  // const pathServerImage = `${process.env.CLOUDINARY_BASE_URL}`;
  const pathServerImage = `https://res.cloudinary.com/redfuel/image/upload`;

  let srcPath = src;
  let srcSetPath = null;

  // if (variant === 'cloudinary') {
  //   srcPath = pathServerImage + src;
  // }

  if (srcSet !== null && Object.keys(srcSet).length > 0) {
    // if (variant === 'cloudinary') {
    //   srcSetPath = srcSet.map((source) => {
    //     if (!source.src.includes(pathServerImage)) {
    //       source.src = pathServerImage + source.src;
    //     }
    //     return source;
    //   });
    // }
  }

  const cssClasses = classNames({
    fill: wide,
  });

  const srcClasses = classNames({
    fill: wide,
  });

  return (
    <Wrapper className={className}>
      {srcSetPath !== null
        ? srcSetPath.map((source, index) => {
          let meta;
          const key = index;

          if (source.min) {
            meta = `(min-width:${source.min})`;
          } else {
            meta = `(max-width:${source.max})`;
          }
          return (
            <source
              key={key}
              media={meta}
              srcSet={source.src}
              className={srcClasses}
            />
          );
        })
        : null}
      {srcPath
        ? useLazingLoading
          ?
          <Image src={srcPath} alt={alt} className={cssClasses} layout='fill' />
          :
          <img src={srcPath} alt={alt} className={cssClasses} />
        :
        <></>
      }
    </Wrapper>
  );
};

Picture.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.instanceOf(Array),
  alt: PropTypes.string,
  variant: PropTypes.string,
  wide: PropTypes.bool,
  useLazingLoading: PropTypes.bool,
};

Picture.defaultProps = {
  className: '',
  src: null,
  srcSet: null,
  alt: null,
  variant: 'cloudinary',
  wide: false,
  useLazingLoading: false,
};

export default Picture;
export { Picture };
