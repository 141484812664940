import React from 'react';

interface IconCheckSquareProps {
  color?: string;
}

const IconCheckSquare = React.memo(
  ({ color = '#00AD8B' }: IconCheckSquareProps) => (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_230_24140)">
        <path
          d="M8.2998 9.16659L10.7998 11.6666L19.1331 3.33325"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2998 10V15.8333C18.2998 16.2754 18.1242 16.6993 17.8116 17.0118C17.4991 17.3244 17.0752 17.5 16.6331 17.5H4.96647C4.52444 17.5 4.10052 17.3244 3.78796 17.0118C3.4754 16.6993 3.2998 16.2754 3.2998 15.8333V4.16667C3.2998 3.72464 3.4754 3.30072 3.78796 2.98816C4.10052 2.67559 4.52444 2.5 4.96647 2.5H14.1331"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_230_24140">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.799805)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
);

export default IconCheckSquare;
export { IconCheckSquare };
