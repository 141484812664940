import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  z-index: 300;
  background-color: ${(props) => props.theme.colorSystem.black};

  .search-bar {
    background-color: ${(props) => props.theme.colorPallete.base[600]};
    width: 100%;
    height: 8rem;

    display: flex;
    align-items: center;
    padding: 1rem;

    .search-bar__search-icon {
      margin: 1rem;
      display: none;
    }

    .search-bar__close-icon {
      background: transparent;
      cursor: pointer;
      outline: none;
      margin: 1rem;
    }

    .search-bar__do-search-icon {
      background: transparent;
      border: none;
      box-shadow: none;
      margin-bottom: -0.5rem;
      margin-left: 1.5rem;
    }

    .search-bar__form {
      width: 100%;
      padding: 1rem;
      background-color: ${(props) => props.theme.colorPallete.base[600]};
      color: ${(props) => props.theme.colorSystem.white};
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 2rem;

      &:focus,
      &:active {
        box-shadow: none;
        border: none;
        outline: none;
      }

      &::placeholder {
        color: #7c7c7c;
      }
    }
  }

  .search-results {
    background-color: ${(props) => props.theme.colorSystem.black};
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    &__loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.show {
      overflow: auto;
      height: calc(100% - 6rem);
      padding: 10px;
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }

    .block-title {
      display: flex;
      align-items: center;
      padding-bottom: 1.6rem;

      button {
        margin-left: 2rem;
        background: transparent;
        outline: none;
        cursor: pointer;
      }
    }

    .search-results__suggestions {
      width: 100%;
      padding: 2rem 1rem;

      .search-suggestion {
        display: block;
        padding-bottom: 2rem;

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        display: inline-flex;
        margin-top: 1rem;
      }

      &--item {
        width: 100%;
      }
    }

    .search-results__recent,
    .search-results__popular {
      padding: 2rem 1rem;
    }

    .search-results__tags {
      & > span {
        margin-right: 1rem;
      }
    }
  }
`;

export default Wrapper;
export { Wrapper };
