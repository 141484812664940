import { Skeleton } from 'skeletons/Skeleton';

import * as S from './VideoPlayerSkeleton.styles';

export const VideoPlayerSkeleton = () => {
  return (
    <S.Wrapper>
      <Skeleton isFullWidth isFullHeight />
    </S.Wrapper>
  );
};
