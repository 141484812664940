import cookies from 'js-cookie';

export const setCookie = (name: string, value: string, options = {}) => {
  const existingCookie = cookies.get(name);
  if (existingCookie) {
    cookies.remove(name);
  }
  cookies.set(name, value, options);
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const clearCookie = (name: string, options = {}) => {
  cookies.remove(name, options);
};
