import * as S from './ContinueWatchingSkeleton.styles';
import { CardSkeleton, Skeleton } from 'skeletons';

export const ContinueWatchingSkeleton = () => (
  <S.SkeletorWrapper>
    <Skeleton height={28} width={220} mt={36} />
    <S.SkeletorSlider>
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </S.SkeletorSlider>
  </S.SkeletorWrapper>
);
