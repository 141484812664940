import React from 'react';
import PropTypes from 'prop-types';

const IconSquareCheck = React.memo(({ size, color }) => (
  <svg
    id="icon-check"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      d="M14.2222 0H1.77778C0.791111 0 0 0.8 0 1.77778V14.2222C0 15.2 0.791111 16 1.77778 16H14.2222C15.2089 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2089 0 14.2222 0ZM6.22222 12.4444L1.77778 8L3.03111 6.74667L6.22222 9.92889L12.9689 3.18222L14.2222 4.44444L6.22222 12.4444Z"
      fill={color}
    />
  </svg>
));

IconSquareCheck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconSquareCheck.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconSquareCheck;
export { IconSquareCheck };
