import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Theme
import { main } from '@themes';

const style = ({ size, color }) => css`
  .loading-squares {
    display: inline-block;
    position: relative;
    width: ${(props) => props.size};
    height: ${(props) => props.size};

    opacity: 0.8;

    div {
      display: inline-block;
      position: absolute;
      left: 0px;
      width: 6px;
      background: ${(props) => props.color};
      animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    div:nth-child(1) {
      left: 0px;
      animation-delay: -0.24s;
    }
    div:nth-child(2) {
      left: 20px;
      animation-delay: -0.12s;
    }
    div:nth-child(3) {
      left: 40px;
      animation-delay: 0;
    }
  }
  @keyframes lds {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;

const Wrapper = styled.div([style]);

const IconLoadingSquare = React.memo(({ size }) => (
  <Wrapper size={size} color={main.colorPallete.primary.default}>
    <div className="loading-squares">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Wrapper>
));

IconLoadingSquare.propTypes = {
  size: PropTypes.string,
};

IconLoadingSquare.defaultProps = {
  size: '40px',
};

export default IconLoadingSquare;
export { IconLoadingSquare };
