import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseWatchingDataProps } from './types';

export const useGetWatchingData = (
  msApiLib: MSApi,
  params?: UseWatchingDataProps,
) => {
  const { enabled, cacheTime, bearerToken } = params ?? {};

  return useQuery(
    ['getWatching'],
    async () => {
      const res = await msApiLib.v3.watching.list({
        query: { pageSize: 11 },
        bearerToken,
      });

      return res.data;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
