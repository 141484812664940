import React from 'react';
import ContentLoader from 'react-content-loader';

import { main as theme } from '@themes';

import { Wrapper } from './TagbarSkeleton.styles';

const TagbarSkeleton = () => (
  <Wrapper>
    <div className="smaller">
      <ContentLoader
        speed={1}
        width={335}
        viewBox="0 0 335 50"
        backgroundColor={theme.colorPallete.base[700]}
        foregroundColor={theme.colorPallete.base[600]}
      >
        <rect x="0" y="0" rx="8" ry="8" width="90" height="10" />
        <rect x="100" y="0" rx="8" ry="8" width="50" height="10" />
        <rect x="160" y="0" rx="8" ry="8" width="110" height="10" />
        <rect x="280" y="0" rx="8" ry="8" width="50" height="10" />

        <rect x="0" y="20" rx="8" ry="8" width="110" height="10" />
        <rect x="120" y="20" rx="8" ry="8" width="50" height="10" />
        <rect x="180" y="20" rx="8" ry="8" width="70" height="10" />
        <rect x="260" y="20" rx="8" ry="8" width="70" height="10" />

        <rect x="0" y="40" rx="8" ry="8" width="100" height="10" />
        <rect x="110" y="40" rx="8" ry="8" width="50" height="10" />
        <rect x="170" y="40" rx="8" ry="8" width="90" height="10" />
        <rect x="270" y="40" rx="8" ry="8" width="60" height="10" />
      </ContentLoader>
    </div>
    <div className="small">
      <ContentLoader
        speed={1}
        width={440}
        viewBox="0 0 440 50"
        backgroundColor={theme.colorPallete.base[700]}
        foregroundColor={theme.colorPallete.base[600]}
      >
        <rect x="0" y="0" rx="8" ry="8" width="90" height="10" />
        <rect x="100" y="0" rx="8" ry="8" width="50" height="10" />
        <rect x="160" y="0" rx="8" ry="8" width="110" height="10" />
        <rect x="280" y="0" rx="8" ry="8" width="50" height="10" />
        <rect x="340" y="0" rx="8" ry="8" width="90" height="10" />

        <rect x="0" y="20" rx="8" ry="8" width="110" height="10" />
        <rect x="120" y="20" rx="8" ry="8" width="50" height="10" />
        <rect x="180" y="20" rx="8" ry="8" width="70" height="10" />
        <rect x="260" y="20" rx="8" ry="8" width="90" height="10" />
        <rect x="360" y="20" rx="8" ry="8" width="70" height="10" />

        <rect x="0" y="40" rx="8" ry="8" width="100" height="10" />
        <rect x="110" y="40" rx="8" ry="8" width="50" height="10" />
        <rect x="170" y="40" rx="8" ry="8" width="90" height="10" />
        <rect x="270" y="40" rx="8" ry="8" width="60" height="10" />
        <rect x="340" y="40" rx="8" ry="8" width="90" height="10" />
      </ContentLoader>
    </div>
    <div className="medium">
      <ContentLoader
        speed={1}
        width={658}
        viewBox="0 0 658 66"
        backgroundColor={theme.colorPallete.base[700]}
        foregroundColor={theme.colorPallete.base[600]}
      >
        <rect x="0" y="0" rx="8" ry="8" width="90" height="14" />
        <rect x="100" y="0" rx="8" ry="8" width="50" height="14" />
        <rect x="160" y="0" rx="8" ry="8" width="110" height="14" />
        <rect x="280" y="0" rx="8" ry="8" width="50" height="14" />
        <rect x="340" y="0" rx="8" ry="8" width="90" height="14" />
        <rect x="440" y="0" rx="8" ry="8" width="50" height="14" />
        <rect x="500" y="0" rx="8" ry="8" width="90" height="14" />
        <rect x="600" y="0" rx="8" ry="8" width="50" height="14" />

        <rect x="0" y="24" rx="8" ry="8" width="110" height="14" />
        <rect x="120" y="24" rx="8" ry="8" width="50" height="14" />
        <rect x="180" y="24" rx="8" ry="8" width="70" height="14" />
        <rect x="260" y="24" rx="8" ry="8" width="90" height="14" />
        <rect x="360" y="24" rx="8" ry="8" width="110" height="14" />
        <rect x="480" y="24" rx="8" ry="8" width="70" height="14" />
        <rect x="560" y="24" rx="8" ry="8" width="90" height="14" />

        <rect x="0" y="48" rx="8" ry="8" width="100" height="14" />
        <rect x="110" y="48" rx="8" ry="8" width="50" height="14" />
        <rect x="170" y="48" rx="8" ry="8" width="90" height="14" />
        <rect x="270" y="48" rx="8" ry="8" width="60" height="14" />
        <rect x="340" y="48" rx="8" ry="8" width="90" height="14" />
        <rect x="440" y="48" rx="8" ry="8" width="90" height="14" />
        <rect x="540" y="48" rx="8" ry="8" width="50" height="14" />
        <rect x="600" y="48" rx="8" ry="8" width="50" height="14" />
      </ContentLoader>
    </div>
  </Wrapper>
);

export default TagbarSkeleton;
export { TagbarSkeleton };
