import React from 'react';

import * as S from './CardListSkeleton.styles';
import { Skeleton } from 'skeletons/Skeleton';

const CardListSkeleton = () => {
  return (
    <S.SkeletorWrapper>
      <Skeleton height={560} width={144} />
      <Skeleton height={560} width={144} />
      <Skeleton height={560} width={144} />
      <Skeleton height={560} width={144} />
      <Skeleton height={560} width={144} />
      <Skeleton height={560} width={144} />
    </S.SkeletorWrapper>
  );
};

export default CardListSkeleton;
export { CardListSkeleton };
