import React from 'react';

const IconCaretDown = React.memo(() => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 9.5L12 17L4.5 9.5"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
));

export default IconCaretDown;
export { IconCaretDown };
