import React from 'react';
import PropTypes from 'prop-types';

const IconFast = React.memo(({ size, color }) => (
  <svg
    id="icon-fast"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 3V6.54667L0 3V12.7778L6.4 9.22222V12.7778L13.7778 8.86667V12.7778H16V3H13.7778V7.4L6.4 3Z"
      fill={color}
    />
  </svg>
));

IconFast.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconFast.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconFast;
export { IconFast };
