import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { main as theme } from '@themes';

import Compress from 'compress.js';
import getOrientation from '@helpers/imageOrientation';

// Other components
import { IconAvatar, IconUpload } from '../Icons';
import { Text } from '../Text';

// Styles
import { styles } from './UploadPhoto.styles';

const Wrapper = styled.button`
  ${styles}
`;

const UploadPhoto = React.memo(({ imagePlaceholder, setAvatar }) => {
  const [placeholder, setPlaceholder] = useState('Carregar Imagem');

  const handleChangeAvatar = (e) => {
    e.preventDefault();

    const compress = new Compress();
    const files = [...e.target.files];
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    setPlaceholder(file.name);

    reader.onloadend = async () => {
      let rotation = 0;
      getOrientation(file, function (orientation) {
        rotation = orientation * -1;
      });

      compress
        .compress(files, {
          size: 9, // the max size in MB, defaults to 2MB
          quality: 0.75, // the quality of the image, max is 1,
          maxWidth: 1920, // the max width of the output image, defaults to 1920px
          maxHeight: 1920, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          // returns an array of compressed images
          setAvatar('avatar', data[0].prefix + '' + data[0].data, rotation);
        });
    };
  };

  return (
    <Wrapper type="button" imagePlaceholder={imagePlaceholder}>
      <div className="upload__avatar">
        {imagePlaceholder ? (
          <div className="upload__avatar__image" />
        ) : (
          <IconAvatar size="115px" />
        )}
      </div>
      <label className="upload__button" htmlFor="avatar">
        <input
          onChange={(e) => handleChangeAvatar(e)}
          type="file"
          name="avatar"
          id="avatar"
          accept="image/*"
        />
        <Text
          className="upload__button__text"
          dsize="1.4rem"
          size="1.4rem"
          weight={theme.fonts.fontWeight.bold}
          color={theme.colorPallete.base.default}
        >
          {placeholder}
        </Text>
        <span className="upload__button__icon">
          <IconUpload
            imagePlaceholder={imagePlaceholder}
            size="22px"
            color={theme.colorPallete.white}
          />
        </span>
      </label>
    </Wrapper>
  );
});

UploadPhoto.propTypes = {
  setAvatar: PropTypes.func,
  imagePlaceholder: PropTypes.string,
};

UploadPhoto.defaultProps = {
  setAvatar: () => '',
  imagePlaceholder: null,
};

export default UploadPhoto;
export { UploadPhoto };
