import { useState } from 'react';
import { characterLimiter } from 'helpers';
import { ImageTypes } from './types';
import { CustomTooltip, IconArrowLeft, IconMedal, IconInfo } from 'components';
import { useWindowSize } from 'helpers/useWindowSize';
import Link from 'next/link';
import * as S from './CourseNewBanner.styles';
import { useFeatureToggle } from 'hooks';

interface ContentBanner {
  images: any[];
  progress: any;
  type: string;
  description: string;
}

interface CourseNewBannerProps {
  content: ContentBanner;
  color: string;
  canCertify: boolean;
  url?: string;
  handleIssueCertificate?: () => Promise<void>;
}

const CourseNewBanner = ({
  content,
  color,
  url,
  canCertify,
  handleIssueCertificate,
}: CourseNewBannerProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { width } = useWindowSize();
  const isMobile = !!width && width < 768;
  const certificateInfoBreakpoint = !!width && width >= 1200;

  const getImagePath = (type: ImageTypes) => {
    return content.images.find((image) => image.type === type).url;
  };

  const bannerPath = isMobile
    ? `${getImagePath(ImageTypes.bannerResponsive)}`
    : `${getImagePath(ImageTypes.banner)}`;

  const CertifyButton = () => (
    <S.Button color={color} onClick={() => handleIssueCertificate()}>
      Emitir Certificado
    </S.Button>
  );

  const ProgressButton = () => {
    if (url)
      return (
        <Link href={url} passHref legacyBehavior>
          <S.Button color={color}>
            {content.progress > 0
              ? 'Continuar de onde parou'
              : 'Iniciar Programa de Estudo'}
          </S.Button>
        </Link>
      );
  };

  const GetBannerButton = () => {
    if (content?.progress >= 0.95 && canCertify) {
      return <CertifyButton />;
    }
    return <ProgressButton />;
  };

  const CertificateInfoComponent = () => {
    if (certificateInfoBreakpoint && canCertify)
      return (
        <S.CertificateInfoConatiner>
          <IconMedal size="36px" />
          <S.CertificateText>
            <p>Este Curso possui</p>
            <h4>Certificado de conclusão</h4>
          </S.CertificateText>
          <S.InfoButton
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}>
            <IconInfo color="#838383" className="icon-info" />
          </S.InfoButton>

          <CustomTooltip
            bottom={'-135px'}
            right={'-350px'}
            shouldAppear={showTooltip}>
            <S.TooltipContent>
              <h5>Requisitos para emissão de certificado do curso:</h5>
              <ul>
                <li>
                  Assistir todos os documentários e aulas de empreendedores e
                  especialistas do curso;
                </li>
              </ul>
              <p>Nem todos os cursos emitem certificado</p>
            </S.TooltipContent>
          </CustomTooltip>
        </S.CertificateInfoConatiner>
      );
  };

  const CertificateInfo = useFeatureToggle({
    ComponentWhenEnabled: () => <CertificateInfoComponent />,
    featureKeys: ['certificates'],
  });

  const BannerButton = useFeatureToggle({
    ComponentWhenEnabled: () => <GetBannerButton />,
    ComponentWhenDisabled: () => <ProgressButton />,
    featureKeys: ['certificates'],
  });

  return (
    <S.Wrapper>
      <Link href="/home" passHref legacyBehavior>
        <S.ReturnButton>
          <IconArrowLeft />
          Voltar
        </S.ReturnButton>
      </Link>
      <S.Banner url={bannerPath}>
        <S.Content className="banner-content">
          <div className="content-wrapper">
            <S.ContainerProgress color={color}>
              <S.ProgressBar>
                <S.Progress progress={Number(content.progress)} color={color} />
              </S.ProgressBar>
              <p>{`${(Number(content.progress) * 100).toFixed()}%`}</p>
            </S.ContainerProgress>

            <S.CourseType>
              {content.type === 'case' ? 'Estudo de Caso' : 'Série'}
            </S.CourseType>

            <S.TitleImage src={`${getImagePath(ImageTypes.logo)}`} />

            <S.Description textColor={content.description}>
              {characterLimiter(content.description, 184)}
            </S.Description>

            <S.ButtonContainer>
              <BannerButton />
            </S.ButtonContainer>
            <CertificateInfo />
          </div>
        </S.Content>
        <S.Content className="filler" />
      </S.Banner>
    </S.Wrapper>
  );
};

export default CourseNewBanner;
export { CourseNewBanner };
