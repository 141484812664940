import { css } from 'styled-components';

const styles = ({ theme }) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 1rem;
  padding-top: 0.1rem;
  border: 0.15rem solid ${theme.colorPallete.base[400]};
  border-radius: 3rem;

  span {
    color: ${theme.colorPallete.base[400]};
  }

  &.active {
    border-color: ${theme.colorPallete.primary.default};
    background-color: ${theme.colorPallete.primary.default};

    span {
      color: ${theme.colorSystem.white};
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export default styles;
export { styles };
