import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = css`
  position: relative;
  display: flex;
  z-index: 1;
  overflow: hidden;
  background: ${theme.colorPallete.base.default};

  /** container size, prevent glitch screen size on load */
  flex-direction: column;
  padding-bottom: 171%;

  @media all and (min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
    padding-bottom: 40.98%;
  }

  img,
  source {
    width: 100%;
    height: auto;
    display: block !important;
  }

  .poster__picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img,
    source {
      position: absolute;
      object-fit: cover;
      object-position: 0 0;
      max-width: 100%;
      width: 100%;
      height: 100%;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        object-position: 50% 50%;
      }
    }
  }

  .poster__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    width: 100%;
    height: auto;

    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    z-index: 5;

    @media all and (min-width: ${theme.breakpoints.medium}) {
      width: 52%;
      height: 100%;
      top: 0;
      bottom: auto;
      padding: unset;
    }

    &__wrap {
      display: inline-flex;
      flex-direction: column;
      position: relative;
      padding: 0 1rem 0 2rem;
      transition: all 0.2s ease-in-out;

      width: 100%;
      margin: auto;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        width: 60%;
      }
    }

    &__tag {
      position: relative;
      margin-bottom: 0.4em;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.82em;
      }
    }

    &__logo {
      margin: 0.82em 0 0;
      height: auto;
      width: auto;
      max-height: 26.9vw;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        max-height: 8.9vw;
        width: auto;
        margin-bottom: 0.32em;
      }

      @media all and (min-width: ${theme.breakpoints.big}) {
        max-height: 10.9vw;
      }

      img,
      source {
        max-width: 100%;
        height: auto;
        width: auto;
        max-height: 26.9vw;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          max-height: 8.9vw;
          width: auto;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          max-width: 100%;
          max-height: 10.9vw;
        }
      }
    }
    &__title {
      width: 100%;
      margin-bottom: 0;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.82em;
      }

      * {
        font-size: 1.4rem;
        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.2vw;
        }
      }
    }
    &__subtitle {
      width: 100%;
      margin-bottom: 0.09em;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.22em;
      }

      * {
        font-size: 1.3rem;

        line-height: 100%;
        margin-top: 0.15em;
        margin-bottom: 0.15em;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1vw;
        }
      }
    }

    &__summary {
      width: 100%;
      margin-bottom: 0.09em;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin-bottom: 0.82em;
      }

      * {
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 100%;
        margin-top: 0.15em;
        margin-bottom: 0.15em;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 0.9vw;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    &__description,
    &__details {
      width: 100%;
      margin-top: 0.2em;
      margin-bottom: 0.2em;
      padding: 1.4rem 0 1.6rem;

      @media all and (min-width: ${theme.breakpoints.medium}) {
        padding: 0.5rem 0;
        margin-top: 0.6em;
        margin-bottom: 0.6em;
      }

      @media all and (min-width: ${theme.breakpoints.big}) {
        padding: 0.9rem 0;
        margin-top: 0.8em;
        margin-bottom: 0.8em;
      }

      * {
        display: inline-block;
        font-size: 1.4rem;
        line-height: 2rem !important;

        @media all and (min-width: ${theme.breakpoints.small}) {
          font-size: 1.4rem;
          line-height: 172% !important;
        }

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.1vw;
          line-height: 160%;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          font-size: 1.1vw;
          line-height: 140%;
        }
      }
    }

    &__cta {
      margin: 0.7vw 0 1.1vw;

      @media all and (min-width: ${theme.breakpoints.big}) {
        margin: 1.1vw 0;
      }

      * {
        font-size: 1.6rem !important;
        width: auto !important;
        min-height: unset !important;
        height: unset !important;
        max-height: unset !important;
        line-height: 100%;

        @media all and (min-width: ${theme.breakpoints.medium}) {
          font-size: 1.4vw !important;
        }

        @media all and (min-width: ${theme.breakpoints.big}) {
          font-size: 1.1vw !important;
        }

        & > button,
        & > a {
          min-height: unset !important;
          height: unset !important;
          max-height: unset !important;

          width: 100% !important;
          max-width: 100% !important;

          padding: 1.6rem !important;

          transition: all 0.35s ease-in-out !important;

          @media all and (min-width: ${theme.breakpoints.medium}) {
            padding: 1vw 4.3vw !important;
          }

          @media all and (min-width: ${theme.breakpoints.big}) {
            padding: 0.8vw 4.3vw !important;
          }

          path {
            transition: all 0.4s ease-in-out !important;
          }

          &:hover {
            transition-timing-function: 0.35s !important;
            transition-property: color, background-color !important;

            path {
              fill: inherit;

              transition-timing-function: 0.4s !important;
              transition-property: color, background-color, fill !important;
            }
          }
        }
      }
    }
  }

  .poster__progress {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    bottom: 0;
    z-index: 15;
    display: none;

    @media (min-width: ${theme.breakpoints.medium}) {
      display: flex;
    }

    span {
      border-radius: 0;
      &::before {
        border-radius: 0;
      }
    }
  }

  /* fullbanner & sidebanner video */
  &.banner-full,
  &.banner-video {
    .poster__content {
      top: auto;
      bottom: 0;
      /* left: auto; */

      @media (min-width: ${theme.breakpoints.medium}) {
        left: 0;
      }
    }
  }

  &.rtl {
    .poster__content {
      left: auto;
      right: 0;
    }
  }

  &.banner-video {
    margin-top: 5rem;
  }
`;

const tagStyles = css`
  display: inline-table;
  padding: 0.55em 1em;
  border: 0.1em solid ${(props) => props.color};
  border-radius: 0.1rem;

  color: ${(props) => props.text_color || theme.colorPallete.white};
  font-family: ${theme.fonts.sans};
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;

  @media all and (min-width: ${theme.breakpoints.medium}) {
    font-size: 0.82vw;
    border: 0.1em solid ${(props) => props.color};
    padding: 0.45em 0.9em;
  }
`;

export { styles, tagStyles };
