import { Dispatch, SetStateAction } from 'react';

import { Modal } from '@containers';
import { IconWarningExclamation, IconClose } from '@components';

import * as S from './UpdateRenewalModal.styles';

interface UpdateRenewalModalProps {
  showRenewalModal: boolean;
  setShowRenewalModal: Dispatch<SetStateAction<boolean>>;
}

export const UpdateRenewalModal = ({
  showRenewalModal,
  setShowRenewalModal,
}: UpdateRenewalModalProps) => {
  const handleCloseModal = () => {
    setShowRenewalModal(false);
    sessionStorage.setItem('hasShownUpdateRenewalModal', 'true');
  };

  return (
    <Modal
      setVisibility={setShowRenewalModal}
      visible={showRenewalModal}
      onClose={() =>
        sessionStorage.setItem('hasShownUpdateRenewalModal', 'true')
      }>
      <S.Wrapper>
        <S.CloseButton onClick={handleCloseModal}>
          <IconClose size={'24px'} />
        </S.CloseButton>
        <IconWarningExclamation />
        <S.Title>Atenção!</S.Title>
        <S.Description>
          Precisamos atualizar seus dados cadastrais.
        </S.Description>
        <a href="https://wa.me/5541988014291" target="_blank">
          <S.ActionButton onClick={handleCloseModal}>Atualizar</S.ActionButton>
        </a>
      </S.Wrapper>
    </Modal>
  );
};
