import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    width: 100%;
    position: absolute;
    left: 0;
    @media (min-width: 768px) {
      position: relative;
    }
  `}
`;

export const HeadContentNotification = styled.h1`
  ${() => css`
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #868585;
  `}
`;

export const ContainerNotification = styled.div`
  ${() => css`
    display: block;
    width: 100%;
    height: 453px;
    overflow-y: auto;
    background-color: #272727;
    position: absolute;
    right: 0;
    top: 42px;
    border-radius: 0px 0px 8px 8px;
    padding: 20px;

    scrollbar-color: '#868585' '#DBDBDB';
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: ${({ theme }) => theme.colorPallete.base[100]};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colorPallete.base[300]};
      border-radius: 2.5px;
    }

    @media (min-width: 768px) {
      width: 450px;
    }
  `}
`;

export const ButtonNotification = styled.button`
  ${() => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
    background: transparent;
    color: #ffff;
    text-align: left;
    cursor: pointer;

    &.caixa-icone-notificacao {
      width: 43px;
      float: right;
      position: relative;
      background: transparent;
      border-radius: 8px 8px 0px 0px;
      justify-content: center;
      padding: 10px;

      &.visible {
        background: #272727;
      }

      @media (max-width: 768px) {
        margin-right: 75px;
        svg path {
          stroke: #fff;
        }
      }
    }

    :last-child {
      border-bottom: 0;
    }
  `}
`;

export const NotificationBlockContent = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 15px;
  `}
`;

export const ContentTextNotification = styled.div`
  ${() => css``}
`;

export const HeadNotification = styled.h2`
  ${() => css`
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
    margin-bottom: 5px;
  `}
`;

export const DescriptionNotification = styled.p`
  ${() => css`
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;
  `}
`;

export const CreatedAtNotification = styled.div`
  ${() => css`
    font-size: 12px;
    color: #868585;
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-weight: 700;
  `}
`;

export const AmountNotification = styled.span`
  ${() => css`
    background-color: #da571a;
    color: #313030;
    font-size: 13px;
    border-radius: 100%;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 16px;
    right: 6px;
    top: 6px;
    height: 16px;
  `}
`;
