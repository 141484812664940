import React from 'react';

interface Props {
  className?: string;
  color?: string;
  size?: string;
}

const IconCheckCircleSolid = React.memo(
  ({ className, color = '#fff', size = '59' }: Props) => (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.8202 6.09375C27.7004 6.09375 22.6956 7.61195 18.4386 10.4564C14.1816 13.3008 10.8637 17.3437 8.90444 22.0738C6.94518 26.8039 6.43254 32.0087 7.43137 37.0302C8.4302 42.0516 10.8956 46.6641 14.5159 50.2843C18.1361 53.9046 22.7486 56.37 27.7701 57.3688C32.7915 58.3677 37.9964 57.855 42.7265 55.8958C47.4566 53.9365 51.4994 50.6186 54.3438 46.3616C57.1883 42.1047 58.7065 37.0998 58.7065 31.98C58.6933 25.1186 55.9618 18.542 51.11 13.6902C46.2583 8.83843 39.6816 6.10691 32.8202 6.09375ZM45.1411 27.4499L30.5552 41.3887C30.1781 41.7434 29.679 41.9394 29.1613 41.9362C28.9083 41.9399 28.657 41.8933 28.422 41.7994C28.1871 41.7054 27.973 41.5658 27.7923 41.3887L20.4994 34.4193C20.2971 34.2428 20.1326 34.0272 20.0157 33.7856C19.8989 33.5439 19.8321 33.2811 19.8194 33.0129C19.8067 32.7448 19.8484 32.4768 19.9419 32.2252C20.0354 31.9736 20.1788 31.7434 20.3635 31.5487C20.5483 31.3539 20.7705 31.1984 21.0168 31.0917C21.2631 30.985 21.5284 30.9292 21.7969 30.9276C22.0653 30.926 22.3313 30.9788 22.5788 31.0826C22.8264 31.1865 23.0504 31.3393 23.2373 31.532L29.1613 37.1821L42.4031 24.5626C42.7903 24.2248 43.2931 24.0497 43.8063 24.074C44.3195 24.0983 44.8035 24.32 45.1571 24.6929C45.5106 25.0657 45.7064 25.5608 45.7034 26.0746C45.7004 26.5884 45.4989 27.0812 45.1411 27.4499Z"
        fill={color}
      />
    </svg>
  ),
);

export default IconCheckCircleSolid;
export { IconCheckCircleSolid };
