import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import {
  Avatar,
  Logo,
  IconFind,
  IconPlayHollow,
  IconHomeHollow,
  IconChalkboardTeacher,
  IconTelevisionSimple,
  IconClockClockwise,
  Submenu,
} from 'components';

import { logoutHandler } from 'utils';
import { useFeatureToggle } from 'hooks';

import { main as theme } from 'themes';

import { SearchInput } from '../../SearchInput';
import { Notifications } from '../../Notifications';

import * as S from './HeaderDesk.styles';

interface HeaderDeskProps {
  refreshToken: string;
}

const HeaderDesk = React.memo(({ refreshToken }: HeaderDeskProps) => {
  const [showSeach, setShowSearch] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const router = useRouter();
  const activeHref = router.pathname;
  const inputRef = useRef(null);
  const submenuRef = useRef(null);
  const notificationsRef = useRef(null);

  const dispatch = useDispatch();
  const logOut = () => logoutHandler(dispatch);

  const handleClickOutside = (e: any) => {
    if (
      submenuRef &&
      submenuRef.current &&
      !submenuRef.current.contains(e.target)
    ) {
      setShowSubmenu(false);
    }
    if (
      notificationsRef &&
      notificationsRef.current &&
      !notificationsRef.current.contains(e.target)
    ) {
      setShowNotifications(false);
    }
  };

  const handleOnClickMenu = () => {
    setShowSubmenu((prevState) => !prevState);
  };

  const handleOnClickNotifications = () => {
    setShowNotifications((prevState) => !prevState);
  };

  const SubmenuWithCertificates = () => (
    <Submenu
      isVisible={showSubmenu}
      position={{ bottom: '-175px', right: '16px' }}
      width="262px"
      options={[
        {
          item: 'Perfil',
          action: () => {
            router.push('/perfil');
          },
        },
        {
          item: 'Meus Certificados',
          action: () => {
            router.push('/certificados');
          },
        },
        { item: 'Ajuda', href: 'https://meusucesso.com/faq/' },
        { item: 'Sair', action: logOut },
      ]}
      subMenuRef={submenuRef}
      showSocial
      onClick={handleOnClickMenu}
    />
  );

  const SubmenuWithoutCertificates = () => (
    <Submenu
      isVisible={showSubmenu}
      position={{ bottom: '-139px', right: '16px' }}
      width="262px"
      options={[
        {
          item: 'Perfil',
          action: () => {
            router.push('/perfil');
          },
        },
        { item: 'Ajuda', href: 'https://meusucesso.com/faq/' },
        { item: 'Sair', action: logOut },
      ]}
      subMenuRef={submenuRef}
      showSocial
      onClick={handleOnClickMenu}
    />
  );

  const SubmenuToggle = useFeatureToggle({
    ComponentWhenEnabled: () => <SubmenuWithCertificates />,
    ComponentWhenDisabled: () => <SubmenuWithoutCertificates />,
    featureKeys: ['certificates'],
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <S.Navbar>
      <Link href="/home" className="ms-logo" id="header-logo">
        <Logo color={theme.colorPallete.primary.default} />
      </Link>
      <S.MenuList>
        <S.MenuItem
          className={
            activeHref === '/home' ? 'menu-item__active' : 'menu-item'
          }>
          <Link href="/home">
            <IconHomeHollow />
            Início
          </Link>
        </S.MenuItem>
        <S.MenuItem
          className={
            activeHref === '/estudos-de-caso'
              ? 'menu-item__active'
              : 'menu-item'
          }>
          <Link href="/estudos-de-caso">
            <IconChalkboardTeacher />
            Estudos de Caso
          </Link>
        </S.MenuItem>
        <S.MenuItem
          className={
            activeHref === '/series' ? 'menu-item__active' : 'menu-item'
          }>
          <Link href="/series">
            <IconTelevisionSimple />
            Séries
          </Link>
        </S.MenuItem>
        <S.MenuItem
          className={
            activeHref === '/assistidos' ? 'menu-item__active' : 'menu-item'
          }>
          <Link href="/assistidos">
            <IconClockClockwise />
            Assistidos
          </Link>
        </S.MenuItem>
        <S.MenuItem
          className={
            activeHref === '/continue-assistindo'
              ? 'menu-item__active'
              : 'menu-item'
          }>
          <Link href="/continue-assistindo">
            <IconPlayHollow />
            Continue estudando
          </Link>
        </S.MenuItem>
      </S.MenuList>

      <S.NavbarRight>
        <S.SearchButton
          type="button"
          onClick={() => {
            setShowSearch(true);
            inputRef.current.focus();
          }}>
          <IconFind size="24px" color={theme.colorPallete.base[320]} />
        </S.SearchButton>
        <Notifications
          notificationsRef={notificationsRef}
          isVisible={showNotifications}
          onClick={handleOnClickNotifications}
        />
        <Avatar onClick={handleOnClickMenu} />
        <SubmenuToggle />
        <SearchInput
          searchState={showSeach}
          hideSearch={setShowSearch}
          refreshToken={refreshToken}
          reference={inputRef}
        />
      </S.NavbarRight>
    </S.Navbar>
  );
});

export default HeaderDesk;
export { HeaderDesk };
