import styled, { css } from 'styled-components';

interface ButtonProps {
  courseColor: string;
}

export const Button = styled.a<ButtonProps>`
  ${({ theme, courseColor }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8px;
    height: 44px;
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid #ffffff1a;
    background-color: #ffffff1a;
    color: ${theme.colorPallete.white};

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: 14px;
    font-weight: ${theme.fonts.fontWeight.bold};

    @media (min-width: 768px) {
      min-width: 185px;
    }

    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    cursor: pointer;

    &.highlight-button {
      border: 2px solid ${courseColor};
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 2px 2px 30px 0px ${courseColor} inset;

      @media (max-width: 768px) {
        min-width: 217px;
      }
    }

    &:last-child {
      margin-right: 22px;
    }
  `}
`;
