import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100%;
`;

const CarouselWrapper = styled.div`
  padding-right: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > * {
    display: block;
    margin: 0 0 0 2rem;
    user-select: none;
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.big}) {
    flex-direction: column;

    cursor: unset;

    &:active {
      cursor: unset;
    }

    & > * {
      user-select: unset;
    }
  }
`;

export { MainContainer, CarouselWrapper };
