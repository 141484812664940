import React from 'react';
import PropTypes from 'prop-types';

const IconInstagramOutline = React.memo(({ size, color }) => (
  <svg
    id="icon-facebook"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      d="M12.9985 7.5C15.957 7.5 18.3735 9.95266 18.3735 13H19.3735C19.3735 9.42034 16.5291 6.5 12.9985 6.5V7.5ZM18.3735 13C18.3735 16.0478 15.9575 18.5 12.9985 18.5V19.5C16.5296 19.5 19.3735 16.5802 19.3735 13H18.3735ZM12.9985 18.5C10.0401 18.5 7.62354 16.0473 7.62354 13H6.62354C6.62354 16.5797 9.46802 19.5 12.9985 19.5V18.5ZM7.62354 13C7.62354 9.95266 10.0401 7.5 12.9985 7.5V6.5C9.46802 6.5 6.62354 9.42034 6.62354 13H7.62354ZM20 5.5C20.5249 5.5 20.9685 5.93761 20.9685 6.5H21.9685C21.9685 5.40539 21.0971 4.5 20 4.5V5.5ZM20.9685 6.5C20.9685 7.06239 20.5249 7.5 20 7.5V8.5C21.0971 8.5 21.9685 7.59461 21.9685 6.5H20.9685ZM20 7.5C19.4745 7.5 19.031 7.06228 19.031 6.5H18.031C18.031 7.59472 18.9025 8.5 20 8.5V7.5ZM19.031 6.5C19.031 5.93772 19.4745 5.5 20 5.5V4.5C18.9025 4.5 18.031 5.40528 18.031 6.5H19.031ZM7.326 25.5H18.674V24.5H7.326V25.5ZM18.674 25.5C22.4395 25.5 25.5 22.3592 25.5 18.5395H24.5C24.5 21.8268 21.8675 24.5 18.674 24.5V25.5ZM25.5 18.5395V7.4605H24.5V18.5395H25.5ZM25.5 7.4605C25.5 3.64083 22.4395 0.5 18.674 0.5V1.5C21.8675 1.5 24.5 4.17317 24.5 7.4605H25.5ZM18.674 0.5H7.326V1.5H18.674V0.5ZM7.326 0.5C3.56049 0.5 0.5 3.64083 0.5 7.4605H1.5C1.5 4.17317 4.13251 1.5 7.326 1.5V0.5ZM0.5 7.4605V18.5395H1.5V7.4605H0.5ZM0.5 18.5395C0.5 22.3592 3.56049 25.5 7.326 25.5V24.5C4.13251 24.5 1.5 21.8268 1.5 18.5395H0.5Z"
      fill={color}
    />
  </svg>
));

IconInstagramOutline.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconInstagramOutline.defaultProps = {
  color: '#F16521',
  size: '26px',
};

export default IconInstagramOutline;
export { IconInstagramOutline };
