import React from 'react';
import PropTypes from 'prop-types';

// Themes
import { main } from '@themes';

// Helpers
import { getInitialOfString } from '@helpers';

import { AvatarWrapper } from './ReactAvatar.styles';

const ReactAvatar = ({ bgColor, className, fontColor, name, round, size }) => {
  return (
    <AvatarWrapper
      bgColor={bgColor}
      className={className}
      fontColor={fontColor}
      round={round}
      size={size}
    >
      <span>{getInitialOfString(name)}</span>
    </AvatarWrapper>
  );
};

ReactAvatar.propTypes = {
  className: PropTypes.string,
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  round: PropTypes.bool,
};

ReactAvatar.defaultProps = {
  className: '',
  bgColor: main.colorSystem.primaryColor,
  fontColor: main.colorSystem.white,
  size: '16',
  round: true,
};

export { ReactAvatar };
export default ReactAvatar;
