const orderArrayByOrder = (array, desc = false) => {
  if (array && array.length > 0) {
    const compare = (a, b) => {
      const orderA = a.order;
      const orderB = b.order;

      let comparison = 0;

      if (orderA > orderB) {
        comparison = desc ? -1 : 1;
      }
      if (orderA < orderB) {
        comparison = desc ? 1 : -1;
      }

      return comparison;
    };

    return array.sort(compare);
  }

  return array;
};

/**
 * Return the of objetc that contain the property 'correct-option' (right alternative):
 * @param {array} array
 */
const getKeyOfRightAlternative = (array) => {
  return array
    .map((item, index) => item.correct_option === 'true' && index)
    .filter((item) => typeof item === 'number')[0];
};

const ramdomizeArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;
  const newArray = array;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = newArray[currentIndex];
    newArray[currentIndex] = newArray[randomIndex];
    newArray[randomIndex] = temporaryValue;
  }

  return newArray;
};

/*
 * Get cookies from server side and polish only auth props:
 * @param {array} array
 */
const polishAuthObject = (array) => {
  const newArray = array
    .split(';')
    .filter((item) => item.search('token') !== -1 && item)
    .map((item) =>
      item
        .substr(item.search('token=s%3A'), item.length)
        .replace('token=s%3A', '')
    );

  return {
    refreshToken: newArray[0],
    token: newArray[1],
  };
};

const updateArrayNewProgress = (array, object) => {
  const index = array.findIndex(
    (item) => item.slug_lesson === object.slug_lesson
  );

  if (index !== -1) {
    array[index].start_at = object.start_at;
    array[index].duration = object.duration;
  } else {
    array.push(object);
  }

  return array;
};

export {
  orderArrayByOrder,
  polishAuthObject,
  getKeyOfRightAlternative,
  ramdomizeArray,
  updateArrayNewProgress,
};
