import React from 'react';
import PropTypes from 'prop-types';

const IconThinLock = React.memo(({ size, color }) => (
  <svg
    id="icon-thin-lock"
    viewBox="0 0 13 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16667 4.83857C2.16667 2.55387 4.11667 0.68457 6.5 0.68457C8.88333 0.68457 10.8333 2.55387 10.8333 4.83857V8.30024C12.0611 8.30024 13 9.20027 13 10.3772V16.6082C13 17.7852 12.0611 18.6852 10.8333 18.6852H2.16667C0.938889 18.6852 0 17.7852 0 16.6082V10.3772C0 9.20027 0.938889 8.30024 2.16667 8.30024V4.83857ZM2.16671 17.3008H10.8334C11.2667 17.3008 11.5556 17.0238 11.5556 16.6084V10.3774C11.5556 9.96199 11.2667 9.68506 10.8334 9.68506H10.1112H2.88893H2.16671C1.73338 9.68506 1.44449 9.96199 1.44449 10.3774V16.6084C1.44449 17.0238 1.73338 17.3008 2.16671 17.3008ZM3.61121 8.30033V4.83867C3.61121 3.31554 4.91121 2.06934 6.5001 2.06934C8.08898 2.06934 9.38899 3.31554 9.38899 4.83867V8.30033H3.61121ZM5.7779 11.0696H7.22235C7.65568 11.0696 7.94457 11.3465 7.94457 11.7619C7.94457 12.1773 7.65568 12.4542 7.22235 12.4542V15.2236C7.22235 15.639 6.93346 15.9159 6.50012 15.9159C6.06679 15.9159 5.7779 15.639 5.7779 15.2236V12.4542C5.34457 12.4542 5.05568 12.1773 5.05568 11.7619C5.05568 11.3465 5.34457 11.0696 5.7779 11.0696Z"
      fill={color}
    />
  </svg>
));

IconThinLock.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconThinLock.defaultProps = {
  color: '#565555',
  size: '19px',
};

export default IconThinLock;
export { IconThinLock };
