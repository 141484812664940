import { Skeleton } from '@skeletons/Skeleton';
import * as S from './BannerCourseSkeleton.styles';

const BannerCourseSkeleton = () => {
  return (
    <S.Wrapper>
      <S.LinkContainer>
        <Skeleton className="back-button" />
      </S.LinkContainer>

      <S.Content>
        <S.TextBannerContainer>
          <Skeleton className="title" />
          <Skeleton className="description" />
          <Skeleton className="progress" />
          <Skeleton className="button" />
        </S.TextBannerContainer>
        <S.ImageContainer>
          <Skeleton />
        </S.ImageContainer>
      </S.Content>
    </S.Wrapper>
  );
};

export default BannerCourseSkeleton;
export { BannerCourseSkeleton };
