/* eslint-disable import/no-cycle */
import Router from 'next/router';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';

// Utils
// eslint-disable-next-line import/no-cycle
import { refreshTokenFlow } from '@utils';

// Helpers
import { authRoute } from '@helpers';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Mirror-api
import {
  authApi as mirrorAuthApi,
  firstAccessApi as mirrorFirstAccessApi,
  passwordApi as mirrorPasswordApi,
  paymentsApi as mirrorPaymentsApi,
  profileApi as mirrorProfileApi,
} from '@services/mirror-api';

// MSV2-api
import {
  firstAccessApi as msv2FirstAccessApi,
  passwordApi as msv2PasswordApi,
  paymentsApi as msv2PaymentsApi,
  profileApi as msv2ProfileApi,
} from '@services/msv2-api';

import * as types from './types';
import { isValidRedirectUrl } from '@helpers/isValidUrlRedirect';

/**
 * ---------------------------------------------------
 * AUTH
 * ---------------------------------------------------
 */
export function* login({ payload }) {
  try {
    const response = yield call(mirrorAuthApi.login, {
      data: payload.data,
    });
    if (response.status === 200) {
      yield put(userActions.loginSuccess());
      yield put(
        userActions.loginSuccessDataLayer({
          dataLayerRed: {
            userId: response?.data?.content?.instance?.user_id,
            method: 'email',
          },
        }),
      );

      const redirectUrl = new URLSearchParams(window.location.search).get('redirect');
      if (redirectUrl && isValidRedirectUrl(redirectUrl)) {
        window.location.href = redirectUrl;
      } else {
        Router.push('/home');
      }
    }
  } catch (error) {
    if (
      error.response.data.error.error_content.name === 'AccessDeniedBilling' &&
      error.response.data.error.error_content.content?.status
    ) {
      const token = Math.floor(Date.now() * Math.random()).toString(36);
      Cookies.set('MSJWT', token, {
        domain: '.meusucesso.com',
        expires: 1 / 24,
      });
      window.open(
        error.response.data.error.error_content.content?.url,
        '_self',
      );
    } else {
      yield put(
        userActions.loginFailure({
          toast: {
            type: 'error',
            message: error.response.data.error.message,
          },
        }),
      );
    }
  }
}

export function* logout() {
  Router.push('/login');

  try {
    const response = yield call(mirrorAuthApi.logout);
    if (response.status === 200) {
      yield put(userActions.logoutSuccess());
    }
  } catch (error) {
    yield put(userActions.logoutFailure());
  }
  Router.push('/login');
}

/**
 * ---------------------------------------------------
 * FIRST-ACCESS
 * ---------------------------------------------------
 */
export function* getFirstAccess({ payload }) {
  try {
    const response = yield call(msv2FirstAccessApi.validateFaToken, {
      method: 'GET',
      params: { fa_token: payload.params.token },
    });
    if (response.status === 202) {
      yield put(
        userActions.getFirstAccessSuccess({
          payload: {
            firstAccessData: {
              ...response.data.content.instance,
              fa_source_initial: payload.params.source,
            },
          },
        }),
      );
    }
  } catch (error) {
    // console.log(error.response);
    if (error && error.response) {
      yield put(userActions.getFirstAccessFailure());
    }
  }
}

export function* sendFirstAccess({ payload }) {
  const firstAccessData = yield select(
    (state) => state.userReducer.firstAccessData,
  );

  let endpoint = null;

  if (firstAccessData.fa_step === 'STEP_1') {
    endpoint = 'step1';
  } else if (firstAccessData.fa_step === 'STEP_2') {
    endpoint = 'step2';
  } else if (firstAccessData.fa_step === 'STEP_3') {
    endpoint = 'step3';
  }

  try {
    const response = yield call(mirrorFirstAccessApi[endpoint], {
      method: 'PATCH',
      data: payload.data,
    });

    let dataSagas = null;

    if (firstAccessData.fa_step === 'STEP_1') {
      dataSagas = response.data.content.instance;
    } else if (firstAccessData.fa_step === 'STEP_2') {
      dataSagas = response.data.content.instance.user;
    } else if (firstAccessData.fa_step === 'STEP_3') {
      dataSagas = response.data.content.instance.user;
    }

    if (response.status === 200) {
      yield put(
        userActions.sendFirstAccessSuccess({
          payload: { firstAccessData: dataSagas },
        }),
      );
      if (firstAccessData.fa_step === 'STEP_2') {
        Router.push('/home');
      }
      //TODO: Ativar quando o certificado for ativado;
      // if (firstAccessData.fa_step === 'STEP_3') {
      //   Router.push('/home');
      // }
    }
  } catch (error) {
    yield put(userActions.sendFirstAccessFailure());
  }
}

/**
 * ---------------------------------------------------
 * PASSWORD
 * ---------------------------------------------------
 */
export function* forgotPasswordEmail({ payload }) {
  try {
    const response = yield call(mirrorPasswordApi.forgot, {
      data: payload.data,
    });
    if (response.status === 200) {
      yield put(userActions.forgotPasswordEmailSuccess());
    }
  } catch (error) {
    yield put(userActions.forgotPasswordEmailFailure());
  }
  Router.push('/esqueci-minha-senha/sucesso');
}

export function* validatePasswordToken({ payload }) {
  const {
    params: { token },
  } = payload;

  try {
    const response = yield call(mirrorPasswordApi.validatePasswordToken, {
      params: { token },
    });
    if (response.status === 200) {
      yield put(userActions.validatePasswordSuccess());
    }
  } catch (error) {
    yield put(userActions.validatePasswordFailure());
  }
}

export function* resetPassword({ payload }) {
  try {
    const response = yield call(mirrorPasswordApi.reset, {
      data: payload.data,
    });
    if (response.status === 200) {
      yield put(
        userActions.resetPasswordRequestSuccess({
          toast: {
            type: 'success',
            message: response.data.content.message,
          },
        }),
      );
      Router.push('/esqueci-minha-senha/email-enviado');
    }
  } catch (error) {
    yield put(
      userActions.resetPasswordRequestFailure({
        toast: {
          type: 'error',
          message: error.response.data.error.message,
        },
      }),
    );
  }
}

export function* forgotNewPassword({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorPasswordApi.newPassword, {
      method: 'PUT',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(
        userActions.forgotNewPasswordSuccess({
          payload: { profileData: response.data.content.instance },
          toast: {
            type: 'success',
            message: response.data.content.message,
          },
        }),
      );
      yield put(userActions.setEditModal({ payload: { editModal: false } }));
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2PasswordApi.newPassword,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.forgotNewPasswordSuccess({
            toast: {
              type: 'success',
              message: responseReq.data,
            },
          }),
        );
        yield put(userActions.setEditModal({ payload: { editModal: false } }));
      }
    } else if (error.response) {
      yield put(
        userActions.forgotNewPasswordFailure({
          toast: {
            type: 'error',
            message: error.response.data.error.message,
          },
        }),
      );
    }
  }
}

/**
 * ---------------------------------------------------
 * PROFILE
 * ---------------------------------------------------
 */
export function* getUserData({ payload }) {
  const { auth, isServer, keepMeLogged } = payload;

  const request = isServer ? msv2ProfileApi : mirrorProfileApi;
  const token = isServer ? auth.token : null;
  const refreshToken = isServer ? auth.refreshToken : null;

  try {
    const response = yield call(request.me, {
      method: 'GET',
      token,
      refreshToken,
    });

    if (response.status === 200) {
      yield put(
        userActions.getUserDataSuccess({
          payload: {
            userData: response.data.content.instance,
          },
        }),
      );
    }
  } catch (error) {
    if (error.response && error.response.status === 401 && auth) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.me,
        auth?.res || null,
        auth.refreshToken,
        { query: payload.query }, // payload
        isServer,
        keepMeLogged, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.getUserDataSuccess({
            payload: {
              userData: responseReq.data,
            },
          }),
        );
      } else {
        yield put(userActions.getUserDataFailure());
        if (!isServer && authRoute(payload.query)) {
          localStorage.setItem('route', Router.asPath);
          Router.push('/login');
        }
      }
    } else {
      yield put(userActions.getUserDataFailure());
    }
  }
}

export function* editPersonalData({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorProfileApi.personalData, {
      method: 'PATCH',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(
        userActions.editPersonalDataSuccess({
          payload: { profileData: response.data.content.instance },
          toast: {
            type: 'success',
            message: response.data.content.message,
          },
        }),
      );
      yield put(userActions.setEditModal({ payload: { editModal: false } }));
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.personalData,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.editPersonalDataSuccess({
            payload: { profileData: responseReq.data },
            toast: {
              type: 'success',
              message: responseReq.data,
            },
          }),
        );
        yield put(userActions.setEditModal({ payload: { editModal: false } }));
      }
    } else if (error.response) {
      yield put(
        userActions.editPersonalDataFailure({
          toast: {
            type: 'error',
            message:
              error?.response?.data?.error?.message || error?.response?.data,
          },
        }),
      );
    }
  }
}

export function* editAddressData({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorProfileApi.addressData, {
      method: 'PATCH',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(
        userActions.editAddressDataSuccess({
          payload: { profileData: response.data.content.instance },
          toast: {
            type: 'success',
            message: response.data.content.message,
          },
        }),
      );
      yield put(userActions.setEditModal({ payload: { editModal: false } }));
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.addressData,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.editAddressDataSuccess({
            payload: { profileData: responseReq.data },
            toast: {
              type: 'success',
              message: responseReq.data,
            },
          }),
        );
        yield put(userActions.setEditModal({ payload: { editModal: false } }));
      }
    } else if (error.response) {
      yield put(
        userActions.editAddressDataFailure({
          toast: {
            type: 'error',
            message: error.response.data.error.message,
          },
        }),
      );
    }
  }
}

export function* editFinancialData({ payload }) {
  const { auth } = payload;
  let type = 'success';

  try {
    const response = yield call(mirrorPaymentsApi.newCreditCard, {
      method: 'POST',
      data: payload.data,
    });

    if (response.status === 200) {
      if (response.data.content.instance.type) {
        type = response.data.content.instance.type;
      }

      yield put(
        userActions.editFinancialDataSuccess({
          payload: {
            paymentMethodData: response.data.content.instance.payment_method[0],
          },
          toast: {
            type,
            message: response.data.content.message,
          },
        }),
      );
      yield put(userActions.setEditModal({ payload: { editModal: false } }));
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2PaymentsApi.newCreditCard,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        if (response.data.instance.type) {
          type = response.data.instance.type;
        }

        yield put(
          userActions.editFinancialDataSuccess({
            payload: { paymentMethodData: responseReq.data.payment_method[0] },
            toast: {
              type,
              message: responseReq.message,
            },
          }),
        );
        yield put(userActions.setEditModal({ payload: { editModal: false } }));
      }
    } else if (error.response) {
      yield put(
        userActions.editFinancialDataFailure({
          toast: {
            type: 'error',
            message: error.response.data.error.message,
          },
        }),
      );
    }
  }
}

export function* linkToSocial({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorProfileApi.linkToSocial, {
      method: 'POST',
      data: payload.data,
    });
    if (response.status === 200) {
      yield put(
        userActions.linkToSocialSuccess({
          payload: { providerData: response.data.content.instance },
        }),
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.linkToSocial,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.linkToSocialSuccess({
            payload: { providerData: responseReq.data },
          }),
        );
      }
    } else {
      yield put(userActions.linkToSocialFailure());
    }
  }
}

export function* socialLogin({ payload }) {
  try {
    const response = yield call(mirrorAuthApi.socialLogin, {
      method: 'POST',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(userActions.socialLoginSuccess());

      if (payload.data.provider === 'google') {
        yield put(
          userActions.loginSuccessDataLayer({
            dataLayerRed: {
              userId: response?.data?.content?.instance?.user_id,
              method: 'google',
            },
          }),
        );
      } else if (payload.data.provider === 'facebook') {
        yield put(
          userActions.loginSuccessDataLayer({
            dataLayerRed: {
              userId: response?.data?.content?.instance?.user_id,
              method: 'facebook',
            },
          }),
        );
      } else {
        yield put(
          userActions.loginSuccessDataLayer({
            dataLayerRed: {
              userId: response?.data?.content?.instance?.user_id,
              method: 'email',
            },
          }),
        );
      }

      Router.push('/home');
    }
  } catch (error) {
    yield put(
      userActions.socialLoginFailure({
        toast: {
          type: 'error',
          message: error.response.data.error.message,
        },
      }),
    );
  }
}

export function* editNewsLetter({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorProfileApi.newsletter, {
      method: 'PATCH',
      data: payload.data,
    });
    if (response.status === 200) {
      yield put(
        userActions.editNewsletterSuccess({
          payload: { profileData: response.data.content.instance },
        }),
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.newsletter,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.editPersonalDataSuccess({
            payload: { profileData: responseReq.data },
          }),
        );
      }
    } else {
      yield put(userActions.editNewsletterFailure());
    }
  }
}

export function* getProfileData({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(msv2ProfileApi.profile, {
      method: 'GET',
      token: auth.token,
    });

    if (response.status === 200) {
      yield put(
        userActions.getProfileDataSuccess({
          payload: {
            profileData: response.data.content.instance,
          },
        }),
      );
    }
  } catch (error) {
    if (error && error.response) {
      yield put(userActions.getProfileDataFailure());
    }
  }
}

/**
 * ---------------------------------------------------
 * ZERO LESSON
 * ---------------------------------------------------
 */
export function* updateZeroLesson({ payload }) {
  const { auth } = payload;

  try {
    const response = yield call(mirrorProfileApi.updateZeroLesson, {
      method: 'PATCH',
      data: payload.data,
    });

    if (response.status === 200) {
      yield put(
        userActions.updateZeroLessonSuccess({
          payload: { userData: response.data.content.instance },
        }),
      );
    }
  } catch (error) {
    if (error.response.status === 401) {
      const responseReq = yield call(
        refreshTokenFlow,
        msv2ProfileApi.updateZeroLesson,
        null, // res
        auth.refresh_token,
        { refresh_token: auth.refresh_token, data: payload.data }, // payload
        false, // isServer
        false, // keepMeLogged - serverOnly
      );

      if (responseReq.status === 200) {
        yield put(
          userActions.updateZeroLessonSuccess({
            payload: { userData: responseReq.data },
          }),
        );
      }
    } else if (error.response) {
      yield put(userActions.updateZeroLessonFailure());
    }
  }
}

export function* watchUser() {
  yield takeEvery(types.LOGIN_REQUEST, login);
  yield takeEvery(types.LOGOUT_REQUEST, logout);
  yield takeEvery(types.GET_FIRST_ACCESS_REQUEST, getFirstAccess);
  yield takeEvery(types.SEND_FIRST_ACCESS_REQUEST, sendFirstAccess);
  yield takeEvery(types.FORGOT_PASSWORD_EMAIL_REQUEST, forgotPasswordEmail);
  yield takeEvery(types.VALIDATE_PASSWORD_TOKEN_REQUEST, validatePasswordToken);
  yield takeEvery(types.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeEvery(types.FORGOT_NEW_PASSWORD_REQUEST, forgotNewPassword);
  yield takeEvery(types.GET_USER_DATA_REQUEST, getUserData);
  yield takeEvery(types.EDIT_PERSONAL_DATA_REQUEST, editPersonalData);
  yield takeEvery(types.EDIT_ADDRESS_DATA_REQUEST, editAddressData);
  yield takeEvery(types.EDIT_FINANCIAL_DATA_REQUEST, editFinancialData);
  yield takeEvery(types.LINK_TO_SOCIAL_REQUEST, linkToSocial);
  yield takeEvery(types.SOCIAL_LOGIN_REQUEST, socialLogin);
  yield takeEvery(types.EDIT_NEWSLETTER_REQUEST, editNewsLetter);
  yield takeEvery(types.GET_PROFILE_DATA_REQUEST, getProfileData);
  yield takeEvery(types.UPDATE_ZEROLESSON_REQUEST, updateZeroLesson);
}

export const sagas = [watchUser];
