import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { main as theme } from '@themes';

// Components
import { Button, Text } from '@components';

// Styles
import { styles } from './UpdateUserModal.styles';

const Wrapper = styled.div`
  ${styles}
`;

const UpdateUserModal = React.memo(({ visible, title, message }) => {
  const modclass = classNames({
    visible: visible === true,
  });

  return (
    <Wrapper className={modclass}>
      <div className="update__box">
        <Text
          dsize="3.2rem"
          size="2rem"
          color={theme.colorPallete.primary.default}
          weight={theme.fonts.fontWeight.bold}
        >
          {title}
        </Text>
        <Text
          dsize="1.6rem"
          size="1.4rem"
          color={theme.colorPallete.base[500]}
          weight={theme.fonts.fontWeight.bold}
        >
          {message}
        </Text>
        <div className="button">
          <Button
            variant="primary"
            link="internal"
            href="/perfil"
            size="medium"
          >
            Atualizar
          </Button>
        </div>
      </div>
    </Wrapper>
  );
});

UpdateUserModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

UpdateUserModal.defaultProps = {
  visible: false,
  title: 'Atualize os seus dados',
  message: 'Por favor, atualize seus dados cadastrais',
};

export default UpdateUserModal;
export { UpdateUserModal };
