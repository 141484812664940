import { callApiBase } from './base';

const progressApiMirrorV2 = {
    sendProgress: ({ data, content }) => {
        callApiBase({
            title: 'Video - sendProgressV2',
            endpoint: `/user/progress/${content.slug}`,
            method: 'POST',
            data,
        })
    }

};

export default progressApiMirrorV2;
export { progressApiMirrorV2 };
