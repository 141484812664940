import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseBannersDataProps } from './types';

export const useGetBannersData = (
  msApiLib: MSApi,
  params?: UseBannersDataProps,
) => {
  const { enabled, cacheTime, bearerToken } = params ?? {};

  return useQuery(
    ['getBanners'],
    async () => {
      const res = await msApiLib.v3.banners.list({
        bearerToken,
      });

      return res.data.banners;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
