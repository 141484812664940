import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: 24px;
  gap: 24px !important;

  .slick-track {
    margin: 0 -16px;
  }
  .slick-slide {
    .card-container {
      padding-inline: 16px;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-arrow {
    top: 50%;
    width: 5%;
    height: 100%;

    &::before {
      display: none !important;
    }
    &.slick-disabled {
      display: none !important;
    }

    &.slick-next {
      right: calc(-1% -24px);
      background-image: linear-gradient(to right, transparent, #000000);
      svg {
        transform: rotate(180deg);
      }
    }
    &.slick-prev {
      left: calc(-1% -24px);
      background-image: linear-gradient(to left, transparent, #000000);
    }
  }
`;

export const SliderContainer = styled.div`
  position: relative;
`;

export const HeaderSliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: 2.2rem;
  `}
`;

export const SeeMore = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: 1.6rem;
    cursor: pointer;
  `}
`;

export const Subtitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.regular};
    font-size: 1.4rem;
    span {
      font-weight: ${theme.fonts.fontWeight.bold};
      color: ${theme.colorPallete.primary[200]};
    }
  `}
`;

export const NavigationArrow = styled.button`
  width: 30px;
  z-index: 3;
  cursor: pointer;

  @media (min-width: 768px) {
    height: 100%;
  }
`;
