import React from 'react';

interface Props {
  size: string;
  color: string;
}

const IconFind = React.memo(({ size = '16px', color = '#ffffff' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7024 18.4521C14.9826 18.4521 18.4524 14.9824 18.4524 10.7021C18.4524 6.42194 14.9826 2.95215 10.7024 2.95215C6.42219 2.95215 2.95239 6.42194 2.95239 10.7021C2.95239 14.9824 6.42219 18.4521 10.7024 18.4521Z"
      stroke={color}
      strokeWidth="1.47619"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1826 16.1826L20.6665 20.6665"
      stroke={color}
      strokeWidth="1.47619"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconFind;
export { IconFind };
