import React from 'react';

interface IconPlusCircleProps {
  color?: string;
}

const IconPlusCircle = React.memo(
  ({ color = '#7F7F7F' }: IconPlusCircleProps) => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4287 20.6664C17.3203 20.6664 21.2858 16.701 21.2858 11.8093C21.2858 6.91763 17.3203 2.95215 12.4287 2.95215C7.53701 2.95215 3.57153 6.91763 3.57153 11.8093C3.57153 16.701 7.53701 20.6664 12.4287 20.6664Z"
        stroke={color}
        strokeWidth="1.47619"
        strokeMiterlimit="10"
      />
      <path
        d="M8.73816 11.8096H16.1191"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4287 8.11914V15.5001"
        stroke={color}
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconPlusCircle;
export { IconPlusCircle };
