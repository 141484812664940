import { put, takeEvery, call } from 'redux-saga/effects';

// Services
import { ibgeLocalesApi } from '@services/ibge-api';

import json from './states.json';

import * as actions from './actions';
import * as types from './types';

export function* getBrStates() {
  try {
    const response = yield call(ibgeLocalesApi.getStates);
    if (response.status === 200) {
      yield put(
        actions.getBrStatesSuccess({
          statesData: response.data,
        })
      );
    }
  } catch (error) {
    if (json) {
      yield put(
        actions.getBrStatesSuccess({
          statesData: json,
        })
      );
    }
    if (error && error.response) {
      yield put(actions.getBrStatesFailure());
    }
  }
}
export function* getBrStatesMock() {
  if (json) {
    yield put(
      actions.getBrStatesSuccess({
        statesData: json,
      })
    );
  } else {
    yield call(getBrStates);
  }
}

export function* getBrCities({ payload }) {
  try {
    const response = yield call(ibgeLocalesApi.getCities, {
      params: { uf: payload.params.uf },
    });
    if (response.status === 200) {
      yield put(
        actions.getBrCitiesSuccess({
          citiesData: response.data,
        })
      );
    }
  } catch (error) {
    if (error && error.response) {
      yield put(actions.getBrCitiesFailure());
    }
  }
}

export function* watchPlace() {
  yield takeEvery(types.GET_BR_STATES_REQUEST, getBrStates);
  yield takeEvery(types.GET_BR_STATES_MOCK, getBrStatesMock);
  yield takeEvery(types.GET_BR_CITIES_REQUEST, getBrCities);
}

export const sagas = [watchPlace];
