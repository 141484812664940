import { useSelector } from 'react-redux';

import { ReactAvatar } from 'components';
import { Skeleton } from 'skeletons/Skeleton';

import * as S from './Avatar.styles';

interface AvatarProps {
  onClick?: () => void;
  showName?: boolean;
}

const Avatar = ({ showName, onClick }: AvatarProps) => {
  const userData = useSelector((state: any) => state.userReducer.userData);

  return (
    <S.AvatarWrapper onClick={onClick} cursor={onClick ? 'pointer' : 'default'}>
      {userData?.name ? (
        <>
          {showName && <p>{userData?.name || ''}</p>}
          <div className="menuside__link__avatar">
            {userData && userData.avatar ? (
              <div>
                <img
                  src={`${userData.avatar}`}
                  alt={userData.name}
                  width={'44px'}
                  height={'44px'}
                />
              </div>
            ) : (
              <div className="initials">
                <ReactAvatar name={userData?.name || ''} size="4.4" />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="skeleton">
          <Skeleton width={44} height={44} variant="circle" />
        </div>
      )}
    </S.AvatarWrapper>
  );
};

export { Avatar };
