/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
  searchData: null,
  searchLoading: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.searchReducer,
      };

    /**
     * ---------------------------------------------------
     * SEARCH
     * ---------------------------------------------------
     */
    case types.SEND_SEARCH_REQUEST:
      return { ...state, loading: true, searchLoading: true, error: false };
    case types.SEND_SEARCH_SUCCESS:
      // Content that already exists on state:
      let stateSearchDataContents = [];
      if (state.searchData && state.searchData.data.length > 0) {
        stateSearchDataContents = state.searchData.data;
      }

      // Content coming from the action:
      let actionSearchDataContents = [];
      if (
        action.payload.searchData &&
        action.payload.searchData.data.length > 0
      ) {
        actionSearchDataContents = action.payload.searchData.data;
      }

      // Object that contain the old and the new data:
      const data = [...stateSearchDataContents, ...actionSearchDataContents];

      return {
        ...state,
        loading: false,
        searchLoading: false,
        searchData: {
          ...state.searchData,
          ...action.payload.searchData,
          data,
        },
        error: false,
      };
    case types.SEND_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        searchLoading: false,
        error: true,
      };

    /**
     * ---------------------------------------------------
     * CLEAN SEARCH
     * ---------------------------------------------------
     */
    case types.SEND_SEARCH_CLEAN_SUCCESS:
      return {
        ...state,
        searchData: action.payload.searchData,
        searchLoading: false,
        loading: false,
        error: false,
      };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
