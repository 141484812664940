import { MSApi } from 'libs/ms-api';
import { useQuery } from 'react-query';

import { UseRecommendedCoursesDataProps } from './types';

export const useRecommendedCoursesData = (
  msApiLib: MSApi,
  queryAndParams: UseRecommendedCoursesDataProps,
) => {
  const { enabled, cacheTime, query, params, bearerToken } =
    queryAndParams ?? {};
  const { page = 1, pageSize = 5 } = query ?? {};
  const { courseSlug } = params;

  return useQuery(
    ['getRecommendedCourses', params, page, pageSize],
    async () => {
      const res = await msApiLib.v3.recommendedCourses.list({
        bearerToken,
        query: { page, pageSize },
        params: { courseSlug },
      });

      return res.data;
    },
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
