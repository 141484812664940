/* eslint-disable import/no-cycle */
import { put, takeEvery, call } from 'redux-saga/effects';

// Actions
import { actions as notificationAction } from '@store/ducks/notification';
// import { actions as catalogActions } from '@store/ducks/catalog';

// MSV2-api
import { notificationApi as msv2NotificationApi } from '@services/msv2-api';

// Mirror-api
import { notificationApi as mirrorNotificationApi } from '@services/mirror-api';

import * as types from './types';

/**
 * ---------------------------------------------------
 * HOME
 * ---------------------------------------------------
 */
export function* getNotificationReq({ payload }) {
  const { auth, isServer } = payload;
  const request = isServer ? msv2NotificationApi : mirrorNotificationApi;
  const token = isServer ? auth.token : '';

  try {
    const response = yield call(request.notification, {
      method: 'GET',
      token
    });

    if (response.status === 200) {
      yield put(
        notificationAction.getNotificationSuccess({
          payload: {
            data: response?.data?.content?.instance
          },
        })
      );
    }

  } catch (error) {
    if (error && error.response) {
      yield put(notificationAction.getNotificationFailure());
    }
  }
}

export function* setReadNotificationReq({ payload }) {
  const { auth, isServer } = payload;
  const request = isServer ? msv2NotificationApi : mirrorNotificationApi;
  const token = isServer ? auth.token : '';

  try {
    const response = yield call(request.setnotification, {
      method: 'PUT',
      token,
      data: { idNotification: payload.data }
    });

    if (response.status === 204) {
      yield put(
        notificationAction.setReadNotificationSuccess({
          payload: {
            data: response?.data?.content?.instance
          },
        })
      );
    }

    if (response.status === 200) {
      yield put(
        notificationAction.setReadNotificationSuccess({
          payload: {
            data: response?.data?.content?.instance
          },
        })
      );
    }

  } catch (error) {
    if (error && error.response) {
      yield put(notificationAction.setReadNotificationFailure());
    }
  }
}


export function* watchUser() {
  yield takeEvery(types.GET_NOTIFICATION_REQUEST, getNotificationReq);
  yield takeEvery(types.SET_READ_NOTIFICATION_REQUEST, setReadNotificationReq);
}

export const sagas = [watchUser];
