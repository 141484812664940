import React from 'react';

interface Props {
  className?: string;
  color?: string;
  size?: string;
}

const IconMedal = React.memo(
  ({ className, color = '#fff', size = '25' }: Props) => (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0115 16.9292C16.1022 16.9292 19.4184 13.613 19.4184 9.5222C19.4184 5.43145 16.1022 2.11523 12.0115 2.11523C7.9207 2.11523 4.60449 5.43145 4.60449 9.5222C4.60449 13.613 7.9207 16.9292 12.0115 16.9292Z"
        stroke={color}
        stroke-width="1.48139"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0111 13.9664C14.4655 13.9664 16.4553 11.9766 16.4553 9.52218C16.4553 7.06773 14.4655 5.078 12.0111 5.078C9.55662 5.078 7.56689 7.06773 7.56689 9.52218C7.56689 11.9766 9.55662 13.9664 12.0111 13.9664Z"
        stroke={color}
        stroke-width="1.48139"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4553 15.4478V22.8547L12.0111 20.6326L7.56689 22.8547V15.4478"
        stroke={color}
        stroke-width="1.48139"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
);

export default IconMedal;
export { IconMedal };
