import React from 'react';
import PropTypes from 'prop-types';

const IconDislike = React.memo(({ size, color }) => (
  <svg
    id="icon-dislike"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47535 11.6578C6.02676 11.8444 6.64434 12.5269 6.64434 13.9715L6.64435 14.9697C6.64435 16.6558 8.05554 17.9998 9.76966 17.9998C10.4176 17.9998 11.0506 17.8046 11.5811 17.4392C12.4043 16.871 12.8367 15.8847 12.8367 14.6893V12.1024H15.1509C16.6866 12.1024 17.9637 10.9232 17.9994 9.41614C18.0163 8.70611 17.6535 6.54014 16.8829 2.63177C16.645 0.933552 15.3947 -3.8147e-06 13.5368 -3.8147e-06H6.16463C5.78466 -3.8147e-06 5.42052 0.072361 5.08709 0.204029C4.90422 0.07547 4.68134 -3.8147e-06 4.44082 -3.8147e-06H1.75501C0.797692 -3.8147e-06 0 0.759701 0 1.72501V9.93335C0 10.8987 0.797692 11.6584 1.75501 11.6584H4.44082C4.45237 11.6584 4.46388 11.6582 4.47535 11.6578ZM3.31581 9.4084H2.25002V2.25007H3.31581V9.4084ZM5.56586 9.57474V2.74741C5.56586 2.48972 5.82054 2.25002 6.16466 2.25002H13.5369C14.3428 2.25002 14.5887 2.43574 14.6569 2.96118L14.6688 3.03397C15.3912 6.69551 15.761 8.9014 15.7501 9.36274C15.744 9.61955 15.4918 9.85242 15.151 9.85242H12.5439C11.5738 9.85242 10.5867 10.3656 10.5867 11.4248V14.6893C10.5867 15.1951 10.4632 15.4768 10.3038 15.5868C10.1512 15.6919 9.96371 15.7498 9.76968 15.7498C9.27433 15.7498 8.89439 15.3879 8.89439 14.9697L8.89439 13.9715C8.89439 11.6397 7.70874 10.11 5.56586 9.57474Z"
      fill={color}
    />
  </svg>
));

IconDislike.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconDislike.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconDislike;
export { IconDislike };
