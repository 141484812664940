const authRoute = (pathname) => {
  if (pathname) {
    switch (true) {
      case pathname === '/login':
      case pathname === '/esqueci-minha-senha/email-enviado':
      case pathname === '/esqueci-minha-senha':
      case pathname === '/esqueci-minha-senha/nova-senha':
      case pathname === '/esqueci-minha-senha/sucesso':
      case pathname === '/primeiro-acesso':
      case pathname === '/.well-known/apple-app-site-association':
      case pathname === '/.well-known/assetlinks.json':
      case pathname.startsWith('/certificados/publico/'):
      case pathname === '/nps':
        return false;
      default:
        return true;
    }
  }

  return false;
};

export default authRoute;
export { authRoute };
