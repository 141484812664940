import React from 'react';
import PropTypes from 'prop-types';

// Card Icons
import {
  IconAmexCard,
  IconDinnersCard,
  IconEloCard,
  IconHiperCard,
  IconMasterCard,
  IconVisaCard,
} from '../Icons';

const CardFlag = React.memo(({ name }) => {
  return (
    <>
      {
        {
          'American Express': <IconAmexCard />,
          'Dinners Club': <IconDinnersCard />,
          Elo: <IconEloCard />,
          Hipercard: <IconHiperCard />,
          MasterCard: <IconMasterCard />,
          Visa: <IconVisaCard />,
        }[name]
      }
    </>
  );
});

CardFlag.propTypes = {
  name: PropTypes.string,
};

CardFlag.defaultProps = {
  name: '',
};

export default CardFlag;
export { CardFlag };
