import { css } from 'styled-components';

const ComponentStyles = css`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  .mp-input__tip {
    color: ${(props) => props.theme.colorPallete.base.default};
    font-size: 1.1rem;
    padding: 0.3rem;
    margin-top: 1.5rem;
    transition: all 0.3s ease-in-out;

    &.invalid {
      color: ${(props) => props.theme.colorSystem.danger};
      transition: all 0.3s ease-in-out;
      font-weight: 600;
    }

    &.valid {
      color: ${(props) => props.theme.colorSystem.success};
      transition: all 0.3s ease-in-out;
    }
  }
`;

const RadioStyles = ({ theme }) => css`
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;

  .radio-item {
    display: grid;
    grid-template-columns: 0 1fr;
    align-items: center;
    height: auto;
    margin-right: 2.4rem;

    input[type='radio'] {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }

    input[type='radio'] + label {
      margin: 0;
      padding: 0 0 0 2.5rem;
      width: 100%;
      /* min-width: 60px; */
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 3.2rem minmax(0, 1fr);
      align-items: center;
      justify-content: flex-start;
      line-height: normal;
      font-size: 1.4rem;
      font-family: ${theme.fonts.fontFamily.sans};
      font-weight: ${theme.fonts.fontWeight.regular};
      cursor: pointer;
      position: relative;

      .checkfield {
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        padding: 0.1rem;
        border: 0.2rem solid ${theme.colorSystem.white};
        background-color: ${theme.colorPallete.primary.default};
        border-radius: 2rem;

        position: absolute;
        top: 0.1rem;
        left: 0;

        .checkmark {
          display: block;
          width: 1.6rem;
          height: 1.6rem;
          margin: auto;
          padding: 0;
          background-color: ${theme.colorSystem.white};
          border: 0.1rem solid ${theme.colorSystem.white};
          border-radius: 5rem;
        }
      }

      .label-content {
        margin: 0;
        padding: 0;
        padding-top: 0.3rem;
      }
    }

    input[type='radio']:checked + label {
      .checkmark {
        background-color: ${theme.colorPallete.primary.default};
      }
    }

    input[type='radio']:focus + label {
      .checkfield {
        border: 0.2rem solid rgb(159, 128, 191);
      }
    }
  }

  &.line {
    display: flex;
  }
`;

export { RadioStyles, ComponentStyles };
