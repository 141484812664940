import Cookies from 'js-cookie';
import { actions as userActions } from '@store/ducks/user';
import { actions as notificationActions } from '@store/ducks/notification';

const logoutHandler = (dispatch) => {
  Cookies.remove('showBillingModal');
  dispatch(notificationActions.getNotificationFailure({ payload: {} }));
  Cookies.remove('tokenClient');
  Cookies.remove('refresh_tokenClient');
  return dispatch(userActions.logoutRequest({}));
};

export default logoutHandler;
export { logoutHandler };
