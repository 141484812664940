import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    gap: 32px;
    padding-top: 2.6rem;
    width: 100%;
    background-color: ${theme.colorSystem.black};
    padding-bottom: 45px;

    padding-inline: 20px;

    @media (min-width: 768px) {
      padding-left: 10vw;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    height: 25px;
    width: 250px;
    max-width: 320px;
  }
`;

export const RecommendedContent = styled.div`
  ${() => css`
    display: flex;
    gap: 32px;

    .cards {
      width: 270px;
      min-width: 270px;
      max-width: 276px;
      position: relative;
      height: 378px;

      box-sizing: border-box;
      padding: 10px 10px 20px 10px;
      border-radius: 4px;
    }
  `}
`;
