import React from 'react';

import { orderArrayByOrder } from 'helpers';

import { Card } from 'components';
import * as S from './PosterList.styles';
import { CardSkeleton } from 'skeletons';

const PosterList = ({ content }) => {
  if (!content) {
    return (
      <S.Wrapper>
        <CardSkeleton></CardSkeleton>
        <CardSkeleton></CardSkeleton>
        <CardSkeleton></CardSkeleton>
        <CardSkeleton></CardSkeleton>
      </S.Wrapper>
    );
  }
  return (
    <S.Wrapper>
      {content.pages.map((page, index) => {
        return (
          <React.Fragment key={index}>
            {orderArrayByOrder(page.data, true).map((value) => {
              return <Card cardContent={value}></Card>;
            })}
          </React.Fragment>
        );
      })}
    </S.Wrapper>
  );
};

export default PosterList;
export { PosterList };
