import { callApiBase } from './base';

const popupsApi = {
  getPopup: ({ token,refreshToken }) =>
    callApiBase({
      title: 'PopUp - GET',
      endpoint: `/popups`,
      method: 'GET',
      token,
      refreshToken,
    }),
};

export default popupsApi;
export { popupsApi };
