import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #252525;
    font-family: ${theme.fonts.fontFamily.sans};
    padding-block: 2.7rem;

    padding-inline: 20px;
    @media (min-width: 768px) {
      padding-left: 10vw;
    }

    .complementary-material-carousel {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      gap: 24px;
      max-width: 100%;
      white-space: nowrap;
    }
  `}
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 1.6rem;
    font-weight: ${theme.fonts.fontWeight.bold};
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    font-size: 1.2rem;
    font-weight: ${theme.fonts.fontWeight.regular};
    margin-top: 4px;
  `}
`;
