import React, { ReactNode, useRef, useState } from 'react';
import * as S from './ScrollableContainer.styles';

interface ScrollableContainer {
  children: ReactNode;
  className?: string;
}

const ScrollableContainer = ({
  children,
  className = '',
}: ScrollableContainer) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollableRef = useRef(null);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - scrollableRef.current.offsetLeft);
    setScrollLeft(scrollableRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - scrollableRef.current.offsetLeft;
    const scrollOffset = x - startX;
    scrollableRef.current.scrollLeft = scrollLeft - scrollOffset;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <S.Wrapper
      ref={scrollableRef}
      className={className}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}>
      {children}
    </S.Wrapper>
  );
};

export { ScrollableContainer };
export default ScrollableContainer;
