import * as types from './types';

/**
 * ---------------------------------------------------
 * SEND PROGRESS
 * ---------------------------------------------------
 */
export const sendProgressRequest = ({ payload }) => ({
  type: types.SEND_PROGRESS_REQUEST,
  payload,
});
export const sendProgressSuccess = () => ({
  type: types.SEND_PROGRESS_SUCCESS,
});
export const sendProgressFailure = () => ({
  type: types.SEND_PROGRESS_FAILURE,
});

/**
 * ---------------------------------------------------
 * GET PROGRESS - HOME
 * ---------------------------------------------------
 */
export const getHomeNewProgress = (payload) => ({
  type: types.GET_HOME_NEW_PROGRESS,
  payload,
});

/**
 * ---------------------------------------------------
 * GET PROGRESS - COURSE
 * ---------------------------------------------------
 */
export const getCourseNewProgress = (payload) => ({
  type: types.GET_COURSE_NEW_PROGRESS,
  payload,
});
