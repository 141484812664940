import React from 'react';

interface IconBooksProps {
  color?: string;
}

const IconBooks = React.memo(({ color = '#7F7F7F' }: IconBooksProps) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.97656 3.75H4.97656C4.56235 3.75 4.22656 4.08579 4.22656 4.5V19.5C4.22656 19.9142 4.56235 20.25 4.97656 20.25H7.97656C8.39078 20.25 8.72656 19.9142 8.72656 19.5V4.5C8.72656 4.08579 8.39078 3.75 7.97656 3.75Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.22656 7.5H8.72656"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4766 3.75H9.47656C9.06235 3.75 8.72656 4.08579 8.72656 4.5V19.5C8.72656 19.9142 9.06235 20.25 9.47656 20.25H12.4766C12.8908 20.25 13.2266 19.9142 13.2266 19.5V4.5C13.2266 4.08579 12.8908 3.75 12.4766 3.75Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.72656 16.5H13.2266"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9329 3.50449L14.0229 4.28449C13.6211 4.39219 13.3827 4.8052 13.4904 5.20699L17.3904 19.757C17.4981 20.1588 17.9111 20.3972 18.3129 20.2895L21.2229 19.5095C21.6247 19.4018 21.8631 18.9888 21.7554 18.587L17.8554 4.03699C17.7477 3.63521 17.3347 3.3968 16.9329 3.50449Z"
      stroke={color}
      strokeWidth="1.50636"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5269 16.7998L20.8769 15.6279"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2021 8.1L18.5521 6.9375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export default IconBooks;
export { IconBooks };
