import { css } from 'styled-components';

const styles = ({ radius }) => css`
  width: 100%;
  height: fit-content;
  position: relative;
  border-radius: ${radius};
  overflow: hidden;
  cursor: pointer;

  @media all and (max-width: ${(props) => props.theme.breakpoints.big}) {
  }

  .thumb__image {
    width: 100%;
    height: 100%;
    border-radius: ${radius};
    overflow: hidden;

    picture {
      display: block;
      width: 100%;
      padding-bottom: 57.4%;
      position: relative;
      background: ${(props) => props.theme.colorPallete.base[500]};

      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
  }

  .thumb__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
  }

  .thumb__progress {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 3;
  }
`;

export default styles;
export { styles };
