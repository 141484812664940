import styled, { css } from 'styled-components';

interface WrapperProps {
  url: string;
  directionText: string;
}

interface BadgeProps {
  textColor: string;
  color: string;
}

interface ButtonProps {
  textColor: string;
  color: string;
}

interface DescriptionProps {
  textColor: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ url, directionText }) => css`
    display: flex;
    flex-direction: column;
    background-image: url(${url});
    background-size: cover;
    background-position: top center;
    width: 100%;
    aspect-ratio: 1.5;

    @media (min-width: 768px) {
      border-radius: 8px;
      aspect-ratio: 3.5;
      flex-direction: ${directionText === 'right' ? 'row' : 'row-reverse'};
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 16px;

  @media (min-width: 769px) {
    justify-content: center;
  }

  &.banner-content {
    padding: 0 15px 30px 15px;

    div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 8px;

      @media (min-width: 769px) {
        padding: 0;
        justify-content: flex-end;
      }

      @media (min-width: 1024px) {
        gap: 16px;
        margin-inline: 6vw auto;
      }
    }

    @media (min-width: 768px) {
      margin-top: 15px;
      padding: 30px;
    }

    @media (min-width: 1280px) {
      padding: 0;
    }
  }
`;

export const Badge = styled.span<BadgeProps>`
  ${({ theme, textColor, color }) => css`
    width: fit-content;
    font-size: max(1rem, 0.6vw);
    line-height: 1.5;
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    padding: 0.5% 1%;
    text-transform: uppercase;
    color: ${textColor};
    border: 1px solid ${color};
    border-radius: 2px;
  `}
`;

export const TitleImage = styled.img`
  width: min(100%, 500px);
  max-height: 70px;
  object-fit: contain;
  object-position: left top;

  @media (min-width: 768px) {
    max-height: 7vw;
  }
`;

export const Description = styled.p<DescriptionProps>`
  ${({ theme, textColor }) => css`
    width: 100%;
    font-size: max(12px, 0.9vw);
    line-height: 1.3;

    color: ${textColor};
    font-family: ${theme.fonts.fontFamily.sans};

    @media (min-width: 768px) {
      width: 90%;
    }

    @media (min-width: 1024px) {
      line-height: 1.5;
      width: 75%;
    }
  `}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: ${theme.fonts.fontWeight.bold};
    font-family: ${theme.fonts.fontFamily.sans};
    gap: 8px;
    font-size: max(14px, 0.9vw);
    white-space: nowrap;

    @media (min-width: 768px) {
      justify-content: flex-start;
      flex-direction: row;
      gap: 16px;
    }
  `}
`;

export const MainButton = styled.a<ButtonProps>`
  ${({ color, textColor }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    color: ${textColor};
    border-radius: 3px;
    background-color: ${color};
    font-weight: inherit;
    font-size: inherit;

    @media (min-width: 768px) {
      justify-content: flex-start;
    }

    @media (min-width: 1024px) {
      padding: 12px 24px;
    }
  `}
`;
