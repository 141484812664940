import { css } from 'styled-components';

const styles = ({ theme }) => css`
  overflow: hidden;
  display: block;
  width: auto;
  min-height: min-content;
  height: auto;
  position: relative;
  z-index: 0;
  transition: all 0.15s ease-out;

  @media (max-width: ${theme.breakpoints.big}) {
    min-width: 20vw;
  }
  @media (max-width: ${theme.breakpoints.medium}) {
    min-width: 28vw;
  }
  @media (max-width: ${theme.breakpoints.small}) {
    min-width: 30vw;
  }

  .list-gap {
    padding: 1.5rem 1rem;
  }

  a {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    position: relative;
  }

  picture {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0px;
    position: relative;
    padding-bottom: 166.5%;

    source,
    img {
      position: absolute;
      border-radius: 0.5rem;
      vertical-align: middle;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .poster-card__no-image {
    display: flex;
    width: 100%;
    height: auto;
    padding-bottom: 166.5%;
  }

  .poster-card__title {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    display: flex;
    justify-content: center;
    min-height: 7%;
    width: 100%;
    text-align: center;
    z-index: 20;
    padding: 0 1.5rem;

    font-family: ${theme.fonts.fontFamily.sans};
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: center;
    color: ${theme.colorPallete.white};

    opacity: 0.9;
  }

  .poster-card__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.6rem;
    z-index: 10;

    span,
    span::before {
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }

  &.resize {
    min-width: unset;
    .poster-card__title {
      bottom: 3.5%;

      @media all and (min-width: 1441px) {
        font-size: calc(1vw * 0.8);
      }

      @media all and (max-width: 768px) {
        font-size: 1.3rem;
      }

      @media all and (max-width: 690px) {
        font-size: 1rem;
        line-height: 1;
        bottom: 3%;
        min-height: 7%;
      }
    }
  }
`;

export { styles };
export default styles;
