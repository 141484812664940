import React from 'react';
import PropTypes from 'prop-types';

const IconPencil = React.memo(({ size, color }) => (
  <svg
    id="icon-pencil"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.693 7.58234L10.4176 5.30695L2.34612 13.3785L2.27588 15.7241L4.62151 15.6539L12.693 7.58234ZM17.4521 6.00526L5.89689 17.5605C5.69415 17.7632 5.42165 17.8809 5.13506 17.8895L1.46585 17.9993C0.680163 18.0229 0.0241655 17.405 0.000637708 16.6193C-0.000212569 16.5767 -0.000212569 16.5767 0.000637708 16.5341L0.110514 12.8649C0.119096 12.5783 0.236777 12.3058 0.439515 12.1031L11.9947 0.547892C12.7252 -0.182631 13.9097 -0.182631 14.6402 0.547892L17.4521 3.35981C18.1826 4.09033 18.1826 5.27474 17.4521 6.00526ZM15.5929 4.68256L13.3175 2.40717L12.0087 3.71596L14.2841 5.99136L15.5929 4.68256Z"
      fill={color}
    />
  </svg>
));

IconPencil.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconPencil.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconPencil;
export { IconPencil };
