/*
  This component was made to handle all text on the project.

  -- PROPS BRIEFING --
  as - polymorphic prop, used to change the underlying element on the component
  children - whatever text is used inside the component
  color - font color
  family - font-family
  height - mobile-first line height
  dheight - desktop line height
  size - mobile-first font size
  dsize - desktop font size
  transform - text-transform
  weight - font weight
*/

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { main as theme } from '@themes';

import { styles } from './Text.styles';

const TextWrapper = styled.span`
  ${styles}
`;

const Text = React.memo(
  ({
    as,
    children,
    color,
    dsize,
    dheight,
    family,
    height,
    size,
    weight,
    transform,
    decoration,
    className,
    cursor,
    spacing,
  }) => {
    let cursorMouse = 'unset';
    if (as === 'a' || cursor) {
      cursorMouse = 'pointer';
    }

    const decorationLine = decoration === 'underline' ? '' : decoration;
    const decorationClass = decoration === 'underline' ? 'link-hover' : '';

    return (
      <TextWrapper
        className={`text-component ${decorationClass} ${className}`}
        as={as}
        color={color}
        family={family}
        height={height}
        size={size}
        dsize={dsize}
        dheight={dheight}
        transform={transform}
        decoration={decorationLine}
        weight={weight}
        cursor={cursorMouse}
        tabIndex={as === 'a' ? `0` : ``}
        spacing={spacing}
      >
        {children}
      </TextWrapper>
    );
  }
);

Text.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  decoration: PropTypes.string,
  dheight: PropTypes.string,
  dsize: PropTypes.string,
  family: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
  transform: PropTypes.string,
  spacing: PropTypes.string,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Text.defaultProps = {
  as: 'span',
  children: <></>,
  className: '',
  color: theme.colorSystem.white,
  cursor: null,
  decoration: 'none',
  size: '1.4rem',
  dsize: '1.4rem',
  dheight: 'normal',
  height: 'normal',
  family: theme.fonts.fontFamily.sans,
  transform: 'initial',
  weight: theme.fonts.fontWeight.regular,
  spacing: 'normal',
};

export default Text;
export { Text };
