/**
 * ---------------------------------------------------
 * CLEAR LESSON
 * ---------------------------------------------------
 */
export const CLEAR_LESSON = '@lesson/CLEAR_LESSON';

/**
 * ---------------------------------------------------
 * GET LESSON
 * ---------------------------------------------------
 */
export const GET_LESSON_REQUEST = '@lesson/GET_LESSON_REQUEST';
export const GET_LESSON_SUCCESS = '@lesson/GET_LESSON_SUCCESS';
export const GET_LESSON_FAILURE = '@lesson/GET_LESSON_FAILURE';

/**
 * ---------------------------------------------------
 * UPDATE LESSON
 * ---------------------------------------------------
 */
export const UPDATE_LESSON_CONTENT = '@lesson/UPDATE_LESSON_CONTENT';

/**
 * ---------------------------------------------------
 * GET COMMENTS
 * ---------------------------------------------------
 */
export const GET_COMMENTS_REQUEST = '@lesson/GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = '@lesson/GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = '@lesson/GET_COMMENTS_FAILURE';

/**
 * ---------------------------------------------------
 * SEND COMMENTS
 * ---------------------------------------------------
 */
export const SEND_COMMENTS_REQUEST = '@lesson/SEND_COMMENTS_REQUEST';
export const SEND_COMMENTS_SUCCESS = '@lesson/SEND_COMMENTS_SUCCESS';
export const SEND_COMMENTS_FAILURE = '@lesson/SEND_COMMENTS_FAILURE';

/**
 * ---------------------------------------------------
 * VIDEOUNAVAILABLE
 * ---------------------------------------------------
 */
export const TOGGLE_VIDEO_UNAVAILABLE = '@lesson/TOGGLE_VIDEO_UNAVAILABLE';
