import React from 'react';

interface IconPlayHollowProps {
  color?: string;
}

const IconPlayHollow = React.memo(
  ({ color = '#7F7F7F' }: IconPlayHollowProps) => (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3844 9.36265L1.89375 1.11265C1.78018 1.0426 1.64998 1.00411 1.51658 1.00116C1.38318 0.99821 1.25141 1.0309 1.13486 1.09587C1.0183 1.16083 0.921193 1.25571 0.853541 1.37072C0.78589 1.48574 0.750147 1.61672 0.75 1.75015V18.2501C0.750147 18.3836 0.78589 18.5146 0.853541 18.6296C0.921193 18.7446 1.0183 18.8395 1.13486 18.9044C1.25141 18.9694 1.38318 19.0021 1.51658 18.9991C1.64998 18.9962 1.78018 18.9577 1.89375 18.8877L15.3844 10.6377C15.4952 10.5721 15.5869 10.4789 15.6507 10.3671C15.7145 10.2553 15.748 10.1288 15.748 10.0002C15.748 9.87145 15.7145 9.74498 15.6507 9.63319C15.5869 9.5214 15.4952 9.42816 15.3844 9.36265V9.36265Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconPlayHollow;
export { IconPlayHollow };
