/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// helper
import { maskPattern } from '@helpers/maskPattern';

// Theme
import { main } from '@themes';

// Components
import { IconDownArrow } from '../Icons';

// Style
import { InputStyles } from './Input.styles';

export const InputWrapper = styled.div`
  ${InputStyles}
`;

const Input = ({
  type,
  value,
  selectOptions,

  placeholder,
  validation,
  validationMsg,
  label,
  required = false,

  resize = false,
  readonly = false,
  disabled = false,

  primary,
  secondary,
  maxLength,
  maskType,

  ...restProps

  // size,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [passInputType, setPassInputType] = useState('password');
  const { id } = restProps;

  useEffect(() => {
    if (showPass) {
      setPassInputType('text');
    } else {
      setPassInputType('password');
    }
  }, [showPass]);

  const cssShowPass = classNames({
    'mp-input__password--toggle': true,
    hide: !showPass,
  });

  const cssWrapInput = classNames({
    'mp-input': true,
    primary,
    secondary,
    invalid: validation === 'invalid',
    valid: validation === 'valid',
    readonly,
    disabled,
  });

  const cssLabel = classNames({
    'mp-input__label': true,
    primary,
    secondary,
    invalid: validation === 'invalid',
    valid: validation === 'valid',
    readonly,
    disabled,
  });

  const cssInput = classNames({
    'mp-input__element': true,
    'mp-input__select': type === 'select',
    primary,
    secondary,
    textarea: type === 'textarea',
    password: type === 'password',
    resize,
    invalid: validation === 'invalid',
    valid: validation === 'valid',
    readonly,
    disabled,
  });

  const cssSelect = classNames({
    'mp-input__select--custom': true,
    primary,
    secondary,
  });

  const cssValidation = classNames({
    'mp-input__tip': true,
    primary,
    secondary,
    invalid: validation === 'invalid',
    valid: validation === 'valid',
  });

  let optionsArray;
  if (selectOptions) {
    const arrayOfData = selectOptions;
    optionsArray = arrayOfData.map((data) => (
      <option
        key={`key-${data.value}`}
        value={data.value}
        selected={data.selected}
      >
        {data.text}
      </option>
    ));
  }

  return (
    <InputWrapper className={cssWrapInput}>
      {{
        textarea: (
          <>
            {label !== '' ? (
              <label className={cssLabel} htmlFor="textarea">
                {label}
              </label>
            ) : null}
            <textarea
              className={cssInput}
              placeholder={placeholder}
              readOnly={readonly}
              required={required}
              value={value}
              {...restProps}
            />
          </>
        ),
        select: (
          <>
            {label !== '' ? (
              <label className={cssLabel} htmlFor="select">
                {label}
              </label>
            ) : null}

            <div className={cssInput}>
              <select
                className={cssSelect}
                required={required}
                disabled={disabled}
                value={value}
                {...restProps}
              >
                <option value="">{placeholder}</option>
                {optionsArray}
              </select>
              <span className="mp-input__select--arrow">
                <IconDownArrow
                  size="1.8rem"
                  color={
                    !secondary
                      ? main.colorPallete.primary.default
                      : main.colorSystem.white
                  }
                />
              </span>
            </div>
          </>
        ),
        phone: (
          <>
            {label !== '' ? (
              <label className={cssLabel} htmlFor="input">
                {label}
              </label>
            ) : null}
            <PhoneInput
              type="text"
              placeholder={placeholder}
              international
              value={value}
              className={cssInput}
              {...restProps}
            />
          </>
        ),
      }[type] ||
        (maskType ? (
          <>
            {label !== '' ? (
              <label className={cssLabel} htmlFor="input">
                {label}
              </label>
            ) : null}

            <MaskedInput
              type="text"
              placeholder={placeholder}
              value={value}
              readOnly={readonly}
              required={required}
              className={cssInput}
              mask={maskPattern(maskType)}
              maxLength={maxLength}
              {...restProps}
            />
          </>
        ) : (
          <>
            {label !== '' ? (
              <label className={cssLabel} htmlFor="input">
                {label}
              </label>
            ) : null}

            <input
              type={type !== 'password' ? type : passInputType}
              placeholder={placeholder}
              value={value}
              readOnly={readonly}
              required={required}
              className={cssInput}
              maxLength={maxLength}
              {...restProps}
            />

            {type === 'password' ? (
              <button
                id={restProps?.id ? `${restProps.id}--pass-tip` : null}
                className={cssShowPass}
                type="button"
                onClick={() => {
                  setShowPass(!showPass);
                }}
              >
                Mostrar senha
              </button>
            ) : null}
          </>
        ))}

      {validationMsg !== '' ? (
        <span className={cssValidation} id={id ? `${id}--tip` : ``}>
          {validationMsg}
        </span>
      ) : null}
    </InputWrapper>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  selectOptions: PropTypes.instanceOf(Array),

  placeholder: PropTypes.string.isRequired,
  validation: PropTypes.string,
  validationMsg: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,

  resize: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,

  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  maxLength: PropTypes.string,
  maskType: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  selectOptions: [],
  validation: null,
  validationMsg: '',
  label: null,
  value: null,
  required: false,

  resize: false,
  readonly: false,
  disabled: false,

  primary: false,
  secondary: false,
  maxLength: '1000',
  maskType: null,
};

export { Input };
export default Input;
