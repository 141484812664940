import React from 'react';
import PropTypes from 'prop-types';

const IconLike = React.memo(({ size, color }) => (
  <svg
    id="icon-like"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47535 6.34193C6.02676 6.15535 6.64434 5.47291 6.64434 4.02826L6.64435 3.03005C6.64435 1.34399 8.05554 0 9.76966 0C10.4176 0 11.0506 0.195178 11.5811 0.560609C12.4043 1.12878 12.8367 2.11503 12.8367 3.31047V5.89733H15.1509C16.6866 5.89733 17.9637 7.07655 17.9994 8.58363C18.0163 9.29366 17.6535 11.4596 16.8829 15.368C16.645 17.0662 15.3947 17.9998 13.5368 17.9998H6.16463C5.78466 17.9998 5.42052 17.9274 5.08709 17.7957C4.90422 17.9243 4.68134 17.9998 4.44082 17.9998H1.75501C0.797692 17.9998 0 17.2401 0 16.2748V8.06642C0 7.10112 0.797692 6.34141 1.75501 6.34141H4.44082C4.45237 6.34141 4.46388 6.34159 4.47535 6.34193ZM3.31581 8.59137H2.25002V15.7497H3.31581V8.59137ZM5.56586 8.42503V15.2524C5.56586 15.5101 5.82054 15.7498 6.16466 15.7498H13.5369C14.3428 15.7498 14.5887 15.564 14.6569 15.0386L14.6688 14.9658C15.3912 11.3043 15.761 9.09837 15.7501 8.63703C15.744 8.38022 15.4918 8.14735 15.151 8.14735H12.5439C11.5738 8.14735 10.5867 7.63413 10.5867 6.57495V3.31047C10.5867 2.80466 10.4632 2.52296 10.3038 2.41294C10.1512 2.30784 9.96371 2.25002 9.76968 2.25002C9.27433 2.25002 8.89439 2.61186 8.89439 3.03006L8.89439 4.02826C8.89439 6.36005 7.70874 7.88977 5.56586 8.42503Z"
      fill={color}
    />
  </svg>
));

IconLike.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconLike.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconLike;
export { IconLike };
