import styled from 'styled-components';

export const SkeletorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 24px;
  gap: 24px;
`;

export const SkeletorSlider = styled.div`
  display: flex;
  flex: row;
  width: 100%;
  gap: 30px;
`;
