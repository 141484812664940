import React from 'react';
import App from 'next/app';

import NProgress from 'nprogress';
import Router from 'next/router';

// Redux Saga
import wrapper from 'store';
import withReduxSaga from 'next-redux-saga';

import { QueryClientProvider, queryClient } from 'services/config/react-query';
import { RecoilRoot } from 'recoil';

// Themes
import { globalStyles, main } from 'themes';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

// Components
import { StyledToasts } from 'components';
import ErrorBoundary from 'components/ErrorBoundary';

// Utils
import { AuthProvider } from 'utils';
import errorLogger from 'utils/monitoring/errorLogger';

import 'react-toastify/dist/ReactToastify.css';
import 'public/assets/css/nprogress.css';
import 'react-phone-number-input/style.css';

const GlobalStyle = createGlobalStyle`
  ${globalStyles}
`;

errorLogger.init();

// Loader NProgress

NProgress.configure({
  showSpinner: true,
  easing: 'ease',
  speed: 300,
  trickle: true,
  trickleSpeed: 300,
  parent: '#loading-hold',
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    const defaultProps = { ...pageProps };

    return (
      // @ts-ignore
      <ErrorBoundary>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={main}>
              <StyledToasts />
              {/* @ts-ignore */}
              <GlobalStyle />
              <div id="loading-hold" />
              <AuthProvider>
                <Component props={defaultProps} />
              </AuthProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </RecoilRoot>
      </ErrorBoundary>
    );
  }
}

export default wrapper.withRedux(withReduxSaga(MyApp));
