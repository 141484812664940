import React from 'react';
import PropTypes from 'prop-types';

const IconLess = React.memo(({ size, color }) => (
  <svg
    id="icon-less"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3396 6H9.66038H6.33962H1.66038C-0.553459 6 -0.553459 9.32076 1.66038 9.32076H6.33962H9.66038H14.3396C16.5535 9.32076 16.5535 6 14.3396 6Z"
      fill={color}
    />
  </svg>
));

IconLess.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconLess.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconLess;
export { IconLess };
