import React from 'react';
import PropTypes from 'prop-types';

const IconAttachments = React.memo(({ size, color }) => (
  <svg
    id="icon-attachments"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8907 13.4028C18.5099 12.8668 19.5121 12.8683 20.1291 13.4062C20.7461 13.9442 20.7443 14.8148 20.1251 15.3508L15.0214 19.7689C11.585 22.7437 6.01593 22.7437 2.57959 19.7689C-0.859862 16.7915 -0.859862 11.9621 2.57959 8.98464L11.0858 1.62105C13.5826 -0.540348 17.6283 -0.540348 20.1251 1.62105C22.625 3.78513 22.625 7.29583 20.1251 9.45991L12.4695 16.0871C10.9123 17.4352 8.38989 17.4352 6.83268 16.0871C5.27237 14.7364 5.27237 12.5444 6.83268 11.1937L11.0838 7.51364L14.2581 4.75595C14.8762 4.21898 15.8783 4.21898 16.4964 4.75595C17.1145 5.29292 17.1145 6.16352 16.4964 6.70049L13.3201 9.45991L9.06704 13.1417C8.74821 13.4177 8.74821 13.8632 9.06704 14.1392C9.38897 14.4178 9.91323 14.4178 10.2352 14.1392L17.8907 7.51192C19.1491 6.42256 19.1491 4.65839 17.8907 3.56903C16.6292 2.47699 14.5816 2.47699 13.3201 3.56903L4.81394 10.9326C2.61598 12.8353 2.61598 15.9182 4.81394 17.8209C7.015 19.7264 10.586 19.7264 12.787 17.8209L17.8907 13.4028Z"
      fill={color}
    />
  </svg>
));

IconAttachments.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconAttachments.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconAttachments;
export { IconAttachments };
