import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import {
  PaginationDto,
  CourseListItemDto,
  GetCourseQueryDto,
  GetOneCourseQueryDto,
  CoursebySlugDto,
  GetCheckCourseProgressQueryDto,
  CheckCourseProgressDto,
} from 'libs/ms-api/dtos';
import qs from 'qs';

import { AxiosImpUtils } from '../utils';

type ICoursesResource = MSApi['v3']['courses'];

export class CoursesResource implements ICoursesResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(data: {
    bearerToken: string;
    query?: GetCourseQueryDto;
  }): Promise<PaginationDto<CourseListItemDto[]>> {
    const parsedQuery = AxiosImpUtils.parserPaginationQuery(data.query);
    const query = qs.stringify(parsedQuery);

    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(`v3/courses?${query}`);
    return result;
  }

  async listOneCourse(data: {
    bearerToken: string;
    query?: GetOneCourseQueryDto;
  }): Promise<PaginationDto<CoursebySlugDto>> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(
      `v3/courses/${data.query?.courseSlug}`,
    );
    return result;
  }

  async checkCourseProgress(data: {
    bearerToken: string;
    query?: GetCheckCourseProgressQueryDto;
  }): Promise<PaginationDto<CheckCourseProgressDto>> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;

    const { data: result } = await this.msApi.get(
      `v3/students/${data.query?.studentId}/courses/${data.query?.courseId}/lessons/${data.query?.lessonId}/progress`,
    );
    return result;
  }
}
