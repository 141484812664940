import { callApiBase } from './base';

const paymentsApi = {
  newCreditCard: ({ data }) =>
    callApiBase({
      title: `New credit card - POST`,
      endpoint: '/payments/new-credit-card',
      method: 'POST',
      data,
    }),
};

export default paymentsApi;
export { paymentsApi };
