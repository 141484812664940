import React from 'react';
import PropTypes from 'prop-types';

const IconComments = React.memo(({ size, color }) => (
  <svg
    id="icon-comments"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.34979 14.3867C6.67958 14.1889 7.07716 14.1382 7.44606 14.2469C8.38199 14.5229 9.42102 14.6668 10.5417 14.6668C15.2623 14.6668 18.3333 12.1013 18.3333 8.70846C18.3333 5.31568 15.2623 2.75013 10.5417 2.75013C5.82098 2.75013 2.74998 5.31568 2.74998 8.70846C2.74998 10.3201 3.45067 11.777 4.74699 12.8525C5.12362 13.1649 5.3058 13.654 5.22535 14.1367L5.05412 15.1641L6.34979 14.3867ZM20.7808 10.9253C21.5721 11.9501 22 13.1921 22 14.5139C22 15.9997 21.4595 17.3811 20.4757 18.4669L20.7905 20.4045C20.9794 21.5666 19.7154 22.4131 18.7127 21.796L16.7093 20.5631C16.0628 20.7055 15.379 20.7778 14.6667 20.7778C11.6 20.7778 9.10538 19.4107 7.97321 17.193C7.73382 17.1496 7.4979 17.1 7.26563 17.0441L3.91577 19.0541C2.91013 19.6574 1.65924 18.8058 1.85204 17.649L2.38989 14.4219C0.849523 12.8846 0 10.8798 0 8.70833C0 3.67634 4.40064 0 10.5417 0C16.6827 0 21.0833 3.67634 21.0833 8.70833C21.0833 9.4822 20.9793 10.224 20.7808 10.9253ZM19.1899 13.8906C17.5566 15.8125 14.9684 17.0871 11.83 17.3611C12.5784 17.7844 13.5411 18.0277 14.6666 18.0277C15.3467 18.0277 15.9731 17.9387 16.5335 17.7692C16.9022 17.6577 17.3003 17.706 17.6312 17.9016C17.6528 17.5314 17.8238 17.1806 18.1128 16.9347C18.8529 16.305 19.25 15.4581 19.25 14.5138C19.25 14.3006 19.2298 14.0924 19.1899 13.8906Z"
      fill={color}
    />
  </svg>
));

IconComments.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconComments.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconComments;
export { IconComments };
