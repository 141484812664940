import React from 'react';

interface Props {
  className?: string;
  color?: string;
  size?: string;
}

const IconMedalCheckedSolid = React.memo(
  ({ className, color = '#fff', size = '59' }: Props) => (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5 35.0312C36.6279 35.0312 42.4062 29.2529 42.4062 22.125C42.4062 14.9971 36.6279 9.21875 29.5 9.21875C22.3721 9.21875 16.5938 14.9971 16.5938 22.125C16.5938 29.2529 22.3721 35.0312 29.5 35.0312Z"
        fill={color}
      />
      <path
        d="M29.5002 1.84379C25.3219 1.83528 21.2432 3.11893 17.8229 5.51888C14.4026 7.91882 11.8081 11.3176 10.3948 15.2496C8.98147 19.1816 8.81855 23.4545 9.92833 27.4827C11.0381 31.5109 13.3663 35.0974 16.5939 37.7508V55.3125C16.592 55.6272 16.6717 55.9369 16.8252 56.2116C16.9787 56.4862 17.2007 56.7164 17.4697 56.8797C17.7365 57.0474 18.0418 57.144 18.3565 57.1601C18.6712 57.1762 18.9848 57.1115 19.2673 56.9719L29.5002 51.8325L39.733 56.9719C39.9946 57.0876 40.2766 57.1502 40.5627 57.1563C41.0517 57.1563 41.5206 56.962 41.8664 56.6163C42.2122 56.2705 42.4064 55.8015 42.4064 55.3125V37.7508C45.634 35.0974 47.9622 31.5109 49.072 27.4827C50.1818 23.4545 50.0189 19.1816 48.6056 15.2496C47.1922 11.3176 44.5977 7.91882 41.1774 5.51888C37.7571 3.11893 33.6784 1.83528 29.5002 1.84379ZM29.5002 38.7188C26.2182 38.7188 23.01 37.7456 20.2812 35.9222C17.5523 34.0989 15.4255 31.5073 14.1695 28.4752C12.9136 25.4431 12.585 22.1066 13.2253 18.8878C13.8655 15.6689 15.4459 12.7122 17.7666 10.3915C20.0873 8.07081 23.044 6.49041 26.2629 5.85014C29.4818 5.20986 32.8182 5.53847 35.8503 6.79442C38.8824 8.05036 41.474 10.1772 43.2974 12.906C45.1207 15.6349 46.0939 18.8431 46.0939 22.125C46.0878 26.5241 44.3376 30.7412 41.227 33.8519C38.1164 36.9625 33.8992 38.7127 29.5002 38.7188Z"
        fill={color}
      />
      <path
        d="M24 23.1604L27.1702 26.2976C27.4092 26.5341 27.7944 26.5331 28.0322 26.2953L35 19.3275"
        stroke="#1D1D1D"
        stroke-width="2.44444"
        stroke-linecap="round"
      />
    </svg>
  ),
);

export default IconMedalCheckedSolid;
export { IconMedalCheckedSolid };
