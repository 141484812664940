import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { styles } from './UserAvatar.styles';

const Wrapper = styled.div`
  ${styles}
`;

const UserAvatar = React.memo(({ userPhoto, badge }) => {
  return (
    <Wrapper bgimage={userPhoto} className="user-avatar">
      <div className="avatar-image" />
      {badge && <span className="notification-badge" />}
    </Wrapper>
  );
});

UserAvatar.propTypes = {
  userPhoto: PropTypes.string,
  badge: PropTypes.bool,
};

UserAvatar.defaultProps = {
  userPhoto: '',
  badge: false,
};

export default UserAvatar;
export { UserAvatar };
