import React from 'react';
import PropTypes from 'prop-types';

const IconWatched = React.memo(({ size, color }) => (
  <svg
    id="icon-watched"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6431 0.348774C14.9316 0.946299 16.9235 2.46328 18.1078 4.51065C19.2478 6.46916 19.604 8.76597 19.1108 10.9777C18.589 13.3168 17.1876 15.3127 15.1649 16.5979C13.7086 17.523 12.0555 18 10.3712 18C9.71572 18 9.0558 17.9278 8.40047 17.7816C3.73711 16.7416 0.730676 12.2243 1.50025 7.56664C1.26963 7.68776 1.00596 7.71636 0.753463 7.64723C0.489647 7.57474 0.269876 7.40376 0.134527 7.16594C-0.000822065 6.92828 -0.0353858 6.65192 0.0369534 6.3881C0.109446 6.12429 0.280429 5.90436 0.518246 5.76917L2.57555 4.59813C2.81383 4.46293 3.09019 4.42822 3.3537 4.50056C3.61751 4.57305 3.83729 4.74388 3.97263 4.98185L5.14383 7.03916C5.42186 7.52947 5.24996 8.15514 4.76087 8.4344C4.33127 8.67956 3.79737 8.57893 3.48324 8.2209C3.08178 11.6156 5.2362 14.8405 8.60173 15.7246C10.3909 16.1947 12.2555 15.9399 13.853 15.0072C15.4504 14.0746 16.5889 12.5756 17.0588 10.7867C18.0289 7.0936 15.8139 3.29985 12.121 2.32961L12.0771 2.31325C12.048 2.29872 12.0165 2.29046 11.9838 2.28863L11.9381 2.28129C9.95629 1.75656 7.85953 2.13309 6.18456 3.31361C5.72223 3.63876 5.08204 3.52711 4.7572 3.06494C4.43236 2.60291 4.54401 1.96272 5.00603 1.63788C7.17666 0.10744 9.89405 -0.380123 12.4616 0.300752L12.5039 0.316658C12.5337 0.331339 12.5656 0.339751 12.5986 0.341586L12.6431 0.348774ZM9.36949 4.83367C9.36949 4.26887 9.82907 3.80945 10.3939 3.80945C10.9587 3.80945 11.4181 4.26887 11.4181 4.83382V8.59102L13.0502 10.2232C13.4483 10.6222 13.4483 11.2712 13.0504 11.67C12.8572 11.8637 12.6001 11.9705 12.3265 11.9708H12.3253C12.0522 11.9708 11.7952 11.8646 11.6019 11.6716L9.67017 9.73989C9.62245 9.69202 9.57917 9.63941 9.54185 9.58297C9.52824 9.56279 9.51677 9.54122 9.50576 9.51935L9.50117 9.51033C9.46018 9.44487 9.43051 9.37376 9.41308 9.29851L9.40971 9.28826C9.40191 9.26456 9.39457 9.2407 9.38968 9.21577C9.37653 9.15047 9.3698 9.08302 9.36949 9.01573V4.83367Z"
      fill={color}
    />
  </svg>
));

IconWatched.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconWatched.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconWatched;
export { IconWatched };
