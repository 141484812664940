import { css } from 'styled-components';

const styles = ({
  theme,
  color,
  family,
  height,
  size,
  weight,
  transform,
  decoration,
  dsize,
  dheight,
  cursor,
  spacing,
}) => css`
  color: ${color};
  font-family: ${family};
  line-height: ${height};
  font-size: ${size};
  text-transform: ${transform};
  text-decoration: ${decoration};
  font-weight: ${weight};
  letter-spacing: ${spacing};
  cursor: ${cursor};

  @media all and (min-width: ${theme.breakpoints.big}) {
    font-size: ${dsize};
    line-height: ${dheight};
  }

  &.link-hover {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-bottom: 4px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.2rem;

      background: ${color};

      position: absolute;
      bottom: 0;
      left: 0%;

      /* transition: all 0.25s ease-in-out; */
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0.2rem;

      background: ${color};

      position: absolute;
      bottom: 0;
      left: -20%;

      transition: all 0.45s ease-in-out;
    }

    &:hover {
      &::after {
        left: 100%;
        transition: all 0.45s ease-in-out;
      }

      &::before {
        width: 100%;
        left: 0;
        transition: all 0.45s ease-in-out;
        transition-delay: 0.2s;
      }
    }
  }
`;

export default styles;
export { styles };
