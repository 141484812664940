import { msApiFactory } from 'libs/ms-api/impl/axiosFactory';
import { baseApiFactory } from '../base-api/index';

const api = baseApiFactory(`${process.env.MSV2_API_BASE_URL}`);

const msApiLib = msApiFactory({
  axiosInstance: api,
});

export { api, msApiLib };
