import React from 'react';

interface Props {
  color?: string;
  strokeWidth?: string;
  className?: string;
  width?: string;
  height?: string;
}

const IconChecked = React.memo(
  ({
    color = '#fff',
    strokeWidth = '2',
    width = '14',
    height = '11',
    className,
  }: Props) => (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5625 1.1875L5.9375 10.8125L1.125 6"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconChecked;
export { IconChecked };
