import styled, { css, DefaultTheme } from 'styled-components';

import { convertHexToRGBA } from '../../utils/hexToRgba';
import { SkeletonProps } from './types';

export const Wrapper = styled.div<SkeletonProps>`
  ${({
    theme,
    variant = 'text',
    width,
    height,
    isRounded = true,
    isFullHeight = false,
    isFullWidth = false,
    mt = 0,
    mr = 0,
    mb = 0,
    ml = 0,
    br = 8,
  }) => css`
    width: 100%;
    margin: ${mt}px ${mr}px ${mb}px ${ml}px;
    border-radius: ${br}px;
    background: linear-gradient(
      to right,
      ${convertHexToRGBA('#313030', '32%')} 0%,
      ${convertHexToRGBA('#313030', '64%')} 40%,
      ${convertHexToRGBA('#313030', '32%')} 60%,
      ${convertHexToRGBA('#313030', '32%')} 100%
    );
    background-size: 80rem 14rem;
    animation: placeholderShimmer 1.3s linear infinite forwards;

    ${!!modifiers[variant] &&
    modifiers[variant](theme, { width, height, isRounded, br })}

    ${isFullHeight && modifiers.isFullHeight(theme, {})}

    ${isFullWidth && modifiers.isFullWidth(theme, {})}


    @keyframes placeholderShimmer {
      0% {
        background-position: -40rem 0;
      }

      100% {
        background-position: 40rem 0;
      }
    }
  `}
`;

const modifiers: {
  [key: string]: (theme: DefaultTheme, props: SkeletonProps) => any;
} = {
  text: (theme, { width = 100, height = 16, isFullWidth }) => css`
    max-width: ${width}px;
    height: ${height}px;
  `,
  circle: (theme, { width = 32, height = 32 }) => css`
    max-width: ${width}px;
    height: ${height}px;
    border-radius: 100%;
  `,
  rectangular: (theme, { width, height, isRounded, br }) => css`
    max-width: ${width ? `${width}px` : ''};
    height: ${height ? `${height}px` : ''};
    border-radius: ${isRounded ? br : 0}px;
  `,
  isFullHeight: () => css`
    height: 100%;
  `,
  isFullWidth: () => css`
    width: 100%;
    max-width: 100%;
  `,
};
