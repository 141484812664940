import { css } from 'styled-components';

const styles = ({ theme }) => css`
  background-color: ${theme.colorPallete.base[700]};
  width: 100%;
  height: auto;
  padding: 3rem 0.5rem;
  padding-bottom: 1rem;

  @media all and (min-width: ${theme.breakpoints.medium}) {
    padding: 4.8rem 9rem 1rem 9rem;
  }

  a {
    cursor: pointer;
  }

  span {
    /* font-size: 1.3rem; */
  }

  p {
    @media all and (max-width: 767px) {
      padding-left: 1.5rem;
    }
  }

  .footer__content {
    display: block;
    margin-bottom: 3rem;

    @media all and (min-width: 1320px) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    ul {
      list-style-type: none;
    }

    h5 {
      padding-bottom: 1rem;
    }

    .footer-grid {
      max-width: 80rem;
      margin-bottom: 2rem;
    }

    .info__logo {
      padding-bottom: 2rem;

      margin: 0 4rem 0 1.5rem;

      @media all and (min-width: 1320px) {
        margin: 0 4rem 0 0;
      }

      svg,
      img {
        height: auto;
      }
    }

    .content__info {
      display: flex;
      flex-wrap: wrap;

      @media all and (min-width: 1320px) {
        width: auto;
        max-width: 100%;
      }

      li {
        padding: 1.2rem 0 0;
        margin: 0 0 1rem;

        a {
          display: inline-block;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }

      .info__sitemap,
      .info__blog,
      .info__institution {
        padding: 0 1.5rem 1rem;
        margin-right: 4rem;
      }

      @media all and (min-width: 360px) {
        .info__sitemap,
        .info__blog {
          padding: 0 0 1rem 1.5rem;
        }
      }

      @media all and (min-width: ${theme.breakpoints.medium}) {
        .info__sitemap,
        .info__blog,
        .info__institution {
          padding: 0 1.5rem 1rem;
        }
      }

      .info__institution {
        ul {
          padding-right: 1rem;
        }
      }
    }

    .content__buttons {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 26rem;
      max-width: 100%;
      margin: 0 0 0 1.5rem;

      @media all and (min-width: 360px) {
        width: 30rem;
      }

      @media all and (min-width: ${theme.breakpoints.medium}) {
        flex-direction: row-reverse;
      }

      @media all and (min-width: 1320px) {
        max-width: 60rem;
        margin: 0 0 0 auto;
      }

      .buttons__download {
        max-width: 13rem;

        a {
          display: block;
          outline: none;
          margin: 0.5rem 0;
          margin-bottom: 1rem;

          &:last-child {
            margin: 0;
          }
        }
      }

      .buttons__social {
        span {
          display: block;
          padding-top: 0.5rem;

          a {
            outline: none;
            margin: 0.5rem;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`;

export default styles;
export { styles };
