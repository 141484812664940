import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { actions as notificationAction } from 'store/ducks/notification';
import * as S from './Notifications.styles';
import { IconBell, IconVibratingBell, IconMedalChecked } from 'components';
import { characterLimiter } from 'helpers';

interface NotificationsProps {
  notificationsRef: React.MutableRefObject<any>;
  isVisible: boolean;
  onClick: () => void;
}

const Notifications = ({
  notificationsRef,
  isVisible,
  onClick,
}: NotificationsProps) => {
  const dispatch = useDispatch();

  const {
    notificationReducer: { content },
    userReducer: { userData },
  } = useSelector((state: any) => state);

  const router = useRouter();

  const lerNotificacao = (idNotification: any) => {
    dispatch(
      notificationAction.setReadNotification({
        payload: { data: idNotification },
      }),
    );
  };

  const handleGoToNotification = (value: any) => {
    lerNotificacao(value.idNotification);
    if (value?.type === 'certificate') {
      router.push({
        pathname: `/certificados/${value?.data?.certificate_id}`,
        query: { courseId: value?.data?.course_id },
      });
    } else {
      router.push({
        pathname: `/curso/aula/${value?.data?.lesson}`,
        query: { idComment: value?.data?.commentId },
      });
    }
  };

  const NotificationContent = ({ value }: any) => {
    return (
      <S.ButtonNotification
        onClick={() => handleGoToNotification(value)}
        type="button">
        <S.NotificationBlockContent>
          {value?.type === 'certificate' ? (
            <IconMedalChecked
              color={value?.isRead ? '#868585' : '#DA571A'}
              size="32px"
            />
          ) : (
            <IconVibratingBell color={value?.isRead ? '#868585' : '#DA571A'} />
          )}
          <S.ContentTextNotification>
            <S.HeadNotification>
              {characterLimiter(value?.title, 40)}
            </S.HeadNotification>
            <S.DescriptionNotification>
              {characterLimiter(value?.description, 40)}
            </S.DescriptionNotification>
          </S.ContentTextNotification>
        </S.NotificationBlockContent>

        <S.CreatedAtNotification>
          {value?.createdAtDiff.replace(' ', '')}
        </S.CreatedAtNotification>
      </S.ButtonNotification>
    );
  };

  useEffect(() => {
    dispatch(notificationAction.getNotification({ payload: {} }));
  }, []);

  return (
    <S.Wrapper ref={notificationsRef}>
      <S.ButtonNotification
        onClick={onClick}
        className={`caixa-icone-notificacao ${isVisible ? 'visible' : ''}`}
        type="button">
        <S.AmountNotification>{content?.total || 0}</S.AmountNotification>
        <IconBell />
      </S.ButtonNotification>

      {isVisible && (
        <S.ContainerNotification>
          <S.HeadContentNotification>Notificações</S.HeadContentNotification>
          {content?.notifications.map((value: any) => {
            return <NotificationContent value={value} />;
          })}
        </S.ContainerNotification>
      )}
    </S.Wrapper>
  );
};

export default memo(Notifications);
