/**
 * ---------------------------------------------------
 * KEEP WATCHING
 * ---------------------------------------------------
 */
export const GET_KEEP_WATCHING_REQUEST =
  '@keepWatching/GET_KEEP_WATCHING_REQUEST';
export const GET_KEEP_WATCHING_SUCCESS =
  '@keepWatching/GET_KEEP_WATCHING_SUCCESS';
export const GET_KEEP_WATCHING_FAILURE =
  '@keepWatching/GET_KEEP_WATCHING_FAILURE';
