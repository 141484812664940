import React from 'react';

import * as S from './FiltersByThemeSkeleton.styles';
import { Skeleton } from 'skeletons/Skeleton';

const FiltersByThemeSkeleton = () => {
  return (
    <S.SkeletorWrapper>
      <S.TitleContainer>
        <Skeleton height={30} width={130} mt={36} />
      </S.TitleContainer>
      <S.FilteredItems>
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
        <Skeleton br={100} height={30} width={130} />
      </S.FilteredItems>
    </S.SkeletorWrapper>
  );
};

export default FiltersByThemeSkeleton;
export { FiltersByThemeSkeleton };
