import React from 'react';
import PropTypes from 'prop-types';

const IconLeftArrow = React.memo(({ size, color }) => (
  <svg
    id="icon-left-arrow"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.39769 14.1844C9.82412 14.5721 9.85554 15.232 9.46788 15.6584C9.08022 16.0849 8.42028 16.1163 7.99385 15.7286L0.341687 8.77211C-0.113724 8.3581 -0.113724 7.6419 0.341687 7.22789L7.99385 0.271374C8.42028 -0.116285 9.08022 -0.0848591 9.46788 0.341566C9.85554 0.767991 9.82412 1.42794 9.39769 1.8156L2.59485 8L9.39769 14.1844Z"
      fill={color}
    />
  </svg>
));

IconLeftArrow.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconLeftArrow.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconLeftArrow;
export { IconLeftArrow };
