import { css } from 'styled-components';
import { main as theme } from '@themes';

const styles = ({ colorStrip, colorBg }) => css`
  width: 100%;
  max-width: 120rem;
  height: 100%;
  padding-left: 2rem;
  border-radius: 0.4rem;
  overflow: hidden;
  background-color: ${colorBg};
  display: flex;
  justify-content: flex-start;
  position: relative;

  .notification__color {
    display: flex;
    width: 1rem;
    height: inherit;
    background-color: ${colorStrip};
    position: absolute;
    left: 0;
  }

  .notification__filling {
    width: 100%;
    padding: 2rem;
    padding-left: 2.4rem;

    .notification__filling__top-container {
      display: flex;

      .top-container__image {
        width: 11rem;
        max-width: 11rem;
        min-width: 11rem;
        max-height: auto;
        margin-bottom: 1rem;
        margin-right: 1rem;
      }

      .top-container__icon {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: auto;
        margin-right: 2.5rem;
        padding-top: 1rem;
      }

      .top-container__header__title {
        margin: 0.5rem 0;
      }

      .top-container__header__subtitle {
        margin-bottom: 1rem;
      }
    }

    .notification__filling__text {
      display: block;
      width: 100%;
      max-width: 100rem;
    }
  }

  .notification__close-button {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;

    button {
      background-color: transparent;
      cursor: pointer;
    }
  }
`;

export default styles;
export { styles };
