import React from 'react';

interface Props {
  color?: string;
}

const IconPlusThin = React.memo(({ color = '#fff' }: Props) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0.166504V10.1665" stroke={color} />
    <path d="M10 5.1665L-1.63913e-07 5.1665" stroke={color} />
  </svg>
));

export default IconPlusThin;
export { IconPlusThin };
