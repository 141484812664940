/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  error: null,
  loading: null,
  homeProgress: null,
  courseProgress: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.userReducer,
      };

    /**
     * ---------------------------------------------------
     * SEND PROGRESS
     * ---------------------------------------------------
     */
    case types.SEND_PROGRESS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_PROGRESS_SUCCESS:
      return { ...state, getNewProgress: true, loading: false, error: false };
    case types.SEND_PROGRESS_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * GET PROGRESS - HOME
     * ---------------------------------------------------
     */
    case types.GET_HOME_NEW_PROGRESS:
      return { ...state, homeProgress: action.payload };

    /**
     * ---------------------------------------------------
     * GET PROGRESS - COURSE
     * ---------------------------------------------------
     */

    case types.GET_COURSE_NEW_PROGRESS:
      return { ...state, courseProgress: action.payload };

    default:
      return state;
  }
}

export { actions, sagas };
