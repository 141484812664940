import React from 'react';
import PropTypes from 'prop-types';

const IconDownload = React.memo(({ size, color }) => (
  <svg
    id="icon-download"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 18.2C10.8 18.2 10.5 18.1 10.3 17.9L3.2 10.8C2.8 10.4 2.8 9.8 3.2 9.4C3.6 9 4.2 9 4.6 9.4L10 14.8V1C10 0.4 10.4 0 11 0C11.6 0 12 0.4 12 1V14.8L17.4 9.4C17.8 9 18.4 9 18.8 9.4C19.2 9.8 19.2 10.4 18.8 10.8L11.7 17.9C11.5 18.1 11.2 18.2 11 18.2ZM1 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22H1C0.4 22 0 21.6 0 21C0 20.4 0.4 20 1 20Z"
      fill={color}
    />
  </svg>
));

IconDownload.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconDownload.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconDownload;
export { IconDownload };
