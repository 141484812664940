/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */

import * as Sentry from '@sentry/react';
import { withScope, configureScope } from '@sentry/browser';
import getConfig from 'next/config';

import packageInfo from '../../../../../package.json';

const { publicRuntimeConfig } = getConfig();

export default class SentryAdapter {
  createStoreWatcher() {
    this.storeWatchers = Sentry.createReduxEnhancer({
      actionTransformer: (action) => {
        const transformerdAction = {
          ...action,
          payload: 'hidden',
        };
        return transformerdAction;
      },
    });
  }

  init() {
    const ignoreClientErrors = [
      'Request failed with status code 400',
      '401 Unauthorized',
      'Request failed with status code 401',
      'Request failed with status code 403',
      'Request failed with status code 404',
      '422 Unprocessable Entity',
      'Request failed with status code 422',
      'self._mergeOptions is not a function',
      "undefined is not an object (evaluating 'e.config.url')",
      "null is not an object (evaluating 'e.element.contentWindow.postMessage')",
      'Seconds must be a positive number less than the duration of the video',
    ];
    const fieldsToRemove = [
      'name',
      'email',
      'token',
      'phone',
      'mobilephone',
      'document',
      'rg',
      'cpf',
      'dateOfBirthday',
      'street',
      'streetNumber',
      'complement',
      'neighborhood',
      'city',
      'postalCode',
      'createdbyName',
      'addressUpdated',
      'documentUpdated',
      'emailUpdated',
      'newPassword',
      'auth_token',
      'paidAt',
      'brand',
      'firstSix',
      'lastFour',
      'expireMonth',
      'expireYear',
      'holderName',
      'contactName',
      'contactEmail',
      'contactPhone',
      'createdByName',
      'lastModifiedByName',
      'gender',
      'avatar',
      'province',
      'zip_code',
      'userData',
    ];
    Sentry.init({
      dsn: process.env.SENTRY_DSN || publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN,
      normalizeDepth: 10,
      environment:
        process.env.SENTRY_ENV || publicRuntimeConfig.NEXT_PUBLIC_SENTRY_ENV,
      release: packageInfo.version,
      async beforeSend(event, hint) {
        async function sanatizeObject(baseObject, fieldsToRemove) {
          const body = baseObject;
          if (!fieldsToRemove || fieldsToRemove.length === 0) return body;
          const encryptFields = async (body, fieldsToRemove) => {
            if (Array.isArray(body)) {
              for (const object of body)
                await encryptFields(object, fieldsToRemove);
            }
            if (typeof body === 'object') {
              for (const field in body) {
                if (typeof body[field] === 'object')
                  await encryptFields(body[field], fieldsToRemove);
                if (fieldsToRemove.includes(field)) {
                  body[field] = '* * * * *';
                  continue;
                }
              }
            }
            return body;
          };
          return encryptFields(body, fieldsToRemove);
        }

        const error = hint.originalException;
        event.message = error.message;

        if (event.message === 'self._mergeOptions is not a function') {
          return null;
        }

        if (event.contexts) {
          const transformedState = await sanatizeObject(
            event.contexts.state,
            fieldsToRemove,
          );
          event.contexts.state = transformedState;
          return event;
        }
        return event;
      },
      ignoreErrors: ignoreClientErrors,
      tracesSampleRate: 0.1,
    });
  }

  captureException(transactionName, severityLevel, error, details) {
    Sentry.withScope((scope) => {
      scope.setLevel(severityLevel);
      scope.setTransactionName(transactionName);
      if (details) {
        scope.setExtra('Error Details', details);
      }
      Sentry.captureException(error);
    });
  }

  captureLog(message, severityLevel, details) {
    Sentry.withScope((scope) => {
      scope.setLevel(severityLevel);
      scope.setTransactionName(message);
      if (details) {
        scope.setExtra('Details', details);
      }
      scope.setFingerprint([message, severityLevel]);
      Sentry.captureMessage(message);
    });
  }

  captureUIException(error, { componentStack }) {
    withScope((scope) => {
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = componentStack;

      scope.setTag('Origin', 'ErrorBoundary');
      scope.setExtra('Cause', errorBoundaryError);
      scope.setExtra('stack', error.stack);
      scope.setExtra('Componentstack', componentStack);
      scope.setExtra('Name', error.name);
      scope.setExtra('ErrorMessage', error.message);
      configureScope((scope) =>
        scope.setTransactionName('Capturado com Error Boundary'),
      );
      const eventId = Sentry.captureException(error, {
        contexts: { react: { componentStack } },
      });
      scope.setExtra('eventId', eventId);
    });
  }

  captureHTTPException(error) {
    Sentry.withScope((scope) => {
      const requestUrl = error?.config?.url;
      const statusCode = error?.response?.status;
      const requestMethod = error?.config?.method;
      const errorMessage = error?.response?.data?.error;
      const genericErrorMessage = error?.message;
      scope.setFingerprint([requestUrl, requestMethod, String(statusCode)]);
      scope.setExtra('requestUrl', requestUrl);
      scope.setExtra('statusCode', statusCode);
      scope.setExtra('requestMethod', requestMethod);
      scope.setExtra('errorMessage', errorMessage);
      scope.setExtra('genericErrorMessage', genericErrorMessage);
      scope.setExtra('date', new Date());
      scope.setExtra('originalError', error);
      scope.setTag('requestUrl', requestUrl);
      scope.setTag('statusCode', statusCode);
      scope.setTag('requestMethod', requestMethod);
      Sentry.configureScope((scope) =>
        scope.setTransactionName('Axios request error'),
      );
      Sentry.captureException(error);
    });
  }
}
