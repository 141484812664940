import styled, { css } from 'styled-components';
import Link from 'next/link';
interface BannerProps {
  url: string;
}

interface BadgeProps {
  textColor: string;
  color: string;
}

interface ButtonProps {
  color: string;
}

interface DescriptionProps {
  textColor: string;
}

interface ContainerProgressProps {
  color: string;
}

interface ProgressProps {
  color: string;
  progress: number;
}

export const Wrapper = styled.div`
  ${() => css`
    width: 100%;
    position: relative;

    @media (min-width: 768px) {
      padding: 1vw;
    }
  `}
`;

export const Banner = styled.div<BannerProps>`
  ${({ theme, url }) => css`
    display: flex;
    width: 100%;
    aspect-ratio: 0.74;
    flex-direction: column-reverse;
    background-image: url(${url});
    background-size: cover;
    background-position: top center;

    @media (min-width: 768px) {
      flex-direction: row;
      border-radius: 12px;
      aspect-ratio: 3.5;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  gap: 16px;

  @media (min-width: 768px) {
    justify-content: center;
  }

  &.filler {
    height: 20%;
  }

  &.banner-content {
    padding: 0 15px 30px 0px;
    height: 100%;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 16px;
      width: 100%;

      padding-left: calc(10vw - 16px);
      @media (min-width: 768px) {
        justify-content: flex-end;
        gap: 10px;
      }

      @media (min-width: 1024px) {
        gap: min(32px, 2vw);
        width: 70%;
      }

      @media (min-width: 1400px) {
        gap: 32px;
      }
    }

    @media (min-width: 768px) {
      margin-top: 45px;
    }
  }
`;

export const ReturnButton = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;

    background-color: transparent;
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.md};
    color: ${theme.colorPallete.white};
    font-weight: ${theme.fonts.fontWeight.bold};

    position: absolute;
    top: max(15%, 80px);
    left: 10px;

    @media (min-width: 768px) {
      position: static;
      margin: 12px 24px 24px 24px;
    }

    @media (min-width: 1024px) {
      position: static;
      margin: 0 24px 24px 24px;
    }
  `};
`;

export const ContainerProgress = styled.div<ContainerProgressProps>`
  ${({ color, theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 18px;
    margin-left: 0;
    width: 80%;
    gap: 14px;

    order: 1;

    @media (min-width: 768px) {
      order: 5;
    }

    @media (min-width: 1500px) {
      margin-block: 0 9px;
    }

    p {
      font-size: ${theme.fonts.fontSize.md};
      color: ${color};
      font-weight: ${theme.fonts.fontWeight.regular};
    }
  `}
`;

export const ProgressBar = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 4px;
    background: ${theme.colorPallete.base[425]};
    border-radius: 10px;
  `}
`;

export const Progress = styled.div<ProgressProps>`
  ${({ progress, color, theme }) => css`
    width: calc(${progress} * 100%);
    margin-left: 0;
    height: 100%;
    background-color: ${color};
    border-radius: 10px;
  `}
`;

export const CourseType = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-size: ${theme.fonts.fontSize.sm};
    font-weight: ${theme.fonts.fontWeight.bold};
    margin-top: 8px;

    order: 2;

    @media (min-width: 768px) {
      display: none;
    }
  `}
`;

export const TitleImage = styled.img`
  width: min(100%, 500px);
  max-height: 70px;
  object-fit: contain;
  object-position: left top;

  order: 3;

  @media (min-width: 768px) {
    max-height: 6vw;
  }
`;

export const Description = styled.p<DescriptionProps>`
  ${({ theme, textColor }) => css`
    width: 100%;
    font-size: max(12px, 0.9vw);
    line-height: 1.3;

    color: ${textColor};
    font-family: ${theme.fonts.fontFamily.sans};

    order: 4;

    @media (min-width: 1024px) {
      width: 90%;
    }
  `}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: ${theme.fonts.fontWeight.bold};
    font-family: ${theme.fonts.fontFamily.sans};
    gap: 8px;
    font-size: max(14px, 0.9vw);
    white-space: nowrap;

    order: 6;

    @media (min-width: 768px) {
      justify-content: flex-start;
      flex-direction: row;
      gap: 16px;
    }
  `}
`;

export const Button = styled.button<ButtonProps>`
  ${({ color, theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-block: 13px;
    color: ${theme.colorPallete.white};
    border-radius: 3px;
    background-color: ${color};
    font-weight: inherit;
    font-size: inherit;
    cursor: pointer;

    @media (min-width: 768px) {
      width: 200px;
      padding-block: 8px;
    }

    @media (min-width: 1024px) {
      padding-block: 13px;
      width: 275px;
    }

    @media (min-width: 2100px) {
      width: 350px;
    }
  `}
`;

export const CertificateInfoConatiner = styled.div`
  ${({ theme }) => css`
    max-width: 300px;
    position: relative;
    order: 7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  `}
`;

export const CertificateText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-feature-settings:
      'clig' off,
      'liga' off;

    p {
      font-size: ${theme.fonts.fontSize.sm};
      font-weight: ${theme.fonts.fontWeight.regular};

      @media (min-width: 1400) {
        font-size: ${theme.fonts.fontSize.md};
      }
    }

    h4 {
      font-size: ${theme.fonts.fontSize.md};
      font-weight: ${theme.fonts.fontWeight.bold};

      @media (min-width: 1400) {
        font-size: ${theme.fonts.fontSize.g};
      }
    }
  `}
`;

export const InfoButton = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0;
  margin-bottom: -4px;

  align-self: flex-end;
  cursor: pointer;
`;

export const TooltipContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorPallete.white};
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.sm};

    max-width: 375px;

    @media (max-width: 768px) {
      font-size: ${theme.fonts.fontSize.xs};
    }

    h5 {
      font-size: ${theme.fonts.fontSize.sm};
      font-weight: ${theme.fonts.fontWeight.bold};

      @media (max-width: 768px) {
        font-size: ${theme.fonts.fontSize.xs};
      }
    }

    ul {
      margin-inline: 24px 12px;
      margin-block: 12px;
    }

    p {
      font-style: italic;
    }
  `}
`;
