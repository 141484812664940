import { css } from 'styled-components';

const InputStyles = ({ theme }) => css`
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: ${theme.fonts.fontFamily.sans};
  font-weight: ${theme.fonts.fontWeight.regular};

  z-index: 1;

  .mp-input__element.password:placeholder-shown + .mp-input__password--toggle {
    display: none;
  }

  .mp-input {
    &__label {
      color: ${theme.colorPallete.primary.default};
      font-size: 1.4rem;
      font-weight: ${theme.fonts.fontWeight.bold};
      padding: 0.3rem;
      margin: 0;
    }

    &__element:not([type='submit']),
    &__select {
      font-family: ${theme.fonts.fontFamily.sans};
      font-size: 1.4rem;
      padding: 1.5rem;
      color: ${theme.colorPallete.base.default};
      background-color: transparent;
      border-radius: 0.4rem;
      border: 0.1rem solid ${theme.colorPallete.base[400]};
      height: 5rem;
      resize: none;

      transition: all 0.1s ease-in-out !important;

      &:placeholder-shown {
        border: 0.1rem solid ${theme.colorPallete.base[400]};
      }

      &:-webkit-autofill,
      &:-internal-autofill-selected {
        box-shadow: inset 0 0 0 1px ${theme.colorSystem.white},
          inset 0 0 0 100px ${theme.colorSystem.white}!important;
        background: ${theme.colorSystem.white}!important;

        color: ${theme.colorPallete.base.default}!important;
        -webkit-text-fill-color: ${theme.colorPallete.base.default}!important;

        transition: all 0.2s ease-in-out !important;
      }

      &.password {
        padding-right: 3.5rem;
      }

      &.textarea {
        min-height: 5.8rem;
        font-family: ${theme.fonts.fontFamily.sans};
        color: ${theme.colorPallete.base.default};
      }

      &.readonly {
        opacity: 0.6;
      }

      &.resize {
        resize: vertical;
      }

      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
        border: 0.1rem solid ${theme.colorPallete.primary[500]};
      }

      &:not(:placeholder-shown) {
        border: 0.1rem solid ${theme.colorPallete.primary.default};
        outline: none;
        box-shadow: none;
      }

      &:invalid {
        border: 0.1rem solid ${theme.colorSystem.danger};
        outline: none;
        box-shadow: none;
      }
    }

    &__password {
      font-family: ${theme.fonts.fontFamily.sans};

      &--toggle {
        position: absolute;
        top: 3.5rem;
        right: 2rem;
        text-indent: -299em;
        overflow: hidden;

        width: 2.3rem;
        height: 3rem;

        background-color: transparent;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjAuMSAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAuMSAxNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM4Njg1ODU7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTAsNC4yYzAuNywwLDEuNSwwLjMsMiwwLjhzMC44LDEuMiwwLjgsMlMxMi41LDguNSwxMiw5Yy0wLjUsMC41LTEuMiwwLjgtMiwwLjhjLTAuNywwLTEuNS0wLjMtMi0wLjgKCUM3LjUsOC41LDcuMiw3LjcsNy4yLDdzMC4zLTEuNSwwLjgtMlM5LjMsNC4yLDEwLDQuMnogTTEwLDBjNC4yLDAsNy45LDIuNCw5LjgsNS45YzAuNCwwLjcsMC40LDEuNSwwLDIuMUMxOCwxMS42LDE0LjMsMTQsMTAsMTQKCWMtNC4yLDAtNy45LTIuNC05LjgtNS45Yy0wLjQtMC43LTAuNC0xLjUsMC0yLjFDMi4xLDIuNCw1LjgsMCwxMCwweiBNMi42LDUuN0MyLDYuNSwyLDcuNSwyLjYsOC4zYzEuNywyLjQsNC41LDMuOSw3LjUsMy45CgljMywwLDUuOC0xLjUsNy41LTMuOWMwLjUtMC44LDAuNS0xLjgsMC0yLjVjLTEuNy0yLjQtNC41LTMuOS03LjUtMy45QzcsMS45LDQuMywzLjMsMi42LDUuN3oiLz4KPC9zdmc+Cg==);

        background-repeat: no-repeat;
        background-position: center center;

        cursor: pointer;

        z-index: 3;

        &.hide {
          top: 3.65rem;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjAgMTYuNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAgMTYuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM4Njg1ODU7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMS4zLDEuNUMxLDEuMSwxLDAuNiwxLjMsMC4yYzAuMy0wLjMsMC45LTAuMywxLjIsMEwxNi45LDE1YzAuMywwLjMsMC4zLDAuOSwwLDEuMmMtMC4zLDAuMy0wLjksMC4zLTEuMiwwCglsLTIuMy0yLjNjLTEuMSwwLjQtMi4yLDAuNi0zLjQsMC42Yy00LjIsMC03LjktMi41LTkuOC02LjFjLTAuMy0wLjctMC4zLTEuNSwwLTIuMUMwLjksNSwxLjcsMy45LDIuNywzTDEuMywxLjV6IE0xMCw0LjQKCWMwLjcsMCwxLjUsMC4zLDIsMC44YzAuNSwwLjUsMC44LDEuMywwLjgsMmMwLDAuMy0wLjEsMC43LTAuMiwxTDkuMSw0LjZDOS40LDQuNSw5LjcsNC40LDEwLDQuNHogTTEwLDAuMWM0LjIsMCw3LjksMi41LDkuOCw2LjEKCWMwLjMsMC43LDAuMywxLjUsMCwyLjJjLTAuOCwxLjUtMS45LDIuOS0zLjIsMy45bC0xLjMtMS40YzAuOS0wLjYsMS43LTEuNCwyLjMtMi4zQzE4LDcuOCwxOCw2LjgsMTcuNSw2QzE1LjgsMy42LDEzLDIsMTAsMgoJQzksMiw4LDIuMiw3LjEsMi41TDUuNiwxLjFDNywwLjUsOC41LDAuMSwxMCwwLjF6IE0yLjUsNkMyLDYuOCwyLDcuOCwyLjUsOC42YzEuNywyLjUsNC41LDQsNy41LDRjMC42LDAsMS4zLTAuMSwxLjktMC4ybC0yLjEtMi4yCglDOC40LDEwLDcuNCw4LjksNy4yLDcuNkw0LjEsNC4zQzMuNSw0LjgsMyw1LjQsMi41LDZ6Ii8+Cjwvc3ZnPgo=);
        }
      }
    }

    &__select {
      padding: 0 !important;
      position: relative;
      font-family: ${theme.fonts.fontFamily.sans};

      &.secondary {
        border-color: ${theme.colorSystem.white} !important;
        color: ${theme.colorSystem.white}!important;
      }

      &--arrow {
        display: block;
        position: absolute;
        top: 1.9rem;
        right: 1rem;

        height: 1.1rem;
        width: 1.8rem;

        pointer-events: none;
      }

      &--custom {
        width: 100%;
        display: block;
        background: transparent;
        color: inherit;
        font-size: 1.4rem;
        font-family: ${theme.fonts.fontFamily.sans};
        padding: 1.5rem;

        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        appearance: none;

        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }

        option {
          font-family: ${theme.fonts.fontFamily.sans};
          color: ${theme.colorPallete.base.default};
          background: ${theme.colorSystem.white};
          /* padding: 0.3rem 2rem 0.3rem 0rem; */
          padding: 0 3rem 0 0;
        }
      }

      &.secondary {
        border-color: ${theme.colorSystem.white};
        color: ${theme.colorSystem.white};
      }
    }

    &__tip {
      color: ${theme.colorPallete.base.default};
      font-size: 1.2rem;
      padding: 0.3rem;
      margin-top: 0.5rem;
      transition: all 0.3s ease-in-out;
    }
  }

  &:focus-within {
    .mp-input {
      &__element:not([type='submit']) {
        color: ${theme.colorPallete.base.default};
        border: 0.1rem solid ${theme.colorPallete.primary[500]};

        &.textarea {
          display: block;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  &.invalid {
    .mp-input {
      &__element:not([type='submit']),
      &__element:not([type='submit']):invalid {
        border: 0.1rem solid ${theme.colorSystem.danger};
        transition: all 0.1s ease-in-out !important;
      }

      &__tip {
        color: ${theme.colorSystem.danger};
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &.valid {
    .mp-input {
      &__element:not([type='submit']),
      &__element:not([type='submit']):valid {
        border: 0.1rem solid ${theme.colorSystem.success};
        transition: all 0.1s ease-in-out !important;
      }

      &__tip {
        color: ${theme.colorSystem.success};
        transition: all 0.3s ease-in-out;
      }
    }
  }

  /* overwrite plugin: country selection */
  .PhoneInputInput,
  .PhoneInputCountrySelect {
    background-color: transparent;
    color: inherit;
    font-size: 1.2rem;
    outline: none !important;
    box-shadow: none !important;
  }
`;

export default InputStyles;
export { InputStyles };
