import React from 'react';
import PropTypes from 'prop-types';

const IconClosedEye = React.memo(({ size, color }) => (
  <svg
    id="icon-closed-eye"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.56711 1.82511C1.22975 1.48775 1.23082 0.940439 1.56951 0.604398C1.90632 0.270216 2.44995 0.271282 2.78545 0.606782L17.5488 15.3701C17.8843 15.7056 17.8853 16.2492 17.5512 16.586C17.2151 16.9247 16.6678 16.9258 16.3304 16.5884L13.9989 14.2569C12.9 14.62 11.7342 14.8111 10.5111 14.8111C6.15168 14.8111 2.373 12.337 0.488912 8.71707C0.138999 8.04478 0.138059 7.24388 0.48749 6.57134C1.13261 5.3297 2.0029 4.22461 3.04822 3.30622L1.56711 1.82511ZM10.5111 4.77778C11.2714 4.77778 12.0005 5.0798 12.5381 5.6174C13.0758 6.15501 13.3778 6.88416 13.3778 7.64444C13.3778 7.97889 13.3204 8.30378 13.2153 8.6L9.55555 4.94022C9.85178 4.83511 10.1767 4.77778 10.5111 4.77778ZM10.5111 0.477778C14.8694 0.477778 18.6472 2.95059 20.5318 6.56891C20.8828 7.24284 20.8828 8.04591 20.5319 8.71988C19.7336 10.253 18.5937 11.5838 17.2 12.6038L15.8431 11.2373C16.7483 10.6117 17.5423 9.82839 18.1862 8.9133C18.7221 8.15173 18.723 7.13644 18.1873 6.37479C16.4496 3.9045 13.6043 2.38889 10.5111 2.38889C9.46955 2.38889 8.44711 2.56089 7.49155 2.86667L6.02 1.40467C7.396 0.812222 8.91533 0.477778 10.5111 0.477778ZM2.83338 6.377C2.29906 7.13791 2.29876 8.1514 2.8335 8.91201C4.57105 11.3835 7.41707 12.9 10.5111 12.9C11.1704 12.9 11.8202 12.8331 12.4222 12.6993L10.2436 10.5111C8.87711 10.3678 7.78778 9.27844 7.64444 7.912L4.39555 4.65356C3.81125 5.15523 3.28527 5.73347 2.83338 6.377Z"
      fill={color}
    />
  </svg>
));

IconClosedEye.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconClosedEye.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconClosedEye;
export { IconClosedEye };
