/* eslint-disable no-case-declarations */
import { HYDRATE } from 'next-redux-wrapper';
import * as types from './types';
import * as actions from './actions';
// eslint-disable-next-line import/no-cycle
import { sagas } from './sagas';

const INITIAL_STATE = {
  editModal: false,
  error: null,
  firstAccessData: null,
  loading: null,
  profileData: null,
  reduxMock: false,
  searchData: null,
  searchLoading: null,
  dataLayerRed: null,
  userData: null,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE:
      const userData = action.payload.userReducer.userData
        ? action.payload.userReducer.userData
        : state.userData;
      return {
        ...state,
        ...action.payload.userReducer,
        userData,
      };

    /**
     * ---------------------------------------------------
     * AUTH
     * ---------------------------------------------------
     */
    case types.LOGIN_REQUEST:
      return { ...state, loading: true, error: false };
    case types.LOGIN_REQUEST_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.LOGIN_REQUEST_SUCCESS_DATA_LAYER:
      return { ...state, dataLayerRed: action.payload.dataLayerRed, loading: false, error: false };
    case types.RESET_DATA_LAYER:
      return { ...state, dataLayerRed: action.payload, loading: false, error: false };
    case types.LOGIN_REQUEST_FAILURE:
      return { ...state, loading: false, error: true };

    case types.LOGOUT_REQUEST:
      return { ...state, loading: true, error: false };
    case types.LOGOUT_REQUEST_SUCCESS:
      return { state: INITIAL_STATE, loading: false, error: false };
    case types.LOGOUT_REQUEST_FAILURE:
      return { state: INITIAL_STATE, loading: false, error: false };

    /**
     * ---------------------------------------------------
     * FIRST-ACCESS
     * ---------------------------------------------------
     */
    case types.GET_FIRST_ACCESS_REQUEST:
      return { ...state, firstAccessData: null, loading: true, error: false };
    case types.GET_FIRST_ACCESS_SUCCESS:
      return {
        ...state,
        firstAccessData: action.payload.firstAccessData,
        loading: false,
        error: false,
      };
    case types.GET_FIRST_ACCESS_FAILURE:
      return { ...state, firstAccessData: null, loading: false, error: true };

    case types.SEND_FIRST_ACCESS_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SEND_FIRST_ACCESS_SUCCESS:
      return {
        ...state,
        firstAccessData: action.payload.firstAccessData,
        loading: false,
        error: false,
      };
    case types.SEND_FIRST_ACCESS_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * PASSWORD
     * ---------------------------------------------------
     */
    case types.FORGOT_PASSWORD_EMAIL_REQUEST:
      return { ...state, loading: true, error: false };
    case types.FORGOT_PASSWORD_EMAIL_REQUEST_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.FORGOT_PASSWORD_EMAIL_REQUEST_FAILURE:
      return { ...state, loading: false, error: true };

    case types.VALIDATE_PASSWORD_TOKEN_REQUEST:
      return { ...state, loading: true, error: false };
    case types.VALIDATE_PASSWORD_TOKEN_REQUEST_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.VALIDATE_PASSWORD_TOKEN_REQUEST_FAILURE:
      return { ...state, loading: false, error: true };

    case types.RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: false };
    case types.RESET_PASSWORD_REQUEST_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.RESET_PASSWORD_REQUEST_FAILURE:
      return { ...state, loading: false, error: true };

    case types.FORGOT_NEW_PASSWORD_REQUEST:
      return { ...state, loading: true, error: false };
    case types.FORGOT_NEW_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.FORGOT_NEW_PASSWORD_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * PROFILE
     * ---------------------------------------------------
     */
    case types.GET_USER_DATA_REQUEST:
      return { ...state, userData: null, loading: true, error: false };
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload.userData,
        loading: false,
        error: false,
      };
    case types.GET_USER_DATA_FAILURE:
      return { ...state, userData: false, loading: false, error: true };

    case types.EDIT_PERSONAL_DATA_REQUEST:
      return { ...state, loading: true, error: false };
    case types.EDIT_PERSONAL_DATA_SUCCESS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          profile: action.payload.profileData,
        },
        userData: {
          ...state.userData,
          avatar: action.payload.profileData.avatar || state.userData.avatar,
          restriction: action.payload.profileData.restriction,
        },
        loading: false,
        error: false,
      };
    case types.EDIT_PERSONAL_DATA_FAILURE:
      return { ...state, loading: false, error: true };

    case types.EDIT_ADDRESS_DATA_REQUEST:
      return { ...state, loading: true, error: false };
    case types.EDIT_ADDRESS_DATA_SUCCESS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          profile: action.payload.profileData,
        },
        userData: {
          ...state.userData,
          restriction: action.payload.profileData.restriction,
        },
        loading: false,
        error: false,
      };
    case types.EDIT_ADDRESS_DATA_FAILURE:
      return { ...state, loading: false, error: true };

    case types.EDIT_FINANCIAL_DATA_REQUEST:
      return { ...state, loading: true, error: false };
    case types.EDIT_FINANCIAL_DATA_SUCCESS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          payment_method: action.payload.paymentMethodData,
        },
        loading: false,
        error: false,
      };
    case types.EDIT_FINANCIAL_DATA_FAILURE:
      return { ...state, loading: false, error: true };

    case types.LINK_TO_SOCIAL_REQUEST:
      return { ...state, loading: true, error: false };
    case types.LINK_TO_SOCIAL_SUCCESS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          profile: {
            ...state.profileData.profile,
            provider: action.payload.providerData,
          },
        },
        loading: false,
        error: false,
      };
    case types.LINK_TO_SOCIAL_FAILURE:
      return { ...state, loading: false, error: true };

    case types.SOCIAL_LOGIN_REQUEST:
      return { ...state, loading: true, error: false };
    case types.SOCIAL_LOGIN_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.SOCIAL_LOGIN_FAILURE:
      return { ...state, loading: false, error: true };

    case types.EDIT_NEWSLETTER_REQUEST:
      return { ...state, loading: true, error: false };
    case types.EDIT_NEWSLETTER_SUCCESS:
      return {
        ...state,
        profileData: { ...state.profileData, ...action.payload.profileData },
        loading: false,
        error: false,
      };
    case types.EDIT_NEWSLETTER_FAILURE:
      return { ...state, loading: false, error: true };

    case types.GET_PROFILE_DATA_REQUEST:
      return { ...state, profileData: null, loading: true, error: false };
    case types.GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profileData: action.payload.profileData,
        loading: false,
        error: false,
      };
    case types.GET_PROFILE_DATA_FAILURE:
      return { ...state, profileData: null, loading: false, error: true };

    case types.UPDATE_ZEROLESSON_REQUEST:
      return { ...state, loading: true, error: false };
    case types.UPDATE_ZEROLESSON_SUCCESS:
      return {
        ...state,
        userData: action.payload.userData,
        loading: false,
        error: false,
      };
    case types.UPDATE_ZEROLESSON_FAILURE:
      return { ...state, loading: false, error: true };

    /**
     * ---------------------------------------------------
     * EDIT MODAL
     * ---------------------------------------------------
     */
    case types.SET_EDIT_MODAL:
      return {
        ...state,
        editModal: action.payload.editModal,
      };

    /**
     * ---------------------------------------------------
     * DEFAULT (END SWITCH)
     * ---------------------------------------------------
     */
    default:
      return state;
  }
}

export { actions, sagas };
