import * as S from './CustomTooltip.styles';

interface CustomTooltipProps {
  children: JSX.Element;
  bottom?: string;
  right?: string;
  width?: string;
  shouldAppear?: boolean;
}

export const CustomTooltip = ({
  children,
  bottom = '0',
  right = '0',
  width = 'unset',
  shouldAppear = true,
}: CustomTooltipProps) => {
  return (
    <S.Wrapper
      bottom={bottom}
      right={right}
      width={width}
      shouldAppear={shouldAppear}>
      {children}
    </S.Wrapper>
  );
};
