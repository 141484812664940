import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PosterCard } from '@components';

import {CarouselContainer} from '@containers';

// styles
import { styles } from './PosterListStatic.styles';

const Wrapper = styled.div`
  ${styles}
`;

const PosterListStatic = ({ content, wrap }) => {
  return (
    <CarouselContainer>
      <Wrapper className={`poster-list ${wrap ? `wrap` : null}`}>
        {content.map((value) => {
          return (
            <PosterCard key={`${value.order + value.slug}`} content={value} />
            );
          })}
      </Wrapper>
    </CarouselContainer>
  );
};

PosterListStatic.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  wrap: PropTypes.bool,
};

PosterListStatic.defaultProps = {
  content: [],
  wrap: false,
};

export default PosterListStatic;
export { PosterListStatic };
