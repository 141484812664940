import React from 'react';

interface IconHomeHollowProps {
  color?: string;
}

const IconHomeHollow = React.memo(
  ({ color = '#7F7F7F' }: IconHomeHollowProps) => (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        stroke={color}
        d="M12.0238 17.1905V12.7619C12.0238 12.5661 11.9461 12.3784 11.8076 12.24C11.6692 12.1016 11.4815 12.0238 11.2857 12.0238H8.33335C8.13759 12.0238 7.94986 12.1016 7.81144 12.24C7.67302 12.3784 7.59525 12.5661 7.59525 12.7619V17.1905C7.59525 17.3862 7.51749 17.574 7.37907 17.7124C7.24065 17.8508 7.05291 17.9286 6.85716 17.9286H2.42859C2.23283 17.9286 2.04509 17.8508 1.90667 17.7124C1.76825 17.574 1.69049 17.3862 1.69049 17.1905V8.65624C1.69214 8.55409 1.71422 8.45331 1.75542 8.35982C1.79661 8.26634 1.8561 8.18204 1.93037 8.11189L9.31132 1.40445C9.44739 1.27997 9.62512 1.21094 9.80954 1.21094C9.99395 1.21094 10.1717 1.27997 10.3078 1.40445L17.6887 8.11189C17.763 8.18204 17.8225 8.26634 17.8637 8.35982C17.9049 8.45331 17.9269 8.55409 17.9286 8.65624V17.1905C17.9286 17.3862 17.8508 17.574 17.7124 17.7124C17.574 17.8508 17.3862 17.9286 17.1905 17.9286H12.7619C12.5662 17.9286 12.3784 17.8508 12.24 17.7124C12.1016 17.574 12.0238 17.3862 12.0238 17.1905Z"
        strokeWidth="1.47619"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default IconHomeHollow;
export { IconHomeHollow };
