import styled from 'styled-components';
import { main as theme } from '@themes';

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;

  svg {
    width: 100%;
    max-width: 120rem;
  }

  .smaller {
    display: block;
  }

  .small {
    display: none;
  }

  .medium {
    display: none;
  }

  @media all and (min-width: ${theme.breakpoints.small}) {
    .smaller {
      display: none;
    }

    .small {
      display: block;
    }

    .medium {
      display: none;
    }
  }

  @media all and (min-width: ${theme.breakpoints.medium}) {
    .smaller {
      display: none;
    }

    .small {
      display: none;
    }

    .medium {
      display: block;
    }
  }
`;

export default Wrapper;
export { Wrapper };
