import React from 'react';

interface Props {
  className?: string;
  color?: string;
  size?: string;
}

const IconInfo = React.memo(
  ({ className, color = '#fff', size = '23' }: Props) => (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 20.125C16.2635 20.125 20.125 16.2635 20.125 11.5C20.125 6.73654 16.2635 2.875 11.5 2.875C6.73654 2.875 2.875 6.73654 2.875 11.5C2.875 16.2635 6.73654 20.125 11.5 20.125Z"
        stroke={color}
        stroke-width="1.4375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7812 10.7812H11.5V15.8125H12.2188"
        stroke={color}
        stroke-width="1.4375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3203 8.625C11.9157 8.625 12.3984 8.14231 12.3984 7.54688C12.3984 6.95144 11.9157 6.46875 11.3203 6.46875C10.7249 6.46875 10.2422 6.95144 10.2422 7.54688C10.2422 8.14231 10.7249 8.625 11.3203 8.625Z"
        fill={color}
      />
    </svg>
  ),
);

export default IconInfo;
export { IconInfo };
