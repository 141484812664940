const maskPattern = (mask) => {
  switch (mask) {
    case 'cpf':
      return [
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    case 'cep':
      return [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    case 'phone':
      return [
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    case 'MM/AA':
      return [/[0-9]/, /\d/, '/', /\d/, /\d/];
    case 'MM/AAAA':
      return [/[0-9]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    case 'cardNumber':
      return [
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    case 'cardNumberAmex':
      return [
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    case 'cardNumberDC':
      return [
        /[0-9]/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    case 'CVV':
      return [/[0-9]/, /\d/, /\d/];
    case 'CVV4':
      return [/[0-9]/, /\d/, /\d/, /\d/];
    default:
      return false;
  }
};
export { maskPattern };
export default maskPattern;
