import { combineReducers } from 'redux';

import caseStudiesReducer from './caseStudies';
import catalogReducer from './catalog';
import checklistReducer from './checklist';
import courseReducer from './course';
import favoriteReducer from './favorite';
import keepWatchingReducer from './keepWatching';
import lessonReducer from './lesson';
import libraryReducer from './library';
import placeReducer from './place';
import searchReducer from './search';
import seriesReducer from './series';
import userReducer from './user';
import vvsReducer from './vvs';
import watchedReducer from './watched';
import notificationReducer from './notification';
import progressReducer from './progress';

export default combineReducers({
  caseStudiesReducer,
  catalogReducer,
  checklistReducer,
  courseReducer,
  favoriteReducer,
  keepWatchingReducer,
  lessonReducer,
  libraryReducer,
  placeReducer,
  searchReducer,
  seriesReducer,
  userReducer,
  vvsReducer,
  watchedReducer,
  notificationReducer,
  progressReducer,
});
