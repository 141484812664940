export interface Poster {
  id: string;
  order: number;
  type: string;
  title: string;
  company: string;
  currentLesson: string;
  excerpt: string;
  description: string;
  slug: string;
  image: {
    path: string;
    path_storage: string;
    alt: string;
  };
  tags: {
    text: string;
    slug: string;
  }[];
  mark: {
    text: string;
    color: string;
    text_color: string;
    type: string;
    direction_text: string;
    act: boolean;
  };
  autor: {
    type: string;
    title: string;
    slug: string;
  };
  duration: number;
  progress: string;
  real: string;
  course: string;
}

export const CardTypes = {
  CONTINUE: 'continueStuding',
  RECOMMENDED: 'recommendedCourse',
};
