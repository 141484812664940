import React from 'react';
import ContentLoader from 'react-content-loader';

// Theme
import { main } from '@themes';

// Skeleton
import { TitleSkeleton } from '../TitleSkeleton';

import { Wrapper } from './VideoSkeleton.styles';

const VideoSkeleton = () => {
  return (
    <Wrapper>
      <div className="video">
        <ContentLoader
          speed={1}
          width={375}
          viewBox="0 0 375 200"
          backgroundColor={main.colorPallete.base[700]}
          foregroundColor={main.colorPallete.base[600]}
        >
          <rect x="0" y="0" rx="8" ry="8" width="375" height="200" />
        </ContentLoader>
      </div>
      <div className="separator">
        <ContentLoader
          speed={1}
          width={375}
          viewBox="0 0 375 1"
          backgroundColor={main.colorPallete.base[700]}
          foregroundColor={main.colorPallete.base[600]}
        >
          <rect x="0" y="0" rx="0" ry="0" width="375" height="1" />
        </ContentLoader>
      </div>

      <div className="video-info">
        <TitleSkeleton />
      </div>
    </Wrapper>
  );
};

export default VideoSkeleton;
export { VideoSkeleton };
