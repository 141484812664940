import React from 'react';
import PropTypes from 'prop-types';

const IconError = React.memo(({ size, color }) => (
  <svg
    id="icon-error"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.13432 9L4.69033 5.55601C4.1132 4.97889 4.97889 4.1132 5.55601 4.69033L9 8.13432L12.444 4.69033C13.0211 4.1132 13.8868 4.97889 13.3097 5.55601L9.86568 9L13.3097 12.444C13.8868 13.0211 13.0211 13.8868 12.444 13.3097L9 9.86568L5.55601 13.3097C4.97889 13.8868 4.1132 13.0211 4.69033 12.444L8.13432 9Z"
      fill={color}
    />
  </svg>
));

IconError.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconError.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconError;
export { IconError };
