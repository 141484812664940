import * as types from './types';
import * as actions from './actions';
import { sagas } from './sagas';

const INITIAL_STATE = {
  statesData: [],
  citiesData: [],
  loading: false,
  error: false,
};

export default function stateControl(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_BR_STATES_REQUEST:
      return { ...state, statesData: [], loading: true, error: false };
    case types.GET_BR_STATES_SUCCESS:
      return {
        ...state,
        statesData: action.payload.statesData,
        loading: false,
        error: false,
      };
    case types.GET_BR_STATES_FAILURE:
      return {
        ...state,
        statesData: [],
        loading: false,
        error: true,
      };

    case types.GET_BR_CITIES_REQUEST:
      return { ...state, citiesData: [], loading: true, error: false };
    case types.GET_BR_CITIES_SUCCESS:
      return {
        ...state,
        citiesData: action.payload.citiesData,
        loading: false,
        error: false,
      };
    case types.GET_BR_CITIES_FAILURE:
      return {
        ...state,
        citiesData: [],
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}

export { actions, sagas };
