import { AxiosInstance } from 'axios';
import { MSApi } from 'libs/ms-api/contract';
import { BannersListItemDto, PaginationDto } from 'libs/ms-api/dtos';

type IBannersResource = MSApi['v3']['banners'];

export class BannersResource implements IBannersResource {
  constructor(private readonly msApi: AxiosInstance) {}

  async list(data: {
    bearerToken: string;
  }): Promise<PaginationDto<BannersListItemDto>> {
    if (data.bearerToken)
      this.msApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data.bearerToken}`;
    const { data: result } = await this.msApi.get(`v3/banner`);
    return result;
  }
}
