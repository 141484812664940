import React from 'react';
import PropTypes from 'prop-types';

const IconEye = React.memo(({ size, color }) => (
  <svg
    id="icon-eye"
    viewBox="0 0 21 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2667 4.2C11.0093 4.2 11.7215 4.495 12.2466 5.0201C12.7717 5.5452 13.0667 6.25739 13.0667 7C13.0667 7.74261 12.7717 8.4548 12.2466 8.9799C11.7215 9.505 11.0093 9.8 10.2667 9.8C9.52406 9.8 8.81187 9.505 8.28677 8.9799C7.76167 8.4548 7.46667 7.74261 7.46667 7C7.46667 6.25739 7.76167 5.5452 8.28677 5.0201C8.81187 4.495 9.52406 4.2 10.2667 4.2ZM10.2667 0C14.5139 0 18.1967 2.40431 20.0417 5.92533C20.3943 6.59827 20.3943 7.40173 20.0417 8.07467C18.1967 11.5957 14.5139 14 10.2667 14C6.01946 14 2.33662 11.5957 0.491637 8.07467C0.139025 7.40173 0.139025 6.59827 0.491637 5.92533C2.33662 2.40431 6.01946 0 10.2667 0ZM2.78674 5.73479C2.24962 6.49296 2.24962 7.50704 2.78674 8.26521C4.4856 10.6632 7.25591 12.1333 10.2667 12.1333C13.2774 12.1333 16.0477 10.6632 17.7466 8.26521C18.2837 7.50704 18.2837 6.49296 17.7466 5.73479C16.0477 3.33678 13.2774 1.86667 10.2667 1.86667C7.25591 1.86667 4.4856 3.33678 2.78674 5.73479Z"
      fill={color}
    />
  </svg>
));

IconEye.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

IconEye.defaultProps = {
  color: '#ffffff',
  size: '16px',
};

export default IconEye;
export { IconEye };
