import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// Actions
import { actions as userActions } from '@store/ducks/user';

// Themes
import { main as theme } from '@themes';

// Components
import { Button } from '../Button';
import { IconFacebookColored, IconLoading } from '../../Icons';
import { Text } from '../../Text';

// Styles
import { Wrapper } from './FacebookButton.styles';

const FacebookButton = ({ refreshToken, type, id }) => {
  const dispatch = useDispatch();

  const [eventClicked, toggleEventClicked] = useState(false);

  const { profileData, loading } = useSelector((state) => state.userReducer);

  const facebookResponse = (response) => {
    const data = {
      access_token: response.accessToken,
      provider: 'facebook',
      subscription: response.id,
    };

    if (type === 'login') {
      return dispatch(
        userActions.socialLoginRequest({
          payload: { data },
        })
      );
    }

    toggleEventClicked(true);

    return dispatch(
      userActions.linkToSocialRequest({
        payload: { auth: { refresh_token: refreshToken }, data },
      })
    );
  };

  return loading && eventClicked ? (
    <Wrapper>
      <IconLoading />
    </Wrapper>
  ) : (
    <FacebookLogin
      appId={process.env.FB_CLIENT_ID}
      render={(renderProps) => (
        <Button
          variant="secondary"
          size="medium"
          onClick={renderProps.onClick}
          disabled={profileData?.profile?.provider?.facebook}
          id={id}
        >
          <IconFacebookColored />
          <Text
            className="social-button"
            size="1.4rem"
            dsize="1.4rem"
            color={theme.colorPallete.white}
            weight="bold"
          >
            {profileData?.profile?.provider?.facebook
              ? 'Conectado com o Facebook'
              : 'Conectar Facebook'}
          </Text>
        </Button>
      )}
      buttonText="Login"
      callback={facebookResponse}
    />
  );
};

FacebookButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  refreshToken: PropTypes.string,
};

FacebookButton.defaultProps = {
  id: null,
  type: 'link',
  refreshToken: '',
};

export { FacebookButton };
export default FacebookButton;
