import { css } from 'styled-components';

const styles = ({ theme, disabled }) => css`
  width: 100%;
  text-decoration: none;
  outline: none;
  border-radius: 0.4rem;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-family: ${theme.fonts.fontFamily.sans};
  font-weight: ${theme.fonts.fontWeight.bold};
  cursor: ${disabled ? 'default' : 'pointer'};

  @media (min-width: ${theme.breakpoints.big}) {
    font-size: 1.6rem;
  }

  &:focus {
    box-shadow: 0rem 0rem 0.5rem ${theme.colorPallete.primary.default};
  }

  a {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    color: inherit;
    line-height: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;

    &.disabled {
      pointer-events: none;
    }
  }

  &.primary {
    color: ${theme.button.fontcolor.primary.action};
    background: ${theme.button.bgcolor.primary.action};
    border: 0.2rem solid
      ${disabled
        ? theme.button.bordercolor.primary.disabled
        : theme.button.bordercolor.primary.action};

    &:hover {
      color: ${theme.button.fontcolor.primary.hover};
      background: ${theme.button.bgcolor.primary.hover};
      border: 0.2rem solid
        ${disabled
          ? theme.button.bordercolor.primary.disabled
          : theme.button.bordercolor.primary.hover};
    }

    &:active {
      color: ${theme.button.fontcolor.primary.pressed};
      background: ${theme.button.bgcolor.primary.pressed};
      border: 0.2rem solid
        ${disabled
          ? theme.button.bordercolor.primary.disabled
          : theme.button.bordercolor.primary.pressed};
    }

    &:disabled,
    a.disabled {
      color: ${theme.button.fontcolor.primary.disabled};
      background: ${theme.button.bgcolor.primary.disabled};
      border: 0.2rem solid ${theme.button.bordercolor.primary.disabled};
    }
  }

  &.secondary {
    color: ${theme.button.fontcolor.secondary.action};
    background: ${theme.button.bgcolor.secondary.action};
    border: 0.2rem solid
      ${disabled
        ? theme.button.bordercolor.secondary.disabled
        : theme.button.bordercolor.secondary.action};

    &:hover {
      color: ${theme.button.fontcolor.secondary.hover};
      background: ${theme.button.bgcolor.secondary.hover};
      border: 0.2rem solid
        ${disabled
          ? theme.button.bordercolor.secondary.disabled
          : theme.button.bordercolor.secondary.hover};
    }

    &:active {
      color: ${theme.button.fontcolor.secondary.pressed};
      background: ${theme.button.bgcolor.secondary.pressed};
      border: 0.2rem solid
        ${disabled
          ? theme.button.bordercolor.secondary.disabled
          : theme.button.bordercolor.secondary.pressed};
    }

    &:disabled,
    a.disabled {
      color: ${theme.button.fontcolor.secondary.disabled};
      background: ${theme.button.bgcolor.secondary.disabled};
      border: 0.2rem solid ${theme.button.bordercolor.secondary.disabled};
    }
  }

  &.hollow {
    color: ${theme.button.fontcolor.hollow.action};
    background: ${theme.button.bgcolor.hollow.action};
    border: 0.2rem solid
      ${disabled
        ? theme.button.bordercolor.hollow.disabled
        : theme.button.bordercolor.hollow.action};

    &:hover {
      color: ${theme.button.fontcolor.hollow.hover};
      background: ${theme.button.bgcolor.hollow.hover};
      border: 0.2rem solid
        ${disabled
          ? theme.button.bordercolor.hollow.disabled
          : theme.button.bordercolor.hollow.hover};
    }

    &:active {
      color: ${theme.button.fontcolor.hollow.pressed};
      background: ${theme.button.bgcolor.hollow.pressed};
      border: 0.2rem solid
        ${disabled
          ? theme.button.bordercolor.hollow.disabled
          : theme.button.bordercolor.hollow.pressed};
    }

    &:disabled,
    a.disabled {
      color: ${theme.button.fontcolor.hollow.disabled};
      background: ${theme.button.bgcolor.hollow.disabled};
      border: 0.2rem solid ${theme.button.bordercolor.hollow.disabled};
    }

    a.disabled {
      border: none;
    }

    &.custom-color {
      color: ${(props) => props.color};
      background: transparent;
      border: 0.2rem solid ${(props) => props.color};

      &:hover {
        color: ${(props) => props.color};
        background: transparent;
        border: 0.2rem solid ${(props) => props.color};
      }

      &:active {
        color: ${(props) => props.color};
        background: transparent;
        border: 0.2rem solid ${(props) => props.color};
      }

      &:disabled,
      a.disabled {
        color: ${theme.button.fontcolor.secondary.disabled};
        background: ${theme.button.bgcolor.secondary.disabled};
        border: 0.2rem solid ${theme.button.bordercolor.secondary.disabled};
      }

      a.disabled {
        border: none;
      }
    }
  }

  &.tertiary {
    text-align: left;

    color: ${theme.colorPallete.white};
    background: transparent;
    border: 0.1rem solid
      ${disabled
        ? theme.button.bordercolor.hollow.disabled
        : theme.colorPallete.white};

    &:hover {
      color: ${theme.colorPallete.white};
      background: transparent;
      border: 0.1rem solid
        ${disabled
          ? theme.button.bordercolor.hollow.disabled
          : theme.colorPallete.white};
    }

    &:active {
      color: ${theme.colorPallete.white};
      background: transparent;
      border: 0.1rem solid
        ${disabled
          ? theme.button.bordercolor.hollow.disabled
          : theme.colorPallete.white};
    }

    &:disabled,
    a.disabled {
      color: ${theme.button.fontcolor.hollow.disabled};
      background: transparent;
      border: 0.1rem solid ${theme.button.bordercolor.hollow.disabled};
    }

    a.disabled {
      border: none;
    }

    a {
      justify-content: flex-start;

      & > * {
        display: inline-block;
        padding-left: 2rem;
        text-align: left;
      }
    }
  }

  &.bigger {
    height: 7rem;
    min-height: 7rem;
    max-height: 7rem;
  }

  &.big {
    height: 5rem;
    min-height: 5rem;
    max-height: 5rem;
  }

  &.medium {
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;
  }

  &.small {
    height: 3rem;
    min-height: 3rem;
    max-height: 3rem;
  }
`;

export default styles;
export { styles };
