import React from 'react';

interface Props {
  size: string;
  className?: string;
}

const IconCertificateCircle = React.memo<any>(({ size, className }: Props) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 74 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M36.507 46.3065C44.4259 46.3065 50.8455 39.8869 50.8455 31.9679C50.8455 24.049 44.4259 17.6294 36.507 17.6294C28.588 17.6294 22.1685 24.049 22.1685 31.9679C22.1685 39.8869 28.588 46.3065 36.507 46.3065Z"
        stroke="#858585"
        stroke-width="2.86771"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.5069 40.571C41.2583 40.571 45.1101 36.7192 45.1101 31.9679C45.1101 27.2165 41.2583 23.3647 36.5069 23.3647C31.7556 23.3647 27.9038 27.2165 27.9038 31.9679C27.9038 36.7192 31.7556 40.571 36.5069 40.571Z"
        stroke="#858585"
        stroke-width="2.86771"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.1101 43.4387V57.7773L36.5069 53.4757L27.9038 57.7773V43.4387"
        stroke="#858585"
        stroke-width="2.86771"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <circle
      opacity="0.5"
      cx="36.7408"
      cy="37.4698"
      r="35.3767"
      stroke="#858585"
      stroke-width="2.39032"
    />
  </svg>
));

export default IconCertificateCircle;
export { IconCertificateCircle };
