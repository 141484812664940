import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  padding-bottom: 36px;
  width: 100%;

  &.top-spacing {
    padding-top: 36px;
    padding-bottom: 0;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily.sans};
    font-weight: ${theme.fonts.fontWeight.bold};
    font-size: ${theme.fonts.fontSize.md};
    line-height: 24px;

    &.left-spacing {
      padding-inline: 24px;
    }
  `}
`;

export const ClearFilter = styled.button`
  ${({ theme }) => css`
    background: transparent;
    border: 1px solid ${theme.colorPallete.base[200]};
    border-radius: 4px;
    color: ${theme.colorPallete.base[100]};
    cursor: pointer;
    font-family: ${theme.fonts.fontFamily.sans};
    font-size: ${theme.fonts.fontSize.xs};
    padding: 4px 12px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid ${theme.colorPallete.base[100]};
    }
  `}
`;

export const FilteredItems = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    &.left-spacing {
      padding-inline: 24px;

      @media (max-width: 768px) {
        padding-inline: 48px;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-inline: -24px;
      padding-inline: 24px;

      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      align-items: center;
      border: 1px solid ${theme.colorPallete.base[400]};
      border-radius: 100px;
      cursor: pointer;
      display: flex;
      font-family: ${theme.fonts.fontFamily.sans};
      font-weight: ${theme.fonts.fontWeight.bold};
      font-size: ${theme.fonts.fontSize.md};
      list-style: none;
      transition: all 0.3s;
      padding: 4px 24px;
      white-space: nowrap;

      &:hover {
        border: 1px solid ${theme.colorPallete.base[200]};
      }

      &.active {
        background-color: ${theme.colorPallete.base[400]};
        border: none;
      }
    }
  `}
`;
